import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserData } from "@api/account";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { combineLatest, Observable, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { ExpatData } from "@models/expat";
import { UsersService } from "src/app/_services/users.service";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-expat-selector",
  templateUrl: "./expat-selector.component.html",
})
export class ExpatSelectorComponent implements OnInit {
  caseForm: UntypedFormGroup;

  @Input() submitEvent: Observable<void>;

  expatControl: UntypedFormControl;
  expatSearchFrom: UntypedFormControl;
  expats$: Observable<ExpatData[]>;
  filteredExpats$: Observable<ExpatData[]>;
  currentUser$: Observable<UserData>;

  compareFn = (e1, e2) => e1 && e2 && e1.id === e2.id;

  constructor(
    private readonly userService: UsersService,
    private controlContainer: ControlContainer,
    public readonly expatService: ExpatsService
  ) {
    this.expatControl = new UntypedFormControl("", Validators.required);
    this.expatSearchFrom = new UntypedFormControl("");
  }

  ngOnInit() {
    this.submitEvent.subscribe(() => {
      if (!this.expatControl.valid) {
        this.expatControl.setErrors({ required: true });
        this.expatControl.markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;

    this.caseForm.setControl(
      "expat_name",
      new UntypedFormControl("", Validators.required)
    );
    this.expatControl.valueChanges.subscribe((expat: ExpatData) => {
      this.caseForm.patchValue({
        expat_id: expat.id,
        expat_name: expat.name,
      });
    });
    this.expatControl.disable({ emitEvent: false });
    this.currentUser$ = this.userService.getCurrentUser();

    this.expats$ = combineLatest([
      this.caseForm.get("client_id").valueChanges,
      this.expatSearchFrom.valueChanges.pipe(startWith("")),
      this.currentUser$,
      of(this.caseForm.get("expat_id").value),
    ]).pipe(
      distinctUntilChanged(
        (
          [prevClientId, prevSearchInput, , prevExpatId],
          [clientId, searchInput, , expatId]
        ) =>
          prevClientId === clientId &&
          prevSearchInput === searchInput &&
          prevExpatId === expatId
      ),
      debounceTime(300),
      tap(() => this.expatControl.disable({ emitEvent: false })),
      switchMap(([clientId, searchInput, user, expatId]) => {
        const params = {
          search_text: searchInput,
          per_page: 20,
        };
        if (expatId) {
          params["expat_ids"] = expatId;
        }
        return this.expatService.list(user.entity_id, clientId, params);
      }),
      map((response) => response.result.items),
      tap(() => this.expatControl.enable({ emitEvent: false })),
      shareReplay(1)
    );
    if (this.caseForm.get("expat_id").value) {
      this.expats$
        .pipe(
          take(1),
          map((expats) =>
            expats.find(({ id }) => id == this.caseForm.get("expat_id").value)
          ),
          filterNullish()
        )
        .subscribe((expat) => {
          this.expatControl.setValue(expat);
          if (expat) {
            this.expatControl.disable({ emitEvent: false });
          }
        });
    }
    this.filteredExpats$ = combineLatest([
      this.expats$,
      this.expatControl.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([expats, selectedExpat]) =>
        expats.filter((expat) => !this.compareFn(expat, selectedExpat))
      )
    );
  }

  get selectedExpat() {
    return this.expatControl.value;
  }
}
