<div class="create-contact dialog p-0">
  <app-dialog-header>
    {{ (contact?.id ? "CONTACT.EDIT" : "CONTACT.CREATE") | translate }}
  </app-dialog-header>
  <mat-dialog-content class="dialog-body">
    <app-contact-edit
      [contact]="contact"
      [contactable]="contactable"
      (changes)="onChange($event)"
    ></app-contact-edit>
  </mat-dialog-content>
  <div class="dialog-footer">
    <button
      [disabled]="(canSubmit$ | async) === false"
      (click)="submit()"
      class="btn-primary"
    >
      <mat-icon class="btn-icon" svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
