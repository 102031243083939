import { HttpContext } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { UserData } from "@api/account";
import { DISPLAY_API_ERRORS } from "@helpers/error.interceptor";
import { ExpatData } from "@models/expat";
import { StatusType } from "@modules/expats/models/citizenship";
import { of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { UsersService } from "../../../../../_services/users.service";
import { ExpatEmploymentDetailsComponent } from "../../../components/expat-employment-details/expat-employment-details.component";
import { ExpatsService } from "../../../services/expats.service";

@Component({
  selector: "app-expat-employment-details-card",
  templateUrl: "./expat-employment-details-card.component.html",
  styleUrls: ["./expat-employment-details-card.component.scss"],
})
export class ExpatEmploymentDetailsCardComponent implements OnInit {
  @Input()
  public expat!: ExpatData;

  @Output()
  public readonly getNewExpat = new EventEmitter();

  public managers: UserData[];

  today = new Date();

  public office!: string;

  constructor(
    public dialog: MatDialog,
    private expatService: ExpatsService,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute
  ) {}

  public handleEdit() {
    const dialogRef = this.dialog.open(ExpatEmploymentDetailsComponent, {
      data: {
        expat: this.expat,
        managers: this.managers,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.action === "save") {
          this.saveData(data.expat);
        }
      });
  }

  private getManagers() {
    const params: object = {};
    params["entity_id"] = this.clientId;
    params["status_id"] = StatusType.ACTIVE;
    this.userService
      .list(params, new HttpContext().set(DISPLAY_API_ERRORS, false))
      .pipe(
        map((data) => data?.result?.items ?? []),
        catchError(() => of([]))
      )
      .subscribe((items: UserData[]) => {
        this.managers = items;
      });
  }

  public ngOnInit() {
    this.getManagers();
  }

  private saveData(expat: ExpatData) {
    this.expatService.updateExpat(expat).subscribe(() => {
      this.getNewExpat.emit();
    });
  }

  private get clientId() {
    return +this.activatedRoute.snapshot.params.clientId;
  }
}
