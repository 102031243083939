import { Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { QuoteModel } from "@modules/quotes/models/quote.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from "rxjs";
export interface CartItem {
  id: number;
  name: string;
  quantity: number;
  unit_price: number;
  currency: string;
  price: number;
}
export type ToFormGroupType<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K] | null>;
}>;

export type CartItemForm = ToFormGroupType<CartItem>;

@Component({
  selector: "app-items-step",
  templateUrl: "./items-step.component.html",
  styleUrls: ["./items-step.component.scss"],
})
export class ItemsStepComponent implements OnInit {
  @Input()
  clientId: number;

  @Input()
  providerId: number;

  @Input()
  itemsForm: FormArray<CartItemForm>;

  @Input()
  selectedItem$ = new BehaviorSubject<QuoteModel | null>(null);

  @Input()
  initialQuotes: QuoteModel[] = [];

  autocompleteControl = new FormControl();

  items$: Observable<QuoteModel[]>;

  quoteService = inject(QuotesService);

  total$: Observable<{
    amount: number;
    currency: string;
  }>;

  displayFn = (item) => {
    return item?.service_name ?? null;
  };

  ngOnInit() {
    this.initialQuotes.forEach((quote) => {
      this.addItem(quote);
    });

    const currentItems$ = this.itemsForm.valueChanges.pipe(
      startWith(this.itemsForm.value),
      map((items) => items.map((item) => item.id)),
      distinctUntilChanged(
        (a, b) => a?.length === b?.length && a.every((v, i) => v === b[i])
      )
    );
    const autocompleteSearch$ = this.autocompleteControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300)
    );
    this.items$ = combineLatest([currentItems$, autocompleteSearch$]).pipe(
      debounceTime(100),
      tap(([, value]) => {
        if (value?.id !== this.selectedItem$.value?.id) {
          // clear value if we type something in the autocomplete
          this.selectedItem$.next(null);
        }
      }),
      switchMap(([items, value]) =>
        this.quoteService.list(this.providerId, this.clientId, {
          search_text: value,
          availability: "available",
          not_id: items,
          limit: 10,
        })
      ),
      map((page) => page.items),
      shareReplay(1)
    );

    this.total$ = this.itemsForm.valueChanges.pipe(
      startWith(this.itemsForm.value),
      map((items) => {
        return items.reduce(
          (acc: any, item) => {
            return {
              amount: acc.amount + item.price,
              currency: item.currency,
            };
          },
          { amount: 0, currency: items[0]?.currency ?? "EUR" }
        );
      })
    );
  }

  addItem(item: QuoteModel) {
    this.autocompleteControl.setValue("");
    if (item.id) {
      const existingItem = this.itemsForm.controls.find(
        (control) => control.value.id === item.id
      );
      if (existingItem) {
        existingItem
          .get("quantity")
          .setValue(existingItem.get("quantity").value + 1);
        return;
      }
    }
    const fg: CartItemForm = new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.service_name),
      quantity: new FormControl(1),
      unit_price: new FormControl(item.unit_price),
      currency: new FormControl(item.currency_code),
      price: new FormControl(item.unit_price),
    });
    this.itemsForm.insert(0, fg);
    fg.controls.quantity.valueChanges.subscribe((quantity) => {
      const index = this.itemsForm.controls.indexOf(fg);
      fg.controls.price.setValue(quantity * item.unit_price);
      if (quantity === 0) {
        this.itemsForm.removeAt(index);
      }
    });
    this.selectedItem$.next(null);
  }
  get itemControls() {
    return this.itemsForm.controls;
  }
  selectItem(item) {
    this.selectedItem$.next(item);
  }
}
