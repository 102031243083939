import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportCardComponent } from "./report-card/report-card.component";
import { SharedModule } from "@modules/shared/shared.module";
import { CasesReportCardComponent } from "./cases-report-card/cases-report-card.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { CaseStatusReportComponent } from "./components/case-status-report/case-status-report.component";
import { EstimatedVsActualDurationComponent } from "./components/estimated-vs-actual-duration/estimated-vs-actual-duration.component";
import { AverageProcessDurationComponent } from "./components/average-process-duration/average-process-duration.component";
import { OnTimeVsOverdueCasesComponent } from "./components/ontime-vs-overdue-cases/on-time-vs-overdue-cases.component";

@NgModule({
  declarations: [
    ReportCardComponent,
    CasesReportCardComponent,
    CaseStatusReportComponent,
    EstimatedVsActualDurationComponent,
    AverageProcessDurationComponent,
    OnTimeVsOverdueCasesComponent,
  ],
  exports: [
    ReportCardComponent,
    CasesReportCardComponent,
    CaseStatusReportComponent,
    EstimatedVsActualDurationComponent,
    AverageProcessDurationComponent,
    OnTimeVsOverdueCasesComponent,
  ],
  imports: [CommonModule, SharedModule, NgApexchartsModule],
})
export class ReportsSharedModule {}
