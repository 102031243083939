import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { UserData } from "@api/account";
import { OrderData, OrderManagerData, OrderServiceData } from "@api/billing";
import { environment } from "@environment/environment";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import * as moment from "moment";
import {
  Observable,
  Subject,
  catchError,
  debounceTime,
  finalize,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from "rxjs";
import { AlertService } from "@modules/alert";
import { OrderStatusEnum } from "../../../../_enums/order-status-enum";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { CrmService } from "../../../../_services/crm.service";
import { OrdersService } from "../../../../_services/orders.service";
import { UsersService } from "../../../../_services/users.service";
import { AssignExpatDialogComponent } from "../assign-expat-dialog/assign-expat-dialog.component";
import { ClientDetailsModalComponent } from "../client-details/client-details";
import { OrderHelper } from "@helpers/_models/order.helper";
import { OrderStatuses } from "../../../../_models/_statuses/order-statuses";
import { CreateServiceComponent } from "@modules/order/components/create-service/create-service.component";
import { FormControl } from "@angular/forms";
import { ClientsService } from "@api/clients/services";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public loadingStatuses = false;
  public order: OrderData;
  public total = 0;
  public currency_code = "USD";
  public statuses;
  client;
  billingDetails;
  entity;
  public editArrivingDate;
  marketUrl;
  expat;
  minDate: Date;
  rating;
  orderIdentityNo;
  public orderStatuses = new OrderStatuses();

  private id;
  private user$: Observable<UserData>;
  private destroyed$: Subject<void> = new Subject();

  showEditHrContact: boolean = false;
  userSearchControl: FormControl = new FormControl("");
  users$: Observable<UserData[]>;
  currentOrderManager: OrderManagerData | null;
  clientService = inject(ClientsService);

  constructor(
    private orderService: OrdersService,
    private crmService: CrmService,
    private userService: UsersService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService,
    protected helper: OrderHelper
  ) {
    this.marketUrl = environment.marketplace;
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.user$ = this.userService.getCurrentUser();
    this.getOrderById(this.id);

    this.minDate = new Date(this.addDayToDate(new Date(), 1));
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  public caseCreated(): void {
    this.getOrderById(this.id);
  }

  viewInCRM() {
    this.user$
      .pipe(
        take(1),
        switchMap((user: UserData) =>
          this.crmService.goToOrder(
            this.order.id,
            user.email,
            user.entity_details.company.id
          )
        )
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res.success && res.result.url) {
            window.open(res.result.url);
          }
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.stringError(error.error?.errors[0]["message"]);
          }
        },
      });
  }

  public assignExpat(offer: OrderServiceData): void {
    const data = {
      order: this.order,
      offer: offer,
    };
    const dialogRef = this.dialog.open(AssignExpatDialogComponent, {
      data,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res) {
          this.getOrderById(this.orderIdentityNo);
        }
      });
  }

  getOrderById(id) {
    this.isLoading = true;
    const params = {};

    this.user$
      .pipe(
        take(1),
        switchMap((user: UserData) => {
          params["entity_id"] = user.id;

          return this.orderService.getOrderByIdentityNumber(params, id);
        })
      )
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res) => {
        if (res) {
          this.order = res.result;
          this.currentOrderManager = this.order?.manager;
          this.breadcrumbService.changeBreadcrumbs(
            this.breadcrumbService.setForOrder(this.order, "details")
          );
          this.billingDetails = res.result.billing;
          this.total = res.result.amount;
          this.currency_code = res.result.currency_code;
          this.editArrivingDate = null;
          this.orderIdentityNo = this.order.details[0].order_identity_number;

          this.getStatusTransitions();

          if (
            this.order.entity_type === "expat" &&
            this.order.status_id !== OrderStatusEnum.NEW
          ) {
            this.getExpatById(this.order.entity_id);
          }

          if (this.order.status_id === OrderStatusEnum.FINALIZED) {
            this.getRatingActivity();
          }
        }
      });
  }

  public getStatusTransitions() {
    this.loadingStatuses = true;

    this.orderService
      .getStatusTransitions()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.loadingStatuses = false))
      )
      .subscribe((data) => {
        if (data.success) {
          this.statuses = data.result[this.order.status_id];
        }
      });
  }

  openConfirmAction(action) {
    const ids = [this.order.id];

    if (action.needs_confirmation || action.needs_reason) {
      this.dialog
        .open(ConfirmActionModalComponent, {
          data: {
            action: action,
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((result) => {
          if (result) {
            this.orderService
              .updateStatusBulk(action.id, ids, result)
              .pipe(takeUntil(this.destroyed$))
              .subscribe({
                next: () => {
                  this.getOrderById(this.id);
                },
                error: (error) => {
                  if (error?.error?.errors) {
                    this.alertService.errors(error.error.errors);
                  }
                },
              });
          }
        });
    } else {
      this.orderService
        .updateStatusBulk(action.id, ids, null)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.getOrderById(this.id);
        });
    }
  }

  clientDetailsModal() {
    this.dialog.open(ClientDetailsModalComponent, {
      data: this.billingDetails,
    });
  }

  getExpatById(id) {
    this.orderService
      .getExpatById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.expat = data.result;
      });
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }

  getRatingActivity() {
    const params = {};
    params["order_identity_number"] = this.orderIdentityNo;

    this.user$
      .pipe(
        take(1),
        switchMap((user: UserData) =>
          this.orderService.getRatingActivity(
            user.entity_details.entity_id,
            params
          )
        )
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.result.length > 0) {
          this.rating = res.result;
        }
      });
  }

  openCreateServiceModal() {
    this.dialog
      .open(CreateServiceComponent, {
        data: {
          order: this.order,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.order = result;
        }
      });
  }

  initHrUserSearchControl() {
    this.users$ = this.userSearchControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap((value) =>
        this.clientService
          .getUsers(this.order.client.id, {
            search_text: value,
            only_hr_users: true,
          })
          .pipe(catchError(() => of([])))
      ),
      map((users) => {
        if (this.currentOrderManager) {
          const doesNotExist = !users.some(
            (user) => user.id === this.currentOrderManager.id
          );

          if (doesNotExist) {
            return [this.currentOrderManager, ...users];
          }
        }
        return users;
      }),
      shareReplay(1)
    );
  }

  updateOrderContact(event: any) {
    this.orderService.updateOrderContact(this.order.id, event.id).subscribe({
      next: (resp) => {
        const order = resp.result;

        this.order.manager = order.manager;

        if (resp && resp.message) {
          this.alertService.success(resp.message);
        }

        this.showEditHrContact = false;
        this.currentOrderManager = order.manager;
      },
      error: (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      },
    });
  }

  toggleEditHrContat() {
    this.showEditHrContact = !this.showEditHrContact;

    if (this.showEditHrContact && !this.users$) {
      this.initHrUserSearchControl();
    }
  }

  hrContactChanged(event: any) {
    this.currentOrderManager = event;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
