import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AccountActions } from "@api/account";
import { AuthActions } from "@api/authentication";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap, tap } from "rxjs";
import { AuthenticationService } from "./_services/authentication.service";

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  onAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authorized),
      mergeMap(() => [AccountActions.loadAccount()])
    );
  });

  unauthenticated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.unauthenticated, AuthActions.logout),
        tap(() => {
          try {
            //cancel all dialogs
          } catch (e) {
            /* empty */
          }
          this.authenticationService.removeLocalStorage();
          this.router.navigate(["/login"], {
            queryParams: { returnUrl: window.location.pathname },
          });
        }),
        mergeMap(() => [AccountActions.clear()])
      );
    },
    { dispatch: false } //remove this after we add account actions
  );
}
