import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { PermissionActionData } from "@api/users/models/permission.model";
import { PermissionMapperService } from "@api/users/services/permission-mapper.service";
import { forkJoin } from "rxjs";
import { UserStatus } from "@models/_statuses/user-statuses";
import { AlertService } from "@modules/alert";
import { UsersService } from "../../../../_services/users.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-add-permission-form",
  templateUrl: "./add-permission-form.component.html",
  styleUrls: ["./add-permission-form.component.scss"],
})
export class AddPermissionFormComponent implements OnInit {
  isLoading = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  permissionsForm: FormGroup;
  permissions: PermissionActionData[] = [];
  users: UserData[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      providerId: number;
      resourceType: string;
      resourceId: number;
    },
    public dialogRef: MatDialogRef<AddPermissionFormComponent>,
    private userService: UsersService,
    private permissionsMapperService: PermissionMapperService,
    private fb: FormBuilder,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.setupForm();

    forkJoin(
      [
        this.userService.getAvailableCustomPermissions(this.data.resourceType),
        this.userService.list({ status_id: UserStatus.active }),
      ],
      (customPermissions, users) => {
        return { customPermissions, users };
      }
    ).subscribe(
      (data: any) => {
        this.permissions = data.customPermissions.result;
        this.users = data.users.result.items;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;

        if (error.errors) {
          this.alertService.errors(error.errors);
        }
      }
    );
  }

  private setupForm(): void {
    this.permissionsForm = this.fb.group({
      permissions: ["", Validators.required],
      users: ["", Validators.required],
    });
  }

  save(): void {
    this.permissionsForm.markAllAsTouched();

    if (this.permissionsForm.invalid) {
      return;
    }

    this.dialogRef.close({
      permissions: this.permissionsForm.get("permissions").value,
      users: this.permissionsMapperService.prepareModels(
        this.permissionsForm.get("users").value
      ),
    });
  }

  public cancel() {
    this.dialogRef.close();
  }

  onPermissionRemoved(permission): void {
    const permissions = this.permissionsForm.get("permissions")
      .value as string[];
    this.removeFirst(permissions, permission);
    this.permissionsForm.get("permissions").setValue(permissions);
  }

  onModelRemoved(model): void {
    const users = this.permissionsForm.get("users").value as string[];

    this.removeFirst(users, model);
    this.permissionsForm.get("users").setValue(users);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
