import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "phoneNumber",
})
export class PhoneNumberPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(phonePrefix?: string, phoneNumber?: string): string {
    const formattedPhone = [phonePrefix, phoneNumber].join(" ");

    return formattedPhone || this.translate.instant("N/A");
  }
}
