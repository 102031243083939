import { Component, OnInit, Input } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { Observable, map, switchMap, startWith } from "rxjs";
import { Country } from "@models/country";

@Component({
  selector: "app-country-select",
  templateUrl: "./country-selector.component.html",
  styleUrls: ["./country-selector.component.scss"],
})
export class CountrySelectorComponent implements OnInit {
  public documentForm: UntypedFormGroup;
  public countryControl: UntypedFormControl;

  public countrySearchControl: UntypedFormControl = new UntypedFormControl("");

  public countries$: Observable<Country[]>;

  @Input() public value?: string;

  constructor(
    private controlContainer: ControlContainer,
    private dictionariesService: DictionariesService
  ) {
    this.countryControl = new UntypedFormControl("");
  }

  ngOnInit(): void {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("country_code", this.countryControl);

    if (this.value) {
      this.documentForm.get("country_code").setValue(this.value);
    }

    this.countries$ = this.countrySearchControl.valueChanges.pipe(
      startWith(""),
      switchMap((value: string) =>
        this.dictionariesService
          .getCountryList()
          .pipe(
            map((data) =>
              data.result.filter((country: Country) =>
                country.name
                  .toLocaleLowerCase()
                  .includes(value.toLocaleLowerCase())
              )
            )
          )
      )
    );
  }
}
