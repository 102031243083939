import { PageData } from "./../../shared/models/page.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { Observable, map } from "rxjs";
import { ChartServerData } from "../reports-shared/models/chart-server-data.model";
import { shareReplay } from "rxjs/operators";
import { CaseService } from "@api/cases";
import { CaseReportsParams } from "../reports-shared/models/case-reports-params.model";

@Injectable({
  providedIn: "root",
})
export class ReportsCaseService {
  public casesByStatus$: Observable<ChartServerData[]>;
  public finishedCases$: Observable<ChartServerData[]>;
  public caseServices$: Observable<PageData<CaseService>>;

  constructor(private http: HttpClient) {}

  getCasesStatistics(
    params: {
      start_end_date_range: string;
    },
    reload = false
  ): Observable<ChartServerData[]> {
    if (reload || !this.casesByStatus$) {
      this.casesByStatus$ = this.http
        .get<{ result: PageData<ChartServerData>; success: boolean }>(
          environment.gateway_endpoint + "statistics/cases/by-status",
          {
            params: params,
          }
        )
        .pipe(
          map((data) => data.result.items),
          shareReplay(1)
        );
    }
    return this.casesByStatus$;
  }

  getFinishedCases(
    params: {
      start_end_date_range: string;
    },
    reload = false
  ): Observable<ChartServerData[]> {
    if (reload || !this.finishedCases$) {
      this.finishedCases$ = this.http
        .get<{ result: PageData<ChartServerData>; success: boolean }>(
          environment.gateway_endpoint + "statistics/cases/finalized",
          {
            params: params,
          }
        )
        .pipe(
          map((data) => data.result.items),
          shareReplay(1)
        );
    }

    return this.finishedCases$;
  }

  getEstimatedActualProcessDuration(
    params: CaseReportsParams
  ): Observable<ChartServerData[]> {
    return this.http
      .get<{ result: PageData<ChartServerData>; success: boolean }>(
        environment.gateway_endpoint + "reports/average-process-duration",
        {
          params: params,
        }
      )
      .pipe(map((data) => data.result.items));
  }

  getServices(
    params: {
      page: number;
      search_text: string;
      country: string;
      status: string;
    },
    reload = false
  ): Observable<PageData<CaseService>> {
    if (reload || !this.caseServices$) {
      this.caseServices$ = this.http
        .get<{ result: PageData<CaseService>; success: boolean }>(
          environment.gateway_endpoint + "cases/services",
          {
            params,
          }
        )
        .pipe(
          map((data) => data.result),
          shareReplay(1)
        );
    }

    return this.caseServices$;
  }

  getFilters(): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/cases/filters", {})
      .pipe(map((data) => data.result));
  }
}
