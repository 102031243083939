import {
  Component,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { ColumnDefinition } from "../../../../../_interfaces/column-definition.interface";
import { Store } from "@ngrx/store";
import { TableColumnSettingsActions } from "../state/table-column-settings.actions";

@Component({
  selector: "app-column-selection",
  templateUrl: "./column-selection.component.html",
  styleUrls: ["./column-selection.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnSelectionComponent {
  @Input() columns: ColumnDefinition[];
  @Input() page: string;

  @ViewChild("selection") selection: MatSelectionList;

  constructor(private store: Store) {}

  applyChanges(): void {
    this.updateColumnVisibility();

    if (this.page) {
      const selectedColumns = this.getSelectedColumns();

      this.store.dispatch(
        TableColumnSettingsActions.updateSelectedColumns({
          page: this.page,
          selectedColumns,
        })
      );
    }
  }

  private updateColumnVisibility(): void {
    this.columns.forEach((column) => {
      column.visible = this.isColumnSelected(column.name);
    });
  }

  private isColumnSelected(columnName: string): boolean {
    return this.selection.selectedOptions.selected.some(
      (option: MatListOption) => option.value === columnName
    );
  }

  private getSelectedColumns(): ColumnDefinition[] {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => ({
        ...column,
        visible: true,
      }));
  }
}
