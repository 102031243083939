import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaseTasksService } from "@api/case-tasks";
import { DocumentService } from "@api/documents/services/documents.service";
import { ReminderData } from "@api/reminders/models/reminder.model";
import { RemindersService } from "@api/reminders/services/reminders.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable, combineLatest, finalize, tap } from "rxjs";
import { SubjectStatusEnum } from "src/app/_enums/subject-status-enum";
import { SubjectTypeEnum } from "src/app/_enums/subject-type-enum";
import { AlertService } from "src/app/_services/alert.service";
import { AuthenticationService } from "src/app/_services/authentication.service";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateComponent implements OnInit {
  isLoading = false;

  public remindersForm: UntypedFormGroup;

  public users;

  private user;

  public selectedUsers: any;

  private users$: Observable<any>;

  constructor(
    private fb: UntypedFormBuilder,
    private remindersService: RemindersService,
    protected dialogRef: MatDialogRef<CreateComponent>,
    private authService: AuthenticationService,
    private caseTasksService: CaseTasksService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentService: DocumentService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
    this.users$ = this.getEligibleUsers();

    if (this.data.edit) {
      this.setupForm(this.data.reminder);

      return;
    }

    this.setupForm();
  }

  private getEligibleUsers(): Observable<any> {
    if (this.data.subjectType === "task") {
      return this.caseTasksService
        .getEligibleUsers(
          this.data.eligibleUsersEntity.expat_case_id,
          this.data.eligibleUsersEntity.id
        )
        .pipe(
          tap((response: any) => {
            this.users = response;
            // this.selectedUsers = this.users;
            if (!this.data.edit) {
              this.selfAssign();
            }
          })
        );
    }

    return this.documentService
      .eligibleUsers(this.data.eligibleUsersEntity, { include_self: 1 })
      .pipe(
        tap((response: any) => {
          this.users = response.result;
          if (!this.data.edit) {
            this.selfAssign();
          }
        })
      );
  }
  private selfAssign() {
    this.remindersForm.patchValue({
      subscribers: [this.user.id],
    });

    const user = this.users.find((user) => user.id === this.user.id);

    this.selectedUsers = [user];
  }

  public removeSelectedUser(event, item: any) {
    event.stopPropagation();

    this.selectedUsers = this.selectedUsers.filter(
      (selectedItem) => item.id !== selectedItem.id
    );

    this.remindersForm
      .get("subscribers")
      .setValue(this.selectedUsers.map((user) => user.id));
  }

  private setupForm(reminder?: ReminderData): void {
    this.remindersForm = this.fb.group({
      text: this.fb.control("", Validators.required),
      subscribers: this.fb.control([], Validators.required),
      visibility: this.fb.control("public", Validators.required),
      remind_at: this.fb.control(
        moment().add(1, "day").startOf("day").hour(12).minute(0),
        Validators.required
      ),
    });

    combineLatest([
      this.users$,
      this.remindersForm.get("subscribers").valueChanges,
    ]).subscribe(([users, typedUsers]) => {
      const selectedUsers = [];

      typedUsers.forEach((id) => {
        const user = this.users.find((user) => user.id === id);

        selectedUsers.push(user);
      });

      this.selectedUsers = selectedUsers;
    });

    if (reminder) {
      const date = moment(reminder.remind_at).local();

      this.remindersForm.patchValue({
        text: reminder.text,
        subscribers: reminder.subscribers.map((subscriber) => subscriber.id),
        visibility: reminder.visibility,
        remind_at: date.clone(),
      });
    }
  }

  public submit(): void {
    if (!this.remindersForm.valid) {
      return;
    }

    const data = this.remindersForm.getRawValue();
    const remindedAt =
      this.data.subjectStatus &&
      this.data.subjectStatus === SubjectStatusEnum.DONE &&
      this.data.subjectType === SubjectTypeEnum.TASK
        ? moment().local()
        : null;

    const body = {
      ...data,
      subscribers: data.subscribers.map((id) => ({
        id,
      })),
      subject_type: this.data.subjectType,
      subject_id: this.data.subjectId,
      ...(remindedAt ? { reminded_at: remindedAt } : {}),
    };

    this.isLoading = true;

    this.updateOrCreate(body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close("saved");

          this.alertService.success(
            this.translateService.instant("GENERALS.SUCCESS")
          );
        },
        error: (response) => {
          this.alertService.errors(response?.error.errors);
        },
      });
  }

  private updateOrCreate(data) {
    if (this.data.edit) {
      return this.remindersService.edit(this.data.reminder.id, data);
    }
    return this.remindersService.create(data);
  }

  private getCurrentUser(): void {
    this.authService.currentUser.subscribe((user: any) => {
      this.user = user;
    });
  }

  onDateChange(date: any): void {
    if (moment(date).isValid()) {
      this.remindersForm.get("date").setValue(moment(date));
    }
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
