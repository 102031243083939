import { Component, Inject } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DocumentService } from "@api/documents/services/documents.service";
import { AlertService } from "@modules/alert";
import { finalize } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-share",
  templateUrl: "./share.component.html",
  styleUrls: ["./share.component.scss"],
})
export class ShareComponent {
  documentForm: UntypedFormGroup;
  isLoading = false;

  constructor(
    private documentService: DocumentService,
    private alertService: AlertService,
    protected dialogRef: MatDialogRef<ShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
  ) {
    this.documentForm = new UntypedFormGroup({});
  }

  submit() {
    this.documentForm.markAllAsTouched();
    if (!this.documentForm.valid) {
      return;
    }

    const data = this.documentForm.getRawValue();

    data.entities = data.entities.map(({ entity_id, entity_type }) => ({
      entity_id,
      entity_type,
    }));

    this.isLoading = true;

    data.document_ids = Array.isArray(this.data.documentIds)
      ? this.data.documentIds
      : [this.data.documentIds];

    this.documentService
      .share(data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (documentData) => {
          this.dialogRef.close({
            type: "saved",
            documentData,
          });
        },
        () => {
          this.alertService.stringError(
            this.translate.instant("GENERALS.SHARE-DOCUMENT-ERROR")
          );
          return;
        }
      );
  }

  close() {
    this.dialogRef.close();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
