import * as enabledPages from "./enabled-pages";

const stringToBoolean = (stringValue): boolean => {
  switch (`${stringValue}`?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false;

    default:
      return stringValue;
  }
};

const environment = {
  production: stringToBoolean(process.env.NG_PRODUCTION),
  gateway_endpoint: process.env.NG_GATEWAY_ENDPOINT,
  client_id: process.env.NG_CLIENT_ID,
  client_secret: process.env.NG_CLIENT_SECRET,
  app_host: process.env.NG_APP_HOST,
  user_local_storage_key: process.env.NG_USER_LOCAL_STORAGE_KEY,
  entity_local_storage_key: process.env.NG_ENTITY_LOCAL_STORAGE_KEY,
  last_status_timestamp: process.env.NG_LAST_STATUS_TIMESTAMP,
  lang_cookie_key: process.env.NG_LANG_COOKIE_KEY,
  cookieconsent: process.env.NG_COOKIECONSENT,
  session_local_storage_key: process.env.NG_SESSION_LOCAL_STORAGE_KEY,
  marketplace: process.env.NG_MARKETPLACE,
  admin_portal: process.env.NG_ADMIN_PORTAL,
  role: process.env.NG_ROLE,
  token_key: process.env.NG_TOKEN_KEY,
  domain: process.env.NG_DOMAIN,
  unit_id: process.env.NG_UNIT_ID,
  pusher_key: process.env.NG_PUSHER_KEY,
  wshost: process.env.NG_WSHOST,
  wsport: process.env.NG_WSPORT,
  google_tracking_id: process.env.NG_GOOGLE_TRACKING_ID,
  stripe_public_key: process.env.NG_STRIPE_PUBLIC_KEY,
  stripe_portal_login: process.env.NG_STRIPE_PORTAL_LOGIN,
  aito_api_key: process.env.NG_AITO_API_KEY,
  aito_api_url: process.env.NG_AITO_API_URL,
  document_ai_api: process.env.NG_DOCUMENT_AI_API,
  unreleased_features: stringToBoolean(process.env.NG_UNRELEASED_FEATURES),
  has_crm: stringToBoolean(process.env.NG_HAS_CRM),
  has_marketplace: stringToBoolean(process.env.NG_HAS_MARKETPLACE),
  enabled_pages: process.env.NG_ENABLED_PAGES
    ? enabledPages[process.env.NG_ENABLED_PAGES]
    : undefined,
  recaptcha_site_key: process.env.RECAPTCHA_SITE_KEY,
  show_recaptcha: stringToBoolean(process.env.SHOW_RECAPTCHA),
};
export const processEnvironment = Object.keys(environment).reduce(
  (result, key) => {
    if (environment[key] !== null && environment[key] !== undefined) {
      result[key] = environment[key];
    }
    return result;
  },
  {}
);
