<app-dialog-header>
  {{ "MILESTONES.MILESTONE-REPORTS-CONFIG" | translate }}
</app-dialog-header>
<app-loader-spinner-general
  *ngIf="isLoaded"
  [loadingType]="LoadingType.INFO"
></app-loader-spinner-general>
<app-loader-spinner-general
  *ngIf="isLoaded"
  class="flex justify-end"
  [loadingType]="LoadingType.BUTTONS"
  [repeat]="2"
></app-loader-spinner-general>
<div class="dialog-body" *ngIf="!isLoaded">
  <mat-form-field class="w-full mb-4" *ngIf="saveButton" appearance="outline">
    <mat-label> {{ "LABEL.NAME" | translate }}</mat-label>
    <input matInput [formControl]="reportNameCtrl" type="text" />
    <mat-error [formControlError]="reportNameCtrl"></mat-error>
    <mat-hint>
      {{ "MILESTONES.REPORTS.CONFIGURATION.NAME-HINT" | translate }}
    </mat-hint>
  </mat-form-field>
  <div class="flex w-full justify-between">
    <div class="show-estimate">
      <mat-checkbox [formControl]="showDatesCtrl">
        {{ "MILESTONES.ESTIMATED-END-DATE" | translate }}
      </mat-checkbox>
    </div>
    <div>
      <button
        [disabled]="!this.checklistSelection.hasValue()"
        mat-dialog-title
        class="clear-filters {{
          !this.checklistSelection.hasValue() ? 'disable_clear_filter' : ''
        }}"
        (click)="clearFilters()"
      >
        <mat-icon class="text-sm">close</mat-icon>
        {{ "LABEL.CLEAR-ALL-COLUMNS" | translate }}
      </button>
    </div>
  </div>
  <cdk-accordion>
    <cdk-accordion-item
      #accordionBasicItem="cdkAccordionItem"
      class="block mb-4"
      expanded
    >
      <button
        class="d-flex items-center"
        (click)="accordionBasicItem.toggle()"
        tabindex="0"
        [ngClass]="{ 'text-primary': accordionBasicItem.expanded }"
        [attr.id]="'accordion-header-basic'"
        [attr.aria-expanded]="accordionBasicItem.expanded"
        [attr.aria-controls]="'accordion-body-basic'"
      >
        <span class="text-base">
          {{ "MILESTONES.REPORTS.CONFIGURATION.BASE-COLUMNS" | translate }}
        </span>
        <mat-icon>
          {{ accordionBasicItem.expanded ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-selection-list
        class="flex flex-wrap"
        [style.display]="accordionBasicItem.expanded ? '' : 'none'"
      >
        <mat-list-option
          class="!inline-flex !w-auto"
          [attr.id]="'accordion-body-basic'"
          [attr.aria-labelledby]="'accordion-header-basic'"
          *ngFor="let item of baseColumns"
          [selected]="true"
          [disabled]="true"
        >
          {{ item.label }}
        </mat-list-option>
      </mat-selection-list>
    </cdk-accordion-item>
    <cdk-accordion-item
      #accordionAdvancedItem="cdkAccordionItem"
      class="block mb-4"
    >
      <button
        class="d-flex items-center"
        (click)="accordionAdvancedItem.toggle()"
        [ngClass]="{ 'text-primary': accordionAdvancedItem.expanded }"
        tabindex="0"
        [attr.id]="'accordion-header-advanced'"
        [attr.aria-expanded]="accordionAdvancedItem.expanded"
        [attr.aria-controls]="'accordion-body-advanced'"
      >
        <span class="text-base">
          {{ "MILESTONES.REPORTS.CONFIGURATION.ADVANCED-COLUMNS" | translate }}
        </span>
        <mat-icon>
          {{ accordionAdvancedItem.expanded ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-tree
        class="milestone-column-tree"
        [attr.id]="'accordion-body-advanced'"
        [attr.aria-labelledby]="'accordion-header-advanced'"
        [style.display]="accordionAdvancedItem.expanded ? 'grid' : 'none'"
        [dataSource]="dataSource"
        [treeControl]="treeControl"
      >
        <mat-tree-node
          class="node-border"
          *matTreeNodeDef="let node"
          matTreeNodeToggle
        >
          <mat-checkbox
            [checked]="checklistSelection.isSelected(node)"
            (change)="checklistSelection.toggle(node)"
          >
            <div class="checkbox-label" [title]="node.label">
              {{ node.label.split("ID")[0] | titlecase }}
              {{ node.label.indexOf("ID") > -1 ? "ID" : ("" | uppercase) }}
            </div>
          </mat-checkbox>
        </mat-tree-node>
        <mat-tree-node
          class="tree-border"
          *matTreeNodeDef="let node; when: hasChild"
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.filename"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
          <mat-progress-bar
            *ngIf="node.isLoading"
            mode="indeterminate"
            class="ml-7"
          ></mat-progress-bar>
          <mat-checkbox
            class="text-sm"
            [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="itemSelectionToggle(node)"
            >{{ node.label | uppercase }}
          </mat-checkbox>
        </mat-tree-node>
      </mat-tree>
    </cdk-accordion-item>
  </cdk-accordion>
</div>

<div class="dialog-footer">
  <button
    class="btn-primary"
    *ngIf="saveButton"
    (click)="generateReport(true)"
    [disabled]="isLoaded"
  >
    <mat-icon svgIcon="save"></mat-icon>
    {{ "REPORTS.SAVE-CONFIGURATION" | translate }}
  </button>
  <button
    class="btn-primary ml-4"
    (click)="generateReport()"
    [disabled]="isLoaded"
  >
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.GENERATE-REPORT" | translate }}
  </button>
</div>
