<div class="custom-modal-wrapper p-0">
  <app-dialog-header>
    <ng-container *ngIf="data.action.customTitle">
      {{ data.action.customTitle | translate }}
    </ng-container>
    <ng-container *ngIf="!data.action.customTitle">
      {{ "GENERALS.CONFIRM" | translate }} {{ data.action.text | translate }}
    </ng-container>
  </app-dialog-header>
  <div class="modal-body-custom p-20 m-0">
    <div
      class="notification_row bg_light_blue d-flex justify-content-between align-items-center py-1 m-b-10"
      *ngIf="data.action.needs_confirmation"
    >
      <div class="d-flex py-2 px-3">
        <div class="notification_icon">
          <mat-icon svgIcon="info"></mat-icon>
        </div>
        <div class="notification_text">
          {{
            data.action.customText || "GENERALS.WANT-TO"
              | translate : { text: data.action.text }
          }}
        </div>
      </div>
    </div>

    <div *ngIf="data.action.needs_reason" class="row">
      <div class="w-100">
        <label>{{ "LABEL.ENTER-REASON" | translate }}</label>
        <textarea
          name="reason"
          class="textarea-control"
          [(ngModel)]="reason"
        ></textarea>
      </div>
    </div>

    <div
      *ngIf="!data.action.needs_confirmation && !data.action.needs_reason"
      class="row"
    >
      <div class="w-100">
        <span class="fw-500">{{
          data.action.customText || "GENERALS.WANT-TO"
            | translate : { text: data.action.text }
        }}</span>
      </div>
    </div>
  </div>

  <div class="flex w-full">
    <div class="w-1/2 p-3" *ngIf="data.action?.input">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label> {{ data.action.input.label }}</mat-label>
        <input matInput [formControl]="customInputCtrl" type="text" />
        <mat-error [formControlError]="customInputCtrl"></mat-error>
      </mat-form-field>
    </div>
    <div class="w-1/2 p-3" *ngIf="data.action?.date">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label> {{ data.action.date.label }}</mat-label>
        <input
          matInput
          [formControl]="customDateCtrl"
          [matDatepicker]="customStartDatePicker"
          placeholder="DD/MM/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="customStartDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #customStartDatePicker></mat-datepicker>
        <mat-error [formControlError]="customDateCtrl"></mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="clear"></div>
  <div class="dialog-footer">
    <button (click)="confirm()" class="btn orange-bg fl-r modal-button">
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
  </div>
</div>
