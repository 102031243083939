import { Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import { map, Observable } from "rxjs";
import { LegalEntityData } from "../models/legal-entity.data";
import { StoreLegalEntityData } from "../models/store-legal-entity.data";

@Injectable({
  providedIn: "root",
})
export class LegalEntityService {
  readonly baseUrl = "legal-entities";

  constructor(private http: BackendHttpClient) {}

  list(params: { owner_id: string }): Observable<LegalEntityData[]> {
    return this.http
      .get<DefaultResponseData<LegalEntityData[]>>(this.baseUrl, { params })
      .pipe(map((response) => response.result));
  }

  show(id: string): Observable<LegalEntityData> {
    return this.http
      .get<DefaultResponseData<LegalEntityData>>(`${this.baseUrl}/${id}`)
      .pipe(map((response) => response.result));
  }

  create(
    data: StoreLegalEntityData
  ): Observable<DefaultResponseData<LegalEntityData>> {
    return this.http.post<DefaultResponseData<LegalEntityData>>(
      this.baseUrl,
      data
    );
  }

  update(
    id: string,
    data: Partial<LegalEntityData>
  ): Observable<DefaultResponseData<LegalEntityData>> {
    return this.http.put<DefaultResponseData<LegalEntityData>>(
      `${this.baseUrl}/${id}`,
      data
    );
  }

  delete(id: string): Observable<DefaultResponseData<null>> {
    return this.http.delete<DefaultResponseData<null>>(`${this.baseUrl}/${id}`);
  }
}
