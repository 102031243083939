import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { CreateQuoteComponent } from "@modules/quotes/components/create-quote/create-quote.component";
import { QuotesComponent } from "@modules/quotes/components/quotes/quotes.component";
import { QuoteMapperService } from "@modules/quotes/services/quote-mapper.service";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { SharedModule } from "@modules/shared/shared.module";
import { CreateOrderComponent } from "./components/create-order/create-order.component";
import { QuoteOrderItemComponent } from "./components/quote-order-item/quote-order-item.component";
import { CustomFormsModule } from "@modules/custom-forms/custom-forms.module";
import { CreatedOrderMapperService } from "@modules/quotes/services/created-order-mapper.service";
import { MatStepperModule } from "@angular/material/stepper";
import { CheckoutStepComponent } from "./components/create-order/checkout-step/checkout-step.component";
import { ItemsStepComponent } from "./components/create-order/items-step/items-step.component";

@NgModule({
  declarations: [
    QuotesComponent,
    CreateQuoteComponent,
    CreateOrderComponent,
    QuoteOrderItemComponent,
    CheckoutStepComponent,
    ItemsStepComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    RouterModule,
    CustomFormsModule,
  ],
  exports: [QuotesComponent, ItemsStepComponent, CreateOrderComponent],
  providers: [QuotesService, QuoteMapperService, CreatedOrderMapperService],
})
export class QuotesModule {}
