<div class="dialog create-user p-0">
  <app-dialog-header>
    {{ (edit ? "GENERALS.EDIT-USER-INFO" : "GENERALS.ADD-USER") | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <div class="field-grid" *ngIf="user">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "LABEL.FIRST-NAME" | translate }} *</mat-label>
        <input name="first_name" [(ngModel)]="user.first_name" matInput />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "LABEL.LAST-NAME" | translate }} *</mat-label>
        <input name="last_name" [(ngModel)]="user.last_name" matInput />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "LABEL.EMAIL" | translate }} *</mat-label>
        <input name="email" [(ngModel)]="user.email" matInput />
      </mat-form-field>

      <div class="w-full">
        <mat-form-field appearance="outline" class="w-1/2">
          <mat-label>{{ "LABEL.SELECT-PHONE-CODE" | translate }} *</mat-label>
          <mat-select #singleSelect [(ngModel)]="user.phone_prefix">
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="
                  'EXPATS.EXPAT-NAME-EMAIL-ADDRESS' | translate
                "
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                ngModel
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of prefix" [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-1/2 pl-3">
          <mat-label>{{ "LABEL.PHONE-NUMBER" | translate }} *</mat-label>
          <input
            (keypress)="validateNumericalInput($event)"
            [(ngModel)]="user.phone"
            matInput
          />
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</mat-label>
        <input
          name="company_position"
          [(ngModel)]="user.company_position"
          matInput
        />
      </mat-form-field>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>{{ "LABEL.ROLE" | translate }}*</mat-label>
        <mat-select #singleSelect [(ngModel)]="user.role_id">
          <mat-option *ngFor="let item of roles" [value]="item.id">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Modal footer -->
  <br />
  <div class="dialog-footer">
    <button
      type="button"
      class="btn-primary"
      [disabled]="disabled || isLoading"
      (click)="submit()"
    >
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "LABEL.SAVE" | translate }}
    </button>
  </div>
</div>
