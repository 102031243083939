import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { UserData } from "@api/account";
import { QuoteModel } from "@modules/quotes/models/quote.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { ServiceOffersService } from "@modules/service-offers/services/service-offers.service";
import moment from "moment";
import {
  Observable,
  debounceTime,
  map,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs";
import { AlertService } from "src/app/_services/alert.service";
import { ServiceOfferData } from "../../../../_models/service-offer";
import { WorkflowStatus } from "../../../../_models/workflow-status";
import { UsersService } from "../../../../_services/users.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-create-quote",
  templateUrl: "./create-quote.component.html",
  styleUrls: ["./create-quote.component.scss"],
})
export class CreateQuoteComponent implements OnInit {
  public isLoading = false;
  public user: UserData;
  public minDate: Date;
  public offers: ServiceOfferData[];

  serviceOffers$: Observable<any>;

  serviceOffersSearchCtrl = new UntypedFormControl();

  quoteForm = new UntypedFormGroup({
    offer: new UntypedFormControl(null, Validators.required),
    offer_id: new UntypedFormControl(null, Validators.required),
    service_name: new UntypedFormControl(null, Validators.required),
    unit_price: new UntypedFormControl(null, Validators.required),
    expires_at: new UntypedFormControl(null, Validators.required),
  });

  private editableFields = ["service_name", "unit_price", "expires_at"];

  constructor(
    private userService: UsersService,
    private service: QuotesService,
    private dialogRef: MatDialogRef<any>,
    private alertService: AlertService,
    private offersService: ServiceOffersService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientId: any;
      orderId: any;
      orderIdentityNumber: any;
      quote: QuoteModel;
    }
  ) {}

  ngOnInit() {
    this.userService.getCurrentUser().subscribe((data) => {
      this.user = data;
    });
    this.serviceOffers$ = this.serviceOffersSearchCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(""),
      switchMap((search) => {
        return this.offersService
          .getServiceOffers({
            provider_id: this.user.entity_id,
            search_text: search,
            workflow_status: WorkflowStatus.PUBLISHED,
            active: 1,
          })
          .pipe(map((res) => res.result.items));
      }),
      shareReplay(1)
    );
    this.fillForm();

    this.minDate = moment().add(1, "day").toDate();
  }

  fillForm() {
    if (!this.data.quote) {
      return;
    }

    this.quoteForm.patchValue(
      {
        offer: {
          id: this.data.quote.offer_id,
        },
        ...this.data.quote,
      },
      { emitEvent: false }
    );
    Object.keys(this.quoteForm.controls).forEach((key) => {
      if (this.editableFields.includes(key)) {
        return;
      }
      this.quoteForm.controls[key].disable({ emitEvent: false });
    });
  }

  selectOffer(change: MatSelectChange) {
    const offer = change.value;
    this.quoteForm.patchValue({
      offer_id: offer?.id,
      service_name: offer?.title,
      unit_price:
        offer?.current_price?.price ??
        offer?.sale_price?.price ??
        offer?.listing_price?.price ??
        0,
    });
  }

  public isFormValid(): boolean {
    this.quoteForm.markAllAsTouched();
    return this.quoteForm.valid;
  }

  save() {
    const params = {
      ...this.quoteForm.getRawValue(),
      client_id: this.data.clientId,
      order_id: this.data.orderId,
      order_identity_number: this.data.orderIdentityNumber,
    };
    this.quoteForm.markAllAsTouched();
    if (!params.offer_id || !this.data.clientId || !this.isFormValid()) {
      return;
    }

    this.isLoading = true;

    this.updateOrCreate(params).subscribe({
      next: (data) => {
        this.dialogRef.close({ quote: data });
      },
      error: (error) => {
        this.isLoading = false;

        this.alertService.errors(error?.error);
      },
    });
  }

  updateOrCreate(params) {
    if (this.data.quote) {
      return this.service.update(this.user.entity_id, this.data.clientId, {
        ...this.data.quote,
        ...params,
      });
    }
    return this.service.create(this.user.entity_id, this.data.clientId, params);
  }

  close() {
    this.dialogRef.close();
  }

  compareService(service: any, target: any) {
    if (service && target) {
      return service.id === target.id;
    }
  }

  onDateChange(date: any): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.quoteForm
      .get("expires_at")
      .setValue(moment(date).format("yyyy-MM-DD"));
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
