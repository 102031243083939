import { Component, DestroyRef, inject, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanyUserData } from "@api/entities/models/company-user.model";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { AlertService } from "@modules/alert";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { TranslateService } from "@ngx-translate/core";
import { DictionariesService } from "@services/dictionaries.service";
import { finalize, Observable, shareReplay, take, tap } from "rxjs";
import { RoleData, UsersService } from "src/app/_services/users.service";

export interface EditUserDetailsComponentInput {
  user?: CompanyUserData;
  entityId: number | string;
}

@Component({
  templateUrl: "./edit-user-details.component.html",
  styleUrls: ["./edit-user-details.component.scss"],
})
export class EditUserDetailsComponent implements OnInit {
  form: FormGroup = new FormGroup({
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    phone_prefix: new FormControl("", Validators.required),
    phone_number: new FormControl("", Validators.required),
    role_id: new FormControl("", Validators.required),
    company_position: new FormControl("", Validators.required),
  });

  destroyRef = inject(DestroyRef);

  roles$: Observable<RoleData[]> = this.service
    .getUserRoles()
    .pipe(take(1), shareReplay(1), tap(console.log));

  public isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditUserDetailsComponentInput,
    public dictionariesService: DictionariesService,
    private dialogRef: MatDialogRef<EditUserDetailsComponent>,
    private authService: AuthorizationService,
    private service: UsersService,
    private translate: TranslateService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.authService
      .cannot(
        PermissionActionEnum.UPDATE,
        PermissionResourceEnum.COMPANY_USER,
        PermissionSecondaryResourceEnum.DETAILS
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cannot) => {
        const control = this.form.get("role_id");
        const ops = {
          emitEvent: false,
        };
        cannot ? control.disable(ops) : control.enable(ops);
      });

    this.patchForm();
  }

  get isEdit() {
    return !!this.data.user;
  }

  private patchForm(): void {
    if (!this.data.user) {
      return;
    }
    this.form.patchValue({
      ...this.data.user,
      phone_number: this.data.user.phone_prefix + this.data.user.phone_number,
    });
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;

    const data = this.form.getRawValue();

    const userData = {
      ...(this.data.user ? structuredClone(this.data.user) : {}),
      ...this.form.getRawValue(),
    };

    const action = this.isEdit
      ? this.service.updateUserAccount(userData)
      : this.service.createUser(this.data.entityId, data);

    this.isLoading = false;
    action
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {
          this.alertService.success(
            res.messages ??
              this.translate.instant("GENERALS.SUCCESSFULLY-UPDATED")
          );
          this.dialogRef.close({ user: userData, id: res.result.id });
        },
        error: (message) => {
          this.alertService.errors(message.error.errors);
        },
      });
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }

  onCountryChanged(event: any) {
    this.form.controls.phone_prefix.setValue(`+${event.dialCode}`);
  }
}
