import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { ReportsService } from "@api/reports/services/reports.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { BehaviorSubject, Subject } from "rxjs";

export interface ConfigMilestoneModalComponentInput {
  saveButton: boolean;
  reportName: string;
  columns: string[];
  milestone_columns: string[];
  show_end_dates: boolean;
}

export class ItemNode {
  children?: ItemNode[];
  item: string;
  label: string;
  service?: string;
}

export class ItemFlatNode {
  item: string;
  label: string;
  service?: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: "app-config-milestone-modal",
  templateUrl: "./config-milestone-modal.component.html",
  styleUrls: ["./config-milestone-modal.component.scss"],
})
export class ConfigMilestoneModalComponent implements OnInit, OnDestroy {
  @HostBinding("class")
  class = "dialog";
  public isLoaded = false;
  private unsubscribe$: Subject<void>;

  columns = [];
  selectedColumns = [];
  saveButton: boolean = true;

  baseColumns: ItemNode[] = [
    {
      item: "provider_name",
      label: "Provider Name",
    },
    {
      item: "employee_name",
      label: "Employee Name",
    },
    {
      item: "case_id",
      label: "Case ID",
    },
    {
      item: "process_name",
      label: "Process Name",
    },
    {
      item: "process_status",
      label: "Process Status",
    },
  ];

  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true);

  dataChange = new BehaviorSubject<ItemNode[]>([]);
  showDatesCtrl = new FormControl(true);
  reportNameCtrl = new FormControl("");
  get data(): ItemNode[] {
    return this.dataChange.value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ConfigMilestoneModalComponentInput,
    public dialogRef: MatDialogRef<ConfigMilestoneModalComponent>,
    private reports: ReportsService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    this.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.unsubscribe$ = new Subject();
    this.isLoaded = true;
    const dialogData = this.dialogData;

    this.saveButton = dialogData?.saveButton ?? true;

    if (dialogData?.reportName) {
      this.reportNameCtrl.setValue(dialogData.reportName);
    }

    this.setMilestoneColumns();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  setSelectedColumns() {
    this.selectedColumns = [
      ...(this.dialogData.columns ?? []),
      ...(this.dialogData.milestone_columns ?? []),
    ];
    const items = Array.from(this.treeControl.dataNodes.values()).filter(
      (item) => this.selectedColumns.indexOf(item.item) > -1
    );

    this.checklistSelection.select(...(items as any));

    this.showDatesCtrl.setValue(this.dialogData.show_end_dates);
    this.isLoaded = false;
  }
  public generateReport(save = false) {
    if (save) {
      this.reportNameCtrl.setValidators([Validators.required]);
      this.reportNameCtrl.updateValueAndValidity();
      this.reportNameCtrl.markAsTouched();
      if (this.reportNameCtrl.invalid) {
        return;
      }
    }
    const columns = [];
    const milestones = [];
    let params = {};
    const selected = this.checklistSelection.selected;

    selected.forEach((item) => {
      if (item.service == "milestones") {
        milestones.push(item.item);
      } else {
        columns.push(item.item);
      }
    });

    params = { ...params, show_end_dates: this.showDatesCtrl.getRawValue() };
    if (columns.length > 0) {
      params = { ...params, columns: columns };
    }

    if (milestones.length > 0) {
      params = {
        ...params,
        milestone_columns: milestones,
      };
    }

    if (this.showDatesCtrl.getRawValue()) {
      params = { ...params, show_end_dates: true };
    }

    this.isLoaded = false;
    if (save) {
      params = {
        ...params,
        ...{
          save: true,
          reportName: this.reportNameCtrl.getRawValue(),
        },
      };
    }

    this.dialogRef.close(params);
  }

  public cancel() {
    this.dialogRef.close();
  }

  public setMilestoneColumns() {
    this.reports.milestoneFilters().subscribe((items) => {
      if (items.success) {
        this.columns = items.result.columns;
        this.initialize();
      }
    });
  }

  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.label = node.label;
    flatNode.service = node.service;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
  }

  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  itemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  initialize() {
    const data = this.buildFileTree();
    this.dataChange.next(data);
    this.setSelectedColumns();
  }

  buildFileTree(): ItemNode[] {
    return Object.keys(this.columns).reduce<ItemNode[]>((accumulator, key) => {
      const value = this.columns[key];
      const node = new ItemNode();
      node.item = key;
      node.label = key;
      node.service = key;

      node.children = Object.values(value).reduce<ItemNode[]>(
        (fields, item) => {
          const children = new ItemNode();
          children.item = item["field"];
          children.label = item["label"];
          children.service = key;

          return fields.concat(children);
        },
        []
      );
      return accumulator.concat(node);
    }, []);
  }

  public clearFilters() {
    this.checklistSelection.clear();
    this.showDatesCtrl.setValue(false);
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
