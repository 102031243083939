<mat-expansion-panel
  hideToggle
  [disabled]="pageType !== 'task_details'"
  [expanded]="pageType !== 'task_details'"
  (opened)="isPanelExpanded = true"
  (closed)="isPanelExpanded = false"
  [ngClass]="panelClasses"
>
  <mat-expansion-panel-header
    [collapsedHeight]="'100%'"
    [expandedHeight]="'100%'"
    class="!p-0"
  >
    <div class="box-header w-full flex items-center">
      <div class="box-title font-semibold">
        {{ "LEFT-NAVIGATION.REMINDERS" | translate }}
      </div>
      <div class="box-actions flex items-center">
        <button
          *ngIf="showCreateButton"
          class="btn-outline-none-primary p-0 font-semibold flex items-center"
          (click)="$event.stopPropagation(); openDialog()"
        >
          <mat-icon
            svgIcon="plus-circle"
            class="!w-4 !h-4 font-semibold mr-1"
          ></mat-icon>
          {{ "REMINDERS.ADD" | translate }}
        </button>
        <div
          *ngIf="pageType === 'task_details'"
          class="panel-toggle-arrow ml-3"
        >
          <mat-icon
            class="keyboard-arrow text-secondary"
            [ngClass]="{
              'keyboard-arrow-up': isPanelExpanded
            }"
            >keyboard_arrow_down</mat-icon
          >
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <div class="reminders-wrapper">
    <div class="white-box-wrapper">
      <div class="white-box-body">
        <app-loader-spinner-general
          *ngIf="isLoading"
          [loadingType]="LoadingType.INFO"
        ></app-loader-spinner-general>
        <ng-container *ngIf="!isLoading">
          <ng-container
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <div class="reminder-item row">
              <div class="w-50 name">
                <img
                  [src]="
                    'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                    item.author.first_name +
                    ' ' +
                    item.author.last_name
                  "
                />
                {{ item.author.first_name }}
                {{ item.author.last_name }}
              </div>
              <div class="w-50 d-flex justify-content-end">
                <div class="set-date">
                  <label class="m-b-5"> Reminder set for:</label>
                  <div>
                    {{ item.remind_at | date : "dd/MM/yyyy" }}
                  </div>
                </div>
              </div>
              <div class="w-100">
                <div class="text">
                  {{ item.text }}
                </div>
              </div>
              <div class="w-50">
                <div class="shared-with">
                  <label>{{ "REMINDERS.RECIPIENTS" | translate }}</label>
                  <span class="orange"
                    ><ng-container
                      *ngFor="
                        let subscriber of item.subscribers;
                        let last = last
                      "
                    >
                      {{ subscriber.first_name }}
                      <ng-container *ngIf="!last">, </ng-container
                      >{{ subscriber.last_name }}
                    </ng-container></span
                  >
                </div>
              </div>
              <div class="w-50 d-flex justify-content-end">
                <div class="action d-flex">
                  <ng-container *ngIf="canEdit(item)">
                    <div
                      class="white-box-header-action edit-info"
                      (click)="onEdit(item)"
                    >
                      <i class="icon-edit"></i>
                      {{ "GENERALS.EDIT" | translate }}
                    </div>
                  </ng-container>

                  <div
                    class="white-box-header-action edit-info m-l-10"
                    (click)="onDelete(item.id)"
                  >
                    <i class="icon-trash"></i>
                    {{ "REMINDERS.DELETE" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="items?.length === 0"
            class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
          >
            {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
          </div>
        </ng-container>
      </div>
      <!-- paginator -->
      <div class="paginator p-0 d-none">
        <pagination-controls
          (pageChange)="navigateByUrl($event)"
          previousLabel=""
          nextLabel=""
        >
        </pagination-controls>
      </div>

      <!-- end paginator -->
    </div>
    <div class="clear"></div>

    <!-- table -->
    <div class="table-wrapper d-none">
      <ng-container *ngIf="!isXs">
        <div class="table-responsive">
          <table
            matSort
            (matSortChange)="sortData($event)"
            [matSortActive]="active"
            [matSortDirection]="direction"
            class="doc-list-table"
          >
            <thead>
              <tr>
                <th width="11%">
                  {{ "REMINDERS.AUTHOR" | translate }}
                </th>
                <th width="58%">
                  {{ "REMINDERS.REMINDER-TEXT" | translate }}
                </th>
                <th width="11%">
                  {{ "REMINDERS.REMIND-AT" | translate }}
                </th>
                <th width="11%">
                  {{ "REMINDERS.REMINDED-AT" | translate }}
                </th>
                <th width="11%">
                  {{ "REMINDERS.SUBJECT-TYPE" | translate }}
                </th>
              </tr>
            </thead>
            <tbody *ngIf="isLoading">
              <tr>
                <td colspan="100">
                  <app-loader-spinner-general
                    [loadingType]="LoadingType.INFO"
                  ></app-loader-spinner-general>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                *ngFor="
                  let item of items
                    | paginate
                      : {
                          itemsPerPage: itemsPage,
                          currentPage: p,
                          totalItems: total
                        };
                  let i = index
                "
              >
                <td class="gray-pointer">
                  {{ item.author.first_name }}
                  {{ item.author.last_name }}
                </td>

                <td class="gray-pointer word-wrap">
                  {{ item.text }}
                </td>

                <td class="gray-pointer">
                  {{ item.remind_at | date : "dd/MM/yyyy" }}
                </td>

                <td class="gray-pointer">
                  <ng-container *ngIf="item.reminded_at; else empty">
                    {{ item.reminded_at | date : "dd/MM/yyyy" }}
                  </ng-container>

                  <ng-template #empty> - </ng-template>
                </td>

                <td class="gray-pointer">
                  {{ item.subject_type }}
                </td>

                <!-- table cel with action -->
                <td class="ta-r" width="5%">
                  <!-- table action btn -->
                  <!-- table action with overlay -->
                  <div toggle-action-table class="table-action-wrapper">
                    <i class="icon-dot-3"></i>

                    <div class="table-action">
                      <ul *ngIf="canEdit(item)">
                        <li (click)="onEdit(item)">
                          {{ "REMINDERS.EDIT" | translate }}
                        </li>
                      </ul>
                      <ul>
                        <li (click)="onDelete(item.id)">
                          {{ "REMINDERS.DELETE" | translate }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- end table action with overlay -->
                </td>
              </tr>

              <tr *ngIf="items?.length === 0">
                <td colspan="6" class="gray">
                  {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <!-- paginator -->
      <!-- <div class="paginator ">
      <pagination-controls
        (pageChange)="navigateByUrl($event)"
        previousLabel=""
        nextLabel=""
      >
      </pagination-controls>
    </div> -->

      <!-- end paginator -->
    </div>
    <!-- end table -->
  </div>
</mat-expansion-panel>
