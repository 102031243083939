import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ContactData } from "@modules/contacts/models/contact.model";
import { ContactDialogService } from "@modules/contacts/services/contact-dialog.service";
import { ContactsService } from "@modules/contacts/services/contacts.service";
import { ValueList } from "@modules/shared/models/value-list.model";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { finalize, map, switchMap, take } from "rxjs/operators";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-contact-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  contactable: any;

  @Input()
  resource?;

  @Input()
  filters: ValueList<string> = {};

  isLoading = false;

  contacts$: BehaviorSubject<ContactData[]>;

  isXs: boolean;

  unsubscribe$: Subject<void>;

  public columns = [
    "id",
    "label",
    "company_position",
    "name",
    "email",
    "phone_number",
    "address",
    "actions",
  ];

  constructor(
    private contactService: ContactsService,
    private contactDialogService: ContactDialogService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private windowResizeHelper: WindowResizeHelperService
  ) {}

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });

    this.unsubscribe$ = new Subject();
    this.contacts$ = new BehaviorSubject([]);
    this.populateList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateList();
  }

  populateList() {
    this.isLoading = true;

    this.contactService
      .fetchAll(this.contactable, this.filters)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((contacts) => this.contacts$.next(contacts));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.contacts$.complete();
  }

  upsertContact(incomingContact: ContactData) {
    this.isLoading = true;

    this.contacts$
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((contacts) => {
        const i = contacts.findIndex(
          (_element) => _element.id === incomingContact.id
        );
        if (i > -1) {
          contacts[i] = incomingContact;
        } else {
          contacts.push(incomingContact);
        }
        this.contacts$.next(contacts);
      });
  }

  editContact(contact: ContactData) {
    const ref = this.contactDialogService.openUpdate(this.contactable, contact);
    ref.componentInstance.contactSubmitted
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        this.upsertContact(data);
        this.populateList();
      });
  }

  createContact() {
    this.isLoading = true;
    const ref = this.contactDialogService.openCreate(this.contactable);
    ref.componentInstance.contactSubmitted
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        if (data) {
          this.upsertContact(data);
          this.populateList();
        }
      });
  }

  deleteContact(contact: ContactData) {
    this.openConfirmAction()
      .pipe(
        switchMap((confirmed) => {
          if (!confirmed) {
            throw new Error("cancelled");
          }
          return this.contactService.remove(this.contactable, contact);
        }),
        switchMap(() => this.contacts$.pipe(take(1))),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((contacts) => {
        const updatedContacts = contacts.filter(
          (_element) => _element.id !== contact.id
        );
        this.contacts$.next(updatedContacts);
      });
  }

  openConfirmAction() {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          customText: this.translateService.instant("CONTACT.DELETE_CONFIRM"),
          text: this.translateService.instant("CONTACT.DELETE_CONFIRM_TITLE"),
        },
      },
    });
    return dialogRef.afterClosed().pipe(
      map((result) => {
        return !!result;
      })
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
