import { DOCUMENT } from "@angular/common";
import { inject } from "@angular/core";
import { CanActivateChildFn } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs";
import { AccountSelectors } from "../selectors";

export const entityGuard: CanActivateChildFn = (route, state) => {
  const document = inject(DOCUMENT);
  return inject(Store)
    .select(AccountSelectors.selectAccountState)
    .pipe(
      filter((state) => state.loaded),
      map((state) => {
        if (
          [null, undefined, "unknown", "provider"].includes(
            state?.user?.platform?.name
          )
        ) {
          return true;
        }
        document.location.href =
          state?.user?.platform?.url ?? "https://xpath.global";
        return false;
      })
    );
};
