import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { finalize } from "rxjs";
import { Country } from "@models/country";
import { Dictionary } from "@models/dictionary";
import { ProviderData } from "@models/provider";
import { AlertService } from "@modules/alert";
import { DictionariesService } from "../../../_services/dictionaries.service";
import { UsersService } from "../../../_services/users.service";

@Component({
  selector: "app-create-profile",
  templateUrl: "./create-profile.component.html",
})
export class CreateProfileComponent implements OnInit {
  public provider: ProviderData;
  public countries: Country[] = [];
  public companyTypes: Dictionary[];
  public industries: Dictionary[];
  public companySizes: Dictionary[];
  public isLoading = false;
  employeesNumber: Dictionary[];

  currency = [
    { id: "1", name: "USD" },
    { id: "2", name: "EUR" },
    { id: "3", name: "GBP" },
  ];
  request_call;
  cities;

  constructor(
    private userService: UsersService,
    private dictionaryService: DictionariesService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getClientDetails();
    this.getCountries();
    this.getCompanyTypes();
    this.getProfessionalAssociation();
    this.getCompanySizes();
    this.getEmployeesNumber();
  }

  getClientDetails() {
    this.isLoading = true;

    this.userService.getCurrentUser().subscribe({
      next: (data) => {
        this.provider = { ...data.entity_details.company };
        // API returns '' for the next fields. Ng-select needs a null value to display placeholder
        this.provider.default_legal_entity.country_code = this.provider
          .default_legal_entity.country_code
          ? this.provider.default_legal_entity.country_code
          : null;
        this.provider.company_type_id = this.provider.company_type_id
          ? this.provider.company_type_id
          : null;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  customSearchFn(term, item) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().includes(term);
  }

  save() {
    this.isLoading = true;
    this.userService
      .saveProviderProfile(this.provider)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => this.router.navigateByUrl("/service-portfolio"),
        error: (error) => this.alertService.errors(error.error),
      });
  }

  getCountries() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result.map((country) => {
          const prefix = country.phone_code.replaceAll("+", "");
          return {
            name: `(+${prefix}) ${country.name}`,
            phone_code: country.phone_code,
            country_code: country.country_code,
          };
        });
      }
    });
  }

  getCompanyTypes() {
    this.dictionaryService.getCompanyTypes().subscribe((data) => {
      this.companyTypes = data.result;
    });
  }

  getProfessionalAssociation() {
    this.dictionaryService.getProfessionalAssociation().subscribe((data) => {
      this.industries = data.result;
    });
  }

  getCompanySizes() {
    this.dictionaryService.getCompanySizes().subscribe((data) => {
      this.companySizes = data.result;
    });
  }

  getEmployeesNumber() {
    this.dictionaryService.getEmployeesNumber().subscribe((data) => {
      this.employeesNumber = data.result;
    });
  }

  loadCities(event?) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.request_call = this.dictionaryService
      .cities(event || "", this.provider.default_legal_entity.country_code)
      .subscribe((response) => {
        if (response.success) {
          this.cities = response.result;
        }
      });
  }

  countryChanged(event) {
    this.cities = [];
    this.provider.default_legal_entity.city = null;

    // this.translate.get("GENERALS.SELECT-CITY").subscribe(data => {
    //   this.provider.city = data;
    // });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
