<ng-container *ngIf="!order">
  <app-loader-spinner-general
    class="d-flex justify-content-end"
    [loadingType]="LoadingType.BUTTONS"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    [loadingType]="LoadingType.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<app-quotes
  *ngIf="order"
  [clientId]="order.entity_id"
  [orderId]="orderId"
  [pageName]="pageName"
></app-quotes>
