<app-dialog-header>
  {{ "Assign expats" | translate }}
  <div
    slot="subtitle"
    class="text-secondary font-medium text-base pt-1.5"
    *ngIf="showAlreadyAddedExpats()"
  >
    {{
      "PORTAL.SELECT-MORE-EXPATS"
        | translate : { expatCount: expatsRemaining() }
    }}
  </div>
</app-dialog-header>

<div class="dialog-body">
  <ng-container *ngFor="let controls of expatControlsArr; let i = index">
    <app-offer-expat-item
      [form]="controls"
      [expatsList]="expatsList"
      [selectedExpatsList]="selectedExpatsList"
      class="w-full"
      [order]="order"
    >
      <button
        mat-icon-button
        (click)="expatControls.removeAt(i)"
        *ngIf="expatControlsArr.length > 1"
      >
        <mat-icon>remove</mat-icon>
      </button>
    </app-offer-expat-item>
  </ng-container>
  <div *ngIf="totalNoExpats > 1 && expatsRemaining() > 0" class="w-full">
    <button class="btn-outline-none-primary" (click)="addMoreExpats()">
      <i class="icon-plus"></i>
      {{ "Add more expats" | translate }}
    </button>
  </div>
</div>

<div class="dialog-footer">
  <button class="btn-primary" (click)="save()" [disabled]="isLoading">
    <mat-icon svgIcon="check-outline" class="btn-icon"></mat-icon>
    {{ "GENERALS.ASSIGN" | translate }}
  </button>
</div>
