import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { BillingDetailsService } from "@api/billing-details/services/billing-details.service";
import { BillingDetails } from "@api/clients/models/billing-details.models";
import { ClientsService } from "@api/clients/services";
import { AlertService } from "@modules/alert";
import { CreateUpdateBillingDetailsDialogComponent } from "@modules/clients/modules/billing-details/billing-details-shared/components/create-update-billing-details-dialog/create-update-billing-details-dialog.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs";
import { filterNullish } from "src/app/lib";
export interface CheckoutData {
  billingAddressId: number;
  contactId: number;
  legalEntityId: string;
}
export type ToFormGroupType<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K] | null>;
}>;

export type CheckoutDataForm = ToFormGroupType<CheckoutData>;
@Component({
  selector: "app-checkout-step",
  templateUrl: "./checkout-step.component.html",
  styleUrls: ["./checkout-step.component.scss"],
})
export class CheckoutStepComponent implements OnInit {
  @Input()
  clientId: number;

  @Input() clientUuId: string;

  @Input()
  checkoutForm: CheckoutDataForm;

  clientService = inject(ClientsService);
  changeDetectorRef = inject(ChangeDetectorRef);
  destroyRef = inject(DestroyRef);

  addressesSubject = new BehaviorSubject<BillingDetails[]>([]);
  addresses$ = this.addressesSubject.asObservable();

  billingAddressSearchControl = new FormControl("");
  isLoading: boolean = false;
  isLoadingSearch: boolean = true;
  totalBillingAddresses: number = 0;

  billingParamsSubject = new BehaviorSubject<any>({
    page: 1,
    per_page: 25,
    search_text: "",
  });

  users$: Observable<UserData[]>;

  userSearchControl: FormControl = new FormControl("");
  selectedContact$ = new BehaviorSubject<UserData>(null);

  billingDetailsService = inject(BillingDetailsService);
  dialog = inject(MatDialog);
  alertService = inject(AlertService);

  ngOnInit() {
    this.initBillingAddressListener();

    this.users$ = this.userSearchControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap((value) =>
        this.clientService
          .getUsers(this.clientId, {
            search_text: value,
            only_hr_users: true,
          })
          .pipe(catchError(() => of([])))
      ),
      map((users) => {
        if (this.selectedContact$.value) {
          const exists = !users.some(
            (user) => user.id === this.selectedContact$.value.id
          );
          if (exists) {
            return [this.selectedContact$.value, ...users];
          }
        }
        return users;
      }),
      shareReplay(1)
    );
  }

  private initBillingAddressListener() {
    this.isLoading = true;

    this.billingParamsSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        switchMap((params) => {
          this.isLoadingSearch = true;
          return this.billingDetailsService
            .getBillingAddressesByOwner(this.clientUuId, "entity", params)
            .pipe(
              finalize(() => {
                this.isLoadingSearch = false;
                this.isLoading = false;
              })
            );
        })
      )
      .subscribe((resp) => {
        this.totalBillingAddresses = resp.total;
        this.addressesSubject.next(resp.items);
      });
  }

  onSearchChange(searchTerm: string) {
    const updatedParams = {
      ...this.billingParamsSubject.value,
      search_text: searchTerm,
      page: 1,
    };
    this.billingParamsSubject.next(updatedParams);
  }

  get LoadingTypeEnum() {
    return LoadingTypeEnum;
  }

  selectionChanged(event: UserData) {
    this.selectedContact$.next(event);
    this.changeDetectorRef.markForCheck();
  }

  createBillingAddress() {
    this.dialog
      .open(CreateUpdateBillingDetailsDialogComponent, {
        data: {
          billingDetails: null,
          entityId: null,
          ownerEntityId: this.clientUuId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef), filterNullish())
      .subscribe((resp) => {
        this.alertService.success(resp.message);
        this.updateAddresses(resp.result);
      });
  }

  onBillingAddressChange(event: any) {
    const billingAddress = this.addressesSubject.value.find(
      (address) => address.id === event.value
    );

    this.checkoutForm.controls.legalEntityId.setValue(
      billingAddress.billable_id
    );
  }

  updateAddresses(newAddress: BillingDetails) {
    const currentAddresses = this.addressesSubject.getValue();
    this.addressesSubject.next([newAddress, ...currentAddresses]);
  }

  onPageChange(newPage: number) {
    const updatedParams = { ...this.billingParamsSubject.value, page: newPage };
    this.billingParamsSubject.next(updatedParams);
  }
}
