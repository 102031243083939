import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { ServiceOffer } from "@models/service-offer";
import { ServiceOffersService } from "@modules/service-offers/services/service-offers.service";
import { map, take } from "rxjs/operators";
import { WorkflowStatus } from "@models/workflow-status";

@Component({
  selector: "app-workflow-copy-popup",
  templateUrl: "./workflow-copy-popup.component.html",
  styleUrls: ["./workflow-copy-popup.component.scss"],
})
export class WorkflowCopyPopupComponent implements OnInit {
  public offerFilterControl = new UntypedFormControl();
  public offerControl = new UntypedFormControl();
  public offers: ServiceOffer[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<WorkflowCopyPopupComponent>,
    private serviceOffers: ServiceOffersService
  ) {}

  ngOnInit() {
    this.filterOffers();

    const params = { workflow_status: WorkflowStatus.PUBLISHED };
    this.serviceOffers
      .getServiceOffers(params)
      .pipe(
        take(1),
        map((_) => _.result.items)
      )
      .subscribe((data) => (this.offers = data));
  }

  private filterOffers() {
    this.offerFilterControl.valueChanges.subscribe((inputValue) => {
      this.serviceOffers
        .getServiceOffers({
          search_text: inputValue,
          workflow_status: WorkflowStatus.PUBLISHED,
        })
        .pipe(
          take(1),
          map((_) => _.result.items)
        )
        .subscribe((data) => (this.offers = data));
    });
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close({ offerSourceId: this.offerControl.value });
  }
}
