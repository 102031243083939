import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { CaseMapperService } from "./case-mapper.service";
import {
  CaseData,
  CaseFollower,
  CaseService,
  EligibleUserData,
  RawCaseFollower,
} from "../models/case.model";
import { exhaustMap, map, take, tap } from "rxjs/operators";
import { CreateCaseData } from "../models/create-case.model";
import { PageData } from "@modules/shared/models/page.model";
import { UserData } from "@api/account";
import moment, { Moment } from "moment";
import { Store } from "@ngrx/store";
import { CaseActions } from "../actions";
import { ModelChangeData } from "@api/case-tasks/models/model-change.data";
import { CaseSelectors } from "../selectors";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable()
export class CasesService {
  public clientCanChangeExpatStatus = false;

  constructor(
    private http: HttpClient,
    private readonly caseMapperService: CaseMapperService,
    private readonly store: Store
  ) {}

  public getEligibleUsers(id: number): Observable<EligibleUserData[]> {
    return this.http
      .get<any>(environment.gateway_endpoint + `cases/${id}/eligible-users`)
      .pipe(map((response: any) => response.result));
  }

  public list(params = {}): Observable<PageData<CaseData>> {
    return this.http
      .get<any>(environment.gateway_endpoint + `cases`, {
        params: params,
      })
      .pipe(
        map((response) => {
          const { items, filters, ...pageData } = response.result;

          return {
            ...pageData,
            filters: filters.filter((filter) => filter.id !== "time_frame"),
            items: this.caseMapperService.mapMany(items),
          } as PageData<CaseData>;
        })
      );
  }

  public fetchCase(id): Observable<CaseData> {
    return this.http
      .get<any>(environment.gateway_endpoint + "cases/" + id)
      .pipe(
        map((response: any) =>
          this.caseMapperService.mapOne(response.result.case)
        )
      );
  }

  public details(id): Observable<CaseData> {
    return this.fetchCase(id);
  }

  public create(data: CreateCaseData): Observable<CaseData> {
    data = this.caseMapperService.prepareCreate(data);
    return this.http
      .post(environment.gateway_endpoint + `cases`, data)
      .pipe(
        map((response: any) =>
          this.caseMapperService.mapOne(response.result.case)
        )
      );
  }

  protected requestUpdate(
    caseID,
    params
  ): Observable<{
    case: CaseData;
    other_changes?: Array<ModelChangeData>;
  }> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + `cases/` + caseID,
        params,
        httpOptions
      )
      .pipe(
        map((response: any) => ({
          case: this.caseMapperService.mapOne(response.result.case),
          other_changes: response.result.other_changes,
        }))
      );
  }

  public update(
    caseID: number,
    params
  ): Observable<{
    case: CaseData;
    other_changes?: Array<ModelChangeData>;
  }> {
    this.store.dispatch(
      CaseActions.requestUpdateCase({
        case: {
          id: caseID,
          changes: params,
        },
      })
    );
    return this.requestUpdate(caseID, params).pipe(
      tap({
        next: ({ case: caseData, other_changes }) =>
          this.store.dispatch(
            CaseActions.requestUpdateCaseSuccess({
              case: caseData,
              other_changes,
            })
          ),
        error: (error) =>
          this.store.dispatch(CaseActions.requestUpdateCaseError({ error })),
      })
    );
  }

  public requestAddService(
    caseID,
    params: CaseService[]
  ): Observable<{
    case: CaseData;
  }> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + `cases/` + caseID + "/services",
        params,
        httpOptions
      )
      .pipe(
        map((response: any) => {
          return response.result;
        })
      );
  }

  public addService(
    caseID,
    params: CaseService[]
  ): Observable<{
    case: CaseData;
  }> {
    this.store.dispatch(
      CaseActions.requestCreateServices({ services: params })
    );
    return this.requestAddService(caseID, params).pipe(
      tap({
        next: ({ case: caseData }) =>
          this.store.dispatch(
            CaseActions.requestCreateServicesSuccess({
              case: caseData,
            })
          ),
        error: (error) =>
          this.store.dispatch(
            CaseActions.requestCreateServicesError({ error })
          ),
      })
    );
  }

  protected requestAssignManager(
    caseID,
    caseManagerID
  ): Observable<{ case: CaseData; other_changes?: Array<ModelChangeData> }> {
    return this.http
      .post(environment.gateway_endpoint + `cases/` + caseID + "/managers", {
        user_id: caseManagerID,
      })
      .pipe(
        map((response: any) => ({
          case: this.caseMapperService.mapOne(response.case),
          other_changes: response.other_changes,
        }))
      );
  }

  public assignCaseManager(
    caseID: number,
    caseManagerID: number
  ): Observable<{ case: CaseData; other_changes?: Array<ModelChangeData> }> {
    this.store.dispatch(
      CaseActions.requestAssignManager({
        caseId: caseID,
        caseManagerId: caseManagerID,
      })
    );
    return this.requestAssignManager(caseID, caseManagerID).pipe(
      tap({
        next: ({ case: caseData, other_changes }) =>
          this.store.dispatch(
            CaseActions.requestAssignManagerSuccess({
              case: caseData,
              other_changes,
            })
          ),
        error: (error) =>
          this.store.dispatch(CaseActions.requestAssignManagerError({ error })),
      })
    );
  }

  /**
   * Export filtered clients to CSV
   * @param params
   */
  export(params = {}) {
    return this.http.get(`${environment.gateway_endpoint}cases/export`, {
      responseType: "blob",
      params: params,
    });
  }

  getCaseStatisticStatuses(params?) {
    return this.http.get(
      `${environment.gateway_endpoint}cases/statistics/cases`,
      {
        params,
      }
    );
  }
  public fetchEligibleFollowers(caseId: number): Observable<UserData[]> {
    return this.http
      .get<{ result: UserData[] }>(
        environment.gateway_endpoint + `cases/${caseId}/followers/eligible`
      )
      .pipe(map((response) => response.result));
  }

  public mapFollowers(
    result: Record<string, RawCaseFollower>,
    id: string | number
  ): CaseFollower[] {
    return Object.values(result).map((follower: any) => {
      const model = follower.models.find((model) => {
        return model.model_type === "case" && model.model_id === id;
      });
      return {
        ...follower,
        role_name: model?.roles[0],
        permissions: model?.permissions,
      };
    });
  }

  public followers(caseID): Observable<{
    result: CaseFollower[];
    allowed_actions: Record<string, boolean>;
  }> {
    return this.http
      .get<any>(environment.gateway_endpoint + `cases/` + caseID + "/managers/")
      .pipe(
        map((res) => ({
          result: this.mapFollowers(res.result, caseID),
          allowed_actions: res.allowed_actions,
        }))
      );
  }

  public addFollower(data: {
    case_id: number;
    user_id: number;
  }): Observable<CaseFollower[]> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + `cases/${data.case_id}/followers`,
        data
      )
      .pipe(map((res) => this.mapFollowers(res.result, data.case_id)));
  }

  public removeFollower(data: {
    case_id: number;
    user_id: number;
  }): Observable<CaseFollower[]> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          `cases/${data.case_id}/followers/${data.user_id}`
      )
      .pipe(map((res) => this.mapFollowers(res.result, data.case_id)));
  }

  public getCaseManagerList(caseID): Observable<any> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + `cases/` + caseID + "/eligible-users/"
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  protected requestUpdateServiceForecast(
    caseService: CaseService,
    data: {
      start_date: Date | string | Moment;
    }
  ): Observable<{
    case_service: CaseService;
    other_changes?: Array<ModelChangeData>;
  }> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          `cases/${caseService.expat_case_id}/services/${caseService.id}/update-start-date`,
        {
          start_date: moment(data.start_date).format(),
        }
      )
      .pipe(map((response) => response.result));
  }

  public updateServiceForecast(
    caseService: CaseService,
    data: {
      start_date: Date | string | Moment;
    }
  ): Observable<{
    case_service: CaseService;
    other_changes?: Array<ModelChangeData>;
  }> {
    this.store.dispatch(
      CaseActions.requestUpdateService({
        service: { id: caseService.id, changes: {} },
      })
    );
    return this.requestUpdateServiceForecast(caseService, data).pipe(
      tap({
        next: ({ case_service, other_changes }) =>
          this.store.dispatch(
            CaseActions.requestUpdateServiceSuccess({
              service: case_service,
              other_changes,
            })
          ),
        error: (error) =>
          this.store.dispatch(CaseActions.requestUpdateCaseError({ error })),
      })
    );
  }

  protected requestCancelService(caseService: CaseService): Observable<{
    case_service: CaseService;
    other_changes?: Array<ModelChangeData>;
  }> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          `cases/${caseService.expat_case_id}/services/${caseService.id}/cancel`,
        {}
      )
      .pipe(map((response) => response.result));
  }

  public cancelService(caseService: CaseService) {
    this.store.dispatch(
      CaseActions.requestUpdateService({
        service: { id: caseService.id, changes: {} },
      })
    );
    return this.requestCancelService(caseService).pipe(
      tap({
        next: ({ case_service, other_changes }) =>
          this.store.dispatch(
            CaseActions.requestUpdateServiceSuccess({
              service: case_service,
              other_changes,
            })
          ),
        error: (error) =>
          this.store.dispatch(CaseActions.requestUpdateCaseError({ error })),
      })
    );
  }
  public getCaseById(id: number, refresh: boolean = false) {
    return this.store.select(CaseSelectors.selectCaseById(id)).pipe(
      take(1),
      exhaustMap((caseData) => {
        if (!caseData || refresh) {
          return this.fetchCase(id).pipe(
            tap((data) =>
              this.store.dispatch(CaseActions.upsertCase({ case: data }))
            )
          );
        }
        return of(caseData);
      })
    );
  }
}
