import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface CategoriesTermsDialogComponentInput {
  displayCategories: any[];
}

@Component({
  selector: "app-categories-terms-dialog",
  templateUrl: "./categories-terms-dialog.component.html",
  styleUrls: ["./categories-terms-dialog.component.scss"],
})
export class CategoriesTermsDialogComponent {
  displayCategories = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: CategoriesTermsDialogComponentInput,
    protected readonly dialogRef: MatDialogRef<CategoriesTermsDialogComponent>
  ) {
    this.displayCategories = data.displayCategories;
  }

  confirm() {
    this.dialogRef.close({
      agree: true,
      categories: this.displayCategories,
    });
  }
}
