import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { Permission } from "@modules/authorization/models/permission.model";

@Directive({
  selector: "[canViewAny]",
})
export class CanViewAnyDirective {
  @Input()
  set canViewAny(permissions: Permission[]) {
    if (permissions.length) {
      this.authService.$canAny(permissions).subscribe((show: boolean) => {
        if (show) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    } else {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthorizationService
  ) {}
}
