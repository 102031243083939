/**
 * Temporary file to contain possible configuration of the app.
 */

export const seller = {
  welcome: false,
  dashboard: true,
  "root-service-offers-list": false,
  "service-offers": true,
  clients: true,
  reports: false,
  orders: true,
  cases: false,
  "roles-and-permissions": false,
  "company-users": false,
  billing: false,
  settings: false,
  rfqs: true,
  financial: true,
  ads: true,
  messaging: true,
  categories: true,
  template: false,
};

export const saas = {
  welcome: false,
  dashboard: true,
  "root-service-offers-list": true,
  "service-offers": false,
  clients: true,
  reports: true,
  orders: true,
  cases: true,
  "roles-and-permissions": false,
  "company-users": true,
  billing: true,
  settings: true,
  rfqs: false,
  financial: false,
  ads: false,
  messaging: false,
  categories: false,
  template: true,
};

export const full = {
  welcome: false,
  dashboard: true,
  "root-service-offers-list": false,
  "service-offers": true,
  clients: true,
  reports: true,
  orders: true,
  cases: true,
  "roles-and-permissions": false,
  "company-users": true,
  billing: true,
  settings: true,
  rfqs: true,
  financial: true,
  ads: true,
  messaging: true,
  categories: true,
  template: true,
  forms: true,
};
