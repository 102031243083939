<ng-container *ngIf="isLoggedInUser">
  <!-- top icons -->
  <!-- user details -->
  <div class="header-top-user fl-l">
    <div class="header-top-user-info fl-l">
      <div class="fw-500">{{ user?.name }}</div>
      <div class="gray">{{ user?.role_name }}</div>
    </div>
    <div class="header-top-user-img fl-l">
      <img
        (error)="onProfileImageError($event)"
        [src]="
          user?.primary_image_path
            ? user.primary_image_path
            : '../assets/images/profile-pics-placeholder.jpg'
        "
        alt="{{ user?.name }}"
        class="user-img"
      />
    </div>
    <div class="header-top-menu fl-l">
      <i
        (click)="userMenuIsOpen = !userMenuIsOpen"
        [matMenuTriggerFor]="userMenu"
        class="icon-angle-down"
      ></i>
    </div>
  </div>

  <!-- marketplace-header top overlay -->

  <!-- log out overlay -->
  <mat-menu
    class="custom-mat-menu-wrapper"
    #userMenu
    [overlapTrigger]="false"
    (closed)="userMenuIsOpen = false"
  >
    <div *ngIf="xs" class="header-top-user-details-responsive">
      <div class="header-top-user-img-responsive">
        <img
          [src]="
            user?.primary_image_path
              ? user.primary_image_path
              : '../assets/images/profile-pics-placeholder.jpg'
          "
          alt="{{ user?.name }}"
          class="user-img"
        />
      </div>
      <div class="header-top-user-info-responsive">
        {{ user?.name }}
      </div>
    </div>

    <div
      class="custom-mat-menu-item"
      mat-menu-item
      [routerLink]="['/my-account']"
    >
      {{ "LABEL.MY-ACCOUNT" | translate }}
    </div>
    <div
      class="custom-mat-menu-item"
      mat-menu-item
      [routerLink]="['/dashboard']"
      *canView="viewReports"
    >
      {{ "LEFT-NAVIGATION.DASHBOARD" | translate }}
    </div>
    <div
      class="custom-mat-menu-item"
      mat-menu-item
      [routerLink]="['/settings']"
      *canView="viewSettings"
    >
      {{ "LABEL.COMPANY-SETTINGS" | translate }}
    </div>
    <div
      class="custom-mat-menu-item"
      mat-menu-item
      [routerLink]="['/company-users']"
      [queryParams]="defaultParams"
      *canView="viewUserDetails"
    >
      {{ "LABEL.COMPANY-USERS" | translate }}
    </div>
    <div class="custom-mat-menu-item" mat-menu-item (click)="about()">
      {{ "GENERALS.PROJECT_DETAILS" | translate }}
    </div>
    <div class="custom-mat-menu-item" mat-menu-item (click)="logout()">
      {{ "AUTH.LOGOUT" | translate }}
    </div>
  </mat-menu>
  <!-- end log out overlay -->

  <!-- end marketplace-header top overlay -->
</ng-container>

<div class="window-width" (window:resize)="onResize()"></div>
