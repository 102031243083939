import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

import { AdsService } from "src/app/_services/ads.service";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  selector: "app-update-ad-banner",
  templateUrl: "./update-ad-banner.component.html",
  styleUrls: ["./update-ad-banner.component.scss"],
})
export class UpdateAdBannerComponent implements OnInit {
  @Input() item;
  @Output() getItem: EventEmitter<any> = new EventEmitter();
  imgURL;
  message: string;
  isLoading: boolean;
  minStartDate: Date;
  minEndDate: Date;

  constructor(
    private translate: TranslateService,
    private adsService: AdsService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.minStartDate = new Date(this.addDayToDate(new Date(), 1));
    this.minEndDate = new Date(this.addDayToDate(new Date(), 2));
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    if (files) {
      this.item.image = files[0];
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.translate.get("GENERALS.ONLY-IMG").subscribe((data) => {
        this.message = data;
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  removeUploaded() {
    this.imgURL = "";
    this.message = "";
  }

  setDate(e, type) {
    if (type === "start_date") {
      this.item.starts_at = e;
    } else if (type === "end_date") {
      this.item.ends_at = e;
    }
  }

  updateAdBanenr(item) {
    const params = item;
    this.isLoading = true;
    if (this.item) {
      this.adsService.editAdBanner(item).subscribe(
        (data) => {
          this.getItem.emit();
          // small modal replaced
          this.isLoading = false;
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
            this.isLoading = false;
          }
        }
      );
    }
  }

  close() {
    // small modal replaced
    this.getItem.emit();
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }
}
