<!--Phone Number Code-->
<mat-form-field
  appearance="outline"
  class="w-1/2 pr-3"
  [formGroup]="accountForm"
>
  <mat-label> {{ "LABEL.SELECT-PHONE-CODE" | translate }}</mat-label>
  <mat-select
    [compareWith]="compareWith"
    formControlName="phone_prefix"
    [placeholder]="'GENERALS.SELECT-PHONE-CODE' | translate"
    disableOptionCentering
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'GENERALS.SELECT-PHONE-CODE' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="phonePrefixSearchControl"
        id="phone_prefix_search"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let prefix of filteredPhonePrefix"
      [value]="prefix.phone_code"
    >
      {{ prefix.name }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="accountForm.get('phone_prefix')">
    </app-form-errors>
  </mat-error>
</mat-form-field>

<!--Phone Number-->
<mat-form-field appearance="outline" class="w-1/2" [formGroup]="accountForm">
  <mat-label>{{ "LABEL.PHONE-NUMBER" | translate }}</mat-label>
  <input matInput formControlName="phone" />
  <mat-error>
    <app-form-errors [control]="accountForm.get('phone')"> </app-form-errors>
  </mat-error>
</mat-form-field>
