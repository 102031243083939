<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top" *ngIf="!hideFilters">
  <!-- left -->
  <div *ngIf="!hideTitle" class="float-left big-title">
    {{ "LEFT-NAVIGATION.CASES" | translate }}
  </div>
  <!-- end left -->

  <div class="button-bar">
    <button class="btn-outline-primary" (click)="exportItems('cases')">
      <mat-icon svgIcon="export" class="btn-icon"></mat-icon>
      {{ "GENERALS.EXPORT" | translate }}
    </button>
    <app-my-cases-button></app-my-cases-button>
    <div *canView="canCreate" class="float-right">
      <button
        class="btn-primary"
        (click)="openDialog()"
        (keyup)="openDialog()"
        *ngIf="!isFormattedParams"
      >
        <mat-icon svgIcon="plus-circle" class="btn-icon"></mat-icon>
        {{ "Add case" | translate }}
      </button>
    </div>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<app-loader-spinner-general
  *ngIf="!hideFilters && !isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="!hideFilters && isFiltersLoaded">
  <!-- left -->
  <div class="float-left w-full p-0 advanced-search-wrapper">
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="LEFT-NAVIGATION.CASES"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    >
    </app-filters>
  </div>
  <!-- end left -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        [matSortActive]="active"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <th class="w-1">&nbsp;</th>
            <th
              mat-sort-header="id"
              [disabled]="isOnDashboard"
              class="text-right border-r w-1"
            >
              {{ "GENERALS.ID" | translate }}
            </th>
            <th mat-sort-header="client_name">
              {{ "GENERALS.CLIENT-NAME" | translate }}
            </th>
            <th mat-sort-header="expat_name">
              {{ "GENERALS.EXPAT-NAME" | translate }}
            </th>
            <th mat-sort-header="manager_name">
              {{ "GENERALS.CASE-MANAGER" | translate }}
            </th>
            <th
              mat-sort-header="start_date"
              [disabled]="isOnDashboard"
              class="text-right"
            >
              {{ "GENERALS.CASE-START" | translate }}
            </th>
            <th
              mat-sort-header="forecast_end_date"
              [disabled]="isOnDashboard"
              class="text-right"
            >
              {{ "GENERALS.CASE-END-DATE" | translate }}
            </th>
            <th mat-sort-header="assignment_type">
              {{ "GENERALS.ASSIGNMENT-TYPE" | translate }}
            </th>
            <th mat-sort-header="home_country">
              {{ "GENERALS.HOME-COUNTRY" | translate }}
            </th>
            <th mat-sort-header="host_country">
              {{ "GENERALS.HOST-COUNTRY" | translate }}
            </th>
            <th mat-sort-header="status" [disabled]="isOnDashboard">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr
            class="cursor-pointer"
            *ngFor="
              let item of items
                | paginate
                  : {
                      id: overdue ? 'overdue' : 'cases-list',
                      itemsPerPage: perPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
            >
              <div class="tags">
                <div
                  *ngFor="let tag of item.tags"
                  [ngClass]="['tag', tag.slug]"
                >
                  <mat-icon>warning</mat-icon>
                </div>
              </div>
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              class="text-right border-r"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
            >
              <a
                [routerLink]="['/cases', item.id]"
                class="font-semibold text-secondary"
                >#{{ item.id }}</a
              >
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
            >
              {{ item.client_name | notAvailable }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
            >
              <ng-container
                *ngIf="
                  item.type === CaseType.EXPAT ||
                    item.type === CaseType.DOCUMENT;
                  else nameForAdminCases
                "
              >
                {{ item.expat_name | notAvailable }}
                <ng-container
                  *ngIf="item.subject?.reference_type === 'dependent'"
                >
                  [{{ item.subject?.name }}]
                </ng-container>
              </ng-container>
              <ng-template #nameForAdminCases>
                {{ item.name | notAvailable }}
              </ng-template>
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
            >
              {{ item.case_manager_name }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
              class="text-right"
              [ngClass]="{ gray: item.status === CaseStatusEnum.PENDING }"
            >
              {{ item.start_date | date : "dd/MM/yyyy" }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              class="text-right"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
              [ngClass]="{ gray: item.status !== CaseStatusEnum.DONE }"
            >
              {{ item.forecast_end_date | date : "dd/MM/yyyy" }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
              [ngClass]="{ gray: !item.assignment?.type }"
            >
              {{ item.assignment?.type | titlecase | notAvailable }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
              [ngClass]="{ gray: !item.assignment?.home_country }"
            >
              {{
                item.assignment?.home_country | country | async | notAvailable
              }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
              [ngClass]="{ gray: !item.assignment?.host_country }"
            >
              {{
                item.assignment?.host_country | country | async | notAvailable
              }}
            </td>
            <td
              [routerLink]="['/cases', item.id]"
              [matTooltip]="item.tooltipServices | tooltipList"
              matTooltipClass="tooltip-list"
            >
              <span
                class="status"
                [appStatusStyle]="item.status_label | lowercase"
              >
                {{ item.status_label | uppercase }}
              </span>
            </td>
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="100" class="text-secondary">
              <div class="notification_row bg_light_blue my-2.5 p-3">
                {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check"></div>
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoading">
      <div
        class="resposive-table-wrapper cursor-pointer"
        *ngFor="
          let item of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
        [routerLink]="['/cases/', item.id]"
      >
        <div class="responsive-table-row">
          <div class="responsive-table-details">
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.id }}</div>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      [id]="overdue ? 'overdue' : 'cases-list'"
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
