<div class="input" *ngIf="isLoading">
  <div class="ph-item">
    <div class="ph-col-12">
      <div class="ph-row">
        <div class="ph-col-12 big"></div>
      </div>
    </div>
  </div>
</div>

<div class="country-wrapper" *ngIf="filteredCountries$ | async as filtered">
  <mat-form-field class="country-field" appearance="outline">
    <mat-label>
      <ng-content select="[slot='label']"></ng-content>
    </mat-label>

    <mat-select
      [formControl]="$any(control)"
      (click)="markAsTouched()"
      (selectionChange)="onSelectChange($any($event))"
      disableOptionCentering
      panelClass="country-panel"
      [compareWith]="preselectCountries"
      [multiple]="multiple"
      [placeholder]="'GENERALS.SELECT-COUNTRY' | translate"
    >
      <mat-option>
        <ngx-mat-select-search
          [placeholderLabel]="'LABEL.COUNTRY' | translate"
          [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          [formControl]="countrySearchControl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let country of filtered"
        [value]="onlyCode ? country.country_code : country"
      >
        {{ country.name }}
      </mat-option>
    </mat-select>
    <mat-error [formControlError]="control"></mat-error>
  </mat-form-field>
</div>
