<div class="create-assignment dialog p-0">
  <app-dialog-header>
    {{
      (edit ? "assignments.EDIT-ASSIGNMENT" : "assignments.CREATE-ASSIGNMENT")
        | translate
    }}
  </app-dialog-header>

  <div class="dialog-body">
    <app-loader-spinner-general
      *ngIf="!isLoaded"
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      *ngIf="!isLoaded"
      class="d-flex justify-content-end"
      [loadingType]="LoadingType.BUTTONS"
      [repeat]="2"
    ></app-loader-spinner-general>
    <form [formGroup]="form">
      <div class="field-grid overflow-x-hidden" *ngIf="isLoaded">
        <!-- select expat -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "GENERALS.SELECT-EXPAT" | translate }}</mat-label>
          <mat-select
            formControlName="expat_id"
            [placeholder]="'GENERALS.SELECT-EXPAT' | translate"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'GENERALS.SELECT-EXPAT' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="expatFilterControl"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let expat of filteredExpats" [value]="expat.id">
              {{ expat.name }}
            </mat-option>
          </mat-select>

          <mat-error
            *ngIf="
              form.controls.expat_id.touched &&
              form.controls.expat_id?.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <!-- select expat -->

        <!-- assignment type -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "assignments.headers.TYPE" | translate }}</mat-label>
          <mat-select formControlName="type">
            <mat-option [value]="type.id" *ngFor="let type of types$ | async">
              {{ type.text }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls.type.touched && form.controls.type?.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <!-- assignment type -->

        <!-- start date -->
        <div class="w-full">
          <app-date-range-calendar
            placeholder="{{ 'GENERALS.START-DATE' | translate }}"
            (change)="onCalendarChangeStartDate($event)"
            [singleDate]="startDate"
            [control]="form.get('start_date')"
            [maxDate]="endDate"
          ></app-date-range-calendar>
        </div>
        <!-- start date -->

        <!-- end date -->
        <div class="w-full">
          <app-date-range-calendar
            [placeholder]="'GENERALS.END-DATE' | translate"
            (change)="onCalendarChangeEndDate($event)"
            [singleDate]="endDate"
            [control]="form.get('end_date')"
            [minDate]="startDate"
            [isDisabled]="
              form.get('type').value === AssignmentTypeEnum.PERMANENT_TRANSFER
            "
          ></app-date-range-calendar>
        </div>
        <!-- end date -->

        <!-- home country -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label
            >{{ "assignments.headers.home-country" | translate }}
          </mat-label>
          <mat-select
            formControlName="home_country"
            [placeholder]="'LABEL.COUNTRY' | translate"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.COUNTRY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="homeCountriesFilterControl"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let country of filteredHomeCountries"
              [value]="country.country_code"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls.home_country.touched &&
              form.controls.home_country?.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <!-- home country -->

        <!-- home city -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-select
            formControlName="home_city"
            placeholder="{{ 'LABEL.HOME-CITY' | translate }} *"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.HOME-CITY' | translate"
                [formControl]="searchHomeCitiesControl"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of homeCities" [value]="city.name">
              {{ city.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            <app-form-errors [control]="form.get('home_city')">
            </app-form-errors>
          </mat-error>
        </mat-form-field>
        <!-- home city -->

        <!-- host country -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label
            >{{ "assignments.headers.host-country" | translate }}
          </mat-label>
          <mat-select
            formControlName="host_country"
            placeholder="{{ 'LABEL.COUNTRY' | translate }}"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.COUNTRY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="hostCountriesFilterControl"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let hostCountry of filteredHostCountries"
              [value]="hostCountry.country_code"
            >
              {{ hostCountry.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls.host_country.touched &&
              form.controls.host_country.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <!-- host country -->

        <!-- host city -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-select
            formControlName="host_city"
            placeholder="{{ 'LABEL.HOST-CITY' | translate }} *"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.HOST-CITY' | translate"
                [formControl]="searchHostCitiesControl"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of hostCities" [value]="city.name">
              {{ city.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            <app-form-errors [control]="form.get('host_city')">
            </app-form-errors>
          </mat-error>
        </mat-form-field>
        <!-- host city -->

        <!-- home manager name -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.home-manager" | translate
          }}</mat-label>
          <input
            matInput
            [readonly]="form.controls.home_manager_id.value > 0"
            formControlName="home_manager_name"
          />
        </mat-form-field>
        <!-- home manager name -->

        <!-- home manager email -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.HOME-MANAGER-EMAIL" | translate
          }}</mat-label>
          <input
            matInput
            [readonly]="form.controls.home_manager_id.value > 0"
            formControlName="home_manager_email"
          />
        </mat-form-field>
        <!-- home manager email -->

        <!-- host manager name -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>
            {{ "assignments.headers.host-manager" | translate }}
          </mat-label>
          <input matInput formControlName="host_manager_name" />
        </mat-form-field>
        <!-- host manager name -->

        <!-- host manager email -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.HOST-MANAGER-EMAIL" | translate
          }}</mat-label>
          <input matInput formControlName="host_manager_email" />
        </mat-form-field>
        <!-- host manager email -->

        <!-- host parent company -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.host-parent-company" | translate
          }}</mat-label>
          <input matInput formControlName="host_parent_company" />
        </mat-form-field>
        <!-- host parent company -->

        <!-- assignment purpose -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "assignments.headers.purpose" | translate }}</mat-label>
          <input matInput formControlName="purpose" />
        </mat-form-field>
        <!-- assignment purpose -->

        <!-- cost center -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.cost-center" | translate
          }}</mat-label>
          <input matInput formControlName="cost_center" />
        </mat-form-field>
        <!-- cost center -->

        <!-- business line -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.business-line" | translate
          }}</mat-label>
          <input matInput formControlName="business_line" />
        </mat-form-field>
        <!-- business line -->

        <!-- accompanying adults -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.accompanying-adults" | translate
          }}</mat-label>
          <input matInput formControlName="number_adults" />
        </mat-form-field>
        <!-- accompanying adults -->

        <!-- accompanying children -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{
            "assignments.headers.accompanying-children" | translate
          }}</mat-label>
          <input matInput formControlName="number_children" />
        </mat-form-field>
        <!-- accompanying children -->

        <!-- assignment notes -->
        <mat-form-field appearance="outline" class="w-full col-span-2">
          <mat-label>{{ "assignments.headers.notes" | translate }} </mat-label>
          <input matInput formControlName="notes" />
        </mat-form-field>
        <!-- assignment notes -->
      </div>
    </form>
  </div>
  <div class="dialog-footer">
    <button class="btn-primary" (click)="save()" [disabled]="!isLoaded">
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
