<div class="form-login">
  <div class="auth-form-wrapper">
    <form name="form" class="auth-form" (ngSubmit)="login()">
      <div class="auth-form-title-wrapper">
        <div class="auth-form-title">
          {{ "AUTH.SIGN-IN" | translate }}
        </div>
      </div>
      <div *ngIf="isLoading">
        <app-loader-spinner-general
          [repeat]="2"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <ng-container *ngIf="!isLoading">
        <input
          type="text"
          class="form-control"
          name="username"
          placeholder="{{ 'LABEL.EMAIL' | translate }}"
          [(ngModel)]="user.username"
          required
        />
        <div class="password-container">
          <input
            [type]="hide ? 'password' : 'text'"
            name="password"
            class="form-control"
            placeholder="{{ 'LABEL.PASSWORD' | translate }}"
            [(ngModel)]="user.password"
            required
          />
          <mat-icon class="show-pass" (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </div>
        <re-captcha
          *ngIf="!recaptchaHide && envShowRecaptcha"
          #recaptcha="ngModel"
          id="recaptcha"
          name="recaptcha"
          [(ngModel)]="user.recaptchaToken"
          required
          [class.is-invalid]="
            recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)
          "
        >
        </re-captcha>
      </ng-container>
      <button [disabled]="isLoading" class="btn orange-bg">
        {{ "AUTH.SIGN-IN" | translate }}
      </button>
    </form>
    <div class="under-form">
      <a class="action-link-orange-sm" routerLink="/recover-password">{{
        "AUTH.FORGOT-PASSWORD" | translate
      }}</a>
    </div>
  </div>

  <div class="auth-footer login-footer">
    <p>
      {{ "AUTH.FOOTER-SING-UP-LINK" | translate }}
      <span class="action-link-orange" [routerLink]="['/sign-up']">
        {{ "AUTH.SIGN-UP" | translate }}
      </span>
      !
    </p>
    <template #projectLink>
      <a
        class="action-link-orange"
        href="https://xpath.global/eu-funds/"
        target="_blank"
        >{{ "GENERALS.PROJECT" | translate | lowercase }}</a
      ></template
    >
    <p
      [innerHTML]="
        'GENERALS.READ_MORE_ABOUT'
          | translate : { value: projectLink.innerHTML }
      "
    ></p>
  </div>
</div>
