import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ReportsCaseService } from "@modules/reports/services/reports-case.service";
import { PageData } from "@modules/shared/models/page.model";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  forkJoin,
  map,
  startWith,
  switchMap,
  take,
} from "rxjs";
import { PieChartNameEnum } from "../../enums/pie-chart-names.enum";
import { CaseReportsParams } from "../../models/case-reports-params.model";
import { ChartServerData } from "../../models/chart-server-data.model";
import { CaseService } from "@api/cases";
import { ChartCardComponent } from "@modules/reports/reports-shared/components/chart-card/chart-card.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CountryPipe } from "@modules/shared/_pipes/country.pipe";
import moment, { Moment } from "moment";

@Component({
  selector: "app-estimated-vs-actual-duration",
  templateUrl: "./estimated-vs-actual-duration.component.html",
  styleUrls: ["./estimated-vs-actual-duration.component.scss"],
})
export class EstimatedVsActualDurationComponent
  extends ChartCardComponent
  implements OnInit
{
  boxes = [];
  form: FormGroup;
  readonly defaultChartData = [
    {
      name: PieChartNameEnum.DEFAULT,
      label: "avg. estimated duration",
      color: "#D0DAE4",
      value: 0,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: "avg. actual duration",
      color: "#D0DAE4",
      value: 1,
    },
  ];

  params = {
    page: 1,
    search_text: "",
    country: "",
    case_manager_id: "",
    client_id: "",
    status: "done",
  };
  totalServices: number;
  serviceSearchFilterCtrl: FormControl = new FormControl();
  countrySearchFilterCtrl: FormControl = new FormControl();
  managerSearchFilterCtrl: FormControl = new FormControl();
  clientSearchFilterCtrl: FormControl = new FormControl();
  filteredCountries$: BehaviorSubject<{ id: string; text: string }[]> =
    new BehaviorSubject<{ id: string; text: string }[]>([]);
  filteredManagers$: BehaviorSubject<{ id: string; text: string }[]> =
    new BehaviorSubject<{ id: string; text: string }[]>([]);
  filteredClients$: BehaviorSubject<{ id: string; text: string }[]> =
    new BehaviorSubject<{ id: string; text: string }[]>([]);
  countries: { id: string; text: string }[] = [];
  managers: { id: string; text: string }[] = [];
  clients: { id: string; text: string }[] = [];
  filteredServices$: ReplaySubject<CaseService[]> = new ReplaySubject<
    CaseService[]
  >(1);
  services: CaseService[] = [];
  filters: any[];
  searchFilterCtrls: FormControl[] = [];
  filteredOptions: BehaviorSubject<any[]>[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private reportsCaseService: ReportsCaseService,
    private countryPipe: CountryPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupForm();
    this.getItems();
    this.initEmptyPie();

    this.serviceSearchFilterCtrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe((search: string) => {
        if (this.params.search_text !== search) {
          this.params.search_text = search;
          this.params.page = 1;
          this.getItems();
        }
      });

    this.initSearchCountries();
    this.initSearchManagers();
    this.initSearchClients();

    this.handleCountryChanges();
    this.handleManagerChanges();
    this.handleClientChanges();

    this.form
      .get("service_id")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => {
        this.submitForm();
      });
  }

  private setupForm(): void {
    this.form = this.fb.group({
      country: [""],
      case_manager_id: [""],
      client_id: [""],
      service_id: ["", Validators.required],
      start_end_date_range: [""],
    });
  }

  protected setValues(chartData: ChartServerData[]): void {
    if (chartData.findIndex((item) => item.value > 0) === -1) {
      this.initEmptyPie();
      return;
    }

    this.pieChartCustomColors = chartData
      .filter(
        (data) =>
          data.name === PieChartNameEnum.ESTIMATED_PROCESS ||
          data.name === PieChartNameEnum.ESTIMATED_VS_ACTUAL
      )
      .map((item) => {
        return {
          ...item,
          value: item.color,
        };
      });

    this.chartItems = chartData
      .filter(
        (data) =>
          data.name === PieChartNameEnum.ESTIMATED_PROCESS ||
          data.name === PieChartNameEnum.ESTIMATED_VS_ACTUAL
      )
      .map((item) => {
        return {
          ...item,
          value: item.value,
        };
      });

    this.boxes = chartData.filter(
      (data) =>
        data.name === PieChartNameEnum.ESTIMATED_PROCESS ||
        data.name === PieChartNameEnum.AVERAGE_PROCESS
    );

    // Swapping item places so Actual is second
    [this.boxes[0], this.boxes[1]] = [this.boxes[1], this.boxes[0]];
  }

  private submitForm(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const params: CaseReportsParams = this.form.getRawValue();

    if (!params.client_id) {
      delete params.client_id;
    }

    if (!params.case_manager_id) {
      delete params.case_manager_id;
    }

    this.isLoading = true;
    this.reportsCaseService
      .getEstimatedActualProcessDuration(params)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (data) => {
          this.setValues(data);
        },
        error: () => {
          this.initEmptyPie();
        },
      });
  }

  getItems(triggeredByScroll: boolean = false): void {
    this.isLoading = true;

    this.reportsCaseService
      .getServices(this.params, this.reload)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.reload = true;
        })
      )
      .subscribe({
        next: (data: PageData<CaseService>) => {
          const offers = data.items;

          if (triggeredByScroll) {
            this.services = [...this.services, ...offers];
          } else {
            this.services = offers;
          }

          const filters = data.filters.reduce((acc, filter) => {
            acc[filter.id] = filter.data;
            return acc;
          }, {});

          const newCountries = filters["host_country"] || [];

          if (JSON.stringify(newCountries) !== JSON.stringify(this.countries)) {
            this.countries = [...newCountries];
            this.filteredCountries$.next([...this.countries]);
          }

          this.managers = filters["manager_id"] || [];
          this.clients = filters["client_id"] || [];

          this.filteredManagers$.next(this.managers);
          this.filteredClients$.next(this.clients);
          this.filteredServices$.next(this.services);

          this.totalServices = data.total;
        },
      });
  }

  getFilteredCountries(
    search: string
  ): Observable<{ id: string; text: string }[]> {
    const countryObservables = this.countries.map((country) =>
      this.countryPipe
        .transform(country.text)
        .pipe(map((transformedText) => ({ ...country, transformedText })))
    );

    return forkJoin(countryObservables).pipe(
      map((transformedCountries) =>
        transformedCountries.filter((country) =>
          country.transformedText.toLowerCase().includes(search.toLowerCase())
        )
      )
    );
  }

  onCalendarChange(dateRange: {
    start_date: Moment | string;
    end_date: Moment | string;
  }): void {
    if (
      moment.isMoment(dateRange.start_date) &&
      moment.isMoment(dateRange.end_date)
    ) {
      this.form.patchValue({
        start_end_date_range: `${dateRange.start_date
          .add(1, "d")
          .format("Y/M/D")};${dateRange.end_date.add(1, "d").format("Y/M/D")}`,
      });
    } else {
      this.form.patchValue({
        start_end_date_range: "",
      });
    }
  }

  private handleCountryChanges(): void {
    this.form
      .get("country")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe((countryCode) => {
        this.form.get("service_id").reset();
        this.params.country =
          typeof countryCode !== "undefined" ? countryCode : "";
        this.filteredServices$.next([]);
        this.initEmptyPie();
        this.getItems();
      });
  }

  private handleManagerChanges(): void {
    this.form
      .get("case_manager_id")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe((managerId) => {
        this.form.get("service_id").reset();
        this.params.case_manager_id =
          typeof managerId !== "undefined" ? managerId : "";
        this.initEmptyPie();
        this.getItems();
      });
  }

  private handleClientChanges(): void {
    this.form
      .get("client_id")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe((clientId) => {
        this.form.get("service_id").reset();
        this.params.client_id = typeof clientId !== "undefined" ? clientId : "";
        this.initEmptyPie();
        this.getItems();
      });
  }

  private initSearchCountries(): void {
    this.countrySearchFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        switchMap((search: string) => this.getFilteredCountries(search))
      )
      .subscribe((filteredCountries) => {
        this.filteredCountries$.next(filteredCountries);
      });
  }

  private initSearchManagers(): void {
    this.managerSearchFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        takeUntilDestroyed(this.destroyRef),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText) => {
        this.filteredManagers$.next(
          this.managers.filter((option) =>
            option.text.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      });
  }

  private initSearchClients(): void {
    this.clientSearchFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        takeUntilDestroyed(this.destroyRef),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText) => {
        this.filteredClients$.next(
          this.clients.filter((option) =>
            option.text.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      });
  }

  getNextBatch(): void {
    this.params.page += 1;
    this.getItems(true);
  }

  trackByItem(index: number, item: { id: string; text: string }) {
    return item.id;
  }

  get PieChartName() {
    return PieChartNameEnum;
  }
}
