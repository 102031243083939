<app-dialog-header>
  {{ "GENERALS.PLACE-ORDER" | translate }}
</app-dialog-header>

<div class="dialog-body">
  <mat-stepper class="quote-order" #stepper linear>
    <mat-step [stepControl]="cartItemsForm" label="cart" class="p-0">
      <ng-template matStepLabel>
        {{ "GENERALS.ORDER-ITEMS" | translate }}
      </ng-template>

      <app-items-step
        [providerId]="providerId"
        [clientId]="clientId"
        [initialQuotes]="dialogData.quotes"
        [itemsForm]="cartItemsForm"
      ></app-items-step>
    </mat-step>
    <mat-step [stepControl]="checkoutForm" label="checkout" class="p-0">
      <ng-template matStepLabel>
        {{ "GENERALS.CHECKOUT" | translate }}
      </ng-template>
      <ng-template matStepContent>
        <app-checkout-step
          [checkoutForm]="checkoutForm"
          [clientId]="clientId"
        ></app-checkout-step>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>

<div class="dialog-footer">
  <button
    type="button"
    class="btn-primary"
    (click)="toCheckout()"
    *ngIf="stepper.selectedIndex === 0"
    [disabled]="cartItemsForm.invalid"
  >
    <mat-icon svgIcon="arrow-right" class="btn-icon"></mat-icon>
    {{ "GENERALS.PROCEED-TO-CHECKOUT" | translate }}
  </button>
  <button
    type="button"
    class="btn-primary"
    (click)="submit()"
    *ngIf="stepper.selectedIndex === 1"
    [disabled]="orderForm.invalid || isLoading"
  >
    <mat-icon svgIcon="check-outline" class="btn-icon"></mat-icon>
    {{ "ACTIONS.FINISH" | translate }}
  </button>
</div>
<!-- end modal -->
