import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CaseFollower, EligibleUserData } from "@api/cases";
import { PageData } from "@modules/shared/models/page.model";
import { ValueList } from "@modules/shared/models/value-list.model";
import { concat, Observable, of } from "rxjs";
import { map, pairwise, shareReplay, tap } from "rxjs/operators";
import { environment } from "@environment/environment";
import { CaseTaskData, CreateTaskData } from "../models";
import { CaseTaskMapperService } from "@api/case-tasks";
import { ModelChangeData } from "../models/model-change.data";
import { Store } from "@ngrx/store";
import { TaskActions } from "../actions";

export interface StatusData {
  id: string;
  needs_confirmation: boolean;
  needs_reason: boolean;
  text: string;
}

export interface CaseTaskListingFilters {
  expat_case_id: string | number;
  per_page: string | number;
  upcoming: string | number;
  start_date: string | number;
  page: string | number;
  period: string | number;
  include: string;
  client_id: string | number;
  case_service_id: string | number;
  parent_id: string | number;
}

export interface ShiftTaskStartDateActionInput {
  task_id: number;
  start_date: Date;
}

export interface UpdateResponse {
  task: CaseTaskData;
  other_changes?: Array<ModelChangeData>;
}

@Injectable()
export class CaseTasksService {
  private eligibleUserTaskMap: Map<string, Observable<any>> = new Map();

  private statusTransitions$: Observable<ValueList<StatusData[]>>;
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly caseTaskMapperService: CaseTaskMapperService
  ) {}

  public fetchTasks(
    filters: Partial<CaseTaskListingFilters> = {}
  ): Observable<PageData<CaseTaskData>> {
    return this.http
      .get<any>(environment.gateway_endpoint + `cases/tasks`, {
        params: filters,
      })
      .pipe(
        map((response: any) => {
          const data = response.result;
          data.items = this.caseTaskMapperService.mapMany(data.items);
          return data;
        })
      );
  }

  public list(
    filters: Partial<CaseTaskListingFilters> = {}
  ): Observable<PageData<CaseTaskData>> {
    const include: Array<string> = (filters?.include ?? "")
      .split(";")
      .filter(
        (value, index, arr) =>
          arr.indexOf(value) === index && value !== "filters" && value !== ""
      );
    const withoutFilters = { ...filters, include: include.join(";") };
    const withFilters = {
      ...filters,
      per_page: 1,
      include: [...include, "filters"].join(";"),
    };
    return concat(
      of({}),
      this.http
        .get<any>(environment.gateway_endpoint + `cases/tasks`, {
          params: withoutFilters,
        })
        .pipe(map((data) => data?.result)),
      this.http
        .get<any>(environment.gateway_endpoint + `cases/tasks`, {
          params: withFilters,
        })
        .pipe(map((res) => ({ filters: res.result?.filters })))
    ).pipe(
      pairwise(),
      map(([prev, curr]) => ({ ...prev, ...curr })),
      map((response: any) => {
        const data = response;
        data.items = this.caseTaskMapperService.mapMany(data.items);
        return data;
      })
    );
  }

  public getTasksByTag(params): Observable<PageData<CaseTaskData>> {
    return this.http
      .get<any>(environment.gateway_endpoint + `cases/tasks`, { params })
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.caseTaskMapperService.mapMany(data.items);
          return data;
        })
      );
  }

  public fetchTask(id): Observable<CaseTaskData> {
    return this.http
      .get<any>(environment.gateway_endpoint + "cases/tasks/" + id)
      .pipe(
        map((response: any) =>
          this.caseTaskMapperService.mapOne(response.result.task)
        )
      );
  }

  public details(id): Observable<CaseTaskData> {
    return this.fetchTask(id);
  }

  protected requestUpdate(input: Partial<CaseTaskData>): Observable<{
    task: CaseTaskData;
    other_changes?: Array<ModelChangeData>;
  }> {
    const data = this.caseTaskMapperService.prepare(input);
    return this.http
      .put<any>(
        environment.gateway_endpoint + "cases/tasks/" + input.id,
        data,
        { params: { include: "user_permissions" } }
      )
      .pipe(
        map((response: any) => ({
          task: this.caseTaskMapperService.mapOne(response.result.task),
          other_changes: response.result.other_changes,
        }))
      );
  }

  public update(input: Partial<CaseTaskData>): Observable<{
    task: CaseTaskData;
    other_changes?: Array<ModelChangeData>;
  }> {
    this.store.dispatch(
      TaskActions.requestUpdateTask({ task: { id: input.id, changes: input } })
    );
    return this.requestUpdate(input).pipe(
      tap({
        next: ({ task, other_changes }) =>
          this.store.dispatch(
            TaskActions.requestUpdateTaskSuccess({ task, other_changes })
          ),
        error: (error) =>
          this.store.dispatch(TaskActions.requestUpdateTaskError({ error })),
      })
    );
  }

  public shiftDates(
    input: ShiftTaskStartDateActionInput
  ): Observable<UpdateResponse> {
    this.store.dispatch(
      TaskActions.requestUpdateTask({
        task: {
          id: input.task_id,
          changes: {},
        },
      })
    );
    return this.requestShiftDates(input).pipe(
      tap({
        next: ({ task, other_changes }) =>
          this.store.dispatch(
            TaskActions.requestUpdateTaskSuccess({ task, other_changes })
          ),
        error: (error) =>
          this.store.dispatch(TaskActions.requestUpdateTaskError({ error })),
      })
    );
  }

  protected requestShiftDates(
    input: ShiftTaskStartDateActionInput
  ): Observable<UpdateResponse> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + `cases/tasks/${input.task_id}/shift`,
        { start_date: input.start_date }
      )
      .pipe(
        map((response) => ({
          task: this.caseTaskMapperService.mapOne(response.result.task),
          other_changes: response.result.other_changes,
        }))
      );
  }

  public assignUser(
    task: CaseTaskData,
    userId: number
  ): Observable<UpdateResponse> {
    this.store.dispatch(
      TaskActions.requestUpdateTask({
        task: { id: task.id, changes: { assignee_id: userId } },
      })
    );
    return this.requestAssignUser(task, userId).pipe(
      tap({
        next: ({ task, other_changes }) =>
          this.store.dispatch(
            TaskActions.requestUpdateTaskSuccess({ task, other_changes })
          ),
        error: (error) =>
          this.store.dispatch(TaskActions.requestUpdateTaskError({ error })),
      })
    );
  }

  protected requestAssignUser(
    task: CaseTaskData,
    userId: number
  ): Observable<UpdateResponse> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          `cases/${task.expat_case_id}/tasks/${task.id}/managers`,
        { user_id: userId }
      )
      .pipe(
        map((response) => ({
          task: this.caseTaskMapperService.mapOne(response.task),
          other_changes: response.other_changes,
        }))
      );
  }

  public getEligibleUsers(
    caseId: number,
    taskId: number
  ): Observable<EligibleUserData[]> {
    let users = this.eligibleUserTaskMap.get(`${taskId}`);
    if (!users) {
      users = this.http
        .get<any>(
          environment.gateway_endpoint +
            `cases/${caseId}/tasks/${taskId}/eligible-users`
        )
        .pipe(
          map((response: any) => response.result),
          tap({
            error: () => {
              this.eligibleUserTaskMap.delete(`${taskId}`);
            },
          }),
          shareReplay(1)
        );
      this.eligibleUserTaskMap.set(`${taskId}`, users);
    }
    return users;
  }

  public getStatusTransitions(): Observable<ValueList<StatusData[]>> {
    if (!this.statusTransitions$) {
      this.statusTransitions$ = this.http
        .get<any>(
          environment.gateway_endpoint + "cases/tasks/status-transitions"
        )
        .pipe(
          map((response: any) => response.result.transitions),
          shareReplay(1)
        );
    }
    return this.statusTransitions$;
  }

  public followers(
    expatCaseId: number,
    id: number
  ): Observable<{
    result: ValueList<CaseFollower>;
    allowed_actions: ValueList<boolean>;
  }> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}cases/${expatCaseId}/tasks/${id}/managers`
      )
      .pipe(map((response: any) => response));
  }

  private requestCreate(task: CreateTaskData): Observable<UpdateResponse> {
    return this.http
      .post<any>(environment.gateway_endpoint + `cases/tasks`, task, {
        params: { include: "user_permissions" },
      })
      .pipe(map((response) => response.result));
  }

  public create(task: CreateTaskData): Observable<{
    task: CaseTaskData;
    other_changes?: Array<ModelChangeData>;
  }> {
    this.store.dispatch(
      TaskActions.requestCreateTask({
        task,
      })
    );
    return this.requestCreate(task).pipe(
      tap({
        next: ({ task, other_changes }) =>
          this.store.dispatch(
            TaskActions.requestCreateTaskSuccess({ task, other_changes })
          ),
        error: (error) =>
          this.store.dispatch(TaskActions.requestCreateTaskError({ error })),
      })
    );
  }
}
