<app-dialog-header>
  {{ "AUTH.CHANGE-PASSWORD" | translate }}
</app-dialog-header>

<div class="dialog-body" [formGroup]="form">
  <mat-form-field
    appearance="outline"
    class="row w-100 password_validate_field"
  >
    <mat-label>{{ "GENERALS.PASSWORD" | translate }}</mat-label>
    <input
      matInput
      [type]="hide ? 'password' : 'text'"
      (focusin)="hideError = true"
      (focusout)="hideError = false"
      formControlName="password"
    />
    <app-form-errors *ngIf="hideError" [control]="form.get('password')">
    </app-form-errors>
    <mat-icon class="show-pass center" (click)="hide = !hide">{{
      hide ? "visibility_off" : "visibility"
    }}</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" class="row w-100">
    <mat-label>{{ "GENERALS.PASSWORD-CONFIRMATION" | translate }}</mat-label>
    <input matInput type="password" formControlName="password_confirmation" />
    <mat-error>
      <app-form-errors [control]="form.get('password_confirmation')">
      </app-form-errors>
    </mat-error>
  </mat-form-field>
</div>

<div class="dialog-footer">
  <button (click)="submit()" class="btn-primary">
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
