<div class="button-bar">
  <button
    class="btn-outline-primary"
    *ngIf="expat.user?.status_id === UserStatuses.statuses.active"
    (click)="resetPassword()"
  >
    {{ "AUTH.CHANGE-PASSWORD" | translate }}
  </button>
  <button
    [disabled]="isLoading"
    *ngIf="this.expat?.user.status_id === UserStatuses.statuses.pending"
    class="btn-outline-primary disabled:opacity-50"
    (click)="invite()"
  >
    {{ "GENERALS.INVITE-EXPAT" | translate }}
  </button>
  <button
    [disabled]="isLoading"
    *ngIf="this.expat?.user.status_id === UserStatuses.statuses.invited"
    class="btn-outline-primary disabled:opacity-50"
    (click)="invite(true)"
  >
    {{ "GENERALS.RESEND-INVITE-EXPAT" | translate }}
  </button>
  <button
    *ngIf="expat?.allowed_actions?.delete"
    class="btn-outline-primary"
    (click)="delete()"
  >
    {{ "GENERALS.DELETE" | translate }}
  </button>
</div>
