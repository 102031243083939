<div [formGroup]="form">
  <div class="wrapper-section pt-3">
    <h6 class="mb-3 font-semibold">
      {{ "assignments.HOME-LOCATION-DETAILS" | translate }}
    </h6>
    <div class="field-grid">
      <!-- home manager name -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label
          >{{ "assignments.headers.home-manager" | translate }}
        </mat-label>
        <input
          matInput
          [readonly]="form.controls.home_manager_id.value > 0"
          formControlName="home_manager_name"
        />
      </mat-form-field>
      <!-- home manager name -->

      <!-- home manager email -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.HOME-MANAGER-EMAIL" | translate
        }}</mat-label>
        <input
          matInput
          [readonly]="form.controls.home_manager_id.value > 0"
          formControlName="home_manager_email"
        />
      </mat-form-field>
      <!-- home manager email -->

      <!-- home company -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.home-company" | translate
        }}</mat-label>
        <input matInput formControlName="home_company" />
      </mat-form-field>
      <!-- home company -->

      <!-- home business unit -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.home-business-unit" | translate
        }}</mat-label>
        <input matInput formControlName="home_business_unit" />
      </mat-form-field>
      <!-- home business unit -->
    </div>
    <div class="flex w-full gap-x-4">
      <!-- home cost center -->
      <mat-form-field appearance="outline" class="flex-1">
        <mat-label>{{
          "assignments.headers.home-cost-center" | translate
        }}</mat-label>
        <input matInput formControlName="home_cost_center" />
      </mat-form-field>
      <!-- home cost center -->

      <!-- home segment -->
      <mat-form-field appearance="outline" class="flex-1">
        <mat-label>{{
          "assignments.headers.home-segment" | translate
        }}</mat-label>
        <input matInput formControlName="home_segment" />
      </mat-form-field>
      <!-- home segment -->
    </div>
  </div>
  <hr *ngIf="!isReview" class="pt-3" />
  <div classs="wrapper-section">
    <h6 class="mb-3 font-semibold">
      {{ "assignments.HOST-LOCATION-DETAILS" | translate }}
    </h6>
    <div class="field-grid">
      <!-- host manager name -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.host-manager" | translate
        }}</mat-label>
        <input matInput formControlName="host_manager_name" />
      </mat-form-field>
      <!-- host manager name -->

      <!-- host manager email -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.HOST-MANAGER-EMAIL" | translate
        }}</mat-label>
        <input matInput formControlName="host_manager_email" />
      </mat-form-field>
      <!-- host manager email -->

      <!-- host company -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.host-company" | translate
        }}</mat-label>
        <input matInput formControlName="host_company" />
      </mat-form-field>
      <!-- host company -->

      <!-- host business unit -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.host-business-unit" | translate
        }}</mat-label>
        <input matInput formControlName="host_business_unit" />
      </mat-form-field>
      <!-- host business unit -->
    </div>
    <div class="flex w-full gap-x-4">
      <!-- host cost center -->
      <mat-form-field appearance="outline" class="flex-1">
        <mat-label>{{
          "assignments.headers.host-cost-center" | translate
        }}</mat-label>
        <input matInput formControlName="host_cost_center" />
      </mat-form-field>
      <!-- host cost center -->

      <!-- host segment -->
      <mat-form-field appearance="outline" class="flex-1">
        <mat-label>{{
          "assignments.headers.host-segment" | translate
        }}</mat-label>
        <input matInput formControlName="host_segment" />
      </mat-form-field>
      <!-- host segment -->
    </div>
  </div>

  <hr *ngIf="!isReview" class="pt-3" />

  <div classs="wrapper-section">
    <h6 class="mb-3 font-semibold">
      {{ "assignments.ADDITIONAL_DETAILS" | translate }}
    </h6>
    <div class="field-grid">
      <!-- payroll -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "assignments.headers.payroll" | translate }}</mat-label>
        <mat-select
          formControlName="payroll"
          disableOptionCentering
          panelClass="create-assignemt-panel"
        >
          <mat-option
            [value]="item"
            *ngFor="let item of ['home', 'host', 'other']"
          >
            {{ "assignments.headers.payroll-" + item | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- payroll -->

      <!-- assignment sponsor -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "assignments.headers.sponsor" | translate }}</mat-label>
        <input matInput formControlName="sponsor" />
      </mat-form-field>
      <!-- assignment sponsor -->

      <!-- accompanying adults -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.accompanying-adults" | translate
        }}</mat-label>
        <input type="number" matInput formControlName="number_adults" />
      </mat-form-field>
      <!-- accompanying adults -->

      <!-- accompanying children -->
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{
          "assignments.headers.accompanying-children" | translate
        }}</mat-label>
        <input type="number" matInput formControlName="number_children" />
      </mat-form-field>
      <!-- accompanying children -->
    </div>

    <!-- assignment notes -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "assignments.headers.notes" | translate }} </mat-label>
      <input matInput formControlName="notes" />
    </mat-form-field>
    <!-- assignment notes -->
  </div>
</div>
