import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { ExpatData } from "src/app/_models/expat";
import { AssignmentData } from "@api/assignments/models/assignment.model";
import { AssignmentsService } from "@api/assignments/services/assignments.service";
import { CreateAssignmentModalComponent } from "@modules/clients/modules/assignments/modals/create-assignment-modal/create-assignment-modal.component";
import { ValueList } from "@modules/shared/models/value-list.model";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { UsersService } from "src/app/_services/users.service";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import {
  debounceTime,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { AddExpatForm } from "@modules/shared/_components/add-expat-form/add-expat-form.component";
import { ExpatListItem } from "../assign-expat-dialog/assign-expat-dialog.component";
import { AssignmentAssignableResourceEnum } from "@modules/clients/modules/assignments/enums/assignment-assignable-resource.enum";

@Component({
  selector: "app-offer-expat-item",
  templateUrl: "./offer-expat-item.component.html",
  styleUrls: ["./offer-expat-item.component.scss"],
})
export class OfferExpatItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  expatsList: ExpatListItem[];

  @Input()
  order;

  @Input()
  selectedExpatsList: { expat: number; assignment: number }[] = [];

  expatsList$: BehaviorSubject<ExpatListItem[]>;

  expatSearchControl = new UntypedFormControl();
  filteredExpats$: Observable<ExpatData[]>;

  @Input()
  form: FormGroup;

  assignmentsSearchForm: UntypedFormControl;
  assignments$: Observable<AssignmentData[]>;
  filteredAssignments$: Observable<AssignmentData[]>;
  noResultValue$: Subject<string>;
  minDate;
  customList: boolean = false;
  user$: Observable<UserData>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<OfferExpatItemComponent>,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private assignmentService: AssignmentsService,
    private dialog: MatDialog
  ) {
    this.expatsList$ = new BehaviorSubject([]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expatsList && !this.customList) {
      this.expatsList$.next(changes.expatsList.currentValue);
    }
  }

  ngOnInit() {
    this.user$ = this.userService.getCurrentUser();

    this.expatsList$.next(this.expatsList);
    this.minDate = new Date();
    this.form
      .get("expat")
      .valueChanges.pipe(
        startWith(this.form.get("expat").value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        if (value === "new_expat" || !value) {
          this.form.get("dependent").disable({ emitEvent: false });
        } else {
          this.form.get("dependent").enable({ emitEvent: false });
        }
      });
    this.form
      .get("expat")
      .valueChanges.pipe(
        filter((data) => data === "new_expat"),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.addExpat()
          .pipe(
            takeUntil(this.unsubscribe$),
            tap((res) => {
              if (!res) {
                this.form.get("expat").setValue("");
              }
            }),
            switchMap((result) => {
              return this.getExpatList().pipe(
                map((list) => ({ ...result, list }))
              );
            })
          )
          .subscribe(({ list, id }) => {
            this.expatsList$.next(list);
            this.form.patchValue({
              expat: id,
            });
          });
      });

    this.form
      .get("assignment")
      .valueChanges.pipe(
        filter((data) => data === "new_assignment"),
        switchMap(() =>
          this.addAssignment(this.form.get("expat").value).pipe(
            switchMap((value) => {
              if (!value) {
                this.form.get("assignment").setValue("");

                return of("");
              }

              return this.assignmentService
                .updateStatus([value.assignment.id], "approved")
                .pipe(
                  switchMap(() => {
                    return of(value);
                  })
                );
            })
          )
        )
      )
      .subscribe((value) => {
        this.assignments$ = this.assignmentService
          .list({
            expat_id: this.form.get("expat").value,
            assignable_to: AssignmentAssignableResourceEnum.ORDER,
          })
          .pipe(map((result) => result.items));

        this.initAssignmentSearchControl();
        this.form.patchValue({ assignment: value.assignment.id });
      });

    this.initExpatSearchControl();
    this.initAssignmentSearchControl();
  }

  checkAssignment(id: number): boolean {
    return this.selectedExpatsList.some((item) => item.assignment === id);
  }

  private initExpatSearchControl() {
    this.assignments$ = this.form.controls.expat.valueChanges.pipe(
      startWith(this.form.controls.expat.getRawValue()),
      filter((value) => Number.isInteger(value)),
      switchMap((value) =>
        this.assignmentService
          .list({
            expat_id: value,
            assignable_to: AssignmentAssignableResourceEnum.ORDER,
          })
          .pipe(map((result) => result.items))
      ),
      tap((assignments: any[]) => {
        if (assignments.length === 1) {
          this.form.controls.assignment.setValue(assignments[0].id);
        } else {
          this.form.controls.assignment.setValue("");
        }
      })
    );

    this.expatSearchControl = new UntypedFormControl();

    this.noResultValue$ = new Subject();

    this.noResultValue$
      .pipe(
        takeUntil(this.unsubscribe$),
        startWith(""),
        pairwise(),
        filter(([prev, current]) => current !== prev),
        debounceTime(1000),
        switchMap(([_, current]) => this.getExpatList(current)),
        tap(() => (this.customList = true))
      )
      .subscribe((expats) => {
        if (expats.length === 0) {
          return;
        }
        this.expatsList$.next(expats);
      });

    this.filteredExpats$ = combineLatest([
      this.expatSearchControl.valueChanges.pipe(startWith("")),
      this.expatsList$,
    ]).pipe(
      filter(([searchInput, expats]) => !!expats),
      map(([searchInput, expats]) => {
        if (!searchInput) {
          return [searchInput, expats, expats];
        }

        const filteredResults = this.filterExpats(expats, searchInput);

        return [searchInput, expats, filteredResults];
      }),
      tap(([searchInput, expats, filteredResults]) => {
        if (filteredResults.length === 0) {
          this.noResultValue$.next(searchInput);
        }
      }),
      map(([searchInput, expats, filteredResults]) => filteredResults),

      shareReplay(1)
    );
  }

  private initAssignmentSearchControl() {
    this.assignmentsSearchForm = new UntypedFormControl();
    this.filteredAssignments$ = combineLatest([
      this.assignmentsSearchForm.valueChanges.pipe(startWith("")),
      this.assignments$,
    ]).pipe(
      map(([searchInput, assignments]) => {
        if (!searchInput) {
          return assignments;
        }

        return this.filterAssignments(assignments, searchInput);
      }),
      shareReplay(1)
    );
  }

  public addExpat() {
    const dialogRef = this.dialog.open(AddExpatForm, {
      data: {
        openedFrom: "order",
        client_id: this.order.client.id,
      },
    });
    return dialogRef.afterClosed();
  }

  public addAssignment(expatId: number) {
    const dialogRef = this.dialog.open(CreateAssignmentModalComponent, {
      data: {
        openedFrom: "order",
        expatId,
        clientId: this.order.client.id,
      },
    });

    return dialogRef.afterClosed();
  }

  getExpatList(search: string = undefined) {
    const params: ValueList<any> = { status_id: 3 };
    if (search) {
      params.search_text = search;
    }

    return this.user$
      .pipe(
        take(1),
        switchMap((user: UserData) =>
          this.expatsService.list(user.entity_id, this.order.client.id, params)
        )
      )
      .pipe(
        map((response) => {
          const expatsList = response.result.items?.map((exp) => ({
            id: exp.id,
            name: exp.name,
          }));
          return expatsList;
        })
      );
  }

  private filterExpats(
    expats: ExpatListItem[],
    searchInput: string
  ): ExpatListItem[] {
    const filterValue = this.normalizeValue(searchInput);

    return expats.filter((expat) => {
      const normalizedExpatName = this.normalizeValue(expat.name);

      return normalizedExpatName.includes(filterValue);
    });
  }

  private filterAssignments(
    assignments: AssignmentData[],
    searchInput: string
  ): AssignmentData[] {
    const filterValue = this.normalizeValue(searchInput);

    return assignments.filter((assignment) => {
      const normalizedHomeCountry = this.normalizeValue(
        assignment.home_country
      );

      const normalizedHostCity = this.normalizeValue(assignment.host_city);
      const normalizedStartDate = this.normalizeValue(assignment.start_date);

      return (
        normalizedHomeCountry.includes(filterValue) ||
        normalizedHostCity.includes(filterValue) ||
        normalizedStartDate.includes(filterValue)
      );
    });
  }

  private normalizeValue(str: string): string {
    return str.replace(/\s+/g, " ").trim().toLowerCase();
  }
}
