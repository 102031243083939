import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { OrderData, OrderServiceData } from "@api/billing";
import { User } from "@models/user";
import { Observable } from "rxjs";
import { startWith, switchMap, tap } from "rxjs/operators";
import { OrdersService } from "src/app/_services/orders.service";

export function minimumServicesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }
    const valid = value.some((val) => val.enabled);
    return !valid ? { minimumServices: true } : null;
  };
}
@Component({
  selector: "app-order-services-selector",
  templateUrl: "./order-services-selector.component.html",
  styleUrls: ["./order-services-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderServicesSelectorComponent implements OnInit {
  caseForm: UntypedFormGroup;
  currentUser$: Observable<User>;

  @Input() submitEvent: Observable<void>;
  @Input() order: OrderData;

  servicesFromArray: UntypedFormArray = new UntypedFormArray(
    [],
    minimumServicesValidator()
  );

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly orderService: OrdersService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.submitEvent.subscribe(() => {
      if (!this.servicesFromArray.valid) {
        this.servicesFromArray.setErrors({ minimumServices: true });
        this.servicesFromArray.markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;
    this.caseForm.setControl("services", this.servicesFromArray);
    if (this.order) {
      this.populateForm(this.order);
    } else {
      this.caseForm
        .get("order_id")
        .valueChanges.pipe(
          startWith(this.caseForm.get("order_id").value),
          tap(() => this.servicesFromArray.clear()),
          switchMap((order) => this.orderService.getOrderById({}, order))
        )
        .subscribe((order) => {
          this.populateForm(order);
        });
    }
  }

  populateForm(order: OrderData) {
    this.servicesFromArray.clear();
    if (order.details) {
      order.details.forEach((detail: OrderServiceData) => {
        const totalExpats = detail.expats.filter(
          (expat) => expat.expat_id !== this.caseForm.get("expat_id").value
        ).length;

        this.servicesFromArray.push(
          new UntypedFormGroup({
            service_id: new UntypedFormControl(
              detail.offer_id,
              Validators.required
            ),
            service_name: new UntypedFormControl(
              detail.offer_name,
              Validators.required
            ),
            enabled: new UntypedFormControl(false, Validators.required),
            remaining: new UntypedFormControl(
              detail.quantity - totalExpats,
              Validators.required
            ),
          })
        );
      });
    }

    this.changeDetector.detectChanges();
  }

  public get serviceControls(): FormGroup[] {
    return this.servicesFromArray.controls as FormGroup[];
  }
}
