import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map, tap } from "rxjs/operators";
import { ProvidersCommissions } from "@models/providerscommissions";
import { ActivatedRoute } from "@angular/router";
import { AccountActions, CompanyData } from "@api/account";
import { Store } from "@ngrx/store";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ProvidersService {
  private providersUrl = "entities/providers";

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  // GET Providers
  getProviders(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl,
      { params: params }
    );
  }

  // GET Provider by id
  getProvider(id: number): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl + "/" + id
    );
  }

  /**
   * Add commission for provider
   * @param commissions
   */
  addCommission(commissions: ProvidersCommissions): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.providersUrl + "/commission",
        commissions,
        httpOptions
      )
      .pipe(
        tap(() => this.store.dispatch(AccountActions.reloadAccount())),
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Update categories for provider
   * @param categories
   * @param providerId
   */
  updateCategories(categories, providerId): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/categories`,
        categories,
        httpOptions
      )
      .pipe(
        tap(() => this.store.dispatch(AccountActions.reloadAccount())),
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Set countries for service commissions
   * @param countries
   */
  setCountryForCommission(countries, providerId): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}${this.providersUrl}/${providerId}/countries`,
        countries,
        httpOptions
      )
      .pipe(
        tap(() => this.store.dispatch(AccountActions.reloadAccount())),
        map((model) => {
          return model;
        })
      );
  }

  // PUT Edit Provider Company info
  updateProviderCompanyDetails(company: CompanyData): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.providersUrl + "/" + company.id,
        company,
        httpOptions
      )
      .pipe(
        tap(() => this.store.dispatch(AccountActions.reloadAccount())),
        map((model) => {
          return model;
        })
      );
  }

  // PUT Edit Provider Account info
  updateProvierAccountDetails(provider): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          this.providersUrl +
          "/user/" +
          provider.entity_id,
        provider,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Get provider categories
  getProviderCategories(id): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}${this.providersUrl}/${id}/categories`
    );
  }

  // When admin wants to login as provider
  switchToProvider(data): Observable<any> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + "switch-to-provider/" + data.token
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Get Offer Countries for provider
  getProviderCountries(providerId): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        "entities/providers/" +
        providerId +
        "/countries"
    );
  }

  // GET Provider history
  getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "history/provider/" + id,
      { params: params }
    );
  }

  // Get Status transitions for provider
  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/provider/status-transitions"
    );
  }

  updateStatus(statusId, entityId, reason = null) {
    const body = { status_id: statusId };

    return this.http.patch(
      environment.gateway_endpoint + "entities/providers/" + entityId,
      body,
      httpOptions
    );
  }

  getStripeAuthorizationCode() {
    return this.route.snapshot.queryParamMap.get("stripe");
  }

  setStripeAccount(): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          this.providersUrl +
          "/set-stripe-account",
        {},
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getStripeOnBoardingUrl(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.providersUrl +
        "/get-stripe-onboarding-url"
    );
  }

  getStripeDashboardUrl(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.providersUrl +
        "/get-stripe-dashboard-url"
    );
  }
}
