import { Component, OnInit } from "@angular/core";
import { ControlContainer, FormControl, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-phone-number-input",
  templateUrl: "./phone-number-input.component.html",
  styleUrls: ["./phone-number-input.component.scss"],
})
export class PhoneNumberInputComponent implements OnInit {
  accountForm: FormGroup;
  phonePrefix;
  filteredPhonePrefix;
  phonePrefixSearchControl: FormControl;

  constructor(
    private controlContainer: ControlContainer,
    private dictionariesService: DictionariesService
  ) {
    this.phonePrefixSearchControl = new FormControl("");
  }

  ngOnInit() {
    this.accountForm = <FormGroup>this.controlContainer.control;
    this.getPhoneCodeList();

    this.phonePrefixSearchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        if (!value) {
          this.filteredPhonePrefix = this.phonePrefix;
        }

        this.filteredPhonePrefix = this.filteredPhonePrefix.filter((prefix) =>
          prefix.name.toLowerCase().includes(value.toLowerCase())
        );
      });
  }

  compareWith(first, second) {
    return first && second && first === second;
  }

  getPhoneCodeList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.phonePrefix = data.result;
        this.phonePrefix = this.phonePrefix.map((p) => ({
          ...p,
          name: this.dictionariesService.getPhonePrefix(p.phone_code) + p.name,
          phone_code: "+" + p.phone_code,
        }));

        this.filteredPhonePrefix = this.phonePrefix;
      }
    });
  }
}
