import { BackendHttpClient } from "@api/http/backend-http-client";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DefaultResponseData } from "@api/http";
import { ValueList } from "@modules/shared/models/value-list.model";
import { BillingDetails } from "@api/clients/models/billing-details.models";

@Injectable({
  providedIn: "root",
})
export class BillingDetailsService {
  constructor(private readonly http: BackendHttpClient) {}

  list(entityId: string, entityType: string): Observable<BillingDetails[]> {
    return this.http
      .get<any>(`${entityType}/${entityId}/billing-details`)
      .pipe(map((data) => data.result));
  }

  getBillingAddressesByOwner(
    ownerEntityId: string,
    ownerEntityType: string,
    params: ValueList
  ): Observable<any> {
    return this.http
      .get<any>(`owners/${ownerEntityType}/${ownerEntityId}/billing-details`, {
        params,
      })
      .pipe(map((response) => response.result));
  }

  create(
    entityId: string,
    entityType: string,
    data: BillingDetails
  ): Observable<DefaultResponseData<BillingDetails>> {
    return this.http.post<any>(`${entityType}/${entityId}/billing-details`, {
      ...data,
    });
  }

  update(
    entityId: string,
    entityType: string,
    billingDetailsId: number,
    data: BillingDetails
  ): Observable<DefaultResponseData<BillingDetails>> {
    return this.http.put<any>(
      `${entityType}/${entityId}/billing-details/${billingDetailsId}`,
      { ...data }
    );
  }

  delete(
    entityId: string,
    entityType: string,
    billingDetailsId: number
  ): Observable<DefaultResponseData<BillingDetails>> {
    return this.http.delete<any>(
      `${entityType}/${entityId}/billing-details/${billingDetailsId}`
    );
  }
}
