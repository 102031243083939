import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { Observable, Subject, combineLatest, map, takeUntil } from "rxjs";
import { Document } from "@models/_menu/document";

@Component({
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public menu: Document;
  public docName$: Observable<string>;
  public isOnDashboard: boolean = false;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private service: DocumentService
  ) {}

  public ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams, this.route.data])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([params, query, data]) => {
        if (data["isOnDashboard"]) {
          this.isOnDashboard = true;
        }

        this.menu = Document.resolve(data["resource"], params, query);

        this.docName$ = this.service
          .preview(params["doc"])
          .pipe(map((document: DocumentData) => document.name));
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.complete();
  }
}
