import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-details-navigation",
  templateUrl: "./details-navigation.component.html",
  styleUrls: ["./details-navigation.component.scss"],
})
export class DetailsNavigationComponent {
  @Input() menu;

  constructor(
    public router: Router,
    protected activatedRoute: ActivatedRoute
  ) {}

  getActive() {
    let currentUrl = this.router.url.split("?")[0];

    if (currentUrl.includes("legal-entities")) {
      const urlSegments = currentUrl.split("/");
      const index = urlSegments.indexOf("legal-entities");

      if (index !== -1) {
        currentUrl = urlSegments.slice(0, index + 1).join("/");
      }
    }

    const activeLink = this.menu.links.find((link) => {
      if (currentUrl !== link.link) {
        return false;
      }
      return true;
    });
    if (!activeLink) {
      return this.menu.links[0];
    }
    return activeLink;
  }
}
