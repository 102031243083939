import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { SettingCardComponent } from "./setting-card/setting-card.component";
import { SettingInputComponent } from "./setting-input/setting-input.component";

@NgModule({
  declarations: [SettingCardComponent, SettingInputComponent],
  exports: [SettingCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
  ],
})
export class SettingSharedModule {}
