<div windowScrollTableBulk id="bulk-action-fixed">
  <div class="bulk-to-top">
    <div class="bulk-action-wrapper">
      <div class="bulk-action-body">
        {{
          allSelectedItems.length
            ? allSelectedItems.length
            : selection.selected.length
        }}
        {{ "GENERALS.ITEMS-SELECTED" | translate }}
      </div>
      <div class="bulk-action-btn">
        <div *ngFor="let action of availableActions">
          <button class="btn-small orange-bg" (click)="onClick.emit(action)">
            {{ action.text | translate }}
          </button>
        </div>
      </div>
      <div class="bulk-action-close" (click)="clearSelection()">
        <i class="icon-cancel"></i>
      </div>
    </div>
  </div>
</div>
