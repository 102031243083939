import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { finalize, take } from "rxjs";
import { StripeInvoice } from "@models/stripe-invoice";
import { StripeService } from "../../../_services/stripe.service";

@Component({
  selector: "pay-invoice",
  templateUrl: "./pay-invoice.component.html",
  styleUrls: ["./pay-invoice.component.scss"],
})
export class PayInvoiceComponent {
  @Input() invoiceId: number;

  isLoading: boolean = false;
  stripeInvoiceObj = new StripeInvoice();

  constructor(private stripeService: StripeService, private router: Router) {}

  payInvoice() {
    this.isLoading = true;

    this.stripeInvoiceObj.invoice_id = this.invoiceId;
    this.stripeInvoiceObj.success_url =
      location.origin + this.router.url.split("?")[0];
    this.stripeInvoiceObj.fail_url =
      location.origin + this.router.url.split("?")[0];
    this.stripeService
      .goToCheckout(this.stripeInvoiceObj)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        return data;
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
