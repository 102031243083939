import { Injectable, inject } from "@angular/core";
import { UserData } from "@api/account";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import { Client } from "@models/client";
import { ClientData } from "@modules/clients/models/client.model";
import { PageData } from "@modules/shared/models/page.model";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { UsersService } from "../../../_services/users.service";
import { ClientMapperService } from "./client-mapper.service";
export interface EntityUserData {
  id: number;
  user_id: number;
  entity_id: number;
  company_position: string;
  is_primary: string;
  user: UserData;
}

export interface CreateClientData {
  provider_id: number;
  company_name: string;
  user: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    phone_prefix: string;
  };
}
@Injectable()
export class ClientsService {
  private user: UserData;
  clientModel: Client;

  constructor(
    private http: BackendHttpClient,
    private clientMapper: ClientMapperService
  ) {
    inject(UsersService)
      .getCurrentUser()
      .subscribe((user) => {
        this.user = user;
      });
  }

  /**
   * List the provider's clients
   * @param providerId
   * @param params
   */
  public list(providerId, params = {}): Observable<PageData<ClientData>> {
    return this.http
      .get<any>(`entities/providers/${providerId}/clients`, { params })
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: this.clientMapper.mapMany(items),
          } as PageData<ClientData>;
        })
      );
  }

  public show(providerId, clientId): Observable<ClientData> {
    return this.http
      .get(`entities/providers/${providerId}/clients/${clientId}`)
      .pipe(
        map((response) => {
          return response["result"] as ClientData;
        })
      );
  }

  public fetchUsers(clientId, params = {}): Observable<EntityUserData[]> {
    return this.http
      .get<any>(`entities/${clientId}/users`, {
        params,
      })
      .pipe(map((response) => response.result?.items ?? []));
  }

  public getUsers(clientId, params = {}): Observable<any> {
    return this.fetchUsers(clientId, params).pipe(
      map((data) => data.map((item) => item.user))
    );
  }

  // GET Client
  public getClient(id): Observable<any> {
    return this.http.get<any>(`marketplace/clients/${id}`).pipe(
      tap((data) => {
        this.clientModel = data;
      })
    );
  }

  /**
   * Export filtered clients to CSV
   * @param providerId
   * @param params
   */
  export(params, providerId) {
    return this.http.get(`entities/providers/${providerId}/clients/export`, {
      responseType: "blob",
      params: params,
    });
  }

  // POST Create Client step 1
  store(data: CreateClientData): Observable<any> {
    return this.http
      .post<any>(`entities/providers/${data.provider_id}/clients`, data)
      .pipe(map((response: any) => response.result));
  }

  // POST Create Client step 2
  updateClient(
    providerId: number,
    client: ClientData
  ): Observable<DefaultResponseData<ClientData>> {
    return this.http
      .post<any>(
        `entities/providers/${providerId}/clients/${client.id}`,
        client
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public statusTransitions(params = {}): Observable<any> {
    return this.http
      .get<any>(
        `entities/providers/${this.user.entity_id}/clients/status-transitions`,
        { params }
      )
      .pipe(map((data) => data.result));
  }

  public updateStatus(ids, status, notes?, params = {}) {
    const body = { status: status, client_ids: ids, notes: notes };
    return this.http
      .post<any>(`entities/providers/${this.user.entity_id}/clients/status`, {
        ...body,
      })
      .pipe(map((data) => data.result));
  }
}
