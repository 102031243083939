<!-- modal -->

<app-dialog-header>
  {{ title ? (title | translate) : ("GENERALS.ADD-DOC" | translate) }}
</app-dialog-header>

<div class="dialog-body">
  <ng-container *ngIf="isLoading">
    <app-loader-spinner-general
      [loadingType]="LoadingType.INPUT"
    ></app-loader-spinner-general>
  </ng-container>

  <div class="modal-form" *ngIf="!isLoading">
    <div class="row">
      <div class="form-group" [ngClass]="'w-100'">
        <label>{{ "PORTAL.UPLOAD-PIC" | translate }}</label>

        <div class="custom-upload-box">
          <file-upload
            [(ngModel)]="uploadedFiles.file"
            [control]="fileUploadControl"
          >
            <ng-template #placeholder>
              <span><i class="icon-upload-cloud"></i></span>
              <span>{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }} </span>
              <span class="orange"> {{ "GENERALS.BROWSE" | translate }}</span>
            </ng-template>

            <ng-template
              let-i="index"
              let-file="file"
              let-control="control"
              #item
            >
              <div class="file-info">
                <span class="file-name">{{ file.name }}</span>
                <span class="remove-file" (click)="control.removeFile(file)">
                  <i class="icon-trash"></i
                  >{{ "GENERALS.REMOVE-FILE" | translate }}</span
                >
              </div>
            </ng-template>
          </file-upload>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dialog-footer p-20">
  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.BUTTONS"
    [repeat]="2"
  ></app-loader-spinner-general>
  <ng-container *ngIf="!isLoading">
    <button
      [disabled]="isLoading"
      type="button"
      class="btn orange-bg modal-button"
      (click)="uploadImage()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.ADD" | translate }}
    </button>
  </ng-container>
</div>
