import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ReportsRoutingModule } from "./reports-routing.module";
import { SharedModule } from "@modules/shared/shared.module";
import { ReportsComponent } from "./components/reports/reports.component";
import { FinancialComponent } from "./components/financial/financial.component";
import { CasesComponent } from "./components/cases/cases.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { CustomComponent } from "./components/custom/custom.component";
import { MatChipsModule } from "@angular/material/chips";
import { MilestonesReportListComponent } from "@modules/reports/components/custom/milestones/list/milestones-report-list.component";
import { ConfigMilestoneModalComponent } from "@modules/reports/components/custom/milestones/config-modals/config-milestone-modal.component";
import { MultipleSelectorComponent } from "@modules/reports/components/custom/milestones/list/form/multiple-selector/multiple-selector.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ReportFiltersComponent } from "@modules/reports/components/custom/milestones/list/form/report-filters/report-filters.component";
import { ExpensesReportListComponent } from "@modules/reports/components/custom/expenses/list/expenses-report-list.component";
import { ExpensesSharedModule } from "@modules/expenses/expenses-shared/expenses-shared.module";
import { ReportsSharedModule } from "./reports-shared/reports-shared.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { ReportMultiselectFilterComponent } from "./components/custom/milestones/list/form/report-multiselect-filter/report-multiselect-filter.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ReportDateFilterComponent } from "./components/custom/milestones/list/form/report-date-filter/report-date-filter.component";
import { CdkAccordionModule } from "@angular/cdk/accordion";

@NgModule({
  declarations: [
    ReportsComponent,
    FinancialComponent,
    CasesComponent,
    CustomComponent,
    ConfigMilestoneModalComponent,
    MilestonesReportListComponent,
    MultipleSelectorComponent,
    ReportFiltersComponent,
    ExpensesReportListComponent,
    ReportMultiselectFilterComponent,
    ReportDateFilterComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    ReportsRoutingModule,
    SharedModule,
    ReportsSharedModule,
    NgApexchartsModule,
    MatChipsModule,
    MatTreeModule,
    MatProgressBarModule,
    ExpensesSharedModule,
    OverlayModule,
    CdkAccordionModule,
  ],

  providers: [],
  exports: [ReportFiltersComponent],
})
export class ReportsModule {}
