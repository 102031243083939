import { createFeature, createReducer, on } from "@ngrx/store";
import { TableColumnSettingsState } from "./models/table-column-settings.model";
import { TableColumnSettingsActions } from "./table-column-settings.actions";

export const initialState: TableColumnSettingsState = {};

export const tableColumnSettingsReducer = createReducer(
  initialState,
  on(
    TableColumnSettingsActions.updateSelectedColumns,
    (state, { page, selectedColumns }) => ({
      ...state,
      [page]: selectedColumns,
    })
  )
);

export const tableColumnSettingsFeature = createFeature({
  name: "tableColumnSettings",
  reducer: tableColumnSettingsReducer,
});
