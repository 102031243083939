import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ColumnSelectionComponent } from "./column-selection/column-selection.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { StoreModule } from "@ngrx/store";
import { tableColumnSettingsFeature } from "./state";

@NgModule({
  declarations: [ColumnSelectionComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    TranslateModule,
    StoreModule.forFeature(tableColumnSettingsFeature),
  ],
  exports: [ColumnSelectionComponent],
  providers: [],
})
export class TableSettingsModule {}
