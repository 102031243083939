<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "INVOICES.LIST" | translate }}</div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <!--    todo add commissions btn modal-->
  </div>
  <!-- end right -->
</div>

<div class="clear"></div>

<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper w-100 p-0">
    <app-filters
      [showCalendar]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      (date)="setFilteringDate($event); navigateByUrl()"
      [total]="total"
      searchPlaceholder="INVOICES.LIST"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        [matSortActive]="active"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <th mat-sort-header="id">
              {{ "INVOICES.INVOICE-NO" | translate }}
            </th>
            <th mat-sort-header="amount">
              {{ "INVOICES.AMOUNT" | translate }}
            </th>
            <th mat-sort-header="issued_on">
              {{ "INVOICES.ISSUED-ON" | translate }}
            </th>
            <th mat-sort-header="due_on">
              {{ "INVOICES.DUE-ON" | translate }}
            </th>
            <th mat-sort-header="paid_on">
              {{ "INVOICES.PAID-ON" | translate }}
            </th>
            <th>
              {{ "GENERALS.DOWNLOAD" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th mat-sort-header="payment_info">
              {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr
            class="cursor-pointer"
            *ngFor="
              let invoice of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td [routerLink]="['/invoices/' + invoice.id]" class="gray-pointer">
              <a
                class="orange text-decoration-underline"
                [href]="'/invoices/' + invoice.id"
              >
                {{ invoice.id }}
              </a>
            </td>
            <td [routerLink]="['/invoices/' + invoice.id]" class="gray-pointer">
              {{ invoice.amount | currency : invoice.currency_code }}
            </td>
            <td [routerLink]="['/invoices/' + invoice.id]" class="gray-pointer">
              {{ invoice.issued_on | date : "dd/MM/yyyy" }}
            </td>
            <td [routerLink]="['/invoices/' + invoice.id]" class="gray-pointer">
              {{ invoice.due_on | date : "dd/MM/yyyy" }}
            </td>
            <td [routerLink]="['/invoices/' + invoice.id]" class="gray-pointer">
              {{ invoice.paid_on | date : "dd/MM/yyyy" }}
            </td>
            <td>
              <div
                (click)="exportCsv(invoice)"
                class="inner-table-btn"
                title="{{ 'GENERALS.DOWNLOAD-CSV' | translate }}"
              >
                <i class="icon-csv-icon"></i>
              </div>
              <div
                (click)="exportPdf(invoice)"
                class="inner-table-btn"
                title="{{ 'GENERALS.DOWNLOAD-PDF' | translate }}"
              >
                <i class="icon-file-pdf"></i>
              </div>
            </td>
            <td [routerLink]="['/invoices/' + invoice.id]">
              <div class="status-label-{{ invoice.status_id }}">
                {{ "INVOICES.STATUS-" + invoice.status_id | translate }}
              </div>
            </td>
            <td>
              <pay-invoice
                *ngIf="!invoice.paid_on && invoice.id && invoice.amount > 0"
                [invoiceId]="invoice.id"
              ></pay-invoice>
              <span *ngIf="invoice.paid_on">
                <b>{{ "INVOICES.TRANSACTION-ID" | translate }}</b
                >:
                {{ invoice.transaction_id ? invoice.transaction_id : null }}
                <br />
                <b>{{ "INVOICES.PAYMENT-METHOD" | translate }}</b
                >:
                {{ invoice.payment_method ? invoice.payment_method : null }}
              </span>
            </td>
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->

    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoading">
      <div
        class="resposive-table-wrapper cursor-pointer"
        *ngFor="
          let invoice of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check"></div>
          <div
            [routerLink]="['/invoices/view']"
            [queryParams]="{ invoice_id: invoice.id }"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.INVOICE-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ invoice.id }}</div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.AMOUNT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.amount | currency : invoice.currency_code }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.ISSUED-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.issued_on | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.DUE-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.due_on | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.PAID-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ invoice.paid_on | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DOWNLOAD" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <div
              (click)="exportCsv(invoice.id)"
              class="inner-table-btn"
              title="{{ 'GENERALS.DOWNLOAD-CSV' | translate }}"
            >
              <i class="icon-csv-icon"></i>
            </div>
            <div
              (click)="exportPdf(invoice.id)"
              class="inner-table-btn"
              title="{{ 'GENERALS.DOWNLOAD-PDF' | translate }}"
            >
              <i class="icon-file-pdf"></i>
            </div>
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ invoice.status_id }}">
              {{ "INVOICES.STATUS-" + invoice.status_id | translate }}
            </span>
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <pay-invoice
              *ngIf="!invoice.paid_on && invoice.id && invoice.amount > 0"
              [invoiceId]="invoice.id"
            ></pay-invoice>
            <span *ngIf="invoice.paid_on">
              <b>{{ "INVOICES.TRANSACTION-ID" | translate }}</b
              >: {{ invoice.transaction_id ? invoice.transaction_id : null }}
              <br />
              <b>{{ "INVOICES.PAYMENT-METHOD" | translate }}</b
              >:
              {{ invoice.payment_method ? invoice.payment_method : null }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
