import { Injectable } from "@angular/core";
import { DefaultResponseData } from "@api/http";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { AccountActions } from "../actions";
import { UpdateUserData, UserData } from "../models/user.model";
import { AccountSelectors } from "../selectors";

export interface ProviderAccountSetupData {
  type: "provider";
  company_name: string;
  company_position: string;
  member_of: string;
  categories: { id: number[] };
  countries: string[];
}

@Injectable({
  providedIn: "root",
})
export class AccountService {
  data$ = combineLatest([
    this.store.select(AccountSelectors.selectUser),
    this.store.select(AccountSelectors.selectLoaded),
  ]).pipe(
    filter(([_user, loaded]) => loaded),
    map(([user, _loaded]) => user)
  );

  constructor(private api: BackendHttpClient, private store: Store) {}

  get account$(): Observable<UserData> {
    return this.store.select(AccountSelectors.selectAccountState).pipe(
      filter(({ loaded, user }) => loaded),
      map(({ user }) => {
        if (!user) {
          throw Error("no user");
        }
        return user;
      })
    );
  }

  private mapUserData(data: any): UserData {
    return {
      ...data,
    } as UserData;
  }

  fetchData(): Observable<UserData> {
    return this.api
      .get("users/current")
      .pipe(map((data: any) => this.mapUserData(data)));
  }

  /**
   * patch Update current user
   */
  updateCurrentUser(user: UpdateUserData): Observable<UserData> {
    return this.api.patch<any>("users/update-current-user", user).pipe(
      map((data) => this.mapUserData(data.result)),
      tap((user) =>
        this.store.dispatch(AccountActions.updateAccountDataSuccess({ user }))
      )
    );
  }

  setData(data: Partial<UserData>): void {
    this.store.dispatch(AccountActions.updateAccountData({ user: data }));
  }

  setupAccount(input: ProviderAccountSetupData): Observable<UserData> {
    return this.api
      .post<DefaultResponseData<UserData>>("users/setup", input)
      .pipe(
        map((data) => this.mapUserData(data.result)),
        tap((userData) =>
          this.store.dispatch(
            AccountActions.updateAccountDataSuccess({ user: userData })
          )
        )
      );
  }
}
