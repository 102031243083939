<div class="dialog p-0">
  <app-dialog-header>
    {{ data.action.text | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <form [formGroup]="taskForm">
      <div class="field-grid overflow-x-hidden" *ngIf="!isLoading">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.NAME" | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error formControlError="name"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.DURATION" | translate }}</mat-label>
          <input matInput type="number" formControlName="duration" />
          <mat-error formControlError="duration"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.START-AFTER" | translate }}</mat-label>
          <mat-select formControlName="dependent_task_id">
            <mat-option
              *ngFor="let task of startAfterOptions$ | async"
              [disabled]="task.disable"
              [value]="task.id"
            >
              <div
                [ngClass]="{
                  'm-l-10': task.subtask
                }"
              >
                #{{ task.id }} - {{ task.name }}
              </div>
            </mat-option>
          </mat-select>
          <mat-error formControlError="dependent_task_id"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "TASKS.TASK_MANAGER" | translate }}</mat-label>
          <mat-select formControlName="assignee_id">
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'TASKS.TASK_MANAGER' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="assigneeSearchControl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngIf="selectedUser$ | async as selectedUser"
              [value]="selectedUser.id"
            >
              <ng-container
                *ngTemplateOutlet="
                  userRow;
                  context: { $implicit: selectedUser }
                "
              ></ng-container>
            </mat-option>
            <ng-container *ngIf="filteredUsers$ | async as users; else loading">
              <mat-option
                *ngFor="let user of users"
                title="{{ user?.name + ' (' + user.role_type + ')' }}"
                [value]="user.id"
                (click)="selectedUser$.next(user)"
              >
                <ng-container
                  *ngTemplateOutlet="userRow; context: { $implicit: user }"
                ></ng-container>
              </mat-option>
            </ng-container>

            <ng-template #loading>
              <mat-option>
                <mat-spinner></mat-spinner>
              </mat-option>
            </ng-template>
          </mat-select>
          <mat-error formControlError="assignee_id"></mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button class="btn-primary" (click)="submit()" [disabled]="isLoading">
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>

<ng-template #userRow let-user>
  <div class="flex items-center">
    <img
      class="avatar"
      [src]="user.primary_image_path"
      [appAvatar]="user.name"
    />
    <span class="truncate">
      {{ user.name }}

      {{ user.role_type ? "(" + user.role_type + ")" : "" }}
    </span>
  </div>
</ng-template>
