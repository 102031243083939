import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/_services/alert.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { AddProfilePictureComponent } from "../add-profile-picture/add-profile-picture.component";

@Component({
  selector: "app-details-action-panel",
  templateUrl: "./details-action-panel.component.html",
})
export class UserDetailsActionsPanelComponent {
  @Input() user;
  @Input() entity_type;
  @Input() changeStatusPermission = false;
  @Input() readonly;
  @Output() actionChange: EventEmitter<any> = new EventEmitter();
  profilePic;
  constructor(
    private authService: AuthenticationService,
    private alertService: AlertService,
    protected dialog: MatDialog
  ) {}

  handleEvent(e) {
    this.actionChange.emit(e);
  }

  resetPassword() {
    this.authService.sendResetPasswordEmail(this.user.email).subscribe(
      (res) => {
        if (res && res.message) {
          this.alertService.success(res.message);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
  openDocModal() {
    this.dialog
      .open(AddProfilePictureComponent, {
        data: {
          entityType: this.entity_type,
          entityId: this.user.id,
          title: "GENERALS.UPLOAD-PIC",
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (!result) {
          return;
        }
        this.handleEvent("newProfilePicture");
      });
  }
}
