import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PermissionsService } from "@api/users/services/permissions.service";
import { Permission } from "@modules/authorization/models/permission.model";
import { AddPermissionFormComponent } from "@modules/shared/_components/add-permission-form/add-permission-form.component";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { UsersService } from "src/app/_services/users.service";
import { AlertService } from "@modules/alert";
import { WindowResizeHelperService } from "../../../../_services/window-resize-helper.service";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-permissions",
  templateUrl: "./permissions.component.html",
  styleUrls: ["./permissions.component.scss"],
})
export class PermissionsComponent implements OnInit {
  @Input() model: string;
  @Input() modelId: number;
  @Input() providerId: number;
  @Input() resource: PermissionResourceEnum;

  public requestCall;
  public isMobile: boolean;
  public isLoading = false;
  public items = [];
  public canAddPermission: Permission;

  constructor(
    private service: PermissionsService,
    private windowResizeHelper: WindowResizeHelperService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private userService: UsersService
  ) {
    if (this.resource) {
      this.canAddPermission = {
        action: PermissionActionEnum.CREATE,
        resource: this.resource,
        secondaryResource: PermissionSecondaryResourceEnum.PERMISSIONS,
      };
    }
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isMobile = data.responsiveStatus;
    });
    this.getItems();
  }

  public getItems(page = 1) {
    if (this.requestCall) {
      this.requestCall.unsubscribe();
    }
    this.isLoading = true;
    this.requestCall = this.service
      .list(this.model, this.modelId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.items = data;
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(AddPermissionFormComponent, {
      data: {
        providerId: this.providerId,
        resourceType: this.resource,
        resourceId: this.modelId,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.userService
          .grantResourcePermission(
            this.providerId,
            this.resource,
            this.modelId,
            res
          )
          .subscribe(() => {
            this.getItems();
          });
      }
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
