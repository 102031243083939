export enum OrderStatusEnum {
  NEW = 1,
  IN_PROGRESS = 2,
  CANCELLED_BY_CLIENT = 4,
  CANCELLED_BY_PROVIDER = 5,
  REFUNDED = 6,
  FINALIZED = 7,
  AWAITING_PAYMENT = 8,
  ON_HOLD_BY_CLIENT = 10,
  ON_HOLD_BY_PROVIDER = 9,
  ACCEPTED = 11,
}
