import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DocumentService } from "@api/documents/services/documents.service";

import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";

import { LinkResourceType } from "@api/documents/models/link-resource-type.model";
import { LinkResource } from "@api/documents/models/link-resource.model";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";

@Component({
  selector: "app-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"],
})
export class LinkComponent implements OnInit, OnDestroy {
  public resourceTypes$: Observable<LinkResourceType[]>;
  public resources$: Observable<LinkResource[]>;

  public isLoading: boolean = false;

  public form: UntypedFormGroup = new UntypedFormGroup({
    resource_type: new UntypedFormControl("", Validators.required),
    resource_id: new UntypedFormControl([], Validators.required),
  });

  private unsubscribe$: Subject<void> = new Subject<void>();

  public searchText: UntypedFormControl = new UntypedFormControl("");

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      docIds: number | number[];
      source: string;
      sourceId: number;
    },
    private dialogRef: MatDialogRef<LinkComponent>,
    private documentService: DocumentService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    if (!Array.isArray(this.data.docIds)) {
      this.data.docIds = [this.data.docIds];
    }

    this.resourceTypes$ = this.documentService.linkResourceTypes(
      this.data.docIds[0],
      this.data.source
    );

    this.resources$ = combineLatest([
      this.form
        .get("resource_type")
        .valueChanges.pipe(
          tap(() => this.form.get("resource_id").setValue([]))
        ),
      this.searchText.valueChanges.pipe(startWith(""), debounceTime(400)),
    ]).pipe(
      switchMap(([resourceType, searchText]) => {
        return this.documentService.linkResources(
          this.data.docIds[0],
          this.data.source,
          this.data.sourceId,
          resourceType,
          { searchText }
        );
      })
    );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public cancel(): void {
    this.dialogRef.close({ action: "cancel" });
  }

  public submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    const data = this.form.getRawValue();

    data.document_ids = this.data.docIds;

    this.documentService
      .storeLink(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translateService.instant(
              "DOCUMENTS.LINKS-CREATED-SUCCESSFULLY"
            )
          );

          this.dialogRef.close({ reason: "save" });
        },
        error: (error) => {
          this.isLoading = false;

          this.alertService.errors(error.error.errors);
        },
      });
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
