import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { map, Observable } from "rxjs";
import { FilterData, FilterItemData } from "../models/filter-item.model";
import {
  FilterDataRequest,
  ListFilterItemsRequestData,
} from "../models/filter-request.model";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor(private http: BackendHttpClient) {}

  public listFilterItems(
    endpoint: string,
    params: ListFilterItemsRequestData
  ): Observable<FilterItemData[]> {
    return this.http
      .get<any>(endpoint, { params: { ...params } })
      .pipe(map((data) => data.result));
  }
  public getFilter(
    endpoint: string,
    params: FilterDataRequest
  ): Observable<FilterData> {
    return this.http
      .get<any>(endpoint, { params })
      .pipe(map((data) => data.result));
  }
  // this method is used for filters that have a large amount of params.
  public postGetFilter(
    endpoint: string,
    params: FilterDataRequest
  ): Observable<FilterData> {
    return this.http
      .post<any>(endpoint, params)
      .pipe(map((data) => data.result));
  }
}
