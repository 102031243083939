<div class="loading-wrapper" *ngIf="isLoading">
  <app-loader-spinner-general
    class="w-profile"
    [loadingType]="LoadingType.PROFILE"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    class="w-info"
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>
</div>

<div class="details-body-wrapper" *ngIf="!isLoading">
  <div class="details-2c" *ngIf="accountData">
    <!-- left -->
    <div class="details-2c-left">
      <div class="small-box">
        <div class="small-box-header-img" (click)="openDocModal()">
          <img
            [src]="
              accountData.primary_image_path
                ? accountData.primary_image_path
                : '../assets/images/profile-pics-placeholder.jpg'
            "
            alt="img"
            class="profile-pic"
          />
          <div class="edit-trigger">
            {{ "GENERALS.CHANGE-PIC" | translate }}
          </div>
        </div>
        <div class="small-box-details">
          <div class="medium-title">{{ accountData.name }}</div>

          <div class="small-box-status">
            <div
              class="small-box-status entity_status-label-{{
                accountData.status_id
              }}"
            >
              {{ accountData.status_name }}
            </div>
          </div>

          <div class="fw-500">
            <span class="gray block-span"
              >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
            >
            {{ accountData.created_at | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="small-box-list">
          <ul>
            <li class="action-link-gray" (click)="openResetPasswordModal()">
              <i class="icon-right-dir"></i>
              {{ "AUTH.CHANGE-PASSWORD" | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end left -->

    <!-- right -->
    <div class="content-right-side">
      <div class="box">
        <div class="box-header">
          <div class="box-title">
            {{ "GENERALS.ACCOUNT-INFO" | translate }}
          </div>
          <div class="box-actions">
            <button class="btn-outline-none-primary" (click)="openEditModal()">
              <mat-icon svgIcon="edit" class="text-icon"></mat-icon>
              {{ "GENERALS.EDIT" | translate }}
            </button>
          </div>
        </div>
        <div class="box-body">
          <div class="field-grid">
            <div>
              <label> {{ "LABEL.NAME" | translate }} </label>
              <div class="display-field">
                {{ accountData.name }}
              </div>
            </div>
            <div>
              <label> {{ "LABEL.COMPANY-NAME" | translate }} </label>
              <div class="display-field">
                {{ accountData.entity_details.name | notAvailable }}
              </div>
            </div>
            <div>
              <label> {{ "LABEL.ROLE" | translate }} </label>
              <div class="display-field">
                {{ accountData.role_name }}
              </div>
            </div>
            <div>
              <label> {{ "LABEL.EMAIL" | translate }} </label>
              <div class="display-field">
                <a href="mailto:{{ accountData.email }}">
                  <i class="icon-mail"></i>
                  {{ accountData.email }}
                </a>
              </div>
            </div>
            <div>
              <label> {{ "LABEL.PHONE-NUMBER" | translate }} </label>
              <div class="display-field">
                <a href="mailto:{{ accountData.email }}">
                  <i class="icon-phone"></i>
                  {{ accountData.phone_prefix }}
                  {{ accountData.phone_number }}
                </a>
              </div>
            </div>
            <div>
              <label> {{ "LABEL.POSITION-IN-COMPANY" | translate }} </label>
              <div class="display-field">
                {{ accountData.entity_details.company_position }}
              </div>
            </div>
          </div>
          <app-link-social-account></app-link-social-account>
        </div>
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end 2 col details -->
</div>
