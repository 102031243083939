import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { combineLatest, Observable, Subject } from "rxjs";
import {
  debounceTime,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { Order } from "@models/order";
import { User } from "@models/user";
import { OrdersService } from "src/app/_services/orders.service";

@Component({
  selector: "app-order-selector",
  templateUrl: "./order-selector.component.html",
})
export class OrderSelectorComponent implements OnInit, OnDestroy {
  caseForm: UntypedFormGroup;

  @Input() submitEvent: Observable<void>;

  orderControl: UntypedFormControl;
  orderSearchForm: UntypedFormControl;

  orders$: Observable<Order[]>;
  filteredOrders$: Observable<Order[]>;
  currentUser$: Observable<User>;

  initialValue: Partial<Order>;

  unsubscribe$ = new Subject<void>();

  compareFn = (o1: any, o2: any) => o1.identity_number == o2.identity_number;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly orderService: OrdersService
  ) {
    this.orderControl = new UntypedFormControl("", Validators.required);
    this.orderSearchForm = new UntypedFormControl("");
  }

  ngOnInit() {
    this.unsubscribe$ = new Subject<void>();
    this.submitEvent.subscribe(() => {
      if (!this.orderControl.valid) {
        this.orderControl.setErrors({ required: true });
        this.orderControl.markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;

    this.caseForm
      .get("client_id")
      .valueChanges.pipe(takeUntil(this.unsubscribe$), startWith(null))
      .subscribe((value) => {
        if (!value || (value && this.caseForm.get("order_id").value)) {
          this.orderControl.disable({ emitEvent: false });
        } else {
          this.orderControl.enable({ emitEvent: false });
        }
      });

    this.caseForm.valueChanges.subscribe((value) => {
      if (!value.order_number) {
        return;
      }
      this.initialValue = {
        id: value.order_id,
        identity_number: value.order_number,
      };
      this.orderControl.setValue(this.initialValue, { emitEvent: false });
    });

    this.filteredOrders$ = combineLatest([
      this.caseForm
        .get("client_id")
        .valueChanges.pipe(startWith(this.caseForm.get("order_id").value)),
      this.orderSearchForm.valueChanges.pipe(startWith("")),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(300),
      switchMap(([entity_id, search_text]) =>
        this.orderService.getOrdersList(
          { entity_id, search_text, assignable_to: "expat_case" },
          null
        )
      ),
      map((response) => response.result.items),
      shareReplay(1)
    );
    this.orderControl.valueChanges.subscribe((order: Order) => {
      this.caseForm.patchValue({
        order_id: order.id,
        order_number: order.identity_number,
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
