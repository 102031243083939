import { createActionGroup, props } from "@ngrx/store";
import { ColumnDefinition } from "src/app/_interfaces/column-definition.interface";

export const TableColumnSettingsActions = createActionGroup({
  source: "TableColumnSettings",
  events: {
    "Update Selected Columns": props<{
      page: string;
      selectedColumns: ColumnDefinition[];
    }>(),
  },
});
