import { SelectionModel } from "@angular/cdk/collections";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  FilterData,
  FilterItemData,
} from "@modules/shared/models/filter-item.model";
import { FilterService } from "@modules/shared/services/filter.service";
import {
  auditTime,
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  startWith,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { FilterComponent } from "../report-filters/report-filters.component";

@Component({
  selector: "app-report-multiselect-filter",
  templateUrl: "./report-multiselect-filter.component.html",
  styleUrls: ["./report-multiselect-filter.component.scss"],
})
export class ReportMultiselectFilterComponent
  implements OnInit, FilterComponent, AfterViewInit
{
  @Input() filter: FilterData;

  @Input() currentFilters: Record<string, any>;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  filterItems: FilterItemData[] = [];

  public trackByFn = (index, item) => item.id;

  public searchControl = new FormControl();

  currentPage$ = new BehaviorSubject<number>(0);
  isLoaded = false;

  filterService = inject(FilterService);

  loadNextBatch$ = new Subject<number>();

  checklistSelection = new SelectionModel<string | number>(
    true,
    [],
    false,
    (o1, o2) => o1 == o2
  );

  filterValues$ = merge(
    // triggers when the filter is opened and user reached the end of scroll
    this.loadNextBatch$.pipe(
      filter((nextBatch) => this.currentPage$.getValue() < nextBatch),
      map((nextBatch) => ({
        loadNextBatch: nextBatch,
        search: this.searchControl.value,
      }))
    ),
    // triggers when the search text changes
    this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(""),
      debounceTime(300),
      map((search) => ({ loadNextBatch: 1, search })),
      tap(() => (this.filterItems = []))
    )
  ).pipe(
    tap(() => (this.isLoaded = false)),
    switchMap(({ loadNextBatch, search }) =>
      this.filterService.postGetFilter(
        this.filter.dataEndpoint + "/" + this.filter.id,
        {
          ...this.currentFilters,
          filter_page: loadNextBatch,
          filter_search_text: search,
        }
      )
    ),
    tap(() => (this.isLoaded = true))
  );

  ngOnInit(): void {
    if (this.currentFilters) {
      this.checklistSelection.setSelection(
        ...(this.currentFilters[this.filter.id] ?? [])
      );
    }
    this.filterValues$.subscribe((result) => {
      const currentPage = result.metadata.current_page;
      this.currentPage$.next(currentPage);
      if (currentPage === 1) {
        this.filterItems = result.data;
      } else {
        this.filterItems = [...this.filterItems, ...result.data];
      }

      this.isLoaded = true;
    });
  }

  ngAfterViewInit(): void {
    this.viewPort.scrolledIndexChange
      .pipe(auditTime(300))
      .subscribe((currIndex) => {
        const total = this.viewPort.getDataLength();

        const buffer = Math.floor(
          this.viewPort.getViewportSize() / this.filterItems.length
        );
        // * 2;
        // console.log(`${total} <= ${end + buffer}`);
        if (total <= currIndex + buffer && this.isLoaded) {
          // const state = this.getNextBatch(items, currIndex, buffer);
          // console.log('[Next]', state);
          this.loadNextBatch$.next(this.currentPage$.value + 1);
          // this.infinite.next(state);
        }
      });
  }

  onLoadMore() {
    if (!this.isLoaded) {
      return;
    }
    this.loadNextBatch$.next(this.currentPage$.value + 1);
  }

  getFilterValue() {
    return this.checklistSelection.selected;
  }

  clearFilterValue(): void {
    this.checklistSelection.clear();
  }
}
