import { Component, inject, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { passwordStrengthValidator } from "@modules/shared/_validators/password-strength-validator";
import { confirmPasswordValidator } from "@modules/shared/_validators/confirm-password-validator";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";
import {
  ChangePasswordInput,
  UsersService,
} from "src/app/_services/users.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { take, finalize } from "rxjs";

export interface ChangePasswordDialogComponentInput {
  title?: string;
}

@Component({
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"],
})
export class ChangePasswordDialogComponent {
  form: FormGroup = new FormGroup({
    password: new FormControl("", [
      passwordStrengthValidator(),
      confirmPasswordValidator("password_confirmation", true),
    ]),
    password_confirmation: new FormControl("", [
      Validators.required,
      confirmPasswordValidator("password"),
    ]),
  });
  hide: boolean = true;
  hideError: boolean = false;
  isLoading = false;

  alertService = inject(AlertService);
  dialogRef = inject(MatDialogRef<ChangePasswordDialogComponent>);
  userService = inject(UsersService);
  translate = inject(TranslateService);

  disabled = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title?: string }) {}

  submit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      this.hideError = true;
      return;
    }

    const data: ChangePasswordInput = this.form.getRawValue();
    this.isLoading = true;

    this.userService
      .updateCurrentPassword({
        password: data.password,
        password_confirmation: data.password_confirmation,
      })
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant("GENERALS.PASS-SAVE-SUCCESS")
          );
          this.dialogRef.close(data);
        },
        error: (error) => {
          if (error && error.error && error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }
}
