/* eslint-disable no-unexpected-multiline */
import { ChangeDetectorRef, Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort, SortDirection } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import * as saveAs from "file-saver";
import { CompareHelper } from "src/app/_helpers/compare.helper";
import * as moment from "moment";
import { AlertService } from "src/app/_services/alert.service";
import { ConfirmActionModalComponent } from "../confirm-action-modal/confirm-action-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnDefinition } from "../../../../_interfaces/column-definition.interface";
import { take } from "rxjs";
import { Store } from "@ngrx/store";
import { selectPageColumnSettings } from "@modules/shared/modules/table-settings/state";

@Component({
  selector: "app-items-list",
  template: "",
})
export class ItemsListComponent {
  protected dialog: MatDialog = inject(MatDialog);
  protected alertService: AlertService = inject(AlertService);
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  protected _router = inject(Router);
  protected _route = inject(ActivatedRoute);
  protected store = inject(Store);

  /**
   * Loading indicator
   */
  isLoading: boolean;

  /**
   * Pagination
   */
  p: number;
  total: number;
  itemsPage: number;
  public perPageValues = [
    { key: 25, label: "GENERALS.RESULTS_PER_PAGE" },
    { key: 30, label: "GENERALS.RESULTS_PER_PAGE" },
    { key: 50, label: "GENERALS.RESULTS_PER_PAGE" },
    { key: 100, label: "GENERALS.RESULTS_PER_PAGE" },
  ];
  private _perPage = 25;

  public get perPage() {
    return this._perPage;
  }
  public set perPage(value) {
    this._perPage = value;
  }
  public defaultFilters = {};

  /**
   * Sorting
   */
  my_sort: Sort;
  active = "";
  direction: SortDirection = "";

  /**
   * Search
   */
  search_text: string;

  /**
   * Filters
   */
  filters: any = { page: 1 };
  availableFilters: Array<any>;
  startDate;
  endDate;

  /**
   * Bulk actions
   */
  selection = new SelectionModel(true, []);
  actions: Array<any>;
  showBulkActions = false;

  /**
   * Data
   */
  request_call;
  items;

  /**
   * Status
   */
  selectedStatus;

  private compareHelper: CompareHelper = new CompareHelper();

  protected columns: ColumnDefinition[];

  public getColumns(): string[] {
    return this.columns
      .filter((column: ColumnDefinition) => column.visible)
      .map((column: ColumnDefinition) => column.name);
  }

  loadSavedColumnSettings(page: string): void {
    this.store
      .select(selectPageColumnSettings(page))
      .pipe(take(1))
      .subscribe((savedColumns) => {
        if (savedColumns.length) {
          this.updateColumnsVisibility(savedColumns);
        }
      });
  }

  protected updateColumnsVisibility(columns: ColumnDefinition[]): void {
    const savedColumnNames = new Set(columns.map((saved) => saved.name));

    this.columns = this.columns.map((column) => ({
      ...column,
      visible: savedColumnNames.has(column.name),
    }));
  }

  /**
   * Get items to list
   */
  public getItems(page: number = 1): void {
    throw new Error("This method is abstract");
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);

    this._router.navigate([], {
      queryParams: params,
      relativeTo: this._route,
      replaceUrl: true,
    });
  }

  /**
   * The the service
   */
  protected getService() {
    throw new Error("This method is abstract");
  }

  /**
   * Generate parameters for the API call
   */
  getParams(page: number, perPage = 0) {
    const params = this.filters;

    params["page"] = page;
    if (perPage) {
      this.perPage = perPage;
    }
    params["per_page"] = this.perPage;

    if (this.startDate && this.endDate) {
      params["start_date"] = this.formatDataParam(this.startDate);
      params["end_date"] = this.formatDataParam(this.endDate);
    }

    if (this.startDate === "null" && this.endDate === "null") {
      delete params["start_date"];
      delete params["end_date"];
    }

    if (this.my_sort) {
      params["order"] = this.compareHelper.setOrder(this.my_sort);
      params["order_direction"] = this.compareHelper.setDirection(this.my_sort);
    }
    if (params["order"] === "" && params["order_direction"] === "") {
      delete params["order"];
      delete params["order_direction"];
    }

    if (this.search_text) {
      params["search_text"] = this.search_text;
    } else {
      delete params["search_text"];
    }

    if (!this.filters["search_fields"]) {
      params["search_fields"] = "";
    }

    if (this.selectedStatus) {
      params["status_id"] = this.selectedStatus;
    } else if (!this.filters["status_id"]) {
      delete params["status_id"];
    }

    if (params["clientId"]) {
      params["client_id"] = params["clientId"];
      delete params["clientId"];
    }

    if (params["expatId"]) {
      params["expat_id"] = params["expatId"];
      delete params["expatId"];
    }

    return params;
  }

  setCaseTimeFrame(e, returnValue?) {
    if (returnValue === "clearFilterDate") {
      return e;
    } else {
      if (e.start_date && e.end_date) {
        if (returnValue) {
          return {
            start_date: e.start_date,
            end_date: e.end_date,
          };
        } else {
          this.getItems();
        }
      }
    }
  }

  /**
   * Monitor query params for change in filters
   */
  protected watchQueryParams(query) {
    if (query && Object.keys(query).length > 0) {
      this.filters = { ...query };
    } else {
      this.clearFilters();
      this.filters = this.getParams(1);
    }
    if (this.filters["search_text"]) {
      this.search_text = this.filters["search_text"];
    }
    if (this.filters["per_page"]) {
      this.perPage = this.filters["per_page"];
    }
    if (this.filters["order"]) {
      this.active = this.filters["order"];
      this.direction = this.filters["order_direction"];
    } else {
      this.active = "";
      this.direction = "";
    }
    const page = this.filters["page"] || 1;

    this.getItems(page);
  }

  /**
   * Clear filters
   */
  clearFilters() {
    this.selectedStatus = null;
    this.search_text = null;
    this.perPage = 25;
    this.filters = { page: 1 };
    this.startDate = null;
    this.endDate = null;
  }

  /**
   * Process the API response
   */
  protected processResponse(data) {
    this.items = data.result.items || data.result.documents;
    this.isLoading = false;
    this.p = data.result.currentPage;
    this.total = data.result.total;
    this.itemsPage = data.result.perPage;
    if ("filters" in data.result) {
      // keep the old filters if there are no filters in the data.
      if (this.availableFilters?.length === 0 || data.result.filters?.length) {
        this.availableFilters = data.result.filters;
      }
    }
    this.selection = new SelectionModel(true, []);
    this.setBulkAction();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Apply date filter
   */
  setDate(e, returnValue?) {
    if (returnValue === "clearFilterDate") {
      return e;
    } else {
      if (e.start_date && e.end_date) {
        this.startDate = e.start_date;
        this.endDate = e.end_date;

        if (returnValue) {
          return { start_date: this.startDate, end_date: this.endDate };
        } else {
          this.getItems();
        }
      }
    }
  }

  /**
   * Apply sort
   */
  sortData(sort: Sort) {
    this.my_sort = sort;
    this.navigateByUrl();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.items.forEach((row) => this.selection.select(row));

    this.setBulkAction();
  }

  /**
   * Change checkbox selection
   */
  changeSelection(item) {
    this.selection.toggle(item);
    this.setBulkAction();
  }

  /**
   * Show / hide bulk options
   */
  setBulkAction() {
    this.showBulkActions = this.selection.selected.length > 0;
  }

  /**
   * Perform bulk action
   */
  bulkAction(action) {
    const ids = [];
    this.selection.selected.forEach((row) => ids.push(row.id));

    const params = [action.id, ids];

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getService()
            ["updateStatusBulk"](...params, result)
            .subscribe(
              (data) => {
                this.getItems(1);
                this.isLoading = false;
              },
              (error) => {
                if (error.error.errors) {
                  this.alertService.errors(error.error.errors);
                }
              }
            );
        }
        this.isLoading = false;
      });
    } else {
      this.getService()
        ["updateStatusBulk"](...params, null)
        .subscribe((data) => {
          this.getItems(1);
          this.isLoading = false;
        });
    }
  }

  /**
   * Change status for a single item
   */
  changeStatus(action, item) {
    const params = [action.id, [item.id]];

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getService()
            ["updateStatusBulk"](...params, result)
            .subscribe(
              (data) => {
                this.getItems();
              },
              (error) => {
                if (error.error.errors) {
                  this.alertService.errors(error.error.errors);
                }
              }
            );
        }
        this.isLoading = false;
      });
    } else {
      this.getService()
        ["updateStatusBulk"](...params, null)
        .subscribe((data) => {
          this.getItems();
        });
    }
  }

  formatDataParam(data) {
    const formattedDate = moment(new Date(data)).format("YYYY/MM/DD");
    return formattedDate.split("/").join("-");
  }

  setFilteringDate(e) {
    if (e) {
      this.startDate = e.start_date;
      this.endDate = e.end_date;
    }
  }

  /**
   * Apply invoice issue filter
   *
   * @param e
   * @param returnValue
   */
  setInvoiceIssueDate(e, returnValue?) {
    if (returnValue === "clearFilterDate") {
      return e;
    } else {
      if (e.start_date && e.end_date) {
        if (returnValue) {
          return {
            invoice_issue_start_date: e.start_date,
            invoice_issue_end_date: e.end_date,
          };
        } else {
          this.getItems();
        }
      }
    }
  }

  /**
   *
   * @param e
   * @param returnValue
   */
  setInvoiceDueDate(e, returnValue?) {
    if (returnValue === "clearFilterDate") {
      return e;
    } else {
      if (e.start_date && e.end_date) {
        if (returnValue) {
          return {
            invoice_due_start_date: e.start_date,
            invoice_due_end_date: e.end_date,
          };
        } else {
          this.getItems();
        }
      }
    }
  }

  /**
   *
   * @param e
   * @param returnValue
   */
  setInvoicePaymentDate(e, returnValue?) {
    if (returnValue === "clearFilterDate") {
      return e;
    } else {
      if (e.start_date && e.end_date) {
        if (returnValue) {
          return {
            invoice_payment_start_date: e.start_date,
            invoice_payment_end_date: e.end_date,
          };
        } else {
          this.getItems();
        }
      }
    }
  }

  /**
   * Export items to XLS
   */
  exportItems(xlsName: string, id = null) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = { ...this.filters };
    params["page"] = 1;
    this.request_call = this.getService()
      ["export"](params, id)
      .subscribe(
        (data: Blob) => {
          const blob = new Blob([data], { type: "application/vnd.ms-excel" });
          saveAs(blob, `${xlsName}.xlsx`);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  textChange(search: string): void {
    if (search !== this.search_text) {
      this.search_text = search;
      this.navigateByUrl();
    }
  }
}
