import {
  AfterContentChecked,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-preview-banner",
  templateUrl: "./preview-banner.component.html",
  styleUrls: ["./preview-banner.component.scss"],
})
export class PreviewBannerComponent implements AfterContentChecked {
  @Input() item;
  @ViewChild("previewAd") previewAd: ElementRef;
  adCreativeHeight;
  preventInfinteCall = true;

  constructor(public renderer: Renderer2) {}

  ngAfterContentChecked(): void {
    if (this.preventInfinteCall) {
      if (this.previewAd) {
        const elOffsetWidth = this.previewAd.nativeElement.offsetWidth;
        const banenrId = this.previewAd.nativeElement.id;
        const adCreative =
          this.previewAd.nativeElement.getElementsByTagName("img");

        if (adCreative.length > 0) {
          this.adCreativeHeight = adCreative[0].getBoundingClientRect().height;
        }

        setTimeout(() => {
          switch (banenrId) {
            case "main-top-providers":
              if (adCreative.length > 0) {
                //1460 recommended banner width
                const adScale = elOffsetWidth / 1460;
                if (adScale > 1) {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(0.8)"
                  );
                } else {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(" + adScale + ")"
                  );
                }

                this.renderer.setStyle(
                  this.previewAd.nativeElement,
                  "height",
                  this.adCreativeHeight + "px"
                );
              }
              break;
            case "main-category":
              if (adCreative.length > 0) {
                //1140 recommended banner width
                const adScale = elOffsetWidth / 1140;
                if (adScale > 1) {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(0.8)"
                  );
                } else {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(" + adScale + ")"
                  );
                }
                this.renderer.setStyle(
                  this.previewAd.nativeElement,
                  "height",
                  this.adCreativeHeight + "px"
                );
              }
              break;
            case "offer_page":
              if (adCreative.length > 0) {
                //320 recommended banner width
                const adScale = elOffsetWidth / 320;
                if (adScale > 1) {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(0.8)"
                  );
                } else {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(" + adScale + ")"
                  );
                }
                this.renderer.setStyle(
                  this.previewAd.nativeElement,
                  "height",
                  this.adCreativeHeight + "px"
                );
              }
              break;
            case "offers_category":
              if (adCreative.length > 0) {
                //1140 recommended banner width
                const adScale = elOffsetWidth / 1140;
                if (adScale > 1) {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(0.8)"
                  );
                } else {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(" + adScale + ")"
                  );
                }
                this.renderer.setStyle(
                  this.previewAd.nativeElement,
                  "height",
                  this.adCreativeHeight + "px"
                );
              }
              break;
            case "offers_filter":
              if (adCreative.length > 0) {
                //250 recommended banner width
                const adScale = elOffsetWidth / 250;
                if (adScale > 1) {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(0.8)"
                  );
                } else {
                  this.renderer.setStyle(
                    adCreative[0],
                    "transform",
                    "scale(" + adScale + ")"
                  );
                }
                this.renderer.setStyle(
                  this.previewAd.nativeElement,
                  "height",
                  this.adCreativeHeight + "px"
                );
              }
              break;

            case "main-carousel":
              this.renderer.setStyle(adCreative[0], "transform", "scale(1)");
              this.renderer.setStyle(
                this.previewAd.nativeElement,
                "height",
                this.adCreativeHeight + "px"
              );
              break;
          }
        }, 1);
        setTimeout(() => {
          this.preventInfinteCall = false;
        }, 500);
      }
    }
  }

  close() {
    // small modal replaced
    this.preventInfinteCall = false;
  }

  isModalOpen() {
    this.preventInfinteCall = true;
  }
}
