<!-- modal -->

<app-dialog-header>
  {{ "GENERALS.CONFIRM-PAYMENT" | translate }}
</app-dialog-header>

<div class="dialog-body">
  <ng-container *ngIf="isLoading">
    <app-loader-spinner-general
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
  </ng-container>

  <div class="modal-form" *ngIf="!isLoading">
    <div class="row">
      <div class="form-group w-50">
        <div class="row">
          <div class="form-group w-100">
            <label>{{ "GENERALS.PAYMENT-METHOD" | translate }} *</label>
            <ng-select
              class="customiz-select"
              [items]="paymentMethods"
              bindLabel="text"
              bindValue="id"
              [(ngModel)]="paymentConfirmationObj.payment_method"
              placeholder="{{ 'GENERALS.SELECT-PAYMENT-METHOD' | translate }}"
              notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-group w-50">
        <div class="fix-position-calendar-modal">
          <div class="modal-calendar-wrapper">
            <label>{{ "GENERALS.SELECT-DATE" | translate }} *</label>
            <app-date-range-calendar
              [singleDate]="paymentConfirmationObj.payment_date"
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              (change)="setInvoicePaymentDate($event)"
            ></app-date-range-calendar>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "GENERALS.INVOICE-TRANSACTION-ID" | translate }}</label>
        <input
          type="text"
          name="transaction_id"
          class="form-control"
          [(ngModel)]="paymentConfirmationObj.transaction_id"
        />
      </div>
    </div>
  </div>
</div>

<div class="dialog-footer">
  <button
    [disabled]="isLoading"
    type="button"
    class="btn-primary"
    (click)="confirmPayment()"
  >
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.CONFIRM" | translate }}
  </button>
</div>
<!-- end modal -->
