import { Component, Input, OnInit } from "@angular/core";
import { CitizenshipModel } from "../../../models/citizenship";
import { Country } from "../../../../../_models/country";
import { map, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { ExpatNationalitiesComponent } from "../../../components/expat-nationalities/expat-nationalities.component";
import { TranslateService } from "@ngx-translate/core";
import { ExpatsService } from "../../../services/expats.service";
import { ExpatData } from "../../../../../_models/expat";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { CitizenshipStatuses } from "src/app/_models/_statuses/citizenship-statuses";

@Component({
  selector: "app-expat-nationalities-card",
  templateUrl: "./expat-nationalities-card.component.html",
  styleUrls: ["./expat-nationalities-card.component.scss"],
})
export class ExpatNationalitiesCardComponent implements OnInit {
  @Input()
  public citizenships;

  @Input()
  public countries!: Country[];

  @Input()
  public expat!: ExpatData;

  public citizenships$: Observable<any>;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private expatService: ExpatsService,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.getCitizenships();
  }

  public handleEdit(citizenship?: CitizenshipModel) {
    const dialogRef = this.dialog.open(ExpatNationalitiesComponent, {
      data: {
        citizenship: citizenship,
        countries: this.countries,
        isCreated: !citizenship,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.action === "save") {
          if (!data.citizenship.obtained_date) {
            delete data.citizenship.obtained_date;
          }

          const newCitizenship = {
            ...data.citizenship,
            isCreated: data.isCreated,
          };
          this.onUpdateCitizenship(newCitizenship);
        }
      });
  }

  public onUpdateCitizenship(citizenShip: CitizenshipModel) {
    const { id, ...citizenshipUpdateModel } = citizenShip;
    if (citizenShip.isCreated === true) {
      this.expatService
        .createCitizenship(this.expatId, citizenshipUpdateModel)
        .pipe(map(() => this.getCitizenships()))
        .subscribe();
    } else {
      this.expatService
        .updateCitizenship(this.expatId, id, citizenshipUpdateModel)
        .pipe(map(() => this.getCitizenships()))
        .subscribe();
    }
  }

  public handleDelete(citizenship?: CitizenshipModel) {
    if (!confirm(this.translate.instant("EXPATS.DELETE-CITIZENSHIP"))) {
      return;
    }
    this.deleteCitizenship(citizenship).subscribe(() => this.getCitizenships());
  }

  public deleteCitizenship(citizenship: CitizenshipModel) {
    return this.expatService.deleteCitizenship(
      this.expat.expat_id,
      citizenship.id
    );
  }

  private getCitizenships() {
    this.citizenships$ = this.expatService.getCitizenships(this.expatId);
  }

  private get expatId() {
    return +this.activatedRoute.snapshot.params.expatId;
  }

  public get CitizenshipStatuses() {
    return CitizenshipStatuses;
  }
}
