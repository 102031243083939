import { ServiceCategory } from "@models/servicecategory";
import { AlertService } from "@modules/alert";
import { LoadingService } from "../../_services/loading.service";
import { ServiceCategoriesService } from "../../_services/servicecategories.service";

export class CategoryHelper {
  public categories: ServiceCategory[];
  public selectedCategory: ServiceCategory;

  constructor(
    private service: ServiceCategoriesService,
    private loading: LoadingService,
    private alert: AlertService
  ) {}

  /**
   * Get the categories the provider is servicing
   * @param entityId
   */
  getCategories(entityId) {
    this.service.getCategoryTree(entityId).subscribe(
      (data) => {
        if (data.success) {
          this.categories = data.result;
        }
        this.loading.loadingFinished.emit();
      },
      (error) => {
        this.loading.loadingFinished.emit();
        if (error.error.errors) {
          this.alert.errors(error.error.errors);
        }
      }
    );
  }
}
