import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { CitizenshipModel, StatusType } from "../../models/citizenship";
import { Country } from "@models/country";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateManagementService } from "../../../shared/services/date-management.service";
import { CustomValidators } from "../../../shared/_validators";
import moment from "moment";

@Component({
  selector: "app-expat-nationalities",
  templateUrl: "./expat-nationalities.component.html",
  styleUrls: ["./expat-nationalities.component.scss"],
})
export class ExpatNationalitiesComponent implements OnInit {
  public citizenship!: CitizenshipModel;

  public countries!: Country[];
  public filteredCountries!: Country[];

  public countryFilterControl = new UntypedFormControl();
  readonly maxDate = moment().toDate();

  @Output()
  public readonly changeCitizenship = new EventEmitter<CitizenshipModel>();

  public form!: UntypedFormGroup;
  public isLoadingGeneral = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dataManagementService: DateManagementService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ExpatNationalitiesComponent>
  ) {}

  ngOnInit() {
    this.setupCountries();
    this.setupCitizenship();
    this.setupForm();
    this.filterCountries();
    this.isLoadingGeneral = false;
  }

  public save() {
    this.triggerValidation();
    if (this.form.valid) {
      if (this.form.controls.status_id.value === true) {
        this.form.controls.status_id.setValue(StatusType.ACTIVE);
      } else {
        this.form.controls.status_id.setValue(StatusType.INACTIVE);
      }

      const data = this.form.value;

      if (!data["revoked_date"]) {
        delete data["revoked_date"];
      }

      this.dialogRef.close({
        action: "save",
        isCreated: this.data.isCreated,
        citizenship: {
          id: this.data.isCreated ? null : this.data.citizenship.id,
          ...data,
        },
      });
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  private triggerValidation() {
    this.form.markAllAsTouched();
  }

  private filterCountries() {
    this.countryFilterControl.valueChanges.subscribe((inputValue) => {
      this.filteredCountries = this.countries.filter(
        (value) =>
          value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      );
    });
  }

  private setupForm() {
    this.form = this.fb.group(
      {
        status_id: this.fb.control(
          this.citizenship ? this.getStatusId(this.citizenship.status_id) : true
        ),
        country: this.fb.control(
          this.citizenship ? this.citizenship.country : null,
          Validators.required
        ),
        is_primary: this.fb.control(
          this.citizenship ? this.citizenship.is_primary : null
        ),
        obtained_date: this.fb.control(this.getDate("obtained_date"), [
          CustomValidators.futureDateValidator(),
        ]),
        revoked_date: this.fb.control(this.getDate("revoked_date"), [
          CustomValidators.futureDateValidator(),
        ]),
      },
      {
        validators: [
          CustomValidators.dateRangeValidator("obtained_date", "revoked_date"),
        ],
      }
    );
  }

  private getDate(date: string) {
    if (this.citizenship && this.citizenship[date]) {
      return new Date(this.citizenship[date]);
    }
    return null;
  }

  private setupCountries() {
    if (this.data && this.data.countries) {
      this.countries = this.data.countries;
      this.filteredCountries = this.data.countries;
    }
  }

  private setupCitizenship() {
    if (this.data && this.data.citizenship) {
      this.citizenship = this.data.citizenship;
    }
  }

  private getStatusId(statusId: StatusType) {
    return statusId === StatusType.ACTIVE;
  }

  setDate(date: any, controlName: string): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.form.get(controlName).setValue(moment(date));
  }
}
