<div class="w-full flex flex-wrap">
  <div class="w-1/2">
    <app-case-status-report></app-case-status-report>
  </div>
  <div class="w-1/2 pl-6">
    <app-on-time-vs-overdue-cases></app-on-time-vs-overdue-cases>
  </div>
  <div class="w-1/2 pt-6">
    <app-average-process-duration></app-average-process-duration>
  </div>
  <div class="w-1/2 pt-6 pl-6">
    <app-estimated-vs-actual-duration></app-estimated-vs-actual-duration>
  </div>
  <div class="w-full">
    <app-cases-report-card></app-cases-report-card>
  </div>
</div>
