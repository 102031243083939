import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { UserData } from "@api/account";
import { AssignmentData } from "@api/assignments/models/assignment.model";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { DictionariesService } from "@services/dictionaries.service";
import moment from "moment";
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  switchMap,
  take,
} from "rxjs";
import { AssignmentTypeEnum } from "src/app/_enums/assignment-type-enum";

@Component({
  selector: "app-essential-info-step",
  templateUrl: "./essential-info-step.component.html",
  styleUrls: ["./essential-info-step.component.scss"],
})
export class EssentialInfoStepComponent implements OnChanges, OnInit {
  @Input() form!: UntypedFormGroup;
  @Input() user: UserData;
  @Input() types$: Observable<any>;
  @Input() isReview: boolean = false;
  @Input() modalData: any;
  @Input() assignment: AssignmentData;

  AssignmentTypeEnum = AssignmentTypeEnum;

  expats: any;
  filteredExpats: any;
  countries: any;
  filteredHomeCountries: any;
  filteredHostCountries: any;

  homeCountriesFilterControl = new UntypedFormControl();
  hostCountriesFilterControl = new UntypedFormControl();
  expatFilterControl = new UntypedFormControl();

  searchHomeCitiesControl = new UntypedFormControl("");
  homeCities = [];

  searchHostCitiesControl = new UntypedFormControl("");
  hostCities = [];

  startDate: any;
  endDate: any;

  authService = inject(AuthorizationService);
  destroyRef = inject(DestroyRef);
  dictionariesService = inject(DictionariesService);
  expatsService = inject(ExpatsService);

  ngOnChanges(): void {
    if (this.form.get("start_date")?.value) {
      this.startDate = this.form.get("start_date")?.value;
    }

    if (this.form.get("end_date")?.value) {
      this.endDate = this.form.get("end_date")?.value;
    }

    const homeCity = this.form.get("home_city")?.value;
    const hostCity = this.form.get("host_city")?.value;

    if (homeCity) {
      this.searchHomeCitiesControl.setValue(homeCity);

      this.dictionariesService
        .cities(homeCity.substring(0, 4), this.form.get("home_country")?.value)
        .subscribe(({ result }) => {
          this.homeCities = result;
        });
    }

    if (hostCity) {
      this.searchHostCitiesControl.setValue(hostCity);

      this.dictionariesService
        .cities(hostCity.substring(0, 4), this.form.get("host_country")?.value)
        .subscribe(({ result }) => {
          this.hostCities = result;
        });
    }
  }

  ngOnInit() {
    this.getCountryList();
    this.filterHomeCountries();
    this.filterHostCountries();
    this.getExpats();
    this.filterExpats();

    if (this.assignment) {
      this.searchHostCitiesControl.setValue(this.assignment.host_city);

      this.searchHomeCitiesControl.setValue(this.assignment.home_city);

      this.dictionariesService
        .cities(
          this.assignment.home_city.substring(0, 4),
          this.form.get("home_country").value
        )
        .subscribe(({ result }) => {
          this.homeCities = result;
        });

      this.dictionariesService
        .cities(
          this.assignment.host_city.substring(0, 4),
          this.form.get("host_country").value
        )
        .subscribe(({ result }) => {
          this.hostCities = result;
        });

      this.startDate = moment(this.assignment.start_date);
      this.endDate = this.assignment.end_date
        ? moment(this.assignment.end_date)
        : "";
    }

    this.subscribeOnValueChanges();
  }

  subscribeOnValueChanges() {
    this.form.get("home_country").valueChanges.subscribe((value) => {
      this.dictionariesService.cities("", value).subscribe(({ result }) => {
        this.homeCities = result;
      });
    });

    this.searchHomeCitiesControl.valueChanges.subscribe((value) => {
      if (value != "") {
        this.dictionariesService
          .cities(value, this.form.get("home_country").value)
          .subscribe(({ result }) => {
            this.homeCities = result;
          });
      }
    });

    this.form.get("host_country").valueChanges.subscribe((value) => {
      this.dictionariesService.cities("", value).subscribe(({ result }) => {
        this.hostCities = result;
      });
    });

    this.searchHostCitiesControl.valueChanges.subscribe((value) => {
      if (value != "") {
        this.dictionariesService
          .cities(value, this.form.get("host_country").value)
          .subscribe(({ result }) => {
            this.hostCities = result;
          });
      }
    });

    this.form
      .get("type")
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (
          this.form.get("type")?.value === AssignmentTypeEnum.PERMANENT_TRANSFER
        ) {
          this.form.get("end_date")?.setValue(null);
          this.endDate = null;
        }

        this.form.get("end_date")?.updateValueAndValidity();
      });
  }

  onCalendarChangeStartDate(date: string): void {
    this.form.get("start_date").setValue(date);
    this.startDate = date;
  }

  onCalendarChangeEndDate(date: string): void {
    this.form.get("end_date").setValue(date);
    this.endDate = date;
  }

  private getCountryList() {
    this.dictionariesService
      .getCountryList()
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe((data) => {
        this.countries = data.result;
        this.filteredHomeCountries = data.result;
        this.filteredHostCountries = data.result;
      });
  }

  private filterHomeCountries() {
    this.homeCountriesFilterControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((inputValue) => {
        this.filteredHomeCountries = this.countries.filter(
          (value) =>
            value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
        );
      });
  }

  private getExpats() {
    this.expatsService
      .list(this.user.entity_id, this.modalData.clientId, {
        per_page: 1000,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.expats = data.result.items;
        this.filteredExpats = data.result.items;

        if (this.modalData.expatId) {
          this.form.get("expat_id").setValue(this.modalData.expatId);
          this.form.get("expat_id").disable();
        }
      });
  }

  private filterExpats() {
    const selectedExpat = this.expatFilterControl.value;

    this.expatFilterControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap((search_text) =>
          this.expatsService.list(
            this.user.entity_id,
            this.modalData.clientId,
            {
              search_text,
            }
          )
        )
      )
      .subscribe((response) => {
        this.filteredExpats = response.result.items;
        if (selectedExpat) {
          const matchingExpat = this.filteredExpats.find(
            (expat) => expat.id === selectedExpat.id
          );
          if (matchingExpat) {
            this.expatFilterControl.setValue(matchingExpat, {
              emitEvent: false,
            });
          }
        }
      });
  }

  private filterHostCountries() {
    this.hostCountriesFilterControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((inputValue) => {
        this.filteredHostCountries = this.countries.filter(
          (value) =>
            value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
        );
      });
  }

  get isPermanentTransfer(): boolean {
    return (
      this.form.get("type")?.value === AssignmentTypeEnum.PERMANENT_TRANSFER
    );
  }
}
