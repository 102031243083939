import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AccountService, UserData } from "@api/account";
import { finalize, take } from "rxjs";
import { Dictionary } from "@models/dictionary";
import { AlertService } from "@modules/alert";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface AccountDialogComponentInput {
  user: UserData;
}

@Component({
  selector: "app-account-dialog",
  templateUrl: "./account-dialog.component.html",
  styleUrls: ["./account-dialog.component.scss"],
})
export class AccountDialogComponent implements OnInit {
  formGroup = new FormGroup({
    id: new FormControl<number>(null, Validators.required),
    email: new FormControl("", Validators.required),
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    phone_number: new FormControl("", Validators.required),
    phone_prefix: new FormControl("", Validators.required),
    company_position: new FormControl("", Validators.required),
  });

  departments: Dictionary;

  isLoading: boolean = false;

  constructor(
    private dictionariesService: DictionariesService,
    private dialogRef: MatDialogRef<AccountDialogComponent>,
    private accountService: AccountService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA)
    public data: AccountDialogComponentInput
  ) {}

  ngOnInit() {
    this.formGroup.patchValue({
      id: this.data.user.id,
      email: this.data.user.email,
      first_name: this.data.user.first_name,
      last_name: this.data.user.last_name,
      phone_number: this.data.user.phone_prefix + this.data.user.phone_number,
      phone_prefix: this.data.user.phone_prefix,
      company_position: this.data.user.company_position,
    });
  }

  submit() {
    this.isLoading = true;

    this.accountService
      .updateCurrentUser(this.formGroup.getRawValue())
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {
          this.dialogRef.close();
          this.alertService.saved();
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  onCountryChanged(event: any) {
    this.formGroup.controls.phone_prefix.setValue(`+${event.dialCode}`);
  }
}
