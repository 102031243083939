import { Component, Inject, OnInit } from "@angular/core";
import { ExpatData } from "@models/expat";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomValidators } from "../../../shared/_validators";
import { OfficeService } from "@modules/clients/services/office.service";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { OfficeModel } from "@models/office";

@Component({
  selector: "app-expat-employment-details",
  templateUrl: "./expat-employment-details.component.html",
  styleUrls: ["./expat-employment-details.component.scss"],
})
export class ExpatEmploymentDetailsComponent implements OnInit {
  public expat!: ExpatData;

  public form!: UntypedFormGroup;
  public isLoadingGeneral = true;

  public offices$!: Observable<OfficeModel[]>;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ExpatEmploymentDetailsComponent>,
    private officeService: OfficeService
  ) {}

  ngOnInit() {
    this.getOffices();
    this.setupForm();
    this.setupExpat();

    this.isLoadingGeneral = false;
  }

  public closeModal() {
    this.dialogRef.close();
  }

  private getOffices() {
    this.offices$ = this.officeService
      .fetchAll(this.data.expat.client_id, {})
      .pipe(tap(() => (this.isLoadingGeneral = false)));
  }

  public save() {
    this.triggerValidation();
    if (this.form.valid) {
      // this.data.expat.company_name = this.form.controls.company_name.value;
      this.data.expat.details.department_name =
        this.form.controls.department_name.value;
      this.data.expat.details.company_position =
        this.form.controls.company_position.value;
      if (!this.data.expat.details.manager_id) {
        this.data.expat.details.manager_name =
          this.form.controls.manager_name.value;
        this.data.expat.details.manager_email =
          this.form.controls.manager_email.value;
      }

      this.data.expat.details.manager_name =
        this.form.controls.manager_name.value;
      this.data.expat.details.manager_email =
        this.form.controls.manager_email.value;

      this.data.expat.details.manager_id = this.form.controls.manager_id.value;
      this.data.expat.details.joined_at = this.form.controls.joined_at.value;

      this.data.expat.expat_id = this.data.expat.id;
      this.data.expat.payroll_id = this.form.controls.payroll_id.value;

      this.data.expat.details.office_id = this.form.controls.office_id.value;

      // TO DO - this field shouldn't be here
      this.data.expat.first_name = this.data.expat.user.first_name;
      this.data.expat.last_name = this.data.expat.user.last_name;

      this.dialogRef.close({ action: "save", expat: this.expat });
    }
  }

  public onManagerChange(id: number) {
    if (id === 0) {
      this.form.controls.manager_name.setValue("");
      this.form.controls.manager_email.setValue("");
    } else {
      const manager = this.data.managers.find((manager) => manager.id === id);
      this.form.controls.manager_name.setValue(manager.name);
      this.form.controls.manager_email.setValue(manager.email);
    }
  }

  private triggerValidation() {
    this.form.controls.manager_id.markAsTouched();
    this.form.markAsTouched();
  }

  private setupForm() {
    this.form = this.fb.group(
      {
        payroll_id: this.fb.control(this.data.expat.payroll_id),
        company_name: this.fb.control(this.data.expat.company_name),
        department_name: this.fb.control(
          this.data.expat.details.department_name
        ),
        company_position: this.fb.control(
          this.data.expat.details.company_position
        ),
        manager_id: this.fb.control(this.data.expat.details.manager_id),
        manager_name: this.fb.control(this.data.expat.details.manager_name),
        manager_email: this.fb.control(this.data.expat.details.manager_email, [
          Validators.email,
        ]),
        office_id: this.fb.control(this.data.expat.details.office_id),
        joined_at: this.fb.control(this.data.expat.details.joined_at),
      },
      {
        validators: [
          CustomValidators.dependentFieldsValidator(
            "manager_name",
            "manager_email"
          ),
        ],
      }
    );
  }

  private setupExpat() {
    this.expat = this.data.expat;
  }
}
