<app-dialog-header>
  {{ (edit ? "PORTAL.EDIT-EXPAT" : "PORTAL.ADD-EXPAT") | translate }}
</app-dialog-header>
<div class="dialog-body">
  <form [formGroup]="form" class="field-grid w-full">
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.FIRST-NAME" | translate }} </mat-label>
      <input matInput formControlName="first_name" name="first_name" />

      <mat-error formControlError="first_name"></mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.LAST-NAME" | translate }} </mat-label>
      <input
        matInput
        type="text"
        formControlName="last_name"
        name="last_name"
      />
      <mat-error formControlError="last_name"></mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.EMAIL" | translate }} </mat-label>
      <input matInput type="text" formControlName="email" name="email" />
      <mat-error formControlError="email"></mat-error>
    </mat-form-field>

    <mat-form-field class="phone-field w-full">
      <mat-label>
        {{ "LABEL.PHONE-NUMBER" | translate }}
      </mat-label>
      <ngx-mat-input-tel
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone_number"
        name="phone_number"
        describedBy="phoneInput"
        [format]="'national'"
        [required]="true"
        #phone
        maxLength="20"
        (countryChanged)="
          form.controls.phone_prefix.setValue('+' + $event.dialCode)
        "
      ></ngx-mat-input-tel>
      <mat-error formControlError="phone_number"></mat-error>
    </mat-form-field>

    <!-- START: COUNTRY FORM CONTROL -->
    <mat-form-field class="w-full">
      <mat-label> {{ "LABEL.COUNTRY-OF-RESIDENCE" | translate }}</mat-label>
      <mat-select
        formControlName="country_code"
        [placeholder]="'LABEL.COUNTRY' | translate"
      >
        <app-country-options></app-country-options>
      </mat-select>
      <mat-error formControlError="country_code"></mat-error>
    </mat-form-field>
    <!-- END:  COUNTRY FORM CONTROL -->
    <mat-form-field class="w-full" *ngIf="!edit">
      <mat-label>{{ "LABEL.PRIMARY-CITIZENSHIP" | translate }}</mat-label>
      <mat-select
        formControlName="primary_citizenship"
        [placeholder]="'LABEL.COUNTRY' | translate"
      >
        <app-country-options></app-country-options>
      </mat-select>
      <mat-error formControlError="primary_citizenship"></mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "EXPATS.PAYROLL-ID" | translate }} </mat-label>
      <input matInput formControlName="payroll_id" name="payroll_id" />
      <mat-error formControlError="payroll_id"></mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.DEPARTMENT" | translate }} </mat-label>
      <input
        matInput
        formControlName="department_name"
        name="department_name"
      />
      <mat-error formControlError="department_name"></mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} </mat-label>
      <input
        matInput
        type="text"
        formControlName="company_position"
        name="company_position"
      />
      <mat-error formControlError="company_position"></mat-error>
    </mat-form-field>
  </form>
</div>

<div class="dialog-footer">
  <button class="btn-primary" (click)="submit()" [disabled]="isLoading">
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
