import { Injectable } from "@angular/core";
import { Breadcrumb } from "@models/breadcrumb";
import { BehaviorSubject } from "rxjs";
import { AssignmentData } from "@api/assignments/models/assignment.model";

@Injectable()
export class BreadcrumbService {
  private breadcrumbsSource = new BehaviorSubject<Breadcrumb[] | null>(null);
  breadcrumbsChanges$ = this.breadcrumbsSource.asObservable();

  changeBreadcrumbs(breadcrumbs: Breadcrumb[] | null): void {
    this.breadcrumbsSource.next(breadcrumbs);
  }

  setForDashboard() {
    return [
      {
        url: "",
        label: "Dashboard",
      },
    ];
  }

  setForDocuments() {
    return [{ url: "/documents", label: "documents" }];
  }

  setForPlans() {
    return [
      { url: "/billing", label: "Billing" },
      { url: "/billing/plans", label: "Plans" },
    ];
  }

  setForBillingInfo() {
    return [
      { url: "/billing", label: "Billing" },
      { url: "/billing/billing-info", label: "Billing Info" },
    ];
  }

  setForOffer(offer, label: string): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/" + offer.id, label: offer.title },
      { url: null, label: label },
    ];
  }

  setForCasesReports(): Breadcrumb[] {
    return [
      { url: "/reports", label: "Reports" },
      { url: "reports/cases", label: "Cases" },
    ];
  }

  setForCustomReports(): Breadcrumb[] {
    return [
      { url: "/reports", label: "Reports" },
      { url: "reports/custom", label: "Custom" },
    ];
  }

  setForMilestoneReports(): Breadcrumb[] {
    return [
      { url: "/reports/financial", label: "Reports" },
      { url: "reports/milestone", label: "Milestone reports" },
    ];
  }

  setForExpenseReports(): Breadcrumb[] {
    return [{ url: "reports/expense", label: "Expense reports" }];
  }

  setForFinancialReports(): Breadcrumb[] {
    return [
      { url: "/reports", label: "Reports" },
      { url: "reports/financial", label: "Financial" },
    ];
  }

  setForNewOffer(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: null, label: "Add service offer" },
    ];
  }

  setForAddBundle(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: null, label: "Add bundle" },
    ];
  }

  setForEditBundle(offer): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/" + offer.bundle_id, label: offer.title },
      { url: null, label: "edit bundle" },
    ];
  }

  setForOfferSales(): Breadcrumb[] {
    return [
      { url: "/service-offers/list", label: "service offers" },
      { url: "/service-offers/sales", label: "sales" },
    ];
  }

  setStatic(label: string, url: string): Breadcrumb[] {
    return [{ url: url, label: label }];
  }

  setMyAccount(): Breadcrumb[] {
    return [
      { url: "/company-users", label: "company users" },
      { url: null, label: "my account" },
    ];
  }

  setCompanySettings(): Breadcrumb[] {
    return [
      { url: null, label: "company settings" },
      { url: null, label: "details" },
    ];
  }

  setCompanyAgreements(): Breadcrumb[] {
    return [
      { url: "/settings", label: "company settings" },
      { url: null, label: "xpath T&Cs" },
    ];
  }

  setCompanyDocs(): Breadcrumb[] {
    return [
      { url: "/settings", label: "company settings" },
      { url: null, label: "Policies" },
    ];
  }

  setCompanyMilestones(): Breadcrumb[] {
    return [
      { url: "/settings", label: "company settings" },
      { url: null, label: "Milestones" },
    ];
  }

  setForTemplateView(url: string, label: string): Breadcrumb[] {
    return [
      { url: url, label: label },
      { url: null, label: "view" },
    ];
  }

  setCompanyUser(username: string, page?: string): Breadcrumb[] {
    return [
      { url: "/company-users", label: "company users" },
      { url: null, label: username },
      { url: null, label: page },
    ];
  }

  setForOrder(order, label): Breadcrumb[] {
    return [
      { url: "/orders", label: "orders" },
      {
        url: "/orders/" + order.identity_number,
        label: "Order no. " + order.identity_number,
      },
      { url: null, label: label },
    ];
  }

  setForClient(client, label: string): Breadcrumb[] {
    return [
      { url: "/clients", label: "clients" },
      { url: "/clients/" + client.id, label: client.company_name },
      { url: null, label: label },
    ];
  }

  setForCase(caseId, label: string): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: null, label: label },
    ];
  }

  setForTask(caseId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: null, label: taskName },
    ];
  }

  setForSubTask(caseId, taskId, subtaskName): Breadcrumb[] {
    return [
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: "task " + taskId },
      { url: null, label: subtaskName },
    ];
  }

  setForTaskNotes(caseId, taskId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: taskName },
      { url: null, label: "notes" },
    ];
  }

  setForTaskExpenses(caseId, taskId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: taskName },
      { url: null, label: "expenses" },
    ];
  }

  setForTaskReminders(caseId, taskId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: taskName },
      { url: null, label: "reminders" },
    ];
  }

  setForRFQ(rfqId: number, label: string): Breadcrumb[] {
    return [
      { url: "/rfq", label: "RFQs & Bids" },
      { url: "/rfq/" + rfqId, label: "RFQ #" + rfqId },
      { url: null, label: label },
    ];
  }

  setForList(url: string, label: string): Breadcrumb[] {
    return [
      { url: url, label: label },
      { url: null, label: "list" },
    ];
  }

  setForInvoice(invoice): Breadcrumb[] {
    return [
      { url: "/invoices/list", label: "invoices" },
      { url: null, label: "Invoice #" + invoice.id },
      { url: null, label: "View" },
    ];
  }

  setForExpat(expat, clientId, label): Breadcrumb[] {
    return [
      { url: "/clients/" + clientId + "/expats", label: "expats" },
      {
        url: "/clients/" + clientId + "/expats/" + expat.id,
        label: expat.user.name,
      },
      { url: null, label: label },
    ];
  }

  setForAssignment(assignment: AssignmentData, label): Breadcrumb[] {
    /** TODO: add  */
    return [];
  }
}
