export enum EntityStatusEnum {
  active = 3,
  pendingApproval = 4,
  invited = 5,
  pendingRequest = 6,
  inactive = 7,
  suspended = 8,
  draft = 9,
  incomplete = 10,
  offline = 902,
  deactivated = 17,
  refused = 50,
  termsNotAccepted = 200,
  categoriesMissing = 201,
}
