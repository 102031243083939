import { createSelector } from "@ngrx/store";
import { tableColumnSettingsFeature } from "./table-column-settings.reducer";

export const selectTableColumnSettings = createSelector(
  tableColumnSettingsFeature.selectTableColumnSettingsState,
  (state) => state
);

export const selectPageColumnSettings = (page: string) =>
  createSelector(selectTableColumnSettings, (state) => {
    return state[page] || [];
  });
