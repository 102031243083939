import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ItemsListComponent } from "../items-list/items-list.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-elements-on-page",
  templateUrl: "./elements-on-page.component.html",
  standalone: true,
  imports: [CommonModule, NgSelectModule, TranslateModule, FormsModule],
})
export class ElementsOnPageComponent
  extends ItemsListComponent
  implements OnInit
{
  @Output() getItem: EventEmitter<any> = new EventEmitter();
  @Input() set perPage(value) {
    if (value) {
      this.per_page = typeof value === "string" ? parseInt(value, 10) : value;
    }
  }
  per_page;
  placeholder;
  _perPageValues;
  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translate.get("GENERALS.RESULTS_PER_PAGE").subscribe((data) => {
      this.placeholder = data;
      this._perPageValues = this.perPageValues;
      this._perPageValues.map((val) => (val.label = this.placeholder));
    });
  }
}
