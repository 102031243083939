<div class="office-dialog dialog p-0">
  <app-dialog-header>
    {{ "EXPATS.OFFICE" | translate }}
  </app-dialog-header>
  <div class="dialog-body">
    <form [formGroup]="form" *ngIf="form">
      <div class="field-grid">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "GENERALS.COUNTRY" | translate }}</mat-label>
          <mat-select
            formControlName="countryCode"
            [placeholder]="'LABEL.COUNTRY' | translate"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.COUNTRY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="countryFilterControl"
                [clearSearchInput]="false"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let country of filteredCountries"
              [value]="country.country_code"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls.countryCode.errors?.required &&
              form.controls.countryCode.touched
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "GENERALS.CITY" | translate }}</mat-label>
          <mat-select
            formControlName="city"
            [placeholder]="'LABEL.CITY' | translate"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.CITY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="cityFilterControl"
                [clearSearchInput]="false"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let city of cities$ | async"
              [value]="city.name"
            >
              {{ city.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls.city.errors?.required && form.controls.city.touched
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.COUNTY-STATE" | translate }}</mat-label>
          <input matInput formControlName="county" />
          <mat-error *ngIf="form.controls.county?.errors?.minlength">
            {{ "GENERALS.MIN-CHARACTERS" | translate }}
            {{ form.controls.county.errors?.minlength?.requiredLength }}
            {{ "GENERALS.MIN-CHARACTERS2" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.ADDRESS" | translate }}</mat-label>
          <input matInput formControlName="address" />
          <mat-error *ngIf="form.controls.address?.errors?.minlength">
            {{ "GENERALS.MIN-CHARACTERS" | translate }}
            {{ form.controls.address.errors?.minlength?.requiredLength }}
            {{ "GENERALS.MIN-CHARACTERS2" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.ZIP_CODE" | translate }}</mat-label>
          <input matInput formControlName="postalCode" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.LABEL" | translate }}</mat-label>
          <input matInput formControlName="label" />
          <mat-error
            *ngIf="
              form.controls.label.errors?.required &&
              form.controls.label.touched
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="dialog-footer">
    <button class="btn-primary" (click)="save()">
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
