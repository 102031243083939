import { Component, Input, OnInit } from "@angular/core";
import { FilterComponent } from "../report-filters/report-filters.component";
import moment from "moment";
import { FormControl } from "@angular/forms";
import { FilterData } from "@modules/shared/models/filter-item.model";
import isEmpty from "lodash/isEmpty";
import { combineLatest, map, shareReplay, startWith } from "rxjs";
@Component({
  selector: "app-report-date-filter",
  templateUrl: "./report-date-filter.component.html",
  styleUrls: ["./report-date-filter.component.scss"],
})
export class ReportDateFilterComponent implements OnInit, FilterComponent {
  @Input() milestoneId: string;

  milestoneFilter: boolean;

  @Input() filter: FilterData;

  @Input() currentFilters: Record<string, any>;

  startDateControl = new FormControl(null);
  endDateControl = new FormControl(null);

  filterValue$ = combineLatest({
    start: this.startDateControl.valueChanges.pipe(startWith(null)),
    end: this.endDateControl.valueChanges.pipe(startWith(null)),
  }).pipe(shareReplay(1));

  filterValue = null;

  getFilterValue() {
    return this.filterValue;
  }

  clearFilterValue(): void {
    this.startDateControl.setValue("");
    this.endDateControl.setValue("");
  }

  ngOnInit(): void {
    this.milestoneFilter = !!this.milestoneId;
    this.setDateRangeSelectedValues();
    this.filterValue$
      .pipe(
        map(({ start, end }) => {
          if (isEmpty(start)) {
            return undefined;
          }

          let date = "";

          if (this.milestoneFilter) {
            date = this.milestoneId + "_";
          }
          date += moment(new Date(start)).format("YYYY/MM/DD");
          if (!isEmpty(end)) {
            date += ";" + moment(new Date(end)).format("YYYY/MM/DD");
          }
          return date;
        })
      )
      .subscribe((value) => {
        this.filterValue = value;
      });
  }

  get key() {
    return this.filter?.id;
  }

  hasActiveFilter() {
    if (this.milestoneId) {
      return (
        this.currentFilters[this.key] &&
        this.currentFilters[this.key].split("_")[0] == this.milestoneId
      );
    }
    return (
      this.currentFilters[this.key] && !isEmpty(this.currentFilters[this.key])
    );
  }

  setDateRangeSelectedValues() {
    if (!this.hasActiveFilter()) {
      return;
    }
    const filterKey = this.key;
    let startDate = "";
    let endDate = "";
    if (filterKey === "milestone_date") {
      if (
        this.currentFilters[filterKey].split("_")[0].toString() !==
        this.milestoneId.toString()
      ) {
        return;
      }

      const date = this.currentFilters[filterKey].split("_")[1];
      startDate = date.split(";")[0];
      endDate = date.split(";")[1];
    } else {
      startDate = this.currentFilters[filterKey].split(";")[0];
      endDate = this.currentFilters[filterKey].split(";")[1];
    }

    this.startDateControl.setValue(
      new Date(moment(startDate).add(1, "day").format("YYYY/MM/DD"))
    );
    this.endDateControl.setValue(
      new Date(moment(endDate).add(1, "day").format("YYYY/MM/DD"))
    );
  }
}
