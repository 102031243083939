<div class="copy-workflow p-0">
  <app-dialog-header>
    {{ "GENERALS.COPY-OFFER" | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <div class="flex flex-wrap w-full">
      <div
        class="w-full flex items-center notification_row bg_light_blue my-2.5 !p-3 text-secondary"
      >
        <mat-icon svgIcon="info" class="mr-2.5"></mat-icon>
        {{
          "Select the service from which you wish to copy the workflow"
            | translate
        }}
      </div>

      <mat-form-field appearance="outline" class="w-full">
        <mat-select
          [formControl]="offerControl"
          [placeholder]="'LABEL.SELECT-OFFER' | translate"
          #singleSelect
        >
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="'LABEL.OFFER' | translate"
              [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
              [formControl]="offerFilterControl"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let offer of offers"
            [value]="offer.id"
            [title]="offer.title"
          >
            ({{ offer.id }})
            {{ offer.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="dialog-footer">
    <button
      [disabled]="!offerControl.value"
      class="btn-primary"
      (click)="save()"
    >
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
