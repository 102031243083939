import { inject, Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import { map, Observable } from "rxjs";

interface BaseSettingRequest extends Record<string, string | number> {
  model_id: string | number;
  model_name: string;
}

interface ListSettingRequest extends BaseSettingRequest {}

interface UpdateSettingRequest extends BaseSettingRequest {
  name: string;
  value: string;
}

export interface SettingOption {
  value: string;
  label: string;
}

export interface SettingAnnotations extends Record<string, any> {
  inputType: string;
  options: SettingOption[];
}

export interface Setting {
  name: string;
  label: string;
  value: string;
  default: string;
  annotations: SettingAnnotations;
}

@Injectable({
  providedIn: "root",
})
export class SettingService {
  http = inject(BackendHttpClient);

  public listSettings(params: ListSettingRequest): Observable<Setting[]> {
    return this.http
      .get<DefaultResponseData<Setting[]>>("settings", { params })
      .pipe(map((response) => response.result));
  }

  public updateSetting(params: UpdateSettingRequest) {
    return this.http.put(`settings/${params.name}`, params);
  }
}
