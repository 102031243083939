import {
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { AssignmentData } from "@api/assignments/models/assignment.model";
import { AssignmentsService } from "@api/assignments/services/assignments.service";
import { CustomValidators } from "@modules/shared/_validators";
import { finalize, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { AssignmentTypeEnum } from "src/app/_enums/assignment-type-enum";
import { User } from "@models/user";
import { UsersService } from "src/app/_services/users.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { DateHelper } from "@helpers/date.helper";
import { MatStepper } from "@angular/material/stepper";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { AlertService } from "@modules/alert";

export function endDateRequiredValidator(typeControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;

    if (!parent) {
      return null;
    }

    const typeControl = parent.get(typeControlName);

    if (
      typeControl &&
      typeControl.value !== AssignmentTypeEnum.PERMANENT_TRANSFER &&
      !control.value
    ) {
      return { required: true };
    }

    return null;
  };
}

@Component({
  templateUrl: "./create-assignment-modal.component.html",
  styleUrls: ["./create-assignment-modal.component.scss"],
})
export class CreateAssignmentModalComponent implements OnInit {
  @ViewChild("stepper") stepper!: MatStepper;

  public edit = false;
  public form: UntypedFormGroup;

  public assignment: AssignmentData;

  public user: UserData;
  public countries;
  public filteredHomeCountries;
  public filteredHostCountries;
  public managers: User[];
  public types$: Observable<any>;

  public isLoaded = false;
  private clientId: number;

  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CreateAssignmentModalComponent>,
    private userService: UsersService,
    private assignmentsService: AssignmentsService,
    private dateHelper: DateHelper
  ) {}

  ngOnInit() {
    this.clientId = this.data.clientId;
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.user = user;
        this.getAssignments();
        this.patchForm();
        this.getTypes();
        this.isLoaded = true;
      });
  }

  private getAssignments() {
    this.assignment = this.data.assignment;
  }

  private patchForm() {
    this.edit = !!this.assignment;

    this.form = new UntypedFormGroup(
      {
        id: new UntypedFormControl(null),
        client_id: new UntypedFormControl(null),
        expat_id: new UntypedFormControl(null, [Validators.required]),
        expat_name: new UntypedFormControl(null),
        home_country: new UntypedFormControl(null, [Validators.required]),
        home_manager_id: new UntypedFormControl(null),
        home_manager_name: new UntypedFormControl(""),
        home_manager_email: new UntypedFormControl(""),
        home_company: new UntypedFormControl(""),
        home_business_unit: new UntypedFormControl(""),
        home_office_id: new UntypedFormControl(""),
        home_office_name: new UntypedFormControl(""),
        home_cost_center: new UntypedFormControl(""),
        home_segment: new UntypedFormControl(""),
        host_country: new UntypedFormControl(null, [Validators.required]),
        host_manager_id: new UntypedFormControl(null),
        host_manager_name: new UntypedFormControl(""),
        host_manager_email: new UntypedFormControl(""),
        host_city: new UntypedFormControl(null, [Validators.required]),
        home_city: new UntypedFormControl(null, [Validators.required]),
        host_office_id: new UntypedFormControl(null),
        host_office_name: new UntypedFormControl(null),
        host_company: new UntypedFormControl(""),
        host_segment: new UntypedFormControl(""),
        payroll: new UntypedFormControl(""),
        host_cost_center: new UntypedFormControl(""),
        host_business_unit: new UntypedFormControl(""),
        number_adults: new UntypedFormControl(""),
        number_children: new UntypedFormControl(""),
        type: new UntypedFormControl({ value: null }, [Validators.required]),
        status: new UntypedFormControl(""),
        start_date: new UntypedFormControl(null, [Validators.required]),
        end_date: new UntypedFormControl(null, [
          endDateRequiredValidator("type"),
        ]),
        sponsor: new UntypedFormControl(""),
        notes: new UntypedFormControl(""),
        created_at: new UntypedFormControl(null),
      },
      {
        validators: [
          CustomValidators.dateRangeValidator("start_date", "end_date"),
        ],
      }
    );

    if (this.edit) {
      this.form.patchValue(this.assignment);
    }
  }

  public save() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (this.form.controls.host_office_id.value === 0) {
        this.form.controls.host_office_id.setValue(null);
      }
      if (this.form.controls.host_office_id.value === 0) {
        this.form.controls.home_office_id.setValue(null);
      }
      this.form.controls.client_id.setValue(this.clientId);

      this.form.controls.end_date.setValue(
        this.dateHelper.toAPIFormat(this.form.controls.end_date.value)
      );
      this.form.controls.start_date.setValue(
        this.dateHelper.toAPIFormat(this.form.controls.start_date.value)
      );

      this.isLoaded = false;

      if (this.edit) {
        this.assignmentsService
          .update(this.form.getRawValue())
          .pipe(finalize(() => (this.isLoaded = true)))
          .subscribe({
            next: (assignment) => {
              this.dialogRef.close({ assignment: assignment });
            },
          });
      } else {
        this.assignmentsService
          .store(this.form.getRawValue())
          .pipe(finalize(() => (this.isLoaded = true)))
          .subscribe({
            next: (assignment) => {
              this.dialogRef.close({ assignment: assignment });
            },
            error: (err) => {
              if (err?.error?.errors) {
                this.alertService.errors(err.error.errors);
              }
            },
          });
      }
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  private getTypes() {
    this.types$ = this.assignmentsService.types();
  }

  public get AssignmentTypeEnum() {
    return AssignmentTypeEnum;
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 2) {
      this.populateFormWithSelfValues();
    }
  }

  private populateFormWithSelfValues() {
    if (!this.form) return;

    this.form.patchValue({
      ...this.form.value,
    });
  }

  prevStep() {
    this.stepper.previous();
  }

  nextStep() {
    if (!this.form.valid) {
      return;
    }

    this.stepper.next();
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
