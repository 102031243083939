import { Injectable } from "@angular/core";
import { CaseData, CasesService } from "@api/cases";
import {
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from "rxjs";
import { CaseStatusEnum } from "../../../../_enums/case-status-enum";

export interface OrderCaseData {
  id: number;
  status: string;
}
export interface OrderItemData {
  client_id: string | number;
  expat_id: string | number;
  order_id: number;
  assignment_id: string | number;
  dependent_id?: string | number;
  order_detail_id: string | number;
  service_id: string | number;
  order_number: number;
}
@Injectable({
  providedIn: "root",
})
export class CaseOrderService {
  cached = new Map<number, Observable<CaseData[]>>();

  refresh$ = new Subject<number>();
  constructor(private casesService: CasesService) {}

  public fetchOrderCase(orderNumber: number): Observable<CaseData[]> {
    return this.refresh$.pipe(
      startWith(orderNumber),
      filter((value) => value == orderNumber),
      switchMap(() =>
        this.casesService.list({
          order_number: orderNumber,
          status: [
            CaseStatusEnum.PENDING,
            CaseStatusEnum.DONE,
            CaseStatusEnum.IN_PROGRESS,
            CaseStatusEnum.CANCELLED,
          ],
          per_page: 100,
        })
      ),
      map((data) => data.items),
      shareReplay(1)
    );
  }

  public getCaseService(
    data: OrderItemData,
    fresh = false
  ): Observable<OrderCaseData | null> {
    if (!this.cached.has(data.order_number)) {
      this.cached.set(
        data.order_number,
        this.fetchOrderCase(data.order_number)
      );
    } else {
      if (fresh) {
        this.refresh$.next(data.order_number);
      }
    }
    return this.cached.get(data.order_number).pipe(
      map((cases) =>
        cases.find(
          (c) =>
            c.subject.reference_id == (data.dependent_id || data.expat_id) &&
            c.assignment_id == data.assignment_id &&
            c.services.find((s) => s.service_id === data.service_id)
        )
      ),
      map((c) => {
        if (!c) {
          return null;
        }
        return {
          id: c.id,
          status: c.status,
        };
      })
    );
  }
}
