import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { AssignmentData } from "src/app/api/assignments/models/assignment.model";
import { AssignmentsService } from "src/app/api/assignments/services/assignments.service";
import { AssignmentAssignableResourceEnum } from "@modules/clients/modules/assignments/enums/assignment-assignable-resource.enum";

@Component({
  selector: "app-assignment-selector",
  templateUrl: "./assignment-selector.component.html",
})
export class AssignmentSelectorComponent implements OnInit {
  caseForm: UntypedFormGroup;

  @Input() submitEvent: Observable<void>;

  public assignmentVal: string;

  assignmentsSearchForm: UntypedFormControl;
  assignments$: Observable<AssignmentData[]>;
  filteredAssignments$: Observable<AssignmentData[]>;

  handleDocumentType(event: MatSelectChange) {
    this.assignmentVal = event.source.triggerValue;
  }

  constructor(
    private readonly assignmentsService: AssignmentsService,
    private controlContainer: ControlContainer
  ) {
    this.assignmentsSearchForm = new UntypedFormControl(
      "",
      Validators.required
    );
  }

  ngOnInit() {
    this.submitEvent.subscribe(() => {
      if (!this.caseForm.get("assignment_id").valid) {
        this.caseForm.get("assignment_id").setErrors({ required: true });
        this.caseForm.get("assignment_id").markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;

    this.assignments$ = combineLatest([
      this.caseForm.get("client_id").valueChanges,
      this.caseForm.get("expat_id").valueChanges,
    ]).pipe(
      tap(() =>
        this.caseForm.get("assignment_id").disable({ emitEvent: false })
      ),
      switchMap(([client_id, expat_id]) =>
        this.assignmentsService.list({
          client_id,
          expat_id,
          assignable_to: AssignmentAssignableResourceEnum.CASE,
        })
      ),
      map((response) => response.items),
      tap(() => {
        const assignmentId = this.caseForm.get("assignment_id").value;

        if (!assignmentId) {
          this.caseForm.get("assignment_id").enable({ emitEvent: false });
        }
      }),
      shareReplay(1)
    );

    this.filteredAssignments$ = combineLatest([
      this.assignmentsSearchForm.valueChanges.pipe(startWith("")),
      this.assignments$,
    ]).pipe(
      map(([searchInput, assignments]) => {
        if (!searchInput) {
          return assignments;
        }
        const filterValue = searchInput.toLowerCase();
        return assignments.filter((assignment) => true);
      }),
      shareReplay(1)
    );
    this.caseForm.get("assignment_id").disable();
  }
}
