import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { LoadingTypeEnum } from "../_enums/loading-type.enum";

@Component({
  selector: "app-editable-field",
  templateUrl: "./editable-field.component.html",
  styleUrls: ["./editable-field.component.scss"],
})
export class EditableFieldComponent implements OnInit {
  @Input() inputType: "input" | "select" = "select";
  @Input() currentItem: any | null;
  @Input() items$: Observable<any[]>;
  @Input() searchControl?: FormControl;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  selectedValue: any;

  ngOnInit(): void {
    this.selectedValue = this.currentItem;
  }

  selectionChanged(event: any) {
    this.selectedValue = event;
    this.itemSelected.emit(event);
  }

  saveItem() {
    this.save.emit(this.selectedValue);
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
