<div
  id="updateAdBanner"
  #updateAdBanner
  identifier="updateAdBanner"
  data="old-ngx-smart-modal"
  (onAnyCloseEvent)="close()"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.UPDATE-AD-BANNER" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal body -->
  <div class="custom-modal-body" *ngIf="item">
    <div class="row">
      <div class="form-group w-50">
        <div class="fix-position-calendar-modal">
          <div class="modal-calendar-wrapper">
            <app-date-range-calendar
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              [singleDate]="item.starts_at"
              [minDate]="minStartDate"
              (change)="setDate($event, 'start_date')"
            ></app-date-range-calendar>
          </div>
        </div>
      </div>

      <div class="form-group w-50">
        <div class="fix-position-calendar-modal">
          <div class="modal-calendar-wrapper">
            <app-date-range-calendar
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              [singleDate]="item.ends_at"
              [minDate]="minEndDate"
              (change)="setDate($event, 'end_date')"
            ></app-date-range-calendar>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "GENERALS.MAX-COST" | translate }} * </label>
        <input
          type="number"
          name="max_cost"
          class="form-control"
          [(ngModel)]="item.max_cost"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "GENERALS.ACTION-URL" | translate }} * </label>
        <input
          type="text"
          name="action_url"
          class="form-control"
          [(ngModel)]="item.action_url"
        />
      </div>
    </div>

    <div class="row" *ngIf="item.status !== 'active'">
      <div class="form-group w-100">
        <label>{{ "GENERALS.UPLOAD-IMG" | translate }} * </label>

        <div class="upload-photo-wrapper">
          <div class="uplaod-photo-box">
            <img *ngIf="item.image_url && !imgURL" src="{{ item.image_url }}" />
            <img [src]="imgURL" *ngIf="imgURL" />
            <span class="orange" *ngIf="message && !imgURL">{{ message }}</span>
          </div>
          <label *ngIf="!imgURL" class="upload-files">
            <div class="btn-small orange-bg">
              <i class="icon-upload-cloud"></i>
              {{ "GENERALS.ADD-PHOTO" | translate }}
            </div>
            <input
              #file
              type="file"
              accept="image/*"
              (change)="preview(file.files)"
            />
          </label>
          <label *ngIf="imgURL" class="upload-files">
            <div (click)="removeUploaded()" class="btn-small orange-bg">
              <i class="icon-trash"></i>
              {{ "GENERALS.REMOVE-PHOTO" | translate }}
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="item.status !== 'active'">
      <div class="form-group w-100">
        <ng-container [ngSwitch]="item.key">
          <div *ngSwitchCase="'main-carousel'" class="fw-500 fs-12 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}: 1920px/440px ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'main-top-providers'" class="fw-500 fs-12 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}: 1920px/300px ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'main-category'" class="fw-500 fs-12 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }} 1920px/240px ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'offers_category'" class="fw-500 fs-12 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }} 1920px/200px ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'offers_filter'" class="fw-500 fs-12 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }} 250px/600px ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'offer_page'" class="fw-500 fs-12 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }} 320px/300px ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg modal-button" (click)="close()">
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg modal-button"
      (click)="updateAdBanenr(item)"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
