import { CommonModule, DatePipe, NgOptimizedImage } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RouterModule } from "@angular/router";
import { ChipsComponent } from "@modules/documents/components/chips/chips.component";
import { CreateComponent } from "@modules/documents/components/create/create.component";
import { FileSelectorComponent } from "@modules/documents/components/form/file-selector/file-selector.component";
import { NameSelectorComponent } from "@modules/documents/components/form/name-selector/name-selector.component";
import { NumberSelectorComponent } from "@modules/documents/components/form/number-selector/number-selector.component";
import { TagSelectorComponent } from "@modules/documents/components/form/tag-selector/tag-selector.component";
import { TypeSelectorComponent } from "@modules/documents/components/form/type-selector/type-selector.component";
import { DocumentsListComponent } from "@modules/documents/documents-shared/documents-list/documents-list.component";
import { RemindersSharedModule } from "@modules/reminders/reminders-shared/reminders-shared.module";
import { SharedModule } from "@modules/shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { CarouselModule } from "ngx-owl-carousel-o";
import { EditComponent } from "../components/edit/edit.component";
import { CountrySelectorComponent } from "../components/form/country-selector/country-selector.component";
import { PermissionsSelectorComponent } from "../components/form/permissions-selector/permissions-selector.component";
import { ServiceCategorySelectorComponent } from "../components/form/service-category-selector/service-category-selector.component";
import { SetReminder } from "../components/form/set-reminder/set-reminder.component";
import { UserSelectorComponent } from "../components/form/user-selector/user-selector.component";
import { LinkComponent } from "../components/link/link.component";
import { ShareComponent } from "../components/share/share.component";
import { DetailsComponent } from "./details/details.component";
import { PreviewContainerDirective } from "./directives/preview-container.directive";
import { DocumentFilesComponent } from "./document-files/document-files.component";
import { DocumentHistoryComponent } from "./document-history/document-history.component";
import { DocumentLinkedWithComponent } from "./document-linked-with/document-linked-with.component";
import { DocumentPreviewComponent } from "./document-preview/document-preview.component";
import { DocumentSharedWithComponent } from "./document-shared-with/document-shared-with.component";
import { ExcelPreviewStrategyComponent } from "./preview-strategies/excel-preview-strategy/excel-preview-strategy.component";
import { ImgPreviewStrategyComponent } from "./preview-strategies/img-preview-strategy/img-preview-strategy.component";
import { NgxDocViewerStrategyComponent } from "./preview-strategies/ngx-doc-viewer-strategy/ngx-doc-viewer-strategy.component";
import { VideoPreviewStrategyComponent } from "./preview-strategies/video-preview-strategy/video-preview-strategy.component";
import { PreviewStrategyResolverComponent } from "./preview-strategy-resolver/preview-strategy-resolver.component";
import { PreviewStrategyFactory } from "./services/preview-strategy-factory";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    DetailsComponent,
    DocumentsListComponent,
    PermissionsSelectorComponent,
    CreateComponent,
    ShareComponent,
    LinkComponent,
    TagSelectorComponent,
    ServiceCategorySelectorComponent,
    CountrySelectorComponent,
    UserSelectorComponent,
    TypeSelectorComponent,
    NameSelectorComponent,
    NumberSelectorComponent,
    FileSelectorComponent,
    ChipsComponent,
    SetReminder,
    DocumentPreviewComponent,
    PreviewContainerDirective,
    ImgPreviewStrategyComponent,
    VideoPreviewStrategyComponent,
    PreviewStrategyResolverComponent,
    NgxDocViewerStrategyComponent,
    DocumentFilesComponent,
    ExcelPreviewStrategyComponent,
    EditComponent,
    DocumentSharedWithComponent,
    DocumentHistoryComponent,
    DocumentLinkedWithComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedModule,
    MatChipsModule,
    NgxDocViewerModule,
    CarouselModule,
    RemindersSharedModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    NgOptimizedImage,
  ],
  exports: [
    DocumentsListComponent,
    NumberSelectorComponent,
    TypeSelectorComponent,
    NameSelectorComponent,
    TagSelectorComponent,
    UserSelectorComponent,
    ServiceCategorySelectorComponent,
    CountrySelectorComponent,
  ],
  providers: [DatePipe, PreviewStrategyFactory],
})
export class DocumentsSharedModule {}
