<mat-form-field class="w-full" [formGroup]="caseForm">
  <mat-label> {{ "GENERALS.EXPAT" | translate }} </mat-label>
  <mat-select
    [formControl]="expatControl"
    [placeholder]="'GENERALS.EXPAT' | translate"
    #singleSelect
    [compareWith]="compareFn"
  >
    <mat-option>
      <ngx-mat-select-search
        [clearSearchInput]="false"
        [placeholderLabel]="'GENERALS.EXPAT' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="expatSearchFrom"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="selectedExpat" [value]="selectedExpat">
      {{ selectedExpat.name }}
    </mat-option>
    <mat-option *ngFor="let expat of filteredExpats$ | async" [value]="expat">
      {{ expat.name }}
    </mat-option>
  </mat-select>
  <mat-error [formControlError]="expatControl"> </mat-error>
</mat-form-field>
