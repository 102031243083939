<div class="box">
  <div class="box-header">
    <span class="box-title">
      {{ "GENERALS.SETTINGS" | translate }}
    </span>
  </div>
  <div class="box-body">
    <ng-container *ngIf="loaded">
      <app-setting-input
        class="w-full"
        *ngFor="let input of inputs"
        [setting]="input"
      ></app-setting-input>
    </ng-container>
  </div>
</div>
