import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import * as AccountActions from "../actions/account.actions";
import { AccountService } from "../services/account.service";

@Injectable()
export class AccountEffects {
  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadAccount, AccountActions.reloadAccount),
      exhaustMap(() =>
        this.account.fetchData().pipe(
          map((data) => AccountActions.loadAccountSuccess({ user: data })),
          catchError((error) =>
            of(AccountActions.loadAccountFailure({ error }))
          )
        )
      )
    )
  );

  saveInStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AccountActions.loadAccountSuccess,
          AccountActions.updateAccountDataSuccess
        ),
        tap(({ user }) => {
          localStorage.setItem(
            environment.user_local_storage_key,
            JSON.stringify(user)
          );
        })
      ),
    { dispatch: false }
  );
  constructor(private actions$: Actions, private account: AccountService) {}
}
