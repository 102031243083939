import { Component, inject, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { CreateQuoteOrderData } from "@modules/quotes/models/create-quote-order.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { AlertService } from "@services/alert.service";
import { finalize } from "rxjs";
import { CheckoutDataForm } from "./checkout-step/checkout-step.component";
import { CartItemForm } from "./items-step/items-step.component";

export interface CartItem {
  id: number;
  name: string;
  quantity: number;
  unit_price: number;
  price: number;
}

export interface CreateOrderComponentInput {}
@Component({
  templateUrl: "./create-order.component.html",
  styleUrls: ["./create-order.component.scss"],
})
export class CreateOrderComponent {
  @ViewChild("stepper")
  stepper: MatStepper;

  cartItemsForm: FormArray<CartItemForm> = new FormArray<FormGroup>(
    [],
    Validators.compose([Validators.required, Validators.minLength(1)])
  );
  checkoutForm: CheckoutDataForm = new FormGroup({
    billingAddressId: new FormControl(null, Validators.required),
    contactId: new FormControl(null, Validators.required),
  });

  orderForm = new FormGroup({
    items: this.cartItemsForm,
    checkout: this.checkoutForm,
  });

  authService = inject(AuthorizationService);
  dialogData = inject(MAT_DIALOG_DATA);
  quoteService = inject(QuotesService);
  dialogRef = inject(MatDialogRef);
  alertService = inject(AlertService);
  isLoading: boolean = false;

  get clientId() {
    return this.dialogData.client_id;
  }

  get providerId() {
    return this.dialogData.provider_id;
  }

  removeCartItem(index: number) {
    this.cartItemsForm.removeAt(index);
  }

  toCheckout() {
    this.stepper.next();
  }

  submit() {
    const data: CreateQuoteOrderData = {
      quotes: this.cartItemsForm.getRawValue().map((quote) => ({
        id: quote.id,
        quantity: quote.quantity,
      })),
      billing_address_id: this.checkoutForm.value.billingAddressId,
      contact_id: this.checkoutForm.value.contactId,
      client_id: this.clientId,
      provider_id: this.providerId,
      currency_code: this.cartItemsForm.value[0].currency,
    };
    this.isLoading = true;
    this.quoteService
      .order(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (order) => {
          this.dialogRef.close({
            order,
          });
          this.alertService.success("Order created successfully");
        },
        error: (message) => {
          this.alertService.error(message);
        },
      });
  }
}
