import moment from "moment";
import { map, Observable } from "rxjs";

const sortFn = (a, b) =>
  moment(a.forecast_start_date).diff(b.forecast_start_date, "days") ||
  a.id - b.id;
const onlyRoot = () => {
  return (task) => !task.parent_id;
};
const childrenOf = (taskId) => {
  return (task) => task.parent_id === taskId;
};

export function sortTasksWithChildren() {
  return function <T>(source: Observable<Array<T>>): Observable<Array<T>> {
    return source.pipe(
      map((tasks: any) => {
        return tasks
          .filter(onlyRoot())
          .sort(sortFn)
          .reduce((result, task) => {
            result.push(task);
            if (task.is_parent) {
              tasks
                .filter(childrenOf(task.id))
                .sort(sortFn)
                .forEach((child) => result.push(child));
            }
            return result;
          }, []);
      })
    );
  };
}

export function sortTasks() {
  return function <T>(source: Observable<Array<T>>): Observable<Array<T>> {
    return source.pipe(map((tasks) => tasks.sort(sortFn)));
  };
}
