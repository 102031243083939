import { Component, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormControl, FormGroup } from "@angular/forms";
import { Country } from "@models/country";
import { combineLatest, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from "rxjs/operators";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-phone-number-input",
  templateUrl: "./phone-number-input.component.html",
  styleUrls: ["./phone-number-input.component.scss"],
})
export class PhoneNumberInputComponent implements OnInit {
  accountForm: FormGroup;
  phonePrefixSearchControl: FormControl;

  phonePrefix = this.dictionariesService.getCountryList().pipe(
    map((data) => data.result),
    takeUntilDestroyed(),
    map((data) => {
      if (!data) return [];
      return data.map((p) => ({
        ...p,
        name: this.dictionariesService.getPhonePrefix(p.phone_code) + p.name,
        phone_code: p.phone_code.startsWith("+")
          ? p.phone_code
          : "+" + p.phone_code,
      }));
    }),
    shareReplay(1)
  );
  filteredPhonePrefix: Observable<Country[]>;

  constructor(
    private controlContainer: ControlContainer,
    private dictionariesService: DictionariesService
  ) {
    this.phonePrefixSearchControl = new FormControl("");
  }

  ngOnInit() {
    this.filteredPhonePrefix = combineLatest({
      prefixList: this.phonePrefix,
      search: this.phonePrefixSearchControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        distinctUntilChanged()
      ),
    }).pipe(
      map(({ prefixList, search }) => {
        if (!search) {
          return prefixList;
        }
        return prefixList.filter((prefix) =>
          prefix.name.toLowerCase().includes(search.toLowerCase())
        );
      })
    );
    this.accountForm = <FormGroup>this.controlContainer.control;
  }

  compareWith(first, second) {
    return first && second && first === second;
  }
}
