import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { map, Observable, tap } from "rxjs";
import { UserStatus } from "@models/_statuses/user-statuses";
import { UsersService } from "../_services/users.service";

@Injectable({
  providedIn: "root",
})
export class ActiveGuard implements CanActivate {
  constructor(private router: Router, private userService: UsersService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.getCurrentUserStatus().pipe(
      map((data) => data.result),
      tap((statuses) => {
        if (
          statuses.user_status_id !== UserStatus.active ||
          statuses.entity_status_id !== UserStatus.active
        ) {
          this.router.navigateByUrl("/my-account").then();
        }
      }),
      map(
        (statuses) =>
          statuses.user_status_id === UserStatus.active &&
          statuses.entity_status_id === UserStatus.active
      )
    );
  }
}
