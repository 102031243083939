import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, Host, Input, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatOptionModule,
  MatOptionSelectionChange,
} from "@angular/material/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { CurrencyData, CurrencyService } from "@api/intl";
import { AbstractEmbeddedOptionsDirective } from "@modules/shared/_directives/abstract-embedded-options.directive";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  map,
  startWith,
} from "rxjs";

@Component({
  selector: "app-currency-options",
  templateUrl: "./currency-options.component.html",
  styleUrls: ["./currency-options.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatOptionModule,
    MatSelectModule,
    TranslateModule,
  ],
})
export class CurrencyOptionsComponent
  extends AbstractEmbeddedOptionsDirective
  implements OnInit, AfterViewInit
{
  @Input()
  actualOption: CurrencyData;

  currencies$: Observable<CurrencyData[]>;
  filteredCurrencies$: Observable<CurrencyData[]>;

  teamSearchControl = new FormControl("");

  opened$: Subject<void> = new Subject();

  constructor(
    @Host() select: MatSelect,
    private readonly currencyService: CurrencyService
  ) {
    super(select);
    select.openedChange.subscribe(() => this.opened$.next());
  }

  ngAfterViewInit(): void {
    this.initOptions();
  }

  selectionChange(event: MatOptionSelectionChange, team: CurrencyData) {
    if (!event.source.selected) {
      return;
    }
    this.actualOption = team;
  }

  ngOnInit(): void {
    this.currencies$ = this.currencyService.currencies();

    this.filteredCurrencies$ = combineLatest([
      this.currencies$,
      this.teamSearchControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300)
      ),
    ]).pipe(
      map(([currencies, search]) => {
        if (search) {
          return currencies.filter(
            (currency) =>
              currency.code
                .toLocaleLowerCase()
                .includes(search?.toLocaleLowerCase()) ||
              currency.name
                .toLocaleLowerCase()
                .includes(search?.toLocaleLowerCase())
          );
        }
        return currencies;
      })
    );
  }
}
