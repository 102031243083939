export enum UserStatus {
  setup = 1,
  active = 3,
  unverified = 53,
  invited = 11,
  "pending-request" = 6,
  deleted = 7,
  suspended = 8,
  draft = 9,
  incomplete = 10,
  declined = 17,
  refused = 50,
  pending = 2,
  "no-categories" = 52,
}
export enum UserStatusName {
  SETUP = "setup",
  ACTIVE = "active",
  UNVERIFIED = "unverified",
  INVITED = "invited",
  PENDING_REQUEST = "pending-request",
  DELETED = "deleted",
  SUSPENDED = "suspended",
  DRAFT = "draft",
  INCOMPLETE = "incomplete",
  DECLINED = "declined",
  REFUSED = "refused",
  PENDING = "pending",
  NO_CATEGORIES = "no-categories",
}
