<app-dialog-header>
  {{ "GENERALS.ADD-DOC" | translate }}
</app-dialog-header>

<div class="dialog-body">
  <ng-container *ngIf="isLoading">
    <app-loader-spinner-general
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
  </ng-container>
  <!-- <div class="modal-body-desc">{{ 'GENERALS.IPSUM' | translate }}</div> -->

  <div class="modal-form" *ngIf="!isLoading">
    <div class="row">
      <div class="form-group w-50">
        <label>{{ "GENERALS.UPLOAD-DOC" | translate }} *</label>

        <div class="custom-upload-box">
          <file-upload
            [(ngModel)]="uploadedFiles.file"
            [control]="fileUploadControl"
            (ngModelChange)="limitFilesUpload($event)"
          >
            <ng-template #placeholder>
              <span><i class="icon-upload-cloud"></i></span>
              <span>{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }} </span>
              <span class="orange"> {{ "GENERALS.BROWSE" | translate }}</span>
            </ng-template>

            <ng-template
              let-i="index"
              let-file="file"
              let-control="control"
              #item
            >
              <div class="file-info">
                <span class="file-name">{{ file.name }}</span>
                <span class="remove-file" (click)="control.removeFile(file)">
                  <i class="icon-trash"></i
                  >{{ "GENERALS.REMOVE-FILE" | translate }}</span
                >
              </div>
            </ng-template>
          </file-upload>
        </div>
      </div>

      <div class="form-group w-50">
        <div class="row">
          <div class="form-group w-100">
            <label>{{ "GENERALS.DOC-NAME" | translate }}</label>
            <input
              type="text"
              name="doc_name"
              class="form-control"
              [(ngModel)]="uploadedFiles.name"
              placeholder="{{ 'GENERALS.DOC-NAME' | translate }}"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group w-100">
            <label>{{ "GENERALS.STATUS" | translate }} *</label>
            <ng-select
              class="customiz-select"
              [items]="statuses"
              bindLabel="text"
              bindValue="id"
              [(ngModel)]="uploadedFiles.status"
              placeholder="{{ 'GENERALS.SELECT-STATUS' | translate }}"
              notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
            >
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="w-100 gray fs-12 fw-500">
        <ng-container *ngIf="docType === ordersService.docTypeInvoice">
          {{ "GENERALS.DOC-TYPE-INVOICE" | translate }}.
        </ng-container>
        <ng-container *ngIf="docType !== ordersService.docTypeInvoice">
          {{ "GENERALS.DOC-TYPE" | translate }}.
        </ng-container>
      </div>
    </div>

    <div *ngIf="docType === ordersService.docTypeInvoice">
      <div class="row">
        <div class="form-group w-50">
          <label>{{ "GENERALS.INVOICE-AMOUNT" | translate }} *</label>
          <input
            type="number"
            step="any"
            name="invoice_amount"
            class="form-control"
            placeholder="{{ 'GENERALS.INVOICE-AMOUNT' | translate }}"
            [(ngModel)]="invoiceExtraData.invoice_amount"
          />
        </div>

        <div class="form-group w-50">
          <label>{{ "GENERALS.INVOICE-CURRENCY" | translate }} *</label>
          <ng-select
            class="customiz-select"
            [(ngModel)]="invoiceExtraData.invoice_currency"
            placeholder="{{ 'GENERALS.SELECT-CURRENCY' | translate }}"
            notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          >
            <ng-option
              *ngFor="let currency of currencies"
              [value]="currency.code"
            >
              {{ currency.code }}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="form-group w-50">
          <label>{{ "GENERALS.INVOICE-STATUS" | translate }} *</label>
          <ng-select
            class="customiz-select"
            [items]="orderInvoiceStatuses"
            bindLabel="text"
            bindValue="id"
            [(ngModel)]="invoiceExtraData.invoice_status"
            placeholder="{{ 'GENERALS.SELECT-STATUS' | translate }}"
            notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
            >statuses
          </ng-select>
        </div>
        <div class="form-group w-50">
          <label>{{ "GENERALS.INVOICE-NUMBER" | translate }} *</label>
          <input
            type="text"
            name="invoice_number"
            class="form-control"
            [(ngModel)]="invoiceExtraData.invoice_number"
            #invoice_number="ngModel"
            placeholder="{{ 'GENERALS.INVOICE-NUMBER' | translate }}"
          />
        </div>
      </div>

      <div class="row">
        <div class="w-50 invoice-date-select">
          <label class="header-label m-b-10 tree-commissions-action">
            {{ "GENERALS.INVOICE-ISSUE-DATE" | translate }} *
          </label>
          <app-date-range-calendar
            [singleDate]="invoiceExtraData.invoice_issue_date"
            (change)="setDate($event, 'invoice_issue_date')"
            placeholder="{{ 'GENERALS.INVOICE-ISSUE-DATE' | translate }}"
          ></app-date-range-calendar>
        </div>

        <div class="w-50 invoice-date-select">
          <label class="header-label m-b-10 tree-commissions-action">
            {{ "GENERALS.INVOICE-DUE-DATE" | translate }} *
          </label>
          <app-date-range-calendar
            [singleDate]="invoiceExtraData.invoice_due_date"
            (change)="setDate($event, 'invoice_due_date')"
            placeholder="{{ 'GENERALS.INVOICE-DUE-DATE' | translate }}"
          ></app-date-range-calendar>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dialog-footer">
  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.BUTTONS"
    [repeat]="2"
  ></app-loader-spinner-general>
  <ng-container *ngIf="!isLoading">
    <button
      [disabled]="isLoading"
      type="button"
      class="btn-primary"
      (click)="uploadFile()"
    >
      {{ "GENERALS.ADD" | translate }}
    </button>
  </ng-container>
</div>
<!-- end modal -->
