import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { UserData } from "@api/account";
import { CaseActions } from "@api/cases/actions";
import { CaseData, CaseType } from "@api/cases/models/case.model";
import { CasesService } from "@api/cases/services/cases.service";
import { CreateComponent } from "@modules/cases/cases-shared/create/create.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { Subject, combineLatest } from "rxjs";
import { finalize, take } from "rxjs/operators";
import { UserRoleEnum } from "src/app/_enums/user-role-enum";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { CaseStatusEnum } from "../../../../_enums/case-status-enum";

@Component({
  selector: "app-cases-list",
  templateUrl: "./cases-list.component.html",
  styleUrls: ["./cases-list.component.scss"],
})
export class CasesListComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy, OnChanges
{
  isFiltersLoaded: boolean = false;
  isXs: boolean;
  searchFilters = [];
  items: CaseData[];
  url: string;
  private destroyed$: Subject<void> = new Subject();
  @Input() hideTitle = false;
  @Input() hideFilters = false;
  @Input() client;
  @Input() expat;
  @Input() manager: UserData | null;
  @Input() limit = 0;
  @Input() overdue = false;
  @Input() user = null;
  @Input() isOnDashboard = false;
  @Input() defaultFilters;
  isFormattedParams = true;

  public canCreate = {
    action: PermissionActionEnum.CREATE,
    resource: PermissionResourceEnum.CASE,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };
  public canUpdate = {
    action: PermissionActionEnum.UPDATE,
    resource: PermissionResourceEnum.CASE,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };
  constructor(
    private casesService: CasesService,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {
    super();
    this.url = this.router.url;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.expat) {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForExpat(this.expat, this.client, "cases")
      );
    } else if (this.client) {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForClient(this.client, "cases")
      );
    } else if (this.overdue) {
      this.getItems();
    } else {
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForList("/cases", "cases")
      );
    }
    this.watchQueryParams();
  }

  ngOnChanges(changes) {
    if (changes.overdue) {
      this.getItems(this.filters.page);
    }
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoading = true;

    const params = this.getParams(this.filters.page ?? page, this.limit);
    if (this.client) {
      params["client_id"] = this.client.id;
    }
    if (this.expat) {
      params["expat_id"] = this.expat.id;
    }

    if (this.isOnDashboard) {
      if (this.overdue) {
        params["tag"] = "overdue";
      } else {
        delete params["tag"];
      }

      if (this.user.role_id !== UserRoleEnum.PROVIDER_ADMIN) {
        params["user_id"] = this.user.id;
      }

      params["page"] = page;
    }

    this.request_call = this.casesService
      .list(params)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.searchFilters = data.filters;
          this.processResponse({ result: data });
          this.isFiltersLoaded = true;
          this.hideElements();
          this.store.dispatch(CaseActions.upsertCases({ cases: data.items }));
          if (this.client) {
            this.availableFilters = this.availableFilters.filter(
              (item) => item.id !== "client_id"
            );
          }
          if (this.expat) {
            this.availableFilters = this.availableFilters.filter(
              (item) => item.id !== "expat_id"
            );
          }
        },
        error: (error) => {
          this.items = [];
          this.isFiltersLoaded = true;
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);

    if (this.isOnDashboard) {
      this.getItems(page);
      return;
    }

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
        replaceUrl: true,
      })
      .then();
  }

  openDialog(): void {
    this.dialog
      .open(CreateComponent, {})
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.type === "saved") {
          this.router.navigate(["/cases", data.caseData.id]);
        }
      });
  }

  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, query]) => {
        const filters = { ...params, ...query };

        super.watchQueryParams(filters);
      }
    );
  }

  public setFilters(e) {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(take(1))
      .subscribe(([params, query]) => {
        const filters = { ...params, ...query };

        this.defaultFilters = {
          ...filters,
          time_frame:
            e.start_date && e.end_date
              ? `${e.start_date.toISOString()};${e.end_date.toISOString()}`
              : null,
        };
      });

    return this.cleanFilters(this.defaultFilters);
  }

  public cleanFilters(obj) {
    for (const propName in obj) {
      if (obj[propName] === null) {
        delete obj[propName];
      }
    }
    return obj;
  }

  private hideElements() {
    /**
     * If more params are in the future, has to be added here in order for right map
     */
    const params = {
      client_id: this.route.snapshot.params.clientId,
      expat_id: this.route.snapshot.params.expatId,
    };

    const formattedParams = Object.keys(params)
      .filter((key) => params[key])
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: params[key],
        });
      }, {});

    this.isFormattedParams = Object.keys(formattedParams).length !== 0;

    this.searchFilters = this.searchFilters.filter((item) => {
      return Object.entries(formattedParams).every(([k]) => item.id !== k);
    });
  }

  protected getService() {
    return this.casesService;
  }

  get CaseType() {
    return CaseType;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  protected readonly CaseStatusEnum = CaseStatusEnum;
}
