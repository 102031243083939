import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "notAvailable",
  standalone: true,
})
export class NotAvailablePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value && value != "") {
      return value;
    }
    return this.translate.instant("N/A");
  }
}
