<div class="details-body-wrapper">
  <div windowScrollDashboardBodyTop class="dashboard-body-top">
    <!-- right -->
    <div class="button-bar">
      <button class="btn-outline-primary" (click)="export()">
        <mat-icon svgIcon="export" class="btn-icon"></mat-icon>
        {{ "GENERALS.EXPORT" | translate }}
      </button>
      <button
        *ngIf="pageName === 'order_quotes'"
        class="btn-outline-primary"
        (click)="sendQuotesToClient()"
      >
        <mat-icon svgIcon="mail-outline" class="btn-icon"></mat-icon>
        {{ "PAGES.ORDERS.ORDER_QUOTES.SEND_QUOTES_TO_CLIENT" | translate }}
      </button>
      <button class="btn-primary" (click)="createOrEdit()">
        <mat-icon svgIcon="receipt" class="btn-icon"></mat-icon>
        {{ "PAGES.ORDERS.ORDER_QUOTES.ADD_QUOTE" | translate }}
      </button>
      <button class="btn-primary" (click)="placeOrder(selection.selected)">
        <mat-icon svgIcon="cart" class="btn-icon"></mat-icon>
        {{ "GENERALS.PLACE-ORDER" | translate }}
      </button>
    </div>
    <!-- end right -->
  </div>
  <!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.FILTER"
  ></app-loader-spinner-general>

  <!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
  <div class="dashboard-body-action" *ngIf="!isLoading">
    <!-- left -->
    <div class="float-left w-full p-0 advanced-search-wrapper">
      <app-filters
        [availableFilters]="availableFilters"
        [activeFilters]="filters"
        (search)="textChange($event)"
        (filter)="filters = $event; navigateByUrl()"
        [total]="total"
        searchPlaceholder="Service quotes"
        (changePerPage)="perPage = $event; navigateByUrl()"
        (eventClearFilters)="clearFilters(); navigateByUrl()"
      >
      </app-filters>
    </div>
    <!-- end left -->
  </div>
  <!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

  <!-- table -->
  <div class="table-wrapper">
    <table
      mat-table
      matSort
      (matSortChange)="sortData($event)"
      [matSortActive]="active"
      [matSortDirection]="direction"
      [dataSource]="
        items
          | paginate
            : {
                itemsPerPage: itemsPage,
                currentPage: p,
                totalItems: total
              }
      "
    >
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let item">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? changeSelection(item) : null"
            [checked]="selection.isSelected(item)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header="id">
          {{ "LABEL.ID" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <div
            class="flex justify-end items-center font-semibold text-secondary"
          >
            <a class="text-secondary"> #{{ item.id }} </a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="offer_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="offer_id">
          {{ "LABEL.OFFER_ID" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <div
            class="flex justify-end items-center font-semibold text-secondary"
          >
            <a
              class="text-secondary"
              [routerLink]="['/service-offers/', item.offer_id]"
            >
              #{{ item.offer_id }}
            </a>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="service_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="service_name">
          {{ "LABEL.SERVICE_NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <a [routerLink]="['/service-offers/', item.offer_id]">
            {{ item.service_name }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="offer_country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="offer_country">
          {{ "LABEL.COUNTRY" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.offer?.country | country | async }}
        </td>
      </ng-container>
      <ng-container matColumnDef="unit_price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="unit_price">
          {{ "LABEL.UNIT_PRICE" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.unit_price | currency : item.currency_code }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expires_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="expires_at">
          {{ "LABEL.VALID_UNTIL" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="!calendar.isExpired(item.expires_at)">
            {{ item.expires_at | date }}
          </span>
          <span
            class="text-primary"
            *ngIf="calendar.isExpired(item.expires_at)"
          >
            {{ "RFQ.EXPIRED" | translate }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="text-center">
          <app-column-selection [columns]="columns"></app-column-selection>
        </th>
        <td mat-cell *matCellDef="let item" class="text-center">
          <button
            mat-icon-button
            [matMenuTriggerFor]="quoteMenu"
            class="btn-outline-none-primary items-end"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #quoteMenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              class="uppercase"
              (click)="createOrEdit(item)"
            >
              {{ "GENERALS.EDIT" | translate }}
            </button>
            <button mat-menu-item class="uppercase" (click)="remove(item)">
              {{ "GENERALS.DELETE" | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="loading">
        <td mat-footer-cell *matFooterCellDef colspan="100%">
          <app-loader-spinner-general
            [loadingType]="LoadingType.TABLE"
          ></app-loader-spinner-general>
        </td>
      </ng-container>
      <ng-container matColumnDef="no-data">
        <td mat-footer-cell *matFooterCellDef colspan="100%">
          <div
            class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
          >
            {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row sticky *matHeaderRowDef="getColumns()"></tr>
      <tr
        mat-row
        [hidden]="isLoading"
        *matRowDef="let row; columns: getColumns()"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['loading']"
        [hidden]="!isLoading"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['no-data']"
        [hidden]="items?.length || isLoading"
      ></tr>
    </table>
    <!-- end responsive table -->
    <!-- paginator -->
    <div class="paginator">
      <pagination-controls
        (pageChange)="navigateByUrl($event)"
        previousLabel=""
        nextLabel=""
      ></pagination-controls>
    </div>

    <!-- end paginator -->
  </div>
  <!-- end table -->
</div>
