import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { AuthActions } from "@api/authentication";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isUserMenuOpen;
  user;

  hasCrm = environment.has_crm;
  hasMarketplace = environment.has_marketplace;

  @ViewChild("headerRef", { static: true }) headerRef: ElementRef;
  constructor(private renderer: Renderer2, private store: Store) {}

  ngOnInit() {
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
  }

  fixedHeader(e) {
    const scrolled =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentElement ||
            document.body
          ).scrollTop;

    if (scrolled >= 30) {
      this.renderer.addClass(
        this.headerRef.nativeElement,
        "small-fixed-header-portal"
      );
    } else {
      this.renderer.removeClass(
        this.headerRef.nativeElement,
        "small-fixed-header-portal"
      );
    }
  }

  backToAdmin() {
    this.store.dispatch(AuthActions.logout());
  }
}
