import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { combineLatest } from "rxjs";
import { OrdersService } from "src/app/_services/orders.service";
import { OrderDetails } from "@models/_menu/order-details";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent {
  menu;
  id;
  filters;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrdersService
  ) {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, query]) => {
        this.id = params["id"];
        this.filters = query;

        this.menu = new OrderDetails(params["id"], query);
      }
    );
  }

  get order(): any {
    return this.orderService.orderModel;
  }
}
