import { CommonModule, DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DocumentsSharedModule } from "@modules/documents/documents-shared/document-shared.module";
import { CreateExpenseComponent } from "@modules/expenses/components/create/create.component";
import { FileSelectorComponent } from "@modules/expenses/components/form/file-selector/file-selector.component";
import { SharedModule } from "@modules/shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ExpansesListComponent } from "./expanses-list/expanses-list.component";
import { CurrencyOptionsComponent } from "src/app/standalone/components/currency-options/currency-options.component";
// tslint:disable-next-line:max-line-length

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ExpansesListComponent,
    CreateExpenseComponent,
    FileSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    DocumentsSharedModule,
    CurrencyOptionsComponent,
  ],
  providers: [DatePipe],
  exports: [ExpansesListComponent],
})
export class ExpensesSharedModule {}
