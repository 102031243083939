<div
  id="detailsCampaign"
  data="old-ngx-smart-modal"
  #detailsCampaign
  identifier="detailsCampaign"
  (onAnyCloseEvent)="close()"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.CAMPAIGN-DETAILS" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal body -->
  <div class="custom-modal-body" *ngIf="item">
    <div class="row m-b-10">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.CREATIVE" | translate }}:
        </div>
        <div class="img-inside-table inline-block">
          <img src="{{ item.image_url }}" alt="{{ item.key | translate }}" />
        </div>
      </div>

      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.STATUS" | translate }}:
        </div>
        <div class="status-label-{{ item.status }}">
          {{ "AD-CAMPAIGNS." + item.status | translate }}
        </div>
      </div>
    </div>

    <div class="row m-b-10">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.PLACEMENT" | translate }}:
        </div>
        <div class="fw-500">{{ "BANNER-TYPE." + item.key | translate }}</div>
      </div>

      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.DURATION" | translate }}:
        </div>
        <div class="fw-500">
          {{ item.starts_at | date : "dd/MM/yyyy" }} -
          {{ item.ends_at | date : "dd/MM/yyyy" }}
        </div>
      </div>
    </div>

    <div class="row m-b-10">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.SERVICE-CATEGORY" | translate }}:
        </div>
        <div class="fw-500" *ngIf="item.category; else na">
          <ng-container
            *ngFor="
              let category of item.category.breadcrumbs;
              let last = last;
              let first = first
            "
          >
            <a class="breadcrumbs-item">{{ category.label }}</a>
            <ng-container *ngIf="!last">
              <span class="breadcrumbs-separator">/</span></ng-container
            >
          </ng-container>
        </div>
        <ng-template #na>
          <div class="fw-500">N/A</div>
        </ng-template>
      </div>

      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.COUNTRY" | translate }}:
        </div>
        <div class="fw-500" *ngIf="item.country; else na">
          {{ item.country }}
        </div>
        <ng-template #na>
          <div class="fw-500">N/A</div>
        </ng-template>
      </div>
    </div>

    <div class="row m-b-10">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.DAILY-BUDGET" | translate }}:
        </div>
        <div class="fw-500">{{ item.max_cost | currency : "EUR" }}</div>
      </div>

      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.PERFORMANCE-TYPE" | translate }}:
        </div>
        <div class="fw-500">{{ item.performance_type | uppercase }}</div>
      </div>
    </div>

    <div class="row m-b-10">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.CLICKS" | translate }}:
        </div>
        <div class="fw-500">{{ item.clicks }}</div>
      </div>

      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.VIEWS" | translate }}:
        </div>
        <div class="fw-500">{{ item.views }}</div>
      </div>
    </div>

    <div class="row m-b-10">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.COST-THIS-MONTH" | translate }}:
        </div>
        <div class="fw-500">{{ item.cost_this_month | currency : "EUR" }}</div>
      </div>

      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.ACTION-URL" | translate }}:
        </div>
        <div class="fw-500">{{ item.action_url }}</div>
      </div>
    </div>

    <div class="row m-b-10" *ngIf="item.status_reason">
      <div class="w-50">
        <div class="inner-label-block fw-500">
          {{ "GENERALS.DECLINE-MESSAGE" | translate }}:
        </div>
        <div class="fw-500">{{ item.status_reason }}</div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg" (click)="close()">
      {{ "GENERALS.CLOSE" | translate }}
    </button>
  </div>
</div>
