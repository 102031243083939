import {
  HttpContextToken,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthActions } from "@api/authentication";
import { AlertService } from "@modules/alert";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export const DISPLAY_API_ERRORS = new HttpContextToken<boolean>(() => true);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!request.context.get(DISPLAY_API_ERRORS)) {
          return throwError(() => error);
        }

        if (error.status === 401) {
          this.store.dispatch(AuthActions.unauthenticated());
        }

        this.alertService.errors(error?.error?.errors);

        return throwError(() => error);
      })
    );
  }
}
