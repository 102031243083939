import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { environment } from "@environment/environment";
import { User } from "@models/user";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, map, Observable } from "rxjs";
import { AuthActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public currentUser: Observable<User>;

  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private httpClient: BackendHttpClient,
    private store: Store,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(environment.user_local_storage_key))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Login
   *
   * @param username
   * @param password
   * @returns {any}
   */
  login(
    username: string,
    password: string,
    recaptchaToken: string | null = null
  ): any {
    const body = Object.assign(
      {
        username: username,
        password: password,
      },
      recaptchaToken ? { token: recaptchaToken } : {}
    );

    return this.httpClient.post<any>("login", body, this.setOptions()).pipe(
      map((data) => {
        // login successful if there's a jwt token in the response
        if (data && data.result.session.token) {
          this.store.dispatch(
            AuthActions.authorized({ token: data.result.session.token })
          );
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const user: User = data.result.user;

          //this local storage should be removed and not used anymore
          localStorage.setItem(
            environment.user_local_storage_key,
            JSON.stringify(user)
          );

          //this local storage should be removed and not used anymore
          this.cookieService.set(
            environment.session_local_storage_key,
            JSON.stringify(data.result.session),
            5, // expires in 5 days
            "/",
            environment.domain,
            true
          );
        }

        return data;
      })
    );
  }

  /**
   * Register step 1
   * @param user
   */
  register(user: User): any {
    return this.httpClient
      .post<any>("entities/providers/register", user, this.setOptions())
      .pipe();
  }

  /**
   * Set headers for request
   */
  private setOptions() {
    const x_client_secret = btoa(
      environment.client_secret +
        ":" +
        environment.client_id +
        ":" +
        environment.role
    );

    return {
      headers: new HttpHeaders({
        "content-type": "application/json",
        "x-client-secret": x_client_secret,
      }),
    };
  }

  resendConfirmationMail(userId: number) {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}users/${userId}/send-confirmation-mail`,
        {}
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Check if the user has a specific permission
   * @param action
   * @param resource
   * @param secondaryResource
   */
  public $can(
    action: string,
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    const permission = `${action} ${resource} ${secondaryResource}`;
    return this.currentUser.pipe(
      map((user) => user.permissions.indexOf(permission) > -1)
    );
  }
}
