<!-- filter by date start   -->
<mat-form-field
  appearance="outline"
  class="w-full mat-form-date-range tasks-filter-date"
>
  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      [formControl]="startDateControl"
      placeholder="dd/mm/yyyy"
    />

    <input matEndDate [formControl]="endDateControl" placeholder="dd/mm/yyyy" />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
<!--   filter by date end   -->
