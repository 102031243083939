<div class="flex items-center mb-1 gap-2">
  <input
    class="checkbox-switch m-l-10"
    type="checkbox"
    [(ngModel)]="setReminder"
    (ngModelChange)="handleSetReminder($event)"
    [disabled]="!enabled"
  />
  Remind me
  <mat-form-field subscriptSizing="dynamic">
    <mat-select
      [formControl]="reminderControl"
      disableOptionCentering
      panelClass="currency-panel"
      [placeholder]="'GENERALS.SELECT-PERIOD' | translate"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">{{
        option.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  before the document expires
</div>
<mat-error *ngIf="documentForm.errors?.invalidReminderPeriod && setReminder">
  {{
    "GENERALS.AUTOMATIC-REMINDER-ERROR"
      | translate : { amount: reminderControl.value }
  }}
  {{ reminderControl.value > 1 ? "months" : "month" }}
</mat-error>
