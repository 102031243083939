import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import {
  AccountDialogComponent,
  MyAccountPageComponent,
  ChangePasswordDialogComponent,
} from "./components";
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";

@NgModule({
  declarations: [
    AccountDialogComponent,
    ChangePasswordDialogComponent,
    MyAccountPageComponent,
  ],
  exports: [
    AccountDialogComponent,
    ChangePasswordDialogComponent,
    MyAccountPageComponent,
  ],
  providers: [],
  imports: [CommonModule, SharedModule, NgxMatInputTelComponent],
})
export class AccountModule {}
