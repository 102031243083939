<div
  #headerRef
  id="header-wrapper-portal"
  class="header-wrapper-no-bg"
  (window:scroll)="fixedHeader($event)"
>
  <div class="header-top">
    <div class="flex flex-wrap w-full">
      <!-- breadcrumbs - header left section -->
      <div class="header-breadcrumbs w-2/4 self-center">
        <app-portal-breadcrumbs></app-portal-breadcrumbs>
      </div>
      <!-- end breadcrumbs - header left section -->

      <div class="w-2/4 flex justify-end">
        <div class="header-top-action-wrapper">
          <!-- widgets -->
          <app-top-menu></app-top-menu>

          <!-- language selection -->
          <div class="header-top-lang">
            <app-translate></app-translate>
          </div>
        </div>

        <div *ngIf="user.admin_access" class="admin-notif">
          <div class="fw-500">
            {{ "GENERALS.LOGGED-AS-PROVIDER" | translate }}
          </div>
          <div class="admin-return">
            <a (click)="backToAdmin()" class="orange-bg pointer">{{
              "GENERALS.BACK-TO-ADMIN" | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
