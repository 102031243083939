import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { UserData } from "@api/account";
import { CaseData } from "@api/cases";

import { Observable, Subject, take } from "rxjs";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-document-case-form",
  templateUrl: "document-case-form.component.html",
  styleUrls: ["./document-case-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCaseFormComponent implements OnInit {
  @Input()
  data: { case: Partial<CaseData> };

  caseForm: UntypedFormGroup;

  user$: Observable<UserData>;

  @Input()
  submitForm: Subject<void> = new Subject();

  @Output()
  initialized: EventEmitter<any> = new EventEmitter();

  constructor(public usersService: UsersService) {}
  ngOnInit(): void {
    this.caseForm = new UntypedFormGroup({
      provider_name: new UntypedFormControl(""),
      client_id: new UntypedFormControl(""),
      assignment_id: new UntypedFormControl(""),
      expat_id: new UntypedFormControl(""),
      order_id: new UntypedFormControl(""),
      document_id: new UntypedFormControl(""),
      order_number: new UntypedFormControl(""),
      dependent_id: new UntypedFormControl(null),
    });

    this.caseForm.get("expat_id").valueChanges.subscribe((value) => {
      if (typeof value === "object") {
        this.caseForm.get("expat_name").setValue(value.name);
      }
    });

    this.usersService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.caseForm.patchValue({
          provider_name: user.name,
        });
      });

    if (this.data?.case) {
      this.caseForm.patchValue(this.data.case);
    }
    this.initialized.emit({
      form: this.caseForm,
    });
  }
}
