import { inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";
import { AccountSelectors } from "@api/account";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs";
import { EntityStatusEnum } from "../_enums/entity-status-enum";

export const profileGuard: CanActivateChildFn = (route, state) => {
  const router = inject(Router);
  return inject(Store)
    .select(AccountSelectors.selectAccountState)
    .pipe(
      filter((state) => state.loaded),
      map((state) => {
        const provider = state.user.entity_details;
        switch (provider.status) {
          case EntityStatusEnum.incomplete:
            return router.createUrlTree(["/create-profile"]);
          case EntityStatusEnum.categoriesMissing:
            return router.createUrlTree(["/service-portfolio"]);
          case EntityStatusEnum.termsNotAccepted:
            return router.createUrlTree(["/dashboard"]);
          default:
            return true;
        }
      })
    );
};
