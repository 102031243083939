import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ClientDetails } from "src/app/_models/_menu/client-details";
import { AccountDetails } from "src/app/_models/_menu/account-details";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
})
export class AccountComponent implements OnInit, OnDestroy {
  public menu: ClientDetails;
  public filters;
  public title;
  public routerSubscription;

  constructor(private _router: Router, private _translate: TranslateService) {
    this.menu = new AccountDetails();
  }

  ngOnInit() {
    this._setCurrentTitle();
    this.routerSubscription = this._router.events.subscribe((_event) => {
      if (_event instanceof NavigationEnd) {
        this._setCurrentTitle();
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  private _setCurrentTitle() {
    const currentPage = this.menu.links.find(
      (element) => element.link === this._router.url
    );
    this._translate.get("LEFT-NAVIGATION.SETTINGS").subscribe((res: string) => {
      this.title = currentPage?.label ?? res;
    });
  }
}
