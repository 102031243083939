import { Component, inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SettingService } from "@api/setting/services/setting.service";
import { AlertService } from "@modules/alert";
import {
  catchError,
  combineLatest,
  filter,
  of,
  startWith,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { SettingWithControl } from "../setting-input/setting-input.component";

@Component({
  selector: "app-setting-card",
  templateUrl: "./setting-card.component.html",
  styleUrls: ["./setting-card.component.scss"],
})
export class SettingCardComponent implements OnInit {
  @Input()
  modelId: string | number;

  @Input()
  modelName: string;

  settingService = inject(SettingService);
  alertService = inject(AlertService);

  formGroup = new FormGroup({});

  loaded = false;
  inputs: SettingWithControl[] = [];

  ngOnInit(): void {
    this.settingService
      .listSettings({
        model_id: this.modelId,
        model_name: this.modelName,
      })
      .subscribe((settings) => {
        this.formGroup = settings.reduce((acc, setting) => {
          const control = new FormControl(setting.value);
          acc.addControl(setting.name, control);
          const lastSuccessfulValueSubject = new Subject<string>();
          combineLatest({
            lastSuccessfulValue: lastSuccessfulValueSubject.pipe(
              startWith(setting.value)
            ),
            currentValue: control.valueChanges.pipe(startWith(setting.value)),
          })
            .pipe(
              filter(
                ({ lastSuccessfulValue, currentValue }) =>
                  lastSuccessfulValue !== currentValue
              ),
              switchMap(({ lastSuccessfulValue, currentValue }) => {
                return this.settingService
                  .updateSetting({
                    model_id: this.modelId,
                    model_name: this.modelName,
                    name: setting.name,
                    value: currentValue,
                  })
                  .pipe(
                    tap(() => lastSuccessfulValueSubject.next(currentValue)),
                    catchError((error) => {
                      control.setValue(lastSuccessfulValue, {
                        emitEvent: false,
                      });
                      return of(null);
                    })
                  );
              })
            )
            .subscribe((value) => {
              if (!value) {
                return;
              }
              this.alertService.success("Setting updated successfully");
            });

          return acc;
        }, new FormGroup({}));
        this.inputs = settings.map((setting) => ({
          ...setting,
          control: this.formGroup.get(setting.name) as FormControl,
        }));
        this.loaded = true;
      });
  }
}
