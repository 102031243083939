import { Component, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ReportsService } from "@api/reports/services/reports.service";
import { StorageService } from "@api/storage/storage.service";
import {
  ConfigMilestoneModalComponent,
  ConfigMilestoneModalComponentInput,
} from "@modules/reports/components/custom/milestones/config-modals/config-milestone-modal.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import {
  filter,
  finalize,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { sanitizeBoolean } from "src/app/lib/sanitizers/boolean.sanitizer";
import { FilterTypesEnum } from "../../../../../../_enums/filter-types-enum";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-milestones-report-list",
  templateUrl: "./milestones-report-list.component.html",
  styleUrls: ["./milestones-report-list.component.scss"],
})
export class MilestonesReportListComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy, OnChanges
{
  filters$: BehaviorSubject<any> = new BehaviorSubject({});

  isFiltersLoaded: boolean = true;

  private destroyed$: Subject<void> = new Subject();

  selectedColumns = [];
  selectedMilestones = [];
  queryParams: any;

  edit: boolean = false;
  customMilestoneId: string = "new";
  saveButtonLoading: boolean = false;
  reportName: string = "";
  constructor(
    private reports: ReportsService,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private storage: StorageService,
    private router: Router
  ) {
    super();
  }

  get milestoneFilters() {
    return [
      ...this.availableFilters,
      {
        id: "milestone_date",
        type: FilterTypesEnum.DATE_RANGE,
        label: "MILESTONES.MILESTONE_COLUMNS",
      },
    ];
  }
  loadFilters() {
    this.storage
      .getData("milestones_filters")
      .pipe(take(1))
      .subscribe((data) => {
        this.filters$.next(data ?? {});
      });
  }

  saveFilters(filters) {
    this.storage.setData("milestones_filters", filters).subscribe();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForMilestoneReports()
    );

    this.loadFilters();
    this.filters$.subscribe((filters) => {
      this.saveFilters(filters);
    });
    this.isLoading = true;
    this.route.params
      .pipe(
        filter((params) => params.id !== undefined && params?.id !== "new"),
        take(1),
        tap(({ id }) => {
          this.customMilestoneId = id;
          this.edit = true;
        }),
        switchMap(({ id }) => this.reports.customMilestoneView(id))
      )
      .subscribe((data) => {
        if (!data.success) {
          return false;
        }
        this.reportName = data.result.name;
        try {
          this.filters$.next(JSON.parse(data.result.params));
        } catch (e) {
          console.error("Error parsing filters", e);
          // do nothing
        }
      });
    this.watchQueryParams();
  }

  ngOnChanges(changes) {
    if (changes.overdue) {
      this.getItems(this.filters.page);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoading = true;
    this.isFiltersLoaded = true;

    let params = this.getParams(this.filters.page);

    if (params.page === undefined) {
      params.page = 1;
    }
    params = { ...this.filters$.getValue(), ...params };
    this.request_call = this.reports
      .milestones(params)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.selectedColumns = Object.values(data.selected.columns);
          this.selectedMilestones = Object.values(
            data.selected.milestones
          ).sort((a: any, b: any) => a.order_index - b.order_index);

          this.processResponse({ result: data });
          if (data.filters.length) {
            this.isFiltersLoaded = false;
          }
        },
        error: (error) => {
          this.items = [];
          this.isFiltersLoaded = false;
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  protected watchQueryParams() {
    combineLatest([
      this.route.params,
      this.route.queryParams,
      this.filters$,
    ]).subscribe(([params, query]) => {
      if (params.id !== "new") {
        this.edit = true;
        this.customMilestoneId = params.id;
      }
      super.watchQueryParams({
        ...params,
        ...query,
      });
    });
  }

  updateFilters(newFilters) {
    this.filters$.next(newFilters);
    this.navigateByUrl();
  }

  navigateByParams(params) {
    this.navigateByUrl();
  }

  protected getService() {
    return this.reports;
  }

  openMilestoneConfigModal() {
    const filters = this.filters$.getValue();
    this.dialog
      .open(ConfigMilestoneModalComponent, {
        data: {
          saveButton: true,
          reportName: this.reportName,
          columns: filters?.columns,
          show_end_dates: filters?.show_end_dates,
          milestone_columns: filters?.milestone_columns,
        } as ConfigMilestoneModalComponentInput,
      })
      .afterClosed()
      .pipe(filterNullish())
      .subscribe((params) => {
        this.filters = { ...this.filters, ...params };
        if (!params.columns) {
          delete this.filters.columns;
        }

        if (!params.milestone_columns) {
          delete this.filters.milestone_columns;
        }
        if (params.save) {
          if (this.filters.id !== "new") {
            this.customMilestoneId = this.filters.id;
            this.edit = true;
          } else {
            this.edit = false;
          }
          this.updateFilters(params);

          this.createOrUpdate();
        } else {
          this.updateFilters(params);
        }
      });
  }

  createOrUpdate() {
    this.saveButtonLoading = true;

    const filters = this.filters$.getValue();
    const reqData = {
      params: JSON.stringify(filters),
      name: filters.reportName,
    };

    let action = this.reports.createCustomMilestone(reqData);

    if (this.edit) {
      action = this.reports.updateCustomMilestone(
        +this.customMilestoneId,
        reqData
      );
    }

    action
      .pipe(
        take(1),
        finalize(() => {
          this.saveButtonLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.alertService.success(
            this.translate.instant(
              this.edit
                ? "GENERALS.SUCCESSFULLY-UPDATED"
                : "GENERALS.SUCCESSFULLY-SAVED"
            )
          );
          this.router.navigate(["/reports/milestone/", data.id], {
            replaceUrl: true,
          });
        },
        error: (error) => {
          this.items = [];
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  clearAllFilters() {
    const columns = this.filters.columns;
    this.clearFilters();
    if (columns !== undefined) {
      this.filters.columns = columns;
    }
    this.navigateByUrl();
  }

  get FilterType() {
    return FilterTypesEnum;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  get endDateColumn() {
    return sanitizeBoolean(this.filters.show_end_dates)
      ? "forecast_end_date"
      : "end_date";
  }

  exportList() {
    this.isLoading = true;
    this.exportItems("milestones", null, this.filters$.getValue());
  }
}
