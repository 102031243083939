import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentActionsEnum } from "@api/documents/enums/document-actions.enum";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { environment } from "@environment/environment";
import { CreateComponent } from "@modules/documents/components/create/create.component";
import { LinkComponent } from "@modules/documents/components/link/link.component";
import { ShareComponent } from "@modules/documents/components/share/share.component";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { PermissionResourceEnum } from "@modules/shared/_enums/permission-resource.enum";
import { ValueList } from "@modules/shared/models/value-list.model";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subject } from "rxjs";
import { debounceTime, finalize, switchMap, takeUntil } from "rxjs/operators";
import { AlertService } from "src/app/_services/alert.service";
import * as saveAs from "file-saver";
import { ColumnDefinition } from "../../../../_interfaces/column-definition.interface";
import { StorePageTypes } from "@modules/shared/modules/_enums/store-page-types.enum";

@Component({
  selector: "app-documents-list",
  styleUrls: ["./documents-list.component.scss"],
  templateUrl: "./documents-list.component.html",
})
export class DocumentsListComponent
  extends ItemsListComponent
  implements OnInit, OnChanges, OnDestroy
{
  isXs: boolean;
  searchFilters = [];
  items: DocumentData[];
  isFormattedParams = true;
  isFiltersLoaded: boolean = false;
  exportFilters: Partial<ValueList>;
  exportUrl: string;

  @Input() entityType: string = PermissionResourceEnum.PROVIDER;
  @Input() entityId;
  @Input() reqBody = null;

  @Input() isOnDashboard = false;
  @Input() showUploadButton = false;
  @Input() expiredStartDate = null;
  @Input() expiredEndDate = null;
  @Input() shouldShowConfirmation: boolean = false;

  hasCrm = environment.has_crm;

  deleteAction = {
    id: 3, // delete
    text: "GENERALS.DELETE",
    needs_confirmation: true,
    customText: "NOTIFICATIONS.DOC-DELETE",
  };

  archiveAction = {
    text: "GENERALS.ARCHIVE-DOCUMENT",
    needs_confirmation: true,
    customText: "NOTIFICATIONS.PROCEED-CONFIRM",
  };

  public readonly StorePageTypes = StorePageTypes;

  private unsubscribe$: Subject<void> = new Subject<void>();

  protected columns = [
    {
      name: "select",
      label: "Selection",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
    {
      name: "id",
      label: "GENERALS.ID",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "name",
      label: "DOCUMENTS.NAME",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "client",
      label: "DOCUMENTS.CLIENT-NAME",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "expat",
      label: "DOCUMENTS.EXPAT-NAME",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "type",
      label: "DOCUMENTS.TYPE",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "author",
      label: "DOCUMENTS.UPLOADED_BY",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "expiration",
      label: "DOCUMENTS.EXPIRES_AT",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "upload_date",
      label: "DOCUMENTS.UPLOADED-AT",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "document_number",
      label: "DOCUMENTS.NUMBER",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "country",
      label: "DOCUMENTS.COUNTRY",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "service_category",
      label: "LABEL.SERVICE-CATEGORY",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "status",
      label: "DOCUMENTS.STATUS",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "actions",
      label: "Settings",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
  ];

  constructor(
    private documentService: DocumentService,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private router: Router,
    public alertService: AlertService,
    private translateService: TranslateService
  ) {
    super();
    this.getStatusTransitions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.loadSavedColumnSettings(StorePageTypes.DOCUMENTS_LIST);
    this.watchQueryParams();

    if (!this.isOnDashboard) {
      this.navigateByUrl();
    }
  }

  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(debounceTime(500))
      .subscribe(([params, query]) => {
        const filters = { ...params, ...query };
        super.watchQueryParams(filters);
      });
  }

  public linkDocument(doc: DocumentData): void {
    this.dialog
      .open(LinkComponent, {
        data: {
          docIds: doc.id,
          source: doc.entity_type,
          sourceId: doc.entity_id,
        },
      })
      .afterClosed();
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoading = true;
    const params = this.getParams(
      !this.isOnDashboard ? this.filters.page : page
    );

    if (this.isOnDashboard) {
      if (this.expiredStartDate && this.expiredEndDate) {
        const orderParams = {
          order: this.route.snapshot.queryParams["order"]
            ? this.route.snapshot.queryParams["order"]
            : "expiration_date",
          order_direction: this.route.snapshot.queryParams["order_direction"]
            ? this.route.snapshot.queryParams["order_direction"]
            : "asc",
        };

        const data: Partial<ValueList<string>> = {
          ...orderParams,
          per_page: "5",
          page: String(page),
          expiration_date_range:
            this.expiredStartDate.format("YYYY/MM/DD") +
            ";" +
            this.expiredEndDate.format("YYYY/MM/DD"),
        };

        this.exportUrl = "documents/statistics";
        this.exportFilters = data;

        this.request_call = this.documentService
          .getExpiredDocs(data)
          .pipe(
            finalize(() => {
              this.isLoading = false;
              this.isFiltersLoaded = true;
            })
          )
          .subscribe({
            next: (res) => {
              this.searchFilters = res.filters;
              this.processResponse({ result: res });
            },
            error: (error) => {
              this.items = [];
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            },
          });

        return;
      }
    }

    if (!params.tag_ids?.length) {
      delete params.tag_ids;
    }

    if (this.reqBody) {
      this.entityType = this.reqBody.entity_type;
      this.entityId = this.reqBody.entity_id;
    }

    let entityType = this.entityType;
    let entityId = this.entityId;
    if (this.entityType == PermissionResourceEnum.PROVIDER) {
      entityType = "company-list";
      entityId = "";
    }

    this.exportUrl = `documents/` + entityType + "/" + entityId;
    this.exportFilters = params;

    this.request_call = this.documentService
      .list(entityType, entityId, params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.searchFilters = data.filters;
          this.processResponse({ result: data });
          this.hideElements();
        },
        error: (error) => {
          this.items = [];
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);

    this.router
      .navigate([], {
        queryParams: params,
        replaceUrl: true,
        relativeTo: this.route,
      })
      .then();
  }

  downloadDocument(docId) {
    this.documentService.downloadDocument([docId]).subscribe((data: any) => {
      if (data.result) {
        window.open(data.result);
      }
    });
  }

  ngOnChanges(): void {
    if (this.isOnDashboard) {
      this.getItems();
    }
  }

  shareDocument(document: DocumentData): void {
    const dialogRef = this.dialog.open(ShareComponent, {
      data: {
        documentIds: document.id,
        id: document.entity_id,
        type: document.entity_type,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.type === "saved") {
        this.navigateByUrl(1);
      }
      this.getItems();
    });
  }

  openDialog(): void {
    if (this.shouldShowConfirmation) {
      const confirm = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: {
            needs_confirmation: true,
            text: `upload document`,
          },
        },
      });

      confirm.afterClosed().subscribe((res) => {
        if (res) {
          this.openCreateDialog();
        }
      });

      return;
    }

    this.openCreateDialog();
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateComponent, {});
    dialogRef.componentInstance.entityType = this.entityType;
    dialogRef.componentInstance.entityId = this.entityId;
    if (this.reqBody) {
      dialogRef.componentInstance.reqBody = this.reqBody;
    }

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.type === "saved") {
        this.navigateByUrl(1);
        this.getItems();
      }
    });
  }

  private hideElements() {
    /**
     * If more params are in the future, has to be added here in order for right map
     */
    const params = {
      client_id: this.route.snapshot.params.clientId,
      expat_id: this.route.snapshot.params.expatId,
    };

    const formattedParams = Object.keys(params)
      .filter((key) => params[key])
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: params[key],
        });
      }, {});

    this.isFormattedParams = Object.keys(formattedParams).length !== 0;

    this.searchFilters = this.searchFilters.filter((item) => {
      return Object.entries(formattedParams).every(([k]) => item.id !== k);
    });
  }

  public hasPermission(document: DocumentData, actionId: number): boolean {
    const permissions = {
      [this.deleteAction.id]: () => {
        return document.can_delete;
      },
    };

    if (permissions[actionId]) {
      return permissions[actionId]();
    }

    return true;
  }

  public getStatusTransitions() {
    this.documentService.getStatusTransitions().subscribe(
      (data) => {
        if (data.success) {
          this.actions = data.result;
          Object.keys(this.actions).forEach((key) => {
            this.actions[key] = [...this.actions[key], this.deleteAction];
          });
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  getService() {
    let type = null;

    if (this.reqBody) {
      type = {
        case_type: this.reqBody.entity_type,
        case_type_id: this.reqBody.entity_id,
      };
    }
    this.documentService.setEntityData(this.entityType, this.entityId, type);
    return this.documentService;
  }

  archiveOnDashboard(status, item): void {
    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: this.archiveAction,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.documentService
            .archiveExpiredDocument(status.id, [item.id])
            .subscribe({
              next: (res) => {
                this.alertService.success(res.message);
                this.getItems();
              },
              error: (error) => {
                if (error && error.message) {
                  this.alertService.stringError(error.message);
                }
              },
            });
        }
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  public changeStatus(status, item): void {
    if (status.id === DocumentActionsEnum.ARCHIVED) {
      this.archiveOnDashboard(status, item);

      return;
    }

    if (status.id !== DocumentActionsEnum.DELETE) {
      super.changeStatus(status, item);

      return;
    }

    this.delete(item);
  }

  private delete(item): void {
    const closed$ = this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: this.deleteAction,
        },
      })
      .afterClosed();

    const result$ = closed$.pipe(
      switchMap((result) => {
        if (result) {
          return this.documentService.updateStatusBulk(this.deleteAction.id, [
            item.id,
          ]);
        }
      })
    );

    result$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.alertService.success(
        this.translateService.instant("GENERALS.SUCCESSFULLY-DELETED")
      );

      this.getItems(this.p);
    });
  }

  public export(): void {
    const params = { ...this.exportFilters };
    const clientId = this.route.snapshot.paramMap.get("clientId");

    if (clientId) {
      params.client_id = clientId;
    }

    this.isLoading = true;
    this.documentService
      .export(this.exportUrl, params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: Blob) => {
        this.isLoading = false;

        saveAs(data, "documents.xlsx");
      });
  }

  get DocumentType() {
    return DocumentActionsEnum;
  }
}
