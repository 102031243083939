import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-details-navigation",
  templateUrl: "./details-navigation.component.html",
  styleUrls: ["./details-navigation.component.scss"],
})
export class DetailsNavigationComponent {
  @Input() menu;

  constructor(
    public router: Router,
    protected activatedRoute: ActivatedRoute
  ) {}

  getActive() {
    const activeLink = this.menu.links.find((link) => {
      if (this.router.url.split("?")[0] !== link.link) {
        return false;
      }
      return true;
    });
    if (!activeLink) {
      return this.menu.links[0];
    }
    return activeLink;
  }
}
