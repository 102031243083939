<div class="white-box-wrapper m-t-24">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="medium-title fl-l">
        {{ "EXPATS.EMPLOYMENT-DETAILS" | translate }}
      </div>
      <button
        class="white-box-header-action fl-r edit-info border-white transparent-bg p-0 btn"
        (click)="handleEdit()"
      >
        <mat-icon svgIcon="edit" class="button-icon"></mat-icon>
        {{ "GENERALS.EDIT" | translate }}
      </button>
    </div>
    <div class="clear"></div>
    <div class="white-box-body expat-employment-details">
      <div class="row">
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.PAYROLL-ID" | translate }}
          </span>
          <p class="case-label">
            {{ expat.payroll_id | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.COMPANY" | translate }}
          </span>
          <p class="case-label">
            {{ expat.company_name | notAvailable }}
          </p>
        </div>
        <!-- <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.OFFICE" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.office?.label | notAvailable }}
          </p>
        </div> -->
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.DEPARTMENT" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.department_name | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.POSITION" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.company_position | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.MANAGER-NAME" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.manager_name | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.MANAGER-EMAIL" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.manager_email | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.DATE_OF_JOINING" | translate }}
          </span>
          <p class="case-label">
            {{ expat?.details?.joined_at | date : "dd/MM/yyyy" | notAvailable }}
            <ng-container *ngIf="expat?.details?.joined_at">
              ({{ expat?.details?.joined_at | timeSince }})
            </ng-container>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
