<mat-option>
  <ngx-mat-select-search
    [placeholderLabel]="'LABEL.CURRENCY' | translate"
    [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
    [formControl]="teamSearchControl"
  ></ngx-mat-select-search>
</mat-option>
<mat-option
  *ngFor="let currency of filteredCurrencies$ | async"
  [value]="currency.code"
  (onSelectionChange)="selectionChange($event, currency)"
>
  {{ currency.code }}
</mat-option>
