import { Directive, HostBinding, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appAvatar]",
})
export class AvatarDirective implements OnInit {
  @Input("appAvatar")
  public userName: string = "John Doe";

  @HostBinding("src")
  public imageSource: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.imageSource) {
      this.imageSource = `https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=${this.userName}`;
    }
  }
}
