import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DocumentsSharedModule } from "@modules/documents/documents-shared/document-shared.module";
import { CreateExpenseComponent } from "@modules/expenses/components/create/create.component";
import { FileSelectorComponent } from "@modules/expenses/components/form/file-selector/file-selector.component";
import { SharedModule } from "@modules/shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { CurrencyOptionsComponent } from "src/app/standalone/components/currency-options/currency-options.component";
import { ExpansesListComponent } from "./expanses-list/expanses-list.component";
// tslint:disable-next-line:max-line-length

@NgModule({
  declarations: [
    ExpansesListComponent,
    CreateExpenseComponent,
    FileSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedModule,
    DocumentsSharedModule,
    CurrencyOptionsComponent,
  ],
  providers: [DatePipe],
  exports: [ExpansesListComponent],
})
export class ExpensesSharedModule {}
