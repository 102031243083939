import { Component, DestroyRef, inject, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BillingDetailsService } from "@api/billing-details/services/billing-details.service";
import { BillingDetails } from "@api/clients/models/billing-details.models";
import { OfficeModel } from "@models/office";
import { AlertService } from "@modules/alert";
import { LegalEntityData } from "@modules/clients/modules/entities/models/legal-entity.data";
import { LegalEntityService } from "@modules/clients/modules/entities/services/legal-entity.service";
import { OfficeService } from "@modules/clients/services/office.service";
import { ValueList } from "@modules/shared/models/value-list.model";
import {
  BehaviorSubject,
  debounceTime,
  filter,
  finalize,
  map,
  startWith,
} from "rxjs";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-create-update-billing-details-dialog",
  templateUrl: "./create-update-billing-details-dialog.component.html",
  styleUrls: ["./create-update-billing-details-dialog.component.scss"],
})
export class CreateUpdateBillingDetailsDialogComponent implements OnInit {
  billingDetails?: BillingDetails;
  entityId?: string;
  ownerEntityId?: string;

  searchOfficeCtrl = new FormControl();
  officesList: OfficeModel[] = [];
  offices$ = new BehaviorSubject<OfficeModel[]>([]);

  searchLegalEntityCtrl = new FormControl();
  legalEntityList: LegalEntityData[] = [];
  legalEntities$ = new BehaviorSubject<LegalEntityData[]>([]);

  officefilters: ValueList = {
    exclude_assigned_offices: 1,
    selected_office_id: null,
  };

  billingDetailsForm = new FormGroup({
    name: new FormControl("", Validators.required),
    address: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    region: new FormControl("", Validators.required),
    country_code: new FormControl("", Validators.required),
    zip_code: new FormControl(""),
    tax_number: new FormControl("", Validators.required),
    bank_name: new FormControl(""),
    swift_code: new FormControl("", Validators.maxLength(12)),
    bank_account_number: new FormControl(""),
    office_id: new FormControl(null),
    legal_entity_id: new FormControl(""),
  });

  isLoading: boolean = false;

  private destroyRef = inject(DestroyRef);
  private alertService = inject(AlertService);
  private officeService = inject(OfficeService);
  private billingDetailsService = inject(BillingDetailsService);
  private legalEntityService = inject(LegalEntityService);

  constructor(
    private dialogRef: MatDialogRef<CreateUpdateBillingDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      billingDetails: BillingDetails;
      entityId?: string;
      ownerEntityId?: string;
    }
  ) {
    if (data.billingDetails) {
      this.billingDetails = { ...data.billingDetails };
      this.officefilters.selected_office_id = data.billingDetails.office_id
        ? data.billingDetails.office_id
        : null;
    }
    this.entityId = data.entityId;
    this.ownerEntityId = data.ownerEntityId;
  }

  ngOnInit(): void {
    if (this.data.billingDetails) {
      this.billingDetailsForm.patchValue(this.data.billingDetails);
    }

    this.updateValidators();

    if (this.ownerEntityId) {
      this.getLegalEntities();
      this.initLegalEntitiesCtrl();
      this.watchLegalEntityChanges();
    } else {
      this.getOffices();
    }

    this.initSerachOfficeCtrl();
  }

  watchLegalEntityChanges() {
    this.billingDetailsForm
      .get("legal_entity_id")
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((id) => !!id)
      )
      .subscribe((id) => {
        const selectedEntity = this.legalEntityList.find(
          (entity) => entity.id === id
        );

        if (selectedEntity) {
          this.billingDetailsForm.patchValue(selectedEntity);
          this.entityId = selectedEntity.id;
          this.getOffices();
        }
      });
  }

  updateValidators(): void {
    const isOwnerPresent = !!this.ownerEntityId;

    this.billingDetailsForm
      .get("legal_entity_id")
      .setValidators(isOwnerPresent ? Validators.required : null);
    this.billingDetailsForm.get("legal_entity_id").updateValueAndValidity();
  }

  getLegalEntities() {
    this.legalEntityService
      .list({ owner_id: this.ownerEntityId })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        this.legalEntityList = resp;
        this.legalEntities$.next(resp);
      });
  }

  initLegalEntitiesCtrl() {
    this.searchLegalEntityCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(""),
        debounceTime(300),
        map((searchText) =>
          this.legalEntityList.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
      .subscribe((filteredItems) => this.legalEntities$.next(filteredItems));
  }

  getOffices() {
    this.officeService
      .fetchAll(this.entityId, this.officefilters)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((offices) => {
        this.officesList = offices;
        this.offices$.next(offices);
      });
  }

  initSerachOfficeCtrl() {
    this.searchOfficeCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(""),
        debounceTime(300),
        map((searchText) =>
          this.officesList.filter((office) =>
            office.label.toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
      .subscribe((filteredOffices) => this.offices$.next(filteredOffices));
  }

  updateOrCreate(data: any) {
    if (this.billingDetails) {
      data.office_id = data.office_id ? data.office_id : null;

      return this.billingDetailsService.update(
        this.entityId,
        "legal-entity",
        this.billingDetails.id,
        data
      );
    }

    return this.billingDetailsService.create(
      this.entityId,
      "legal-entity",
      data
    );
  }

  save(): void {
    this.billingDetailsForm.markAllAsTouched();

    if (!this.billingDetailsForm.valid) {
      return;
    }

    this.isLoading = true;

    const data = this.billingDetailsForm.getRawValue();

    this.updateOrCreate(data)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (resp) => {
          if (resp.message) {
            this.alertService.success(resp.message);
          }

          this.dialogRef.close(resp);
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  countryUpdated(event: any) {
    this.billingDetailsForm.get("city").setValue("");
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
