<div class="small-box-header-img" (click)="changeAvatar()">
  <img
    [src]="
      user.primary_image_path
        ? user.primary_image_path
        : '../assets/images/profile-pics-placeholder.jpg'
    "
    (error)="onProfileImageError($event)"
    alt="img"
    class="profile-pic"
  />
  <div class="edit-trigger">{{ "PORTAL.CHANGE-PIC" | translate }}</div>
</div>
