<ng-container *ngIf="isLoading" class="d-flex">
  <app-loader-spinner-general
    [loadingType]="LoadingType.PROFILE"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>
</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="details-body-wrapper">
    <div class="details-2c" *ngIf="userInfo && provider">
      <!-- left -->
      <div class="details-2c-left">
        <div class="small-box">
          <div class="small-box-header-img" (click)="openDocModal()">
            <img
              [src]="
                provider.primary_image_path
                  ? provider.primary_image_path
                  : '../assets/images/profile-pics-placeholder.jpg'
              "
              alt="img"
              class="profile-pic"
            />
            <div class="edit-trigger">
              {{ "GENERALS.CHANGE-PIC" | translate }}
            </div>
          </div>
          <div class="small-box-details">
            <div class="medium-title">{{ provider.company_name }}</div>
            <div class="rating">
              <app-rating [rate]="provider.calculatedRating"></app-rating>
              <span class="review-nr"
                >({{
                  provider.calculatedRating ? provider.calculatedRating : "0"
                }}) {{ "GENERALS.RATING" | translate }}</span
              >
            </div>
            <div class="small-box-status">
              <div class="entity_status-label-{{ provider.status_id }}">
                {{ provider.status_name }}
              </div>
            </div>
            <div class="fw-500" *ngIf="provider.status_reason || ''">
              <span class="gray block-span"
                >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
              >
              {{ provider.status_reason }}
            </div>
            <div class="fw-500">
              <span class="gray block-span"
                >{{ "GENERALS.REGISTRED-ON" | translate }}:</span
              >
              {{ provider.created_at | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="small-box-list">
            <ul>
              <ng-container *ngFor="let option of statusOptions">
                <li>
                  <div
                    (click)="openConfirmAction(option)"
                    class="btn-small orange-bg"
                  >
                    {{ option.text }}
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <!-- end left -->

      <!-- right -->
      <div class="details-2c-right grid gap-6 grid-cols-2">
        <div class="white-box-wrapper col-span-2">
          <div class="white-box-row">
            <div class="white-box-header">
              <div class="medium-title fl-l">
                {{ "GENERALS.COMPANY-INFO" | translate }}
              </div>
              <div
                class="white-box-header-action fl-r"
                (click)="openEditModal()"
                *canView="updateProviderDetails"
              >
                {{ "GENERALS.EDIT" | translate }}
                <i class="icon-cog-outline"></i>
              </div>
            </div>
            <div class="clear"></div>

            <div class="white-box-body">
              <div class="row">
                <div class="w-50">
                  <p>
                    {{ "LABEL.COMPANY-NAME" | translate }}
                    <span
                      [class.placeholder-text]="
                        !provider.company_name || !provider.company_name.length
                      "
                      [class.fw-400]="
                        provider.company_name && provider.company_name.length
                      "
                    >
                      {{
                        provider.company_name && provider.company_name.length
                          ? provider.company_name
                          : ("LABEL.COMPANY-NAME" | translate)
                      }}</span
                    >
                  </p>

                  <p>
                    {{ "LABEL.COMPANY-LEGAL-NAME" | translate }}
                    <span
                      [class.placeholder-text]="
                        !provider.company_legal_name ||
                        !provider.company_legal_name.length
                      "
                      [class.fw-400]="
                        provider.company_legal_name &&
                        provider.company_legal_name.length
                      "
                    >
                      {{
                        provider.company_legal_name &&
                        provider.company_legal_name.length
                          ? provider.company_legal_name
                          : ("LABEL.COMPANY-LEGAL-NAME" | translate)
                      }}</span
                    >
                  </p>

                  <p>
                    {{ "LABEL.COMPANY-ADDRESS" | translate }}
                    <br />
                    <span class="inline">
                      <span
                        [class.placeholder-text]="!defaultLegalEntity?.address"
                        [class.fw-400]="defaultLegalEntity?.address"
                      >
                        {{
                          defaultLegalEntity?.address
                            ? defaultLegalEntity.address
                            : ("LABEL.COMPANY-ADDRESS" | translate)
                        }},
                      </span>

                      <span
                        [class.placeholder-text]="!defaultLegalEntity.city"
                        [class.fw-400]="defaultLegalEntity.city"
                      >
                        {{
                          defaultLegalEntity?.city
                            ? defaultLegalEntity.city
                            : ("LABEL.COMPANY-CITY" | translate)
                        }},
                      </span>

                      <span
                        [class.placeholder-text]="
                          !defaultLegalEntity?.country_code
                        "
                        [class.fw-400]="defaultLegalEntity.country_code"
                      >
                        {{
                          defaultLegalEntity.country_code
                            ? defaultLegalEntity.country_code
                            : ("LABEL.COMPANY-COUNTRY" | translate)
                        }},
                      </span>
                      <span
                        [class.placeholder-text]="!defaultLegalEntity?.zip_code"
                        [class.fw-400]="defaultLegalEntity?.zip_code"
                      >
                        {{
                          defaultLegalEntity?.zip_code
                            ? defaultLegalEntity.zip_code
                            : ("LABEL.ZIP-CODE" | translate)
                        }}
                      </span>
                    </span>
                  </p>
                  <p>
                    {{
                      "LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME" | translate
                    }}
                    <span
                      [class.placeholder-text]="
                        !provider.legal_representative_name
                      "
                      [class.fw-400]="provider.legal_representative_name"
                    >
                      {{
                        provider.legal_representative_name
                          ? provider.legal_representative_name
                          : ("LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME"
                            | translate)
                      }},</span
                    >
                  </p>
                  <p>
                    {{ "LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate }}
                    <span
                      [class.placeholder-text]="
                        !provider.legal_representative_email
                      "
                      [class.fw-400]="provider.legal_representative_email"
                    >
                      {{
                        provider.legal_representative_email
                          ? provider.legal_representative_email
                          : ("LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate)
                      }}
                    </span>
                  </p>
                  <p>
                    {{ "LABEL.NUMBER_OF_EMPLOYEES" | translate }}
                    <span
                      [class.placeholder-text]="
                        !provider.number_of_employees_name
                      "
                      [class.fw-400]="provider.number_of_employees_name"
                    >
                      {{
                        provider.number_of_employees_name
                          ? provider.number_of_employees_name
                          : ("LABEL.NUMBER_OF_EMPLOYEES" | translate)
                      }}
                    </span>
                  </p>
                </div>
                <div class="w-50">
                  <p>
                    {{ "GENERALS.TAX-NUMBER" | translate }}:
                    <span
                      [class.placeholder-text]="!defaultLegalEntity.tax_number"
                      [class.fw-400]="defaultLegalEntity.tax_number"
                    >
                      {{
                        defaultLegalEntity.tax_number
                          ? defaultLegalEntity.tax_number
                          : ("GENERALS.TAX-NUMBER" | translate)
                      }}
                    </span>
                  </p>
                  <p>
                    {{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }}:
                    <span
                      [class.placeholder-text]="!provider.bank_account_number"
                      [class.fw-400]="provider.bank_account_number"
                    >
                      {{
                        provider.bank_account_number
                          ? provider.bank_account_number
                          : ("LABEL.BANK-ACCOUNT-NUMBER" | translate)
                      }}
                    </span>
                  </p>
                  <p>
                    {{ "GENERALS.BANK" | translate }}:

                    <span
                      [class.placeholder-text]="!provider.bank_name"
                      [class.fw-400]="provider.bank_name"
                    >
                      {{
                        provider.bank_name
                          ? provider.bank_name
                          : ("GENERALS.BANK" | translate)
                      }}
                    </span>
                  </p>
                  <p>
                    {{ "LABEL.SWIFT-CODE" | translate }}:
                    <span
                      [class.placeholder-text]="!provider.swift_code"
                      [class.fw-400]="provider.swift_code"
                    >
                      {{
                        provider.swift_code
                          ? provider.swift_code
                          : ("LABEL.SWIFT-CODE" | translate)
                      }}
                    </span>
                  </p>
                  <p>
                    {{ "LABEL.PROF-ASSOCIATION" | translate }}:
                    <span
                      [class.placeholder-text]="
                        !provider.professional_association_name
                      "
                      [class.fw-400]="provider.professional_association_name"
                    >
                      {{
                        provider.professional_association_name
                          ? provider.professional_association_name
                          : ("LABEL.PROF-ASSOCIATION" | translate)
                      }}
                    </span>
                  </p>
                  <p>
                    {{ "LABEL.ONLINE-PAYMENTS" | translate }}:
                    <br />
                    <span
                      *ngIf="
                        !userInfo?.stripe_account?.id ||
                        userInfo?.stripe_account?.status === 'inactive'
                      "
                    >
                      <a
                        (click)="goToStripeOnBoarding()"
                        class="btn orange-bg tdn"
                        >{{ "LABEL.ACCEPT-ONLINE-PAYMENTS" | translate }}</a
                      >
                    </span>
                    <span
                      *ngIf="userInfo?.stripe_account?.status === 'pending'"
                      class="small-box-status"
                    >
                      <span class="entity_status_pending">
                        {{ "LABEL.STRIPE-STATUS-PENDING" | translate }}
                      </span>
                    </span>
                    <span
                      *ngIf="userInfo?.stripe_account?.status === 'active'"
                      class="small-box-status"
                    >
                      <a
                        (click)="goToStripeDashboard()"
                        class="btn stripe_dashboard_button tdn"
                        >{{ "LABEL.STRIPE-DASHBOARD" | translate }}</a
                      >
                    </span>
                    <small
                      class="error-description"
                      *ngIf="userInfo?.stripe_account?.error"
                      >{{ userInfo?.stripe_account?.error }}</small
                    >
                  </p>
                </div>
              </div>
              <div class="row">
                <div style="padding-left: 15px">
                  {{ "LABEL.COMPANY-SHORT-DESCRIPTION" | translate }}:
                  <br />
                  <span
                    *ngIf="!provider.short_description"
                    [class.placeholder-text]="!provider.short_description"
                    [class.fw-400]="provider.short_description"
                  >
                    {{ "LABEL.COMPANY-SHORT-DESCRIPTION" | translate }}
                  </span>
                  <span
                    *ngIf="provider.short_description"
                    class="desc-content-innerHtml"
                    [innerHTML]="provider.short_description"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-setting-card
          [modelId]="provider.id"
          [modelName]="'entity'"
        ></app-setting-card>
      </div>
      <!-- end right -->
      <!-- end 2 col details -->
    </div>
  </div>
</ng-container>
