<div class="big-title">
  <b>{{ "GENERALS.CUSTOM-REPORTS" | translate }}</b>
</div>
<div class="flex flex-wrap">
  <div class="w-1/4 custom-card pr-3 pb-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.CLIENTS-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-CLIENTS-FILTERS" | translate }}
      </div>
      <div class="white-box-footer">
        <a class="btn-small btn orange-bg" [routerLink]="['/clients']">
          {{ "GENERALS.ACCESS" | translate }}</a
        >
      </div>
    </div>
  </div>
  <div class="w-1/4 custom-card pr-3 pb-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.SERVICE-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-SERVICE-OFFERS-FILTERS" | translate }}
      </div>
      <div class="white-box-footer">
        <a
          class="btn-small btn orange-bg"
          [routerLink]="['/service-offers/list']"
          >{{ "GENERALS.ACCESS" | translate }}</a
        >
      </div>
    </div>
  </div>
  <div class="w-1/4 custom-card pr-3 pb-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.ORDER-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-ORDERS-FILTERS" | translate }}
      </div>
      <div class="white-box-footer">
        <a class="btn-small btn orange-bg" [routerLink]="['/orders']">{{
          "GENERALS.ACCESS" | translate
        }}</a>
      </div>
    </div>
  </div>
  <div class="w-1/4 custom-card pr-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.CASE-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-CASE-FILTERS" | translate }}
      </div>
      <div class="white-box-footer">
        <a class="btn-small btn orange-bg" [routerLink]="['/cases']">{{
          "GENERALS.ACCESS" | translate
        }}</a>
      </div>
    </div>
  </div>
  <div class="w-1/4 custom-card pr-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.USERS-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-USERS" | translate }}
      </div>
      <div class="white-box-footer">
        <a class="btn-small btn orange-bg" [routerLink]="['/company-users']">{{
          "GENERALS.ACCESS" | translate
        }}</a>
      </div>
    </div>
  </div>
  <div class="w-1/4 custom-card pr-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.MILESTONE-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-MILESTONES" | translate }}
      </div>
      <div class="white-box-footer">
        <a
          class="btn-small btn orange-bg"
          [routerLink]="['/reports', 'milestone', 'new']"
        >
          {{ "GENERALS.CONFIGURE" | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="w-1/4 custom-card pr-3">
    <div class="white-box-wrapper">
      <div class="white-box-header dark-border">
        <div class="medium-title">
          {{ "GENERALS.EXPENSE-REPORTS" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        {{ "GENERALS.CUSTOM-REPORTS-EXPENSES" | translate }}
      </div>
      <div class="white-box-footer">
        <a
          class="btn-small btn orange-bg"
          [routerLink]="['/reports/expense']"
          target="_blank"
        >
          {{ "GENERALS.CONFIGURE" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-body-top mt-4">
  <!-- left -->
  <div class="fl-l big-title">
    {{ "GENERALS.CUSTOM-MILESTONES-REPORTS" | translate }}
  </div>
  <!-- end left -->
</div>
<div class="clear"></div>
<div class="dashboard-body-action">
  <!-- left -->
  <div class="fl-l advanced-search-wrapper docs-filters w-full p-0 mt-3">
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="GENERALS.CUSTOM-MILESTONES-REPORTS"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<div class="table-wrapper">
  <div class="table-responsive">
    <table
      class="custom-report-table"
      matSort
      (matSortChange)="sortData($event)"
      [matSortActive]="active"
      [matSortDirection]="direction"
    >
      <thead>
        <tr>
          <th mat-sort-header="id" class="text-right border-right">
            {{ "GENERALS.ID" | translate }}
          </th>
          <th mat-sort-header="name" class="name">
            <span class="inline-flex items-center">
              {{ "LABEL.NAME" | translate }}
            </span>
          </th>
          <th mat-sort-header="updated_at" class="date">
            {{ "GENERALS.UPDATED-AT" | translate }}
          </th>
          <th mat-sort-header="created_at" class="date">
            {{ "GENERALS.CREATED-AT" | translate }}
          </th>
          <th class="ta-r"></th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="100">
            <app-loader-spinner-general
              [loadingType]="LoadingType.TABLE"
            ></app-loader-spinner-general>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr
          class="cursor-pointer"
          *ngFor="
            let item of items
              | paginate
                : {
                    itemsPerPage: perPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <td class="text-right border-right">
            <a
              class="font-weight-600 gray text-right"
              [routerLink]="['/reports', 'milestone', item.id]"
            >
              #{{ item.id }}
            </a>
          </td>
          <td
            class="text-center"
            [routerLink]="['/reports', 'milestone', item.id]"
          >
            {{ item.name | titlecase }}
          </td>
          <td
            class="text-right"
            [routerLink]="['/reports', 'milestone', item.id]"
          >
            {{ item.updated_at | date : "dd/MM/yyyy" }}
          </td>
          <td
            class="text-right"
            [routerLink]="['/reports', 'milestone', item.id]"
          >
            {{ item.created_at | date : "dd/MM/yyyy" }}
          </td>
          <td class="text-right">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                class="uppercase !h-auto !leading-9 !text-center"
                (click)="export(item.params)"
              >
                {{ "GENERALS.EXPORT" | translate }}
              </button>
              <button
                mat-menu-item
                class="uppercase !h-auto !leading-9 !text-center"
                (click)="remove(item.id)"
              >
                {{ "GENERALS.DELETE" | translate }}
              </button>
            </mat-menu>
          </td>
        </tr>
        <tr *ngIf="items?.length === 0">
          <td colspan="5" class="gray">
            <div class="notification_row bg_light_blue my-2.5 mx-0 !p-5">
              {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--   paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>
  <!--   end paginator -->
</div>
