import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { environment } from "@environment/environment";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AgreementsModule } from "./agreements/agreements.module";
import { AssignmentsModule } from "./assignments/assignments.module";
import { CasesModule } from "@api/cases";
import { SharedModule } from "@api/shared";
import { DocumentsModule } from "./documents/documents.module";
import { CaseTasksModule } from "./case-tasks";
import { ExpensesModule } from "./expenses/expenses.module";
import { BaseUrlInterceptor } from "./http/interceptors/base-url.interceptor";
import { DefaultHeadersInterceptor } from "./http/interceptors/default-headers.interceptor";
import { StorageService } from "./storage/storage.service";
import { BASE_API_URL } from "./http/base-api-url.token";
import { AuthenticationModule } from "@api/authentication";
import { MessagesModule } from "./messages/messages.module";
import { AccountModule } from "./account/account.module";
import { AuthInterceptor } from "./http/interceptors/auth.interceptor";
import { TemplatesModule } from "@api/templates";
import { ClientsModule } from "@api/clients/clients.module";

import {
  BackendHttpClient,
  BACKEND_API_HTTP_INTERCEPTORS,
} from "./http/backend-http-client";
import { MilestonesModule } from "@api/milestones";
import { RfqModule } from "@api/rfq";
import { ReportsModule } from "@api/reports/reports.module";
import { GetArrayInterceptor } from "@helpers/get-array.interceptor";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AssignmentsModule,
    SharedModule,
    CasesModule,
    DocumentsModule,
    CaseTasksModule,
    MessagesModule,
    ExpensesModule,
    AccountModule,
    AuthenticationModule,
    AgreementsModule,
    TemplatesModule,
    ClientsModule,
    IonicStorageModule.forRoot({
      name: "__xpath",
    }),
    MilestonesModule,
    RfqModule,
    ReportsModule,
  ],
  providers: [
    StorageService,
    { provide: BASE_API_URL, useValue: environment.gateway_endpoint },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: GetArrayInterceptor,
      multi: true,
    },
    BackendHttpClient,
  ],
})
export class ApiModule {
  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [],
    };
  }
}
