import { Component, Input, OnInit } from "@angular/core";
import moment, { Moment } from "moment";
import { finalize, take } from "rxjs";
import { CaseStatusEnum } from "src/app/_enums/case-status-enum";
import { ChartCardComponent } from "../chart-card/chart-card.component";
import { PieChartNameEnum } from "../../enums/pie-chart-names.enum";
import { ReportsCaseService } from "@modules/reports/services/reports-case.service";
import { Router } from "@angular/router";
import { CaseTagEnum } from "src/app/_enums/case-tag-enum";

@Component({
  selector: "app-case-status-report",
  templateUrl: "./case-status-report.component.html",
  styleUrls: ["./case-status-report.component.scss"],
})
export class CaseStatusReportComponent
  extends ChartCardComponent
  implements OnInit
{
  @Input() loadedFrom: string = "reports";

  readonly defaultChartData = [
    {
      name: PieChartNameEnum.DEFAULT,
      label: CaseStatusEnum.DONE,
      color: "#89b51a",
      value: 1,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: CaseStatusEnum.IN_PROGRESS.split("_").join(" "),
      color: "#ffb545",
      value: 0,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: CaseStatusEnum.PENDING,
      color: "#D0DAE4",
      value: 0,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: CaseStatusEnum.CANCELLED,
      color: "#708295",
      value: 0,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: PieChartNameEnum.OVERDUE,
      color: "#F2545B",
      value: 0,
    },
  ];

  constructor(
    private reportsCaseService: ReportsCaseService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.initEmptyPie();
  }

  onCalendarChange(dateRange: {
    start_date: Moment | string;
    end_date: Moment | string;
  }): void {
    let start_end_date_range = "";

    if (
      moment.isMoment(dateRange.start_date) &&
      moment.isMoment(dateRange.end_date)
    ) {
      start_end_date_range = `${dateRange.start_date.year()}/${
        dateRange.start_date.month() + 1
      }/${dateRange.start_date.date()};${dateRange.end_date.year()}/${
        dateRange.end_date.month() + 1
      }/${dateRange.end_date.date()}`;
    }

    this.isLoading = true;
    this.reportsCaseService
      .getCasesStatistics({ start_end_date_range }, this.reload)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.reload = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.setValues(data);
        },
        error: () => {
          this.initEmptyPie();
        },
      });
  }

  navigate(name: string): void {
    const baseParams = this.getBaseNavParams();

    const params = {
      ...baseParams,
      ...(name === CaseTagEnum.OVERDUE
        ? { tag: name }
        : { status: name.replace(/\s+/g, "_") }),
    };

    const url = this.router
      .createUrlTree(["/cases"], { queryParams: params })
      .toString();
    window.open(url, "_blank");
  }

  get PieChartNameEnum() {
    return PieChartNameEnum;
  }
}
