<ng-container *ngIf="case$ | async as caseState">
  <ng-container *ngIf="!isMatMenu">
    <ng-container *ngIf="!caseState.loaded">
      <button
        disabled
        class="btn-outline-primary w-full"
        (click)="openCaseDialog()"
      >
        <mat-spinner diameter="20"></mat-spinner>
      </button>
    </ng-container>
    <button
      class="btn-outline-primary w-full"
      *ngIf="caseState.loaded && !caseState.caseData"
      (click)="openCaseDialog()"
    >
      <span>
        {{ "GENERALS.OPEN-CASE" | translate }}
      </span>
    </button>
    <a
      *ngIf="caseState.loaded && caseState.caseData"
      [routerLink]="['/cases/' + caseState.caseData.id]"
      routerLinkActive="router-link-active"
    >
      <div
        class="status"
        [appStatusStyle]="caseState.caseData.status | lowercase"
      >
        {{ "Case" | translate }} #{{ caseState.caseData.id }}
      </div>
    </a>
  </ng-container>
  <button
    *ngIf="isMatMenu && caseState.caseData?.status === CaseStatusEnum.CANCELLED"
    mat-menu-item
    class="font-semibold"
    (click)="openCaseDialog()"
  >
    {{ "GENERALS.OPEN-CASE" | translate | uppercase }}
  </button>
</ng-container>
