<app-dialog-header>
  {{ (isEdit ? "GENERALS.EDIT-USER-INFO" : "GENERALS.ADD-USER") | translate }}
</app-dialog-header>

<div class="dialog-body">
  <form class="field-grid w-full" [formGroup]="form">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "LABEL.FIRST-NAME" | translate }}</mat-label>
      <input matInput formControlName="first_name" />
      <mat-error formControlError="first_name"> </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "LABEL.LAST-NAME" | translate }}</mat-label>
      <input matInput formControlName="last_name" />
      <mat-error formControlError="last_name"> </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "LABEL.EMAIL" | translate }}</mat-label>
      <input matInput formControlName="email" />
      <mat-error formControlError="email"> </mat-error>
    </mat-form-field>
    <mat-form-field class="phone-field w-full">
      <mat-label>
        {{ "LABEL.PHONE-NUMBER" | translate }}
      </mat-label>
      <ngx-mat-input-tel
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone_number"
        name="phone_number"
        describedBy="phoneInput"
        [format]="'national'"
        [required]="true"
        #phone
        maxLength="20"
        (countryChanged)="onCountryChanged($event)"
      ></ngx-mat-input-tel>
      <mat-error formControlError="phone_number"></mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }}</mat-label>
      <input
        matInput
        name="company_position"
        formControlName="company_position"
      />
      <mat-error formControlError="company_position"> </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>{{ "LABEL.ROLE" | translate }}</mat-label>
      <mat-select #singleSelect formControlName="role_id">
        <mat-option *ngFor="let item of roles$ | async" [value]="item.id">{{
          item.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div class="dialog-footer">
  <button (click)="submit()" class="btn orange-bg modal-button">
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
