import { Component } from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { AlertService } from "@modules/alert";
import { AuthenticationService } from "src/app/_services/authentication.service";

@Component({
  selector: "app-recover-password-form",
  templateUrl: "./recover-password.component.html",
})
export class RecoverPasswordComponent {
  userEmail;
  isLoading;
  constructor(
    private service: AuthenticationService,
    private alertService: AlertService
  ) {}

  sendEmail() {
    this.service.sendResetPasswordEmail(this.userEmail).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.message) {
          this.alertService.success(res.message);
        }
      },
      (error) => {
        this.isLoading = false;
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
