import { Component, Inject, OnInit } from "@angular/core";
import { Country } from "@models/country";
import { DictionariesService } from "@services/dictionaries.service";
import { AuthorizationService } from "@api/users/services/authorization.service";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanyUserData } from "@api/entities/models/company-user.model";
import { UsersService } from "src/app/_services/users.service";
import { AlertService } from "src/app/_services/alert.service";
import { finalize, take } from "rxjs";

export interface EditUserDetailsComponentInput {
  user?: CompanyUserData;
}

@Component({
  templateUrl: "./edit-user-details.component.html",
  styleUrls: ["./edit-user-details.component.scss"],
})
export class EditUserDetailsComponent implements OnInit {
  user: Partial<CompanyUserData>;
  edit: boolean;
  isLoading: boolean;

  providerId: number;

  disabled = false;
  phoneNumber: number;
  prefix: any;
  countries: Country[];
  roles: {
    id: number;
    name: string;
  }[];

  isRoleDisabled = false;

  constructor(
    private dictionariesService: DictionariesService,
    private alertService: AlertService,
    private authService: AuthorizationService,
    public dialogRef: MatDialogRef<EditUserDetailsComponent>,
    private service: UsersService,
    @Inject(MAT_DIALOG_DATA)
    private data: EditUserDetailsComponentInput
  ) {}

  ngOnInit() {
    this.user = this.data?.user ?? {};
    this.edit = !!this.data?.user;
    this.service
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.providerId = user.entity_id;
      });
    this.getCountryList();
    this.getUserRoles();

    this.authService
      .cannot(
        PermissionActionEnum.UPDATE,
        PermissionResourceEnum.COMPANY_USER,
        PermissionSecondaryResourceEnum.DETAILS
      )
      .subscribe((cannot) => {
        this.isRoleDisabled = cannot;
      });
  }

  getUserRoles() {
    this.dictionariesService.getUserRoles("provider").subscribe((res) => {
      this.roles = res.result;
    });
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (!data?.result) {
        return;
      }
      this.countries = data.result;
      this.prefix = data.result.map((p) => ({
        value: p.phone_code,
        label:
          this.dictionariesService.getPhonePrefix(p.phone_code) + " " + p.name,
      }));
    });
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }

  private saveAction(data) {
    if (this.edit) {
      return this.service.updateUserAccount(data);
    }
    return this.service.createUser(this.providerId, data);
  }

  submit() {
    //todo
    this.isLoading = true;
    this.saveAction(this.user)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          this.dialogRef.close(res.result);
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }
}
