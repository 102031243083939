import { Component, OnInit, Input } from "@angular/core";

import { ItemsListComponent } from "../../../modules/shared/_components/items-list/items-list.component";
import { DocumentItem } from "@models/document";
import { DocumentsService } from "src/app/_services/documents.service";
import { AlertService } from "@modules/alert";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { finalize } from "rxjs";
import { AddDocComponent } from "../add-doc/add-doc.component";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { ConfirmInvoicePaymentComponent } from "../confirm-invoice-payment/confirm-invoice-payment.component";

@Component({
  selector: "app-documents",
  styleUrls: ["./documents.component.scss"],
  templateUrl: "./documents.component.html",
})
export class DocumentsComponent extends ItemsListComponent implements OnInit {
  public items: Array<DocumentItem>;
  public downloadItems: Array<DocumentItem>;
  isXs: boolean;
  public paymentStatusNew = "new";
  public availableFilters = [];
  isFiltersLoaded: boolean = false;

  @Input() entityType;
  @Input() entityId;
  @Input() hideTitle;
  @Input() docType;

  constructor(
    protected service: DocumentsService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  onSearch(text) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search_text: text,
      },
      replaceUrl: true,
      queryParamsHandling: "merge",
    });
  }

  ngOnInit() {
    if (this.docType == "invoices") {
      this.setAvailableFilters();
    }
    this.getItems();

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  public setFilters(e) {
    this.defaultFilters = {
      ...this.defaultFilters,
      ...e,
    };
    return this.cleanFilters(this.defaultFilters);
  }

  public cleanFilters(obj) {
    for (const propName in obj) {
      if (obj[propName] === null) {
        delete obj[propName];
      }
    }
    return obj;
  }

  public setAvailableFilters() {
    this.service.getFilterOrderInvoicesStatuses().subscribe((data) => {
      const status_filter = {
        id: "status",
        label: "FILTERS.INVOICE-STATUS",
        data: data.result,
      };
      this.availableFilters.push(status_filter);
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = this.getParams(page);
    delete params["download_url"];
    params = this.prepareInvoiceFilterParams(params);

    this.isLoading = true;
    this.request_call = this.service
      .getDocuments(this.entityType, this.entityId, this.docType, params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.downloadItems = new Array<DocumentItem>();
          this.getItemsWithDownloadUrl();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  getItemsWithDownloadUrl(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = this.getParams(page);
    params["download_url"] = 1;
    params = this.prepareInvoiceFilterParams(params);

    this.isLoading = true;
    this.request_call = this.service
      .getDocuments(this.entityType, this.entityId, this.docType, params)
      .subscribe(
        (data) => {
          this.downloadItems = data.result.items;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public openInBrowser(doc: DocumentItem) {
    window.open(doc.file, "_blank");
  }

  uploadFile(event) {
    const files = event.target.files;
    this.service
      .uploadFiles(this.entityId, this.docType, files, this.entityType)
      .subscribe(
        (res) => {
          this.getItems();
          if (res.message) {
            this.alertService.success(res.message);
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public bulkDelete() {
    console.log("bulk delete called");
  }

  openDocModal() {
    this.dialog
      .open(AddDocComponent, {
        data: {
          entityType: this.entityType,
          entityId: this.entityId,
          docType: this.docType,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.getItems();
      });
  }

  deleteDocument(id) {
    this.service.deleteDocument(id).subscribe((data) => {
      this.getItems();
    });
  }

  prepareInvoiceFilterParams(params) {
    if (params["status"] == "null" || params["status"] == null) {
      delete params["status"];
    }

    if (this.docType == "invoices") {
      params["search_fields"] = "invoice_number";
    }

    return params;
  }

  openConfirmPaymentModal(fileId) {
    this.dialog
      .open(ConfirmInvoicePaymentComponent, {
        data: {
          fileId: fileId,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        this.getItems();
      });
  }

  openDeleteInvoiceModal(fileId) {
    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: {
            needs_confirmation: true,
            needs_reason: true,
            text: `delete invoice`,
          },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteDocument(fileId);
        }
      });
  }

  canDeleteItem(item) {
    if (
      this.docType == this.service.docTypeInvoice &&
      item.order_invoice.id &&
      item.order_invoice.invoice_status.toLowerCase() ==
        this.paymentStatusNew.toLowerCase()
    ) {
      return true;
    }
    return false;
  }

  canConfirmPaymentAsProvider(item) {
    if (
      this.docType == this.service.docTypeInvoice &&
      item.order_invoice.id &&
      item.order_invoice.invoice_status.toLowerCase() ==
        this.paymentStatusNew.toLowerCase()
    ) {
      return true;
    }
    return false;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
