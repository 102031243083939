import { Injectable } from "@angular/core";
import { OfficeModel } from "@models/office";
import { Observable } from "rxjs";
import { ValueList } from "../../shared/models/value-list.model";
import { map } from "rxjs/operators";
import { OfficeMapperService } from "./office-mapper.service";
import { BackendHttpClient } from "@api/http";

const url = "entities";

@Injectable({
  providedIn: "root",
})
export class OfficeService {
  constructor(
    private http: BackendHttpClient,
    private officeMapper: OfficeMapperService
  ) {}

  fetchAll(
    legalEntityId: string,
    filters: ValueList
  ): Observable<OfficeModel[]> {
    return this.http
      .get(url + `/${legalEntityId}/offices`, {
        params: filters,
      })
      .pipe(map((data: any) => this.officeMapper.mapMany(data.result)));
  }

  getOfficeById(
    legalEntityId: string,
    officeId: number,
    filters: ValueList
  ): Observable<OfficeModel> {
    return this.http
      .get(url + `/${legalEntityId}/offices/${officeId}`, { params: filters })
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }

  createOffice(legalEntityId: string, office: OfficeModel) {
    const prepared = this.officeMapper.prepare(office);
    return this.http
      .post(url + `/${legalEntityId}/offices`, prepared)
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }

  updateOffice(legalEntityId: string, office: OfficeModel) {
    const prepared = this.officeMapper.prepare(office);
    return this.http
      .put(url + `/${legalEntityId}/offices/${office.id}`, prepared)
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }

  deleteOffice(legalEntityId: string, office: OfficeModel) {
    return this.http
      .delete(url + `/${legalEntityId}/offices/${office.id}`)
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }
}
