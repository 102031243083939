<div class="details-2c">
  <div class="w-100">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.PROFILE"
    ></app-loader-spinner-general>
  </div>
  <div class="details-2c-left" *ngIf="expat">
    <div class="small-box">
      <app-user-profile-image
        [user]="expat.user"
        (change)="onChange($event)"
      ></app-user-profile-image>

      <div class="small-box-details d-flex justify-content-between flex-column">
        <div class="medium-title m-0" title="{{ expat.name }}">
          {{ expat.name }}
        </div>

        <div title="{{ expat.email }}" class="gray d-none">
          {{ expat.email }}
        </div>

        <div
          title="{{ expat.email }}, {{ expat.country_code }}"
          class="gray d-none"
        >
          {{ expat.city ? expat.city + ", " : "" }}{{ expat.country_code }}
        </div>
        <div class="status-title">
          {{ "GENERALS.STATUS-TITLE" | translate }}
        </div>
        <div
          class="m-0 status text-center entity_status-label-{{
            expat.user.status
          }}"
        >
          {{ expat.user.status | uppercase }}
        </div>
      </div>
    </div>
  </div>
</div>
