import { Component, OnInit, OnDestroy } from "@angular/core";
import { WindowResizeHelperService } from "./_services/window-resize-helper.service";
import { Router, NavigationEnd, Scroll } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { GoogleAnalyticsService } from "./_services/google-analytics.service";
import { SvgRegistryService } from "./_services/svg-registry.service";
import { QueryParamsService } from "./_services/query-params.service";
import { ViewportScroller } from "@angular/common";
import { Event } from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  innerWidth;
  xs;

  constructor(
    private windowResizeHelper: WindowResizeHelperService,
    private router: Router,
    private titleService: Title,
    private googleAnalyticsService: GoogleAnalyticsService,
    private svgRegistryService: SvgRegistryService,
    private viewportScroller: ViewportScroller,
    private queryParams: QueryParamsService
  ) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }

    this.windowResizeHelper.sendData(this.xs);
    this.svgRegistryService.init();
    this.handleScrollOnNavigation();
  }

  ngOnInit() {
    this.googleAnalyticsService.subscribe();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route) => {
        let primaryRoute = route["url"].split("/")[1];
        primaryRoute = primaryRoute.split("?")[0];
        primaryRoute =
          "Provider " +
          primaryRoute.charAt(0).toUpperCase() +
          primaryRoute.slice(1) +
          " | xpath.global";

        this.titleService.setTitle(primaryRoute);
      });

    this.windowResizeHelper.sendData(this.xs);
  }

  ngOnDestroy() {
    // unsubscribe to the gtag
    this.googleAnalyticsService.unsubscribe();
  }
  onResize() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }

    this.windowResizeHelper.sendData(this.xs);
  }

  private handleScrollOnNavigation(): void {
    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise()
      )
      .subscribe((e: Scroll[]) => {
        const previous = e[0];
        const current = e[1];
        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // Check if routes match, or if it is only a query param change
          if (
            this.getBaseRoute(previous.routerEvent.url) !==
            this.getBaseRoute(current.routerEvent.url)
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split("?")[0];
  }
}
