import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { ServiceCategory } from "../../../../_models/servicecategory";

@Component({
  selector: "app-add-categories",
  templateUrl: "./add-categories.component.html",
})
export class AddCategoriesComponent implements OnChanges {
  selection = new SelectionModel(true, []);
  readonlySelection = new SelectionModel(true, []);
  categories: ServiceCategory[] = [];
  isOpenFirstLvl = false;
  treeState = "isColapsed";
  stopParentChange;
  @Input() level: number;
  @Input() providerId: number;
  @Input() set serviceCategories(value) {
    if (value) {
      this.categories = [...value];
    }
  }
  @Input() parentIsSelected: boolean | string = false;

  @Input() set selectedServiceCategories(data) {
    if (data) {
      const value = { ...data };
      const keys = Object.keys(value);
      keys.forEach((key) => {
        const idx = this.categories.findIndex((f) => f.id === value[key].id);
        if (idx > -1) {
          this.readonlySelection.select(this.categories[idx]);
        }
      });
      this.previouslySelectedCategory = data;
    }
  }
  @Output() onselect: EventEmitter<any> = new EventEmitter();
  @Output() intermediary: EventEmitter<any> = new EventEmitter();

  idLoadingGeneral: boolean;
  previouslySelectedCategory: ServiceCategory[];

  constructor(public renderer: Renderer2) {}

  parseSelectedCategory(path) {
    if (path && this.previouslySelectedCategory) {
      const path_segments = path.split("/");

      return this.previouslySelectedCategory[
        path_segments[path_segments.length - 1]
      ]
        ? this.previouslySelectedCategory[
            path_segments[path_segments.length - 1]
          ].children
        : null;
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.parentIsSelected &&
      changes.parentIsSelected.currentValue === "no_parent_change"
    ) {
      return;
    }
    if (changes.parentIsSelected && changes.parentIsSelected.previousValue) {
      if (changes.parentIsSelected.currentValue) {
        this.categories.forEach((row) => {
          this.selection.select(row);
        });
      } else {
        this.categories.forEach((row) => {
          const idx = this.selection.selected.find((f) => f.id === row.id);
          if (idx) {
            this.handleEvent(row);
          }
        });
        this.selection.clear();
      }
    } else if (
      changes.parentIsSelected &&
      changes.parentIsSelected.currentValue &&
      !changes.parentIsSelected.firstChange
    ) {
      this.categories.forEach((row) => {
        const idx = this.selection.selected.find((f) => f.id === row.id);
        if (!idx) {
          this.handleEvent(row);
        }
        this.selection.select(row);
      });
    }
  }

  isParentSelected(category): boolean | string {
    if (this.stopParentChange === category.id) {
      // when manually selecting/deselecting all children from category
      // the children already know about parent change
      // so do not notify children
      return "no_parent_change";
    } else {
      // when manually selecting/deselecting the parent category
      // notify the children about parent change
      return this.selection.isSelected(category);
    }
  }

  changeSelection(item) {
    this.stopParentChange = null;
    this.selection.toggle(item);
    this.handleEvent(item);

    // triggered when manually selecting/deselecting a child from category
    this.handleIntermediaryEvent(item);
  }

  handleEvent(item, update = 0) {
    if (!item.children_count) {
      this.onselect.emit({
        selection: item,
        id: item.parent_id,
        update: update,
      });
    }
  }

  handleIntermediaryEvent(item) {
    // triggered when manually selecting/deselecting a child from category
    if (item.level !== 0) {
      this.intermediary.emit(this.selection.selected);
    }
  }

  intermediaryChanges(event, category) {
    if (event && event.length && event.length === category.children_count) {
      // if all children are manually selected
      // check the parent
      this.stopParentChange = category.id;
      this.selection.select(category);
    } else if (!event || !event.length) {
      // if all children are manually deselected
      // uncheck the parent
      this.stopParentChange = category.id;
      this.selection.deselect(category);
    }
  }

  trackById(index, item) {
    return item.id;
  }

  isFirstLvlClicked(e, lvl) {
    if (lvl === 0) {
      const actionElem = e.target.offsetParent;
      const treeRowElem = e.target.offsetParent.nextElementSibling;
      const checkboxElem = treeRowElem.getElementsByClassName("tree-check")[0];

      setTimeout(() => {
        if (actionElem.classList.contains("sub-is-open")) {
          this.isOpenFirstLvl = true;
          this.renderer.setStyle(checkboxElem, "display", "block");
        } else {
          this.isOpenFirstLvl = false;
          this.renderer.setStyle(checkboxElem, "display", "none");
        }
      }, 0);
    }
  }
}
