import { Component } from "@angular/core";
import { ChartData } from "@modules/reports/reports-shared/models/chart-data.model";
import moment, { Moment } from "moment/moment";
import { ChartServerData } from "@modules/reports/reports-shared/models/chart-server-data.model";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { PieChartNameEnum } from "../../enums/pie-chart-names.enum";
@Component({
  selector: "app-items-list",
  template: "",
  standalone: true,
})
export class ChartCardComponent {
  isLoading: boolean = true;
  totalCases: number = 0;

  pieChartCustomColors: ChartData<string>[] = [];
  chartItems: ChartData<number>[] = [];

  dateRange: {
    start_date: string | moment.Moment;
    end_date: string | moment.Moment;
  } = {
    start_date: moment().subtract(1, "y"),
    end_date: moment(),
  };

  protected reload = false;

  protected initEmptyPie(): void {
    this.pieChartCustomColors = [
      {
        name: PieChartNameEnum.DEFAULT,
        value: "#D0DAE4",
      },
    ];

    this.totalCases = 0;
    this.chartItems = [];
  }

  protected setValues(chartData: ChartServerData[]): void {
    if (chartData.findIndex((item) => item.value > 0) === -1) {
      this.initEmptyPie();
      return;
    }

    this.pieChartCustomColors = chartData.map((item) => {
      item.name = item.name.split("_").join(" ") as PieChartNameEnum;

      return {
        ...item,
        value: item.color,
      };
    });

    this.chartItems = chartData;

    this.totalCases = chartData.reduce(
      (prev, current) => prev + current.value,
      0
    );
  }

  clearDate(): void {
    this.dateRange = {
      start_date: "",
      end_date: "",
    };

    this.onCalendarChange(this.dateRange);
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  onCalendarChange(dateRange: {
    start_date: Moment | string;
    end_date: Moment | string;
  }) {
    throw new Error("This method is abstract");
  }

  protected getBaseNavParams(): {
    start_date_range: string;
    end_date_range: string;
  } {
    return {
      start_date_range: this.formatDate(this.dateRange.start_date),
      end_date_range: this.formatDate(this.dateRange.end_date),
    };
  }

  protected formatDate(date: string | Moment): string {
    return moment.isMoment(date) ? date.format("YYYY-MM-DD") : date;
  }
}
