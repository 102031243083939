import { Directive, HostListener, inject, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Clipboard } from "@angular/cdk/clipboard";
@Directive({
  selector: "[appCopyToClipboard]",
})
export class CopyToClipboardDirective {
  public readonly clipboard = inject(Clipboard);
  public readonly snack = inject(MatSnackBar);

  @Input("appCopyToClipboard")
  public text: string;

  @HostListener("click", ["$event"])
  copyToClipboard(event: MouseEvent) {
    if (!this.text) {
      return;
    }
    this.clipboard.copy(this.text);
    this.snack.open("Copied to clipboard", null, {
      duration: 1000,
    });
  }
}
