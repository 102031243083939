<div class="custom-modal-wrapper add-docs p-0">
  <div>
    <div class="modal-title-custom p-20">
      {{ "LABEL.CREATE_DOCS" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>

  <form [formGroup]="documentForm" *ngIf="!isLoading">
    <div class="modal-body-custom p-20">
      <div class="row">
        <div class="w-50 file-wrapper">
          <file-upload
            class="w-100"
            formControlName="files"
            [control]="fileUploadControl"
            [multiple]="false"
          >
            <ng-template #placeholder>
              <span><i class="icon-upload-cloud"></i></span>
              <span class="m-l-5"
                >{{ "DOCUMENTS.DROP-FILE-TO-UPLOAD" | translate }}
              </span>
              <span class="orange"> {{ "DOCUMENTS.BROWSE" | translate }}</span>
              <div class="label">
                {{ "DOCUMENTS.ACCEPTED-FILES" | translate }}
              </div>
            </ng-template>
          </file-upload>

          <div class="error-wrapper">
            <app-form-errors [control]="documentForm.get('files')">
            </app-form-errors>
          </div>
          <div
            *ngIf="documentForm.get('expiration_date').value"
            class="extract-wrapper w-100 p-0"
          >
            <button
              class="btn gray-bg fl-l"
              (click)="extractData()"
              [disabled]="isExtracted || !this.file"
            >
              {{ "GENERALS.EXTRACT-DATA" | translate }}
            </button>
          </div>
          <div class="clear"></div>
        </div>

        <div class="w-50 p-0">
          <div class="w-100 p-0">
            <app-name-selector [formGroup]="documentForm"></app-name-selector>
          </div>

          <div class="w-100 p-0">
            <app-type-selector
              [formGroup]="documentForm"
              [entityType]="entityType"
            ></app-type-selector>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="w-50 p-0">
          <app-number-selector [formGroup]="documentForm"></app-number-selector>
        </div>
        <div class="w-50 expiration-date-select">
          <app-date-range-calendar
            [placeholder]="'GENERALS.EXPIRES-ON' | translate"
            (change)="onDateChange($event)"
            [singleDate]="documentForm.get('expiration_date').value"
          >
            <ng-container slot="label">{{
              "GENERALS.EXPIRES-ON" | translate
            }}</ng-container>
          </app-date-range-calendar>
        </div>
      </div>
      <!-- <div class="w-100"> -->
      <div class="row">
        <div class="w-50 p-0">
          <app-tag-selector [formGroup]="documentForm"></app-tag-selector>
        </div>
        <div class="w-50 document-status-toggle">
          <mat-button-toggle-group
            #group="matButtonToggleGroup"
            value="{{ docStatusControl.getRawValue() }}"
            (change)="changeDocStatus(group.value)"
          >
            <mat-button-toggle
              value="{{ DocumentType.ACTIVATE }}"
              class="btn white-bg-border p-1"
            >
              {{ "DOCUMENTS.ACTIVE" | translate }}
            </mat-button-toggle>
            <mat-button-toggle
              value="{{ DocumentType.NOT_TRACKED }}"
              class="btn white-bg-border p-1"
            >
              {{ "DOCUMENTS.NOT-TRACKED" | translate }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row">
        <div class="form-group w-100 p-0 mb-4">
          <app-set-reminder [formGroup]="documentForm"></app-set-reminder>
        </div>
      </div>
      <div class="row country-category-row">
        <div class="form-group w-50 p-0">
          <app-country-select> </app-country-select>
        </div>
        <div class="form-group w-50 p-0">
          <app-service-category-selector></app-service-category-selector>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </form>
  <div class="clear"></div>
  <div class="modal-footer-custom bottom-buttons p-20">
    <app-loader-spinner-general
      *ngIf="isLoading; else buttons"
      [repeat]="2"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
    <ng-template #buttons>
      <button
        class="btn gray-bg fl-l modal-button"
        [disabled]="isLoading"
        (click)="close()"
      >
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button
        class="btn orange-bg fl-r modal-button"
        [disabled]="isLoading"
        (click)="submit()"
      >
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-template>

    <div class="clear"></div>
  </div>
</div>
