import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import isEmpty from "lodash/isEmpty";
import { BehaviorSubject, Subject } from "rxjs";

import { Overlay } from "@angular/cdk/overlay";
import { FormControl } from "@angular/forms";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  FilterData,
  FilterItemData,
} from "@modules/shared/models/filter-item.model";
import { FilterService } from "@modules/shared/services/filter.service";
import { FilterTypesEnum } from "../../../../../../../../_enums/filter-types-enum";

interface FilterState {
  opened: boolean;
  loading: boolean;
  filter_search_text: string;
  has_more_date: boolean;
  current_page: number;
  total_pages: number;
}

export interface FilterComponent {
  getFilterValue(): any;
  clearFilterValue(): void;
}

@Component({
  selector: "app-report-filters",
  templateUrl: "./report-filters.component.html",
  styleUrls: ["./report-filters.component.scss"],
  providers: [
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      deps: [Overlay],
      useFactory: (overlay: Overlay) => () =>
        overlay.scrollStrategies.close({
          threshold: 30,
        }),
    },
  ],
})
export class ReportFiltersComponent implements OnInit, OnDestroy {
  @Input() activeFilters: any;
  @Input() availableFilters?: Array<any>;
  @Input() milestoneFilter: boolean = false;
  @Input() milestoneId: string;
  @Input() key: string;
  @Input() type: string;

  @Output() reportFilters: EventEmitter<any> = new EventEmitter();

  @ViewChild("filterView", { static: false }) filterView: FilterComponent;

  public isOpen = false;
  public isLoaded = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public startDateControl = new FormControl();
  public endDateControl = new FormControl();

  public trackByFn = (index, item) => item.id;

  selected = {
    columns: [],
    orderAsc: false,
    orderDesc: false,
  };

  protected filterService = inject(FilterService);

  dataChange = new BehaviorSubject<FilterItemData[]>([]);

  filters: Partial<any> = {};

  filterData: FilterItemData[];

  label: string;

  loadMore$ = new Subject<number>();

  get filter(): FilterData {
    return this.availableFilters?.find((element) => element.id == this.key);
  }

  get data(): FilterItemData[] {
    return this.dataChange.value;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.isLoaded = true;
    this.filters = { ...this.filters, ...this.activeFilters };
    if (!this.type) {
      this.type = this.filter.field_type;
    }
  }

  public clearFilters() {
    delete this.filters[this.key];
    if (this.filterView) {
      this.filterView.clearFilterValue();
    }
    this.reportFilters.emit(this.filters);
  }

  applyFilter() {
    this.filters[this.key] = this.filterView.getFilterValue();
    this.reportFilters.emit(this.filters);
    this.isOpen = false;
  }

  sortAsc() {
    if (this.selected.orderAsc) {
      delete this.filters.order;
      delete this.filters.order_direction;

      this.reportFilters.emit(this.filters);
    } else {
      const filter = {
        order: this.key,
        order_direction: "asc",
      };
      this.reportFilters.emit({ ...this.filters, ...filter });
    }
  }

  sortDesc() {
    if (this.selected.orderDesc) {
      delete this.filters.order;
      delete this.filters.order_direction;

      this.reportFilters.emit(this.filters);
    } else {
      const filter = {
        order: this.key,
        order_direction: "desc",
      };
      this.reportFilters.emit({ ...this.filters, ...filter });
    }
  }

  hasActiveFilter() {
    if (this.milestoneId) {
      return (
        this.filters[this.key] &&
        this.filters[this.key].split("_")[0] == this.milestoneId
      );
    }
    return this.filters[this.key] && !isEmpty(this.filters[this.key]);
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
  get FilterType() {
    return FilterTypesEnum;
  }

  get isAsyncFilter() {
    //redo this part and check
    return this.type === FilterTypesEnum.MULTIPLE_SELECT;
  }
}
