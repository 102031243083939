<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->
  <div class="float-left big-title">
    {{ "LEFT-NAVIGATION.EXPENSES" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="button-bar">
    <button
      *ngIf="report"
      class="btn-outline-primary"
      (click)="exportItems('expenses'); isLoading = true"
    >
      <mat-icon svgIcon="export" class="btn-icon"></mat-icon>
      {{ "GENERALS.EXPORT" | translate }}
    </button>
    <button
      *ngIf="displayAddButton && !report"
      class="!btn-primary"
      (click)="openDialog()"
    >
      <mat-icon svgIcon="upload" class="btn-icon"></mat-icon>
      {{ "EXPENSES.ADD_EXPENSE" | translate }}
    </button>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="float-left advanced-search-wrapper w-full p-0">
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="LEFT-NAVIGATION.EXPENSES"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div
  *ngIf="!report"
  class="bg-white px-2.5 !py-4 flex items-center text-primary font-semibold"
>
  <mat-icon svgIcon="cash" class="text-icon"></mat-icon>
  {{ "EXPENSES.TOTAL_EXPENSES" | translate }}:
  <ng-container *ngIf="totalCosts?.length; else noCosts">
    <ng-container *ngFor="let cost of totalCosts; let isLast = last">
      {{ cost.total | currency : cost.currency_code }}
      <ng-container *ngIf="!isLast"> | </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noCosts>
    <b class="ml-2">0.00</b>
  </ng-template>
</div>

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <table
      mat-table
      matSort
      (matSortChange)="sortData($event)"
      [matSortActive]="active"
      [matSortDirection]="direction"
      [dataSource]="
        items
          | paginate
            : {
                itemsPerPage: itemsPage,
                currentPage: p,
                totalItems: total
              }
      "
    >
      <ng-container matColumnDef="select" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef class="border-r-white w-1">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [disableRipple]="true"
          >
          </mat-checkbox>
        </th>
        <td class="border-r-white" mat-cell *matCellDef="let item">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (keyup)="$event.stopPropagation()"
            (change)="$event ? changeSelection(item) : null"
            [checked]="selection.isSelected(item)"
            [disableRipple]="true"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id" [sticky]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="id"
          class="text-right border-r border-r-gray-light w-1"
        >
          {{ "GENERALS.ID" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          class="text-right border-r border-r-gray-light text-secondary font-semibold"
        >
          #{{ item.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="client_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="client_name">
          {{ "EXPENSES.CLIENT-NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.client_name | translate | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expat_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="expat_name">
          {{ "EXPENSES.EXPAT-NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.expat_name | translate | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="service_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="service_name">
          {{ "EXPENSES.SERVICE-NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.service_name | translate | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="task_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="task_name">
          {{ "EXPENSES.TASK-NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.task_name | translate | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="invoice_id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="invoice_id"
          class="text-right"
        >
          {{ "EXPENSES.INVOICE-ID" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let item"
          class="text-right text-secondary font-semibold"
        >
          {{ item.invoice_id ? "#" : ""
          }}{{ item.invoice_id | translate | notAvailable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="invoice_date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="invoice_date"
          class="text-right"
        >
          {{ "EXPENSES.INVOICE-DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let item" class="text-right">
          {{ item.invoice_date | date : "dd/MM/yyyy" | notApplicable }}
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="title">
          {{ "EXPENSES.TITLE" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="amount"
          class="text-right"
        >
          {{ "EXPENSES.AMOUNT" | translate }}
        </th>
        <td mat-cell *matCellDef="let item" class="text-right">
          {{ item.amount | currency : item.currency_code }}
        </td>
      </ng-container>
      <ng-container matColumnDef="converted_amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="converted_amount"
          class="text-right"
        >
          {{ "EXPENSES.CONVERTED_AMOUNT" | translate }}
        </th>
        <td mat-cell *matCellDef="let item" class="text-right">
          {{
            item.converted_amount
              | currency : item.converted_amount_currency_code
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="incurred_at">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="incurred_at"
          class="text-right"
        >
          {{ "EXPENSES.EXPENSE_DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let item" class="text-right">
          {{ item.incurred_at | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th
          mat-header-cell
          mat-sort-header="cost_category_name"
          *matHeaderCellDef
        >
          {{ "EXPENSES.COST_CATEGORY" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.cost_category_name | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="covered_by_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="covered_by_id">
          {{ "EXPENSES.COVERED_BY" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.covered_by_name | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
          {{ "GENERALS.STATUS" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          <div class="status" [appStatusStyle]="item.status | lowercase">
            {{ item.status | uppercase }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" [stickyEnd]="true">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-selection
            [columns]="columns"
            [page]="StorePageTypes.EXPANSES_LIST"
          ></app-column-selection>
        </th>
        <td mat-cell class="text-right" *matCellDef="let item">
          <button
            mat-icon-button
            [matMenuTriggerFor]="expenseMenu"
            class="btn-outline-none-primary items-end"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #expenseMenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              class="uppercase"
              (click)="downloadExpense(item.id)"
            >
              {{ "GENERALS.DOWNLOAD-DOCUMENT" | translate }}
            </button>
            <button mat-menu-item class="uppercase" (click)="editExpense(item)">
              {{ "GENERALS.EDIT" | translate }}
            </button>
            <button
              *ngIf="item.status === ExpenseStatus.PENDING"
              mat-menu-item
              class="uppercase"
              (click)="openInputInvoiceAction(item)"
            >
              {{ "STATUSES.invoice" | translate }}
            </button>
            <button
              mat-menu-item
              class="uppercase"
              (click)="deleteExpense(item.id)"
            >
              {{ "GENERALS.DELETE" | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="no-data">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="getColumns().length"
        >
          <div
            class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
          >
            {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="loading">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="getColumns().length"
        >
          <app-loader-spinner-general
            [loadingType]="LoadingType.TABLE"
          ></app-loader-spinner-general>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getColumns(); sticky: true"></tr>
      <tr
        mat-row
        [hidden]="isLoading"
        *matRowDef="let item; columns: getColumns()"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['loading']"
        [hidden]="!isLoading"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['no-data']"
        [hidden]="items?.length || isLoading"
      ></tr>
    </table>
  </div>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
