import { CommonModule, LowerCasePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { AlertModule } from "@modules/alert";
import { AuthorizationModule } from "@modules/authorization/authorization.module";
import { CasesSharedModule } from "@modules/cases/cases-shared/cases-shared.module";
import { ContactsModule } from "@modules/contacts/contacts.module";
import { ExpatDetailsComponent } from "@modules/expats/expats-shared/components/expat-details/expat-details.component";
import { ExpatEmploymentDetailsCardComponent } from "@modules/expats/expats-shared/components/expat-employment-details-card/expat-employment-details-card.component";
import { ExpatNationalitiesCardComponent } from "@modules/expats/expats-shared/components/expat-nationalities-card/expat-nationalities-card.component";
import { ExpatPersonalInformationCardComponent } from "@modules/expats/expats-shared/components/expat-personal-information-card/expat-personal-information-card.component";
import { ExpatsListComponent } from "@modules/expats/expats-shared/components/expats-list/expats-list.component";
import { CountryOptionsComponent } from "@modules/shared/_components/country-options/country-options.component";
import { FiltersComponent } from "@modules/shared/_components/items-list/filters/filters.component";
import { StatusStyleDirective } from "@modules/shared/_directives/status-style.directive";
import { CountryPipe } from "@modules/shared/_pipes/country.pipe";
import { NotAvailablePipe } from "@modules/shared/_pipes/not-available.pipe";
import { DialogModule } from "@modules/shared/dialog/dialog.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPaginationModule } from "ngx-pagination";
import { FormErrorDirective } from "src/app/_directives/form-error.directive";
import { AddExpatDialogComponent } from "./components/add-expat-dialog/add-expat-dialog.component";
import { DeleteExpatDialogComponent } from "./components/delete-expat-dialog/delete-expat-dialog.component";
import { DependentOptionsComponent } from "./components/dependent-options/dependent-options.component";
import { UserDetailsActionsPanelComponent } from "./components/details-action-panel/details-action-panel.component";
import { ExpatButtonBarComponent } from "./components/expat-details/expat-button-bar/expat-button-bar.component";
import { TimeSincePipe } from "@modules/shared/_pipes/time-since.pipe";
import { MomentModule } from "ngx-moment";

@NgModule({
  declarations: [
    AddExpatDialogComponent,
    ExpatDetailsComponent,
    ExpatEmploymentDetailsCardComponent,
    ExpatNationalitiesCardComponent,
    ExpatPersonalInformationCardComponent,
    ExpatsListComponent,
    DeleteExpatDialogComponent,
    ExpatButtonBarComponent,
    DependentOptionsComponent,
    UserDetailsActionsPanelComponent,
  ],
  exports: [
    AddExpatDialogComponent,
    ExpatDetailsComponent,
    ExpatEmploymentDetailsCardComponent,
    ExpatNationalitiesCardComponent,
    ExpatPersonalInformationCardComponent,
    ExpatsListComponent,
    DependentOptionsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ContactsModule,
    RouterModule,
    DialogModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    CountryOptionsComponent,
    NotAvailablePipe,
    CountryPipe,
    AlertModule,
    CasesSharedModule,
    AuthorizationModule,
    FiltersComponent,
    NgxPaginationModule,
    StatusStyleDirective,
    FormErrorDirective,
    //material
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgxMatInputTelComponent,
    NgxMatSelectSearchModule,
    MomentModule,
    TimeSincePipe,
  ],
  providers: [LowerCasePipe],
})
export class ExpatsSharedModule {}
