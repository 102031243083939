<button
  mat-icon-button
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="$event.stopImmediatePropagation(); isOpen = true"
  class="icon-button sm"
  [color]="hasActiveFilter() ? 'primary' : 'basic'"
>
  <mat-icon>filter_alt</mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="isOpen = false"
  (detach)="isOpen = false"
>
  <div class="box filter-menu max-h-96 flex flex-col">
    <div class="menu grow">
      <!--   clear filters start   -->
      <button mat-menu-item (click)="clearFilters()">
        <mat-icon svgIcon="filter" class="text-icon"></mat-icon>
        <span>{{ "LABEL.CLEAR-FILTERS" | translate }}</span>
      </button>

      <app-report-date-filter
        [milestoneId]="milestoneId"
        [filter]="filter"
        [currentFilters]="filters"
        *ngIf="type === FilterType.DATE_RANGE"
        #filterView
      ></app-report-date-filter>

      <app-report-multiselect-filter
        [filter]="filter"
        [currentFilters]="filters"
        *ngIf="type === FilterType.MULTIPLE_SELECT"
        #filterView
      ></app-report-multiselect-filter>
    </div>
    <button class="btn-primary w-full" (click)="applyFilter()">
      {{ "LABEL.APPLY" | translate }}
    </button>
  </div>
</ng-template>
