<div class="dialog">
  <app-dialog-header>
    {{ (isEdit ? "EXPENSES.EDIT" : "EXPENSES.CREATE") | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <form [formGroup]="expenseForm">
      <div class="field-grid">
        <mat-form-field class="w-full">
          <mat-label>{{ "EXPENSES.TITLE" | translate }}</mat-label>
          <input matInput formControlName="title" />
          <mat-error formControlError="title"> </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full expenses-selector">
          <mat-label> {{ "EXPENSES.COST_CATEGORY" | translate }}</mat-label>
          <mat-select
            formControlName="cost_category_id"
            [placeholder]="'EXPENSES.SELECT_COST_CATEGORY' | translate"
            panelClass="expenses-selector"
          >
            <mat-option
              *ngFor="let cost of costCategories$ | async"
              [value]="cost.id"
            >
              {{ cost.text }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="cost_category_id"> </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ "EXPENSES.AMOUNT" | translate }}</mat-label>
          <input matInput type="number" formControlName="amount" />
          <mat-error formControlError="amount"></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>
            {{ "EXPENSES.CURRENCY" | translate }}
          </mat-label>
          <mat-select formControlName="currency_code">
            <app-currency-options></app-currency-options>
          </mat-select>
          <mat-error formControlError="currency_code"></mat-error>
        </mat-form-field>

        <mat-form-field
          class="w-full expenses-selector"
          [formGroup]="expenseForm"
        >
          <mat-label> {{ "EXPENSES.COVERED_BY" | translate }}</mat-label>
          <mat-select
            formControlName="covered_by"
            [placeholder]="'EXPENSES.SELECT_COVERED_BY' | translate"
            #singleSelect
            panelClass="expenses-selector"
          >
            <mat-option
              *ngFor="let supplier of coveredBy$ | async"
              [value]="supplier.id"
            >
              {{ supplier.text }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="covered_by"></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ "EXPENSES.DATE" | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
            formControlName="incurred_at"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error formControlError="incurred_at"></mat-error>
        </mat-form-field>

        <div class="col-span-2 file-wrapper" *ngIf="shouldUploadFile">
          <file-upload
            class="w-full p-0"
            formControlName="file"
            multiple="false"
            [control]="fileUploadControl"
          >
          </file-upload>
          <div class="error-wrapper">
            <app-form-errors [control]="expenseForm.get('file')">
            </app-form-errors>
          </div>
          <div class="clear"></div>
          <p class="text-secondary">{{ "GENERALS.DOC-TYPE" | translate }}.</p>
        </div>

        <!-- remove-file-button -->
        <div
          *ngIf="!shouldUploadFile"
          class="col-span-2 flex items-center gap-2"
        >
          <img
            class="size-4"
            [src]="
              'assets/images/doc-type-' +
              data.expense?.file?.file_type?.toLowerCase() +
              '.svg'
            "
            alt=""
          />
          <span>{{ data?.expense.file?.name }}</span>
          <button (click)="removeFile()" class="orange">Remove file</button>
        </div>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button
      [disabled]="expenseForm.disabled"
      class="btn-primary"
      (click)="submit()"
    >
      <mat-icon class="btn-icon" svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
