<div class="wrapper">
  <ng-container
    *ngIf="inputType === 'select' && items$ | async as items; else loading"
  >
    <mat-form-field class="w-full mb-3">
      <mat-select [value]="currentItem?.id">
        <mat-select-trigger>
          <ng-container *ngIf="currentItem">
            <ng-container
              *ngTemplateOutlet="itemRow; context: { $implicit: currentItem }"
            >
            </ng-container>
          </ng-container>
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.NAME' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="searchControl"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let item of items"
          [value]="item.id"
          (click)="selectionChanged(item)"
        >
          <ng-container
            *ngTemplateOutlet="itemRow; context: { $implicit: item }"
          ></ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-template #itemRow let-item>
      <div class="flex items-center">
        <img
          class="item-avatar"
          [src]="item.primary_image_path ?? ''"
          [appAvatar]="item?.name"
        />
        <span>{{ item.name }}</span>
      </div>
    </ng-template>

    <div class="actions flex gap-1">
      <div (click)="saveItem()" class="action cursor-pointer p-1 text-success">
        <mat-icon svgIcon="save-outline" class="!w-5 !h-5"></mat-icon>
      </div>
      <div
        (click)="cancel.emit()"
        class="action cursor-pointer p-1 text-danger"
      >
        <mat-icon svgIcon="cancel" class="!w-5 !h-5"></mat-icon>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="w-full h-11">
      <app-loader-spinner-general
        [loadingType]="LoadingType.FULL_BUTTON"
      ></app-loader-spinner-general>
    </div>
  </ng-template>
</div>
