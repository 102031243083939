<app-dialog-header>
  {{ "GENERALS.ACCEPT-TERMS" | translate }}
</app-dialog-header>
<div class="dialog-body">
  <div class="modal-form">
    <div class="modal-subtitle">
      {{ "GENERALS.ACCEPT-TERMS-SUBTITLE" | translate }}:
    </div>
    <div class="row pd-l-20" *ngIf="displayCategories.length">
      <ul>
        <li class="pd-all fw-600" *ngFor="let category of displayCategories">
          <div class="fs-15">
            {{ category.label }}
            <span class="pd-l-20 fs-15">
              <span
                class="orange m-0"
                *ngIf="category.commission_type_id.toString() !== '22'"
                >{{ category.commission | currency : "EUR" }}</span
              >
              <span
                class="orange m-0"
                *ngIf="category.commission_type_id.toString() === '22'"
                >{{ category.commission }} %</span
              >
              {{ "GENERALS.COMMISSION" | translate }}</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-subtitle">
    {{ "GENERALS.ACCEPT-TERMS-FOOTER" | translate }}
  </div>
</div>
<div class="dialog-footer">
  <button type="button" class="btn gray-bg modal-button">
    <mat-icon svgIcon="cancel"></mat-icon>
    {{ "GENERALS.CANCEL" | translate }}
  </button>

  <button type="button" class="btn orange-bg" (click)="confirm()">
    {{ "GENERALS.ADD" | translate }}
  </button>
</div>
