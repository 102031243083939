import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaseTaskListingFilters, CaseTasksService } from "@api/case-tasks";
import { sortTasksWithChildren } from "@api/case-tasks/operators";
import { CaseData, EligibleUserData } from "@api/cases";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Observable, Subject, finalize, takeUntil } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";

@Component({
  templateUrl: "./task-create.component.html",
  styleUrls: ["./task-create.component.scss"],
})
export class TaskCreateComponent extends ItemsListComponent implements OnInit {
  public taskForm: UntypedFormGroup;
  nameControl = new FormControl("", Validators.required);
  durationControl = new FormControl("", [
    Validators.required,
    Validators.min(1),
  ]);
  startAfterControl = new FormControl(null);
  assigneeControl = new FormControl("", Validators.required);

  users$: Observable<EligibleUserData[]>;
  filters: Partial<CaseTaskListingFilters> = {};
  startAfterOptions$: Observable<any>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: {
        text: string;
      };
      case: CaseData & { shouldShowPreview: boolean };
      parentId?: number;
      case_service_id: number;
    },
    private dialogRef: MatDialogRef<TaskCreateComponent>,
    private caseTasksService: CaseTasksService,
    private fb: FormBuilder,
    private caseTaskService: CaseTasksService
  ) {
    super();
  }

  ngOnInit() {
    this.setupForm();
    this.getTasksList();

    this.users$ = this.startAfterControl.valueChanges.pipe(
      tap(() => {
        this.assigneeControl.reset();
        this.assigneeControl.disable({ emitEvent: false });
      }),
      takeUntil(this.unsubscribe$),
      switchMap((data) =>
        this.caseTaskService.getEligibleUsers(this.data.case.id, data)
      ),
      map((users) => [...users]),
      tap(() => {
        this.assigneeControl.enable({ emitEvent: false });
      }),
      shareReplay(1)
    );
  }

  submit() {
    this.taskForm.markAllAsTouched();

    if (!this.taskForm.valid) {
      return;
    }
    const formValue = this.taskForm.getRawValue();
    const data = {
      ...formValue,
      expat_case_id: this.data.case.id,
      parent_id: this.data.parentId,
      case_service_id: this.data.case_service_id,
    };

    this.isLoading = true;

    this.caseTasksService.create(data).subscribe((data) => {
      this.dialogRef.close({
        type: "saved",
        task: data.task,
      });
    });
  }

  private setupForm(): void {
    this.taskForm = this.fb.group({
      name: this.nameControl,
      duration: this.durationControl,
      dependent_task_id: this.startAfterControl,
      assignee_id: this.assigneeControl,
      role_type: null,
    });

    this.taskForm.get("assignee_id").disable();
  }

  public handleStartAfter(event) {
    this.taskForm.get("dependent_task_id").setValue(event);
    this.taskForm.get("assignee_id").enable();
  }

  getTasksList() {
    let disable: boolean;
    let nextTask: boolean;

    this.filters.case_service_id = this.data.case_service_id.toString();
    this.filters.expat_case_id = this.data.case.id.toString();
    this.filters.per_page = "100";
    this.filters.include = "";

    this.startAfterOptions$ = this.caseTasksService
      .fetchTasks(this.filters)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false)),
        map((res) => res.items),
        sortTasksWithChildren(),
        map((tasks) =>
          tasks.map((item) => ({
            id: item.id,
            name: item.name,
            subtask: !!item.parent_id,
            disable:
              item.is_parent ||
              (this.data.parentId < item.id &&
                item.parent_id !== this.data.parentId),
          }))
        ),
        shareReplay(1)
      );
  }

  public close(): void {
    this.dialogRef.close({ action: "cancel" });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
