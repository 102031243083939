/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, Optional } from "@angular/core";
import { StorageKey } from "@api/storage/storage.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, tap } from "rxjs/operators";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import * as AuthActions from "../actions/auth.actions";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@environment/environment";
import { defer, of } from "rxjs";

@Injectable()
export class AuthEffects {
  authorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authorized),
        tap((action) =>
          this.storage.set(
            StorageKey.AUTH_TOKEN,
            action.token,
            3600,
            "/",
            environment.domain,
            true
          )
        )
      ),
    { dispatch: false }
  );

  deauthorize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.unauthenticated, AuthActions.logout),
        tap(() => {
          this.storage.delete(
            StorageKey.AUTH_TOKEN,
            "/",
            environment.domain,
            true
          );
        })
      ),
    { dispatch: false }
  );

  init$ = createEffect(() =>
    defer(() => {
      return of(
        (() => {
          if (this.req) {
            return this.req.cookies[StorageKey.AUTH_TOKEN];
          }
          return this.storage.get(StorageKey.AUTH_TOKEN);
        })()
      ).pipe(
        map((data) => {
          if (!data || typeof data !== "string") {
            throw Error("no token");
          }
          return AuthActions.authorized({ token: data });
        }),
        catchError(() => {
          return of(AuthActions.loaded());
        })
      );
    })
  );
  constructor(
    private actions$: Actions,
    private storage: CookieService,
    @Optional()
    @Inject(REQUEST)
    private req: Request<any>
  ) {}
}
