import { Component, Input } from "@angular/core";

@Component({
  selector: "app-details-campaign",
  templateUrl: "./details-campaign.component.html",
  styleUrls: ["./details-campaign.component.scss"],
})
export class DetailsCampaignComponent {
  @Input() item;

  constructor() {}

  close() {
    // small modal replaced
  }
}
