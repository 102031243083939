import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "@environment/environment";
import {
  WorkflowCreateModel,
  WorkflowModel,
  WorkflowStatusUpdateModel,
  WorkflowsViewModel,
  WorkflowViewModel,
} from "@models/workflow";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export interface WorkflowUpdateResponse {
  workflow: WorkflowModel;
  workflows: WorkflowModel[];
}

@Injectable({
  providedIn: "root",
})
export class WorkflowService {
  private serviceOffersUrl = "offers/offers";

  constructor(private http: HttpClient) {}

  public getWorkflows(id: number, params = {}): Observable<WorkflowsViewModel> {
    return this.http.get<WorkflowsViewModel>(
      environment.gateway_endpoint + this.serviceOffersUrl + `/${id}/workflows`,
      { params: params }
    );
  }

  public getWorkflowById(
    id: number,
    workflowId: number,
    params = {}
  ): Observable<WorkflowViewModel> {
    return this.http.get<WorkflowViewModel>(
      environment.gateway_endpoint +
        this.serviceOffersUrl +
        `/${id}/workflows/${workflowId}`,
      { params: params }
    );
  }

  public deleteWorkflow(
    offerId: number,
    workflowId: number,
    params = {}
  ): Observable<WorkflowModel[]> {
    return this.http
      .delete<{ result: WorkflowModel[] }>(
        environment.gateway_endpoint +
          this.serviceOffersUrl +
          `/${offerId}/workflows/${workflowId}`,
        { params: params }
      )
      .pipe(map(({ result }) => result));
  }

  public createWorkflow(
    offerId: number,
    workflow: WorkflowCreateModel
  ): Observable<WorkflowUpdateResponse> {
    return this.http
      .post<{ result: WorkflowUpdateResponse }>(
        environment.gateway_endpoint +
          this.serviceOffersUrl +
          `/${offerId}/workflows`,
        workflow,
        httpOptions
      )
      .pipe(map(({ result }) => result));
  }

  public updateWorkflow(
    offerId: number,
    workflow: WorkflowCreateModel
  ): Observable<WorkflowUpdateResponse> {
    return this.http
      .put<{ result: WorkflowUpdateResponse }>(
        environment.gateway_endpoint +
          this.serviceOffersUrl +
          `/${offerId}/workflows/${workflow.id}`,
        workflow,
        httpOptions
      )
      .pipe(map(({ result }) => result));
  }

  public updateWorkflowStatus(
    offerId: number,
    workflowStatus: WorkflowStatusUpdateModel
  ): Observable<WorkflowStatusUpdateModel> {
    return this.http.post<WorkflowStatusUpdateModel>(
      environment.gateway_endpoint +
        this.serviceOffersUrl +
        `/${offerId}/workflow-status`,
      workflowStatus,
      httpOptions
    );
  }

  exportWorkflow(offerId): Observable<Blob> {
    return this.http.get<Blob>(
      `${environment.gateway_endpoint}${this.serviceOffersUrl}/${offerId}/workflows/export`,
      { responseType: "blob" as any }
    );
  }
}
