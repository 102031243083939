import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "@modules/alert";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";

import { MatDialog } from "@angular/material/dialog";
import { AccountSelectors, UserData } from "@api/account";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import * as saveAs from "file-saver";
import { combineLatest, finalize } from "rxjs";
import { Order } from "@models/order";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { OrdersService } from "src/app/_services/orders.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { OrderHelper } from "@helpers/_models/order.helper";
import { CreateOrderComponent } from "@modules/quotes/components/create-order/create-order.component";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent extends ItemsListComponent implements OnInit {
  isFiltersLoaded: boolean = false;
  public user: UserData;
  active = "";

  public items: Order[];

  @Input() hideTitle = false;
  isXs: boolean;
  @Input() entity_id: number;
  @Input() client;

  constructor(
    private service: OrdersService,
    private dictionariesService: DictionariesService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService,
    protected helper: OrderHelper
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(AccountSelectors.selectUser).subscribe((user) => {
      this.user = user;
    });

    if (this.entity_id) {
      this.watchQueryParams();
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForClient(this.client, "orders")
      );
    } else {
      this.watchQueryParams();
      this.breadcrumbService.changeBreadcrumbs(
        this.breadcrumbService.setForList("/orders", "orders")
      );
    }

    this.setAvailableFilters();
    this.getStatusTransitions();

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, query]) => {
        const filters = { ...params, ...query };

        super.watchQueryParams(filters);
      }
    );
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
        replaceUrl: true,
      })
      .then();
  }

  public getItems() {
    this.isLoading = true;
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    let params = {};
    params = { ...this.filters };
    params["include"] = "filters";
    if (this.entity_id) {
      params["client_id"] = this.entity_id;
    }

    this.request_call = this.service
      .getOrdersList(params, this.user.entity_id)
      .pipe(
        finalize(() => {
          this.isFiltersLoaded = true;
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoading = false;
        }
      );
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe(
      (data) => {
        if (data.success) {
          this.actions = data.result;
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  public setAvailableFilters() {
    this.availableFilters = [
      // {
      //   id: 'search_fields',
      //   label: 'FILTERS.SEARCH_BY',
      //   service: this.dictionariesService,
      //   method: 'getOrderSearchFields'
      // },
      {
        id: "status_id",
        label: "FILTERS.STATUS",
        service: this.dictionariesService,
        method: "getOrderStatuses",
      },
    ];
  }

  openConfirmAction(action, order?) {
    let ids = [];
    if (order) {
      ids = [order.id];
    } else {
      this.selection.selected.forEach((row) => ids.push(row.id));
    }

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.service.updateStatusBulk(action.id, ids, result).subscribe(
            (data) => {
              this.isLoading = false;
              this.watchQueryParams();
              if (data && data.message) {
                this.alertService.success(data.message);
              }
            },
            (error) => {
              this.isLoading = false;
              if (error.error.errors) {
                this.alertService.errors(error.error.errors);
              }
            }
          );
        }
      });
    } else {
      this.service.updateStatusBulk(action.id, ids, null).subscribe((data) => {
        this.isLoading = false;
        this.watchQueryParams();
        if (data && data.message) {
          this.alertService.success(data.message);
        }
      });
    }
  }

  /**
   * Export orders to CSV
   */
  exportOrders() {
    this.isLoading = true;

    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params = {};
    params = { ...this.filters };
    this.request_call = this.service
      .exportOrders(params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data: Blob) => {
          const blob = new Blob([data], { type: "application/vnd.ms-excel" });
          saveAs(blob, "orders.xlsx");
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  placeOrder() {
    this.dialog
      .open(CreateOrderComponent, {
        data: {
          quotes: [],
          client_id: null,
          client_uuid: null,
          provider_id: this.user.entity_id,
          openedFrom: "orders",
        },
      })
      .afterClosed()
      .pipe(filterNullish())
      .subscribe((result) => {
        if (result.order) {
          this.router.navigate(["/orders", result.order.identity_number]);
        }
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
