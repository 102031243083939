<div class="billing-dialog dialog p-0">
  <app-dialog-header>
    {{
      (billingDetails
        ? "GENERALS.EDIT-BILLING-ADDRESS"
        : "GENERALS.ADD-BILLING-ADDRESS"
      ) | translate
    }}
  </app-dialog-header>

  <div class="dialog-body">
    <form [formGroup]="billingDetailsForm">
      <div class="field-grid">
        <mat-form-field
          *ngIf="ownerEntityId"
          appearance="outline"
          class="w-full"
        >
          <mat-label
            >{{ "LEGAL_ENTITIES.LEGAL_ENTITY" | translate }}
          </mat-label>
          <mat-select
            formPrefix="client"
            #singleSelect
            formControlName="legal_entity_id"
            [placeholder]="'LEGAL_ENTITIES.LEGAL_ENTITY' | translate"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LEGAL_ENTITIES.LEGAL_ENTITY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="searchLegalEntityCtrl"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let item of legalEntities$ | async"
              [value]="item.id"
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="legal_entity_id"></mat-error>
        </mat-form-field>

        <!-- name -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.COMPANY-NAME" | translate }} </mat-label>
          <input matInput formControlName="name" name="name" />
          <mat-error formControlError="name"></mat-error>
        </mat-form-field>

        <!-- tax_number -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.TAX-NUMBER" | translate }} </mat-label>
          <input matInput formControlName="tax_number" name="tax_number" />
          <mat-error formControlError="tax_number"></mat-error>
        </mat-form-field>
        <!-- country_code -->
        <app-country-selector
          #countrySelector
          formControlName="country_code"
          [onlyCode]="true"
          (valueUpdated)="countryUpdated($event)"
        >
          <ng-container slot="label">
            {{ "LABEL.COUNTRY" | translate }}
          </ng-container>
        </app-country-selector>

        <!-- region -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.REGION" | translate }} </mat-label>
          <input matInput formControlName="region" name="region" />
          <mat-error formControlError="region"></mat-error>
        </mat-form-field>
        <!-- city -->

        <app-city-selector
          formControlName="city"
          [countrySelectorComponent]="countrySelector"
        >
          <ng-container slot="label">
            {{ "LABEL.CITY" | translate }}
          </ng-container>
        </app-city-selector>
        <!-- address -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.ADDRESS" | translate }} </mat-label>
          <input matInput formControlName="address" name="address" />
          <mat-error formControlError="address"></mat-error>
        </mat-form-field>
        <!-- zip_code -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.ZIP-CODE" | translate }} </mat-label>
          <input matInput formControlName="zip_code" name="zip_code" />
          <mat-error formControlError="zip_code"></mat-error>
        </mat-form-field>
        <!-- bank_name -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.BANK-NAME" | translate }} </mat-label>
          <input matInput formControlName="bank_name" name="bank_name" />
          <mat-error formControlError="bank_name"></mat-error>
        </mat-form-field>
        <!-- swift_code -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.SWIFT-CODE" | translate }} </mat-label>
          <input matInput formControlName="swift_code" name="swift_code" />
          <mat-error formControlError="swift_code"></mat-error>
        </mat-form-field>
        <!-- bank_account_number -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }} </mat-label>
          <input
            matInput
            formControlName="bank_account_number"
            name="bank_account_number"
          />
          <mat-error formControlError="bank_account_number"></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "EXPATS.OFFICE" | translate }} </mat-label>
          <mat-select
            formPrefix="client"
            #singleSelect
            formControlName="office_id"
            [placeholder]="'EXPATS.OFFICE' | translate"
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'EXPATS.OFFICE' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="searchOfficeCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngIf="billingDetailsForm.get('office_id')?.value"
              [value]="null"
            >
              {{ "GENERALS.CLEAR-SELECTION" | translate }}
            </mat-option>
            <mat-option
              *ngFor="let office of offices$ | async"
              [value]="office.id"
            >
              {{ office.label }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="office_id"></mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button class="btn-primary" (click)="save()" [disabled]="isLoading">
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
