<form [formGroup]="form" *ngIf="expat && form">
  <div class="details-2c m-t-15 edit-employment-info-form p-0">
    <div class="white-box-wrapper custom-modal-wrapper p-0">
      <div class="white-box-row">
        <div class="modal-title-custom fl-l p-20">
          {{ "EXPATS.EMPLOYMENT-DETAILS" | translate }}
        </div>

        <div class="clear"></div>
        <div class="white-box-body p-20">
          <div class="row">
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.PAYROLL-ID" | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="payroll_id"
                  name="payroll_id"
                />
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.COMPANY" | translate }}</mat-label>
                <input
                  matInput
                  readonly
                  formControlName="company_name"
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.DEPARTMENT" | translate }}</mat-label>
                <input matInput formControlName="department_name" type="text" />
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.POSITION" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="company_position"
                  type="text"
                />
              </mat-form-field>
            </div>

            <div class="w-50">
              <mat-form-field appearance="outline" class="fl-r">
                <mat-label>{{ "EXPATS.MANAGER-NAME" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="manager_name"
                  type="text"
                  name="manager_name"
                />
              </mat-form-field>
              <div class="clear"></div>
              <mat-error *ngIf="form.errors?.isDependent">
                {{ "EXPATS.MANAGER-EMAIl-NAME-REQUIRED" | translate }}
              </mat-error>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline" class="fl-r">
                <mat-label> {{ "EXPATS.MANAGER-EMAIL" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="manager_email"
                  type="text"
                  name="manager_email"
                />
              </mat-form-field>
              <div class="clear"></div>
              <mat-error *ngIf="form.errors?.isDependent">
                {{ "EXPATS.MANAGER-EMAIl-NAME-REQUIRED" | translate }}
              </mat-error>
              <mat-error *ngIf="form.controls.manager_email.errors?.email">
                {{ "GENERALS.EMAIL-INVALID" | translate }}
              </mat-error>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "EXPATS.DATE_OF_JOINING" | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="joined_at"
                  name="joined_at"
                  [matDatepicker]="joiningDatePicker"
                  placeholder="DD/MM/YYYY"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="joiningDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #joiningDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="w-50" *ngIf="offices$ | async as offices">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.OFFICE" | translate }}</mat-label>
                <mat-select formControlName="office_id">
                  <mat-option
                    [value]="office.id"
                    *ngFor="let office of offices"
                  >
                    {{ office.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end p-20">
          <button class="btn gray-bg fl-l modal-button" (click)="closeModal()">
            <mat-icon svgIcon="cancel"></mat-icon>
            {{ "GENERALS.CANCEL" | translate }}
          </button>
          <button
            class="btn orange-bg fl-r m-l-20 save-employment-info modal-button"
            (click)="save()"
          >
            <mat-icon svgIcon="save"></mat-icon>
            {{ "GENERALS.SAVE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
