<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
</ng-container>

<div class="auth-form-wrapper auth-form-wrapper-profile" *ngIf="!isLoading">
  <div class="auth-form-title-wrapper">
    <div class="auth-form-title">
      {{ "AUTH.COMPANY-PROFILE" | translate }}
    </div>
  </div>

  <div *ngIf="provider" class="registration-steps">
    <div class="registration-step-item">
      {{ "GENERALS.STEP-2" | translate }}
    </div>
  </div>

  <div *ngIf="provider">
    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.COMPANY-INFORMATION" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-COUNTRY" | translate }} *</label>

        <ng-select
          class="customiz-select"
          [items]="countries"
          bindLabel="name"
          bindValue="country_code"
          placeholder="{{ 'FORMS.SELECT_COUNTRY' | translate }}"
          [(ngModel)]="provider.default_legal_entity.country_code"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [searchFn]="customSearchFn"
          (change)="countryChanged($event)"
        >
        </ng-select>
      </div>
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY_TYPE" | translate }} *</label>
        <ng-select
          class="customiz-select"
          [items]="companyTypes"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'FORMS.SELECT_COMPANY_TYPE' | translate }}"
          [(ngModel)]="provider.company_type_id"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-TAX-NUMBER" | translate }} *</label>
        <input
          type="text"
          name="tax_number"
          class="form-control"
          [(ngModel)]="provider.default_legal_entity.tax_number"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "GENERALS.CURRENCY" | translate }} *</label>
        <ng-select
          class="customiz-select"
          [items]="currency"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="provider.currency_id"
          placeholder="{{ 'GENERALS.SELECT-CURRENCY' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group w-100">
        <label>{{ "LABEL.COMPANY-SHORT-DESCRIPTION" | translate }} *</label>
        <textarea
          name="short_description"
          class="textarea-control"
          [(ngModel)]="provider.short_description"
        ></textarea>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.PROF-ASSOCIATION" | translate }}</label>
        <ng-select
          class="customiz-select"
          [items]="industries"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'LABEL.PROF-ASSOCIATION' | translate }}"
          [(ngModel)]="provider.professional_association_id"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
      <div class="form-group w-50">
        <label>{{ "LABEL.NUMBER_OF_EMPLOYEES" | translate }}</label>
        <ng-select
          class="customiz-select"
          [items]="employeesNumber"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'FORMS.SELECT_COMPANY_SIZE' | translate }}"
          [(ngModel)]="provider.number_of_employees_id"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.COMPANY-ADDRESS" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-ADDRESS" | translate }} *</label>

        <input
          type="text"
          name="address"
          class="form-control"
          [(ngModel)]="provider.default_legal_entity.address"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-CITY" | translate }}*</label>
        <ng-select
          class="customiz-select"
          [items]="cities"
          bindLabel="name"
          bindValue="name"
          placeholder="{{ 'GENERALS.SELECT-CITY' | translate }}"
          [(ngModel)]="provider.default_legal_entity.city"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          (search)="loadCities($event.term)"
          (focus)="loadCities()"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.REGION" | translate }} *</label>
        <input
          type="text"
          name="region"
          class="form-control"
          [(ngModel)]="provider.default_legal_entity.region"
        />
      </div>
      <div class="form-group w-50">
        <label>{{ "LABEL.ZIP-CODE" | translate }} *</label>
        <input
          type="text"
          name="zip_code"
          class="form-control"
          [(ngModel)]="provider.default_legal_entity.zip_code"
        />
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.BANK-DETAILS" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <div class="row">
          <div class="form-group w-50">
            <label>{{ "LABEL.BANK-NAME" | translate }} *</label>
            <input
              type="text"
              name="bank_name"
              class="form-control"
              [(ngModel)]="provider.bank_name"
            />
          </div>
          <div class="form-group w-50">
            <label>{{ "LABEL.SWIFT-CODE" | translate }} *</label>
            <input
              type="text"
              name="swift_code"
              class="form-control"
              [(ngModel)]="provider.swift_code"
            />
          </div>
        </div>
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }} *</label>
        <input
          type="text"
          name="swift_code"
          class="form-control"
          [(ngModel)]="provider.bank_account_number"
        />
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.LEGAL-REPRESENTATIVE" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label
          >{{
            "LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME" | translate
          }}
          *</label
        >
        <input
          type="text"
          name="legal_representative_name"
          class="form-control"
          [(ngModel)]="provider.legal_representative_name"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate }} *</label>
        <input
          type="text"
          name="legal_representative_email"
          class="form-control"
          [(ngModel)]="provider.legal_representative_email"
        />
      </div>
    </div>

    <!-- <div class="row">
      <div class="form-group w-100">
        <label>{{ 'LABEL.COMPANY-SHORT-DESCRIPTION' | translate }} *</label>
        <textarea
          name="short_description"
          class="textarea-control"
          [(ngModel)]="provider.short_description"
        ></textarea>
      </div>
    </div> -->

    <button [disabled]="isLoading" (click)="save()" class="btn orange-bg">
      {{ "GENERALS.NEXT" | translate }}
    </button>
  </div>
  <div *ngIf="provider" class="registration-steps-dots">
    <div class="registration-step-dots-item"></div>
    <div class="registration-step-dots-item active-setp-dot"></div>

    <div class="registration-step-dots-item"></div>
  </div>
</div>
