import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { finalize } from "rxjs";
import { AlertService } from "src/app/_services/alert.service";
import { DocumentsService } from "src/app/_services/documents.service";

@Component({
  selector: "app-add-profile-picture",
  templateUrl: "./add-profile-picture.component.html",
  styleUrls: ["./add-profile-picture.component.scss"],
})
export class AddProfilePictureComponent {
  entityType: string;
  entityId: string;
  title: string;

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  uploadedFiles = { file: null, name: null, status: null };
  statuses;

  sts = [{ id: 1, text: "signed" }];
  constructor(
    protected service: DocumentsService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddProfilePictureComponent>
  ) {
    this.setModalOpen = false;
    this.entityType = data.entityType;
    this.entityId = data.entityId;
    this.title = data.title;
  }

  uploadImage() {
    this.isLoading = true;

    this.service
      .uploadProfilePicture(this.entityId, this.uploadedFiles, this.entityType)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.dialogRef.close({
            result: res,
          });
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
