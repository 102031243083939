<mat-form-field appearance="outline" [formGroup]="caseForm" class="w-full">
  <mat-label>{{ "GENERALS.SERVICES" | translate }} </mat-label>
  <mat-select
    #select
    class="chips-input services"
    multiple
    formControlName="services"
    disableOptionCentering
    panelClass="services-panel"
  >
    <mat-select-trigger>
      <span *ngFor="let item of selectedItems">
        {{ item.service_name }}
        <span class="delete-item" (click)="removeSelectedItem($event, item)"
          >X</span
        >
      </span>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'GENERALS.SERVICES' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="servicesSearchControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      class="chip-option"
      *ngFor="let item of filteredItems"
      [value]="item"
      >{{ item.service_name }}</mat-option
    >
  </mat-select>
</mat-form-field>
