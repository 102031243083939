import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Observable, Subscription, combineLatest } from "rxjs";
import { map, shareReplay, startWith, tap } from "rxjs/operators";
import { ExpatData } from "../../../../_models/expat";
import { AlertService } from "../../../../_services/alert.service";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { UsersService } from "../../../../_services/users.service";

@Component({
  selector: "app-add-expat-form",
  templateUrl: "./add-expat-form.component.html",
  styleUrls: ["./add-expat-form.component.scss"],
})
export class AddExpatForm implements OnInit, OnDestroy {
  @Input()
  public expat: Partial<ExpatData>;

  @Input()
  public clientId: number;

  @Output() emitter: EventEmitter<any> = new EventEmitter();

  public prefix;
  public user;
  public recentlyAddedExpat;
  public showExpatAddedMessage;
  public isLoadingGeneral;
  public countries;
  public filteredCountries;
  public filteredPrefix;
  public edit: boolean;
  public imgURL: any;
  public message: string;
  public form!: UntypedFormGroup;
  private id;
  private departments;
  private subscriptions: Subscription[] = [];
  public countryFilterControl = new UntypedFormControl();

  phonePrefixSearchControl: UntypedFormControl;
  filteredPrefixes$: Observable<any[]>;
  countries$: Observable<any[]>;

  constructor(
    private dictionariesService: DictionariesService,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddExpatForm>
  ) {}

  public ngOnInit() {
    this.getCountryList();
    this.getCurrentUser();
    this.getDepartmentsList();

    this.getExpat();

    this.setupForm();
    this.initPhonePrefixControl();
    this.filterCountries();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private filterCountries() {
    this.countryFilterControl.valueChanges.subscribe((inputValue) => {
      this.filteredCountries = this.countries.filter(
        (value) =>
          value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      );
    });
  }

  private getCurrentUser() {
    this.userService.getCurrentUser().subscribe(
      (data) => {
        this.user = data;
      },
      (error) => {
        this.emitter.emit("cancel");
      }
    );
  }

  private getDepartmentsList() {
    this.dictionariesService.getDepartments().subscribe((res) => {
      this.departments = res.result;
    });
  }

  private getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
        this.filteredCountries = data.result;

        this.prefix = data.result.map((p) => {
          if (this.edit) {
            if (
              this.dictionariesService.getPhonePrefix(
                this.expat.phone_prefix
              ) === this.dictionariesService.getPhonePrefix(p.phone_code)
            ) {
              p.phone_code = this.dictionariesService.getPhonePrefix(
                p.phone_code
              );
              this.expat.phone_prefix = p.phone_code;
            } else {
              p.phone_code =
                this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
            }
          } else {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
          }
          return p;
        });

        this.filteredPrefix = this.prefix;
      }
    });
  }

  public emitChanges(e: string) {
    if (e === "save" || e === "another") {
      this.triggerValidation();
      if (this.form.valid) {
        this.isLoadingGeneral = true;
        this.getCurrentUser();
        const params = this.expat;
        params.client_id = this.expat.client_id
          ? this.expat.client_id
          : this.clientId
          ? this.clientId
          : this.data.client_id;

        params.user_id = this.expat.user_id;
        params.expat_id = this.expat.user_id;
        params.email = this.form.controls.email.value;
        params.country_code = this.form.controls.country_code.value;
        params.primary_citizenship =
          this.form.controls.primary_citizenship.value;

        if (!params.details) {
          params.details = {};
        }

        params.first_name = this.form.controls.first_name.value;
        params.last_name = this.form.controls.last_name.value;
        params.phone_prefix =
          this.form.controls.phone_prefix.value.split(")")[0] + ")";
        params.phone = this.form.controls.phone.value;

        params.details.department_name =
          this.form.controls.department_name.value;
        params.details.company_position =
          this.form.controls.company_position.value;

        const expat$ = this.edit
          ? this.expatsService.updateExpat(params)
          : this.expatsService.createExpat(params);

        params.payroll_id = this.form.controls.payroll_id.value;

        expat$
          .pipe(
            tap((res) => {
              this.alertService.success("Saved successfully");
              this.recentlyAddedExpat = res;
              this.showExpatAddedMessage = true;
              setTimeout(() => (this.showExpatAddedMessage = false), 5000);
            }),
            map((res) => res)
          )
          .subscribe({
            next: (expat) => {
              if (e === "save") {
                this.emitter.emit({ type: e, id: expat.id });
              } else {
                this.expat = {};
                this.imgURL = "";
              }

              Object.keys(this.form.controls).forEach((control) => {
                this.form.controls[control].reset();
              });
              this.getCountryList();
              this.isLoadingGeneral = false;

              if (this.data.client_id) {
                this.dialogRef.close({ id: expat.id });
              }
            },
            error: (error) => {
              this.isLoadingGeneral = false;

              if (error?.error?.message) {
                this.alertService.error(error.error.message);
              } else if (error?.error?.errors) {
                this.alertService.errors(error.error.errors);
              }
            },
          });
      }
    } else {
      this.emitter.emit({ type: e, id: null });
    }
  }

  private triggerValidation() {
    this.form.markAllAsTouched();
  }

  private initPhonePrefixControl() {
    this.phonePrefixSearchControl = new UntypedFormControl();

    this.countries$ = this.dictionariesService.getCountryList().pipe(
      map((data) => {
        let countries = [];
        if (data && data.result) {
          countries = data.result;
        }
        return countries;
      })
    );
    this.filteredPrefixes$ = combineLatest([
      this.phonePrefixSearchControl.valueChanges.pipe(startWith("")),
      this.countries$,
    ]).pipe(
      map(([searchInput, prefixes]) => {
        if (!searchInput) {
          return prefixes;
        }

        const filterValue = searchInput.toLowerCase();
        return prefixes.filter((prefix) =>
          prefix.phone_code.toLowerCase().includes(filterValue)
        );
      }),
      shareReplay(1)
    );
  }

  public filterPrefixes(search: string) {
    return this.prefix.filter((value) =>
      value.phone_code
        .toString()
        .toLowerCase()
        .includes(search.toString().toLowerCase())
    );
  }

  public setPhonePrefix() {
    if (this.expat.user && this.expat.user.phone_prefix) {
      const prefix = this.prefix.find((pre) =>
        pre.phone_code.includes(this.expat.user.phone_prefix)
      );

      return prefix.phone_code;
    }

    return "";
  }

  private setupForm() {
    this.form = this.fb.group({
      first_name: new UntypedFormControl(
        this.getFormControl("user", "first_name"),
        Validators.required
      ),
      last_name: new UntypedFormControl(
        this.getFormControl("user", "last_name"),
        Validators.required
      ),
      department_name: new UntypedFormControl(
        this.getFormControl("details", "department_name")
      ),
      company_position: new UntypedFormControl(
        this.getFormControl("details", "company_position")
      ),
      email: new UntypedFormControl(this.getFormControl("user", "email"), [
        Validators.required,
        Validators.email,
      ]),
      phone_prefix: new UntypedFormControl(this.setPhonePrefix()),
      phone: new UntypedFormControl(this.phone),
      payroll_id: new UntypedFormControl(this.expat?.payroll_id ?? ""),
      country_code: new UntypedFormControl(
        this.expat.country_code,
        Validators.required
      ),
      primary_citizenship: new UntypedFormControl(""),
    });
  }

  private get phone() {
    if (this.expat && this.expat.user && this.expat.user.phone) {
      return this.expat.user.phone;
    }
    return null;
  }

  private getFormControl(firstProperty: string, secondProperty: string) {
    if (
      this.expat[firstProperty] &&
      this.expat[firstProperty][secondProperty] &&
      this.expat[firstProperty][secondProperty]
    ) {
      return this.expat[firstProperty][secondProperty];
    }
    return "";
  }

  private getExpat() {
    if (!this.expat) {
      this.expat = {};
      this.edit = false;
    } else {
      this.edit = true;
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
