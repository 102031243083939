import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AccountService, UserData } from "@api/account";
import {
  AccountDialogComponent,
  AccountDialogComponentInput,
  ChangePasswordDialogComponent,
} from "@modules/account/components";
import { AddProfilePictureComponent } from "@modules/shared/_components/add-profile-picture/add-profile-picture.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { take } from "rxjs";
import { AlertService } from "@modules/alert";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-my-account-page",
  templateUrl: "./my-account-page.component.html",
  styleUrls: ["./my-account-page.component.scss"],
})
export class MyAccountPageComponent implements OnInit {
  about;
  accountData: UserData;
  edit = false;
  isLoading = false;

  constructor(
    private userService: UsersService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService,
    public dictionaryService: DictionariesService,
    public matDialog: MatDialog,
    private readonly accountService: AccountService
  ) {}

  ngOnInit() {
    this.getCurrentUser();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setMyAccount()
    );
  }

  getCurrentUser() {
    this.userService
      .getCurrentUser(true)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.accountData = res;
          this.accountData.company_position =
            this.accountData.entity_details.company_position;
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  openResetPasswordModal() {
    this.dialog
      .open(ChangePasswordDialogComponent)
      .afterClosed()
      .subscribe(() => {
        //todo: update user data?
      });
  }

  openDocModal() {
    this.dialog
      .open(AddProfilePictureComponent, {
        data: {
          entityType: "user",
          entityId: this.accountData.id,
          title: "GENERALS.UPLOAD-PIC",
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (!result) {
          return;
        }
        this.getCurrentUser();
      });
  }

  openEditModal() {
    const data: AccountDialogComponentInput = {
      user: this.accountData,
    };
    this.matDialog
      .open(AccountDialogComponent, { data })
      .afterClosed()
      .subscribe(() => {
        this.getCurrentUser();
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
