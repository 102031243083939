import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExpatData } from "@models/expat";
import { UserStatus, UserStatusName } from "@models/_statuses/user-statuses";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { AlertService } from "@modules/alert";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { DeleteExpatDialogComponent } from "@modules/expats/expats-shared/components/delete-expat-dialog/delete-expat-dialog.component";
import { Router } from "@angular/router";
import { finalize } from "rxjs";

@Component({
  selector: "app-expat-button-bar",
  templateUrl: "./expat-button-bar.component.html",
  styleUrls: ["./expat-button-bar.component.scss"],
})
export class ExpatButtonBarComponent {
  @Input() expat!: ExpatData;

  @Output() expatInvited: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  constructor(
    private auth: AuthenticationService,
    private alert: AlertService,
    private dialog: MatDialog,
    private service: ExpatsService,
    private router: Router
  ) {}

  resetPassword() {
    this.auth.sendResetPasswordEmail(this.expat.user.email).subscribe(
      (res) => {
        if (res && res.message) {
          this.alert.success(res.message);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alert.errors(error.error.errors);
        }
      }
    );
  }

  invite(reInvite: boolean = false) {
    const action = {
      needs_confirmation: true,
      customText: reInvite
        ? "GENERALS.RESEND-INVITE-EXPAT-CONTENT"
        : "GENERALS.INVITE-EXPAT-CONTENT",
      customTitle: reInvite
        ? "GENERALS.RESEND-INVITE-EXPAT"
        : "GENERALS.INVITE-EXPAT",
    };

    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        this.service
          .inviteExpat(this.expat.id)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe({
            next: () => {
              this.expatInvited.emit();
            },
            error: (error) => {
              if (error.error.errors) {
                this.alert.errors(error.error.errors);
              }
            },
          });
      }
    });
  }

  public delete() {
    this.dialog
      .open(DeleteExpatDialogComponent, {
        data: { expat: this.expat },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data.success) {
          this.router
            .navigateByUrl(`clients/${this.expat.client_id}/expats`)
            .then();
        }
      });
  }

  protected readonly UserStatus = UserStatus;
  protected readonly UserStatusName = UserStatusName;
}
