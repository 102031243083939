// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---configuration=development` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

import { full } from "./enabled-pages";
import { processEnvironment } from "./environment.process";

export const environment = {
  production: false,
  gateway_endpoint: "https://gate.dev.xpath.global/",
  client_id: 2,
  client_secret: "JH1QdlJ5gVic2KfQfjtuBxEiRLDA3M0uH85TEXDK",
  app_host: "https://provider.dev.xpath.global",
  user_local_storage_key: "userDEVStorageKey",
  entity_local_storage_key: "entityDEVStorageKey",
  last_status_timestamp: "lastStatusTimeStampDEV",
  lang_cookie_key: "langCookie",
  cookieConsent: "cookieConsent",
  session_local_storage_key: "sessionDEVProviderStorageKey",
  marketplace: "https://client-etnxvd.bunnyenv.com/",
  admin_portal: "https://admin.dev.xpath.global/",
  role: "Provider",
  token_key: "api_token_provider_key",
  domain: "provider.dev.xpath.global",
  unit_id: 3, // days,
  pusher_key: "nestlers-messaging-websocketkey",
  wsHost: "nestlers-messaging.digitalkrikits.com",
  wsPort: 6001,
  // google_tracking_id: 'UA-136866838-2',
  google_tracking_id: "UA-147618337-3",
  stripe_public_key: "pk_test_mOF2egzWkFnA3k8f7j4mb9gz00ud5XptS3",
  stripe_portal_login:
    "https://billing.stripe.com/p/login/test_9AQcQh6SQ2sn8jSfYY",
  enabled_pages: full,
  aito_api_key: "KKbWKlJ0ZnMpHjlNZPiV9TrEy9lGlY27XLIUgPBc",
  aito_api_url: "https://xpath-test.aito.app",
  document_ai_api: "http://localhost:3000",
  unreleased_features: true,
  has_crm: true,
  has_marketplace: true,
  ...(processEnvironment ? processEnvironment : {}),
  recaptcha_site_key: "6LeFKO8pAAAAAC1VwndzCjCCgpFmPHIstowrqKCj",
  show_recaptcha: false,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
