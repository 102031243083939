import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Ads } from "@models/ads";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class AdsService {
  private bannerType = "ads/banner-types";
  private adsUrl = "ads/banners";

  constructor(private http: HttpClient) {}

  // GET List Banner Types
  getListBannerType(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.bannerType);
  }

  // GET List Ad Banners
  getListAdBanner(params): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.adsUrl, {
      params: params,
    });
  }

  // Create Ad Banner
  createAdBanenr(ads: Ads): Observable<any> {
    const formData: FormData = new FormData();

    if (ads.image) {
      formData.append("image", ads.image, ads.image.name);
    }
    if (ads.entity_id) {
      formData.append("entity_id", ads.entity_id);
    }
    if (ads.key) {
      formData.append("key", ads.key);
    }
    if (ads.performance_type) {
      formData.append("performance_type", ads.performance_type);
    }
    if (ads.max_cost) {
      formData.append("max_cost", ads.max_cost);
    }
    if (ads.action_url) {
      formData.append("action_url", ads.action_url);
    }
    if (ads.starts_at) {
      formData.append("starts_at", ads.starts_at);
    }
    if (ads.ends_at) {
      formData.append("ends_at", ads.ends_at);
    }
    if (ads.category_id) {
      formData.append("category_id", ads.category_id);
    }
    if (ads.country) {
      formData.append("country", ads.country);
    }

    return this.http
      .post<any>(environment.gateway_endpoint + this.adsUrl, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Ad Banner Status

  updateAdBanner(status, id): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + this.adsUrl + "/" + id,
        status,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Edit Ad Banner Status

  editAdBanner(ads: Ads): Observable<any> {
    const formData: FormData = new FormData();

    if (ads.image) {
      formData.append("image", ads.image, ads.image.name);
    }
    if (ads.entity_id) {
      formData.append("entity_id", ads.entity_id);
    }
    if (ads.max_cost) {
      formData.append("max_cost", ads.max_cost);
    }
    if (ads.action_url) {
      formData.append("action_url", ads.action_url);
    }
    if (ads.starts_at) {
      formData.append("starts_at", ads.starts_at);
    }
    if (ads.ends_at) {
      formData.append("ends_at", ads.ends_at);
    }

    return this.http
      .post<any>(
        environment.gateway_endpoint + this.adsUrl + "/" + ads.id + "/update",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET List Ad-Banners Status Transitions

  getListAdBannersStatusTransitions(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.adsUrl + "/status-transitions"
    );
  }
}
