<mat-form-field
  appearance="outline"
  class="w-full"
  (click)="$event.stopPropagation()"
>
  <input class="search-input" matInput [formControl]="searchControl" />
  <mat-icon matPrefix class="p-2" svgIcon="search"></mat-icon>
</mat-form-field>
<!--   multiselect filter start  -->
<mat-selection-list class="multiselect-filter-list">
  <cdk-virtual-scroll-viewport itemSize="40">
    <mat-list-option
      *cdkVirtualFor="let item of filterItems; trackBy: trackByFn"
      color="primary"
      [value]="item.id"
      [selected]="checklistSelection.isSelected(item.id)"
      (selectedChange)="
        $event
          ? checklistSelection.select(item.id)
          : checklistSelection.deselect(item.id)
      "
    >
      {{ item.text }}
    </mat-list-option>
  </cdk-virtual-scroll-viewport>
</mat-selection-list>
<!--   multiselect filter end  -->
