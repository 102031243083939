import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { AlertService } from "src/app/_services/alert.service";
import { DocumentsService } from "src/app/_services/documents.service";

@Component({
  templateUrl: "./confirm-invoice-payment.component.html",
  styleUrls: ["./confirm-invoice-payment.component.scss"],
})
export class ConfirmInvoicePaymentComponent implements OnInit {
  file_id = null;
  set fileId(value) {
    if (value) {
      this.file_id = value;
      this.paymentConfirmationObj.file_id = value;
    }
  }

  isLoading = false;
  paymentMethods = [
    { id: "card", text: "Card" },
    { id: "cash", text: "Cash" },
    { id: "bank_transfer", text: "Bank Transfer" },
    { id: "cheque", text: "Cheque" },
  ];
  paymentConfirmationObj = {
    file_id: this.file_id,
    payment_method: null,
    transaction_id: null,
    payment_date: this.getDateString(new Date()),
  };

  constructor(
    protected service: DocumentsService,
    private alertService: AlertService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    protected readonly dialogRef: MatDialogRef<ConfirmInvoicePaymentComponent>
  ) {}

  ngOnInit() {
    this.fileId = this.data.fileId;
  }

  setInvoicePaymentDate(event) {
    this.paymentConfirmationObj.payment_date = this.getDateString(event);
  }

  getDateString(event) {
    const d = new Date(event);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }

  confirmPayment() {
    this.isLoading = true;
    this.service.confirmPayment(this.paymentConfirmationObj).subscribe(
      (res) => {
        if (res.message) {
          this.alertService.success(res.message);
        }
        this.isLoading = false;
        this.dialogRef.close(res);
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
