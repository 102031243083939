import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { FileUploadControl } from "@iplab/ngx-file-upload";

@Component({
  selector: "app-file-selector",
  templateUrl: "./file-selector.component.html",
  styleUrls: ["./file-selector.component.scss"],
})
export class FileSelectorComponent implements OnInit {
  @Input() value;
  expenseForm: UntypedFormGroup;
  fileControl: UntypedFormControl;

  public fileUploadControl = new FileUploadControl();

  constructor(private controlContainer: ControlContainer) {
    this.fileControl = new UntypedFormControl(this.value ? this.value : "");
  }

  ngOnInit() {
    this.expenseForm = <UntypedFormGroup>this.controlContainer.control;
    this.expenseForm.setControl("file", this.fileControl);
  }
}
