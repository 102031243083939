<div class="dialog-wrapper">
  <h2 mat-dialog-title>
    {{ "permissions.ADD-CLIENT-PERMISSION" | translate }}
  </h2>
  <ng-container *ngIf="isLoading">
    <app-loader-spinner-general
      [repeat]="2"
      [loadingType]="LoadingType.INPUT"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      [repeat]="2"
      class="d-flex justify-content-end"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
  </ng-container>
  <mat-dialog-content *ngIf="!isLoading">
    <form [formGroup]="permissionsForm">
      <mat-form-field *ngIf="permissions.length !== 0">
        <mat-label>{{
          "permissions.CUSTOM-PERMISSIONS" | translate
        }}</mat-label>
        <div class="row">
          <mat-select
            formControlName="permissions"
            multiple
            disableOptionCentering
          >
            <mat-select-trigger></mat-select-trigger>
            <mat-option
              *ngFor="let permission of permissions"
              [value]="permission"
              >{{ permission }}</mat-option
            >
          </mat-select>
        </div>
        <div class="row mb-3">
          <mat-chip-listbox>
            <mat-chip
              *ngFor="
                let permission of permissionsForm.get('permissions').value
              "
              [removable]="true"
              (removed)="onPermissionRemoved(permission)"
            >
              {{ permission }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </mat-form-field>
      <app-form-errors
        [control]="permissionsForm.get('permissions')"
        class="w-100 p-0"
      >
      </app-form-errors>

      <mat-form-field *ngIf="users.length !== 0">
        <mat-label>{{ "GENERALS.SELECT-USERS" | translate }}</mat-label>
        <div class="row">
          <mat-chip-listbox>
            <mat-chip
              *ngFor="let model of permissionsForm.get('users').value"
              [removable]="true"
              (removed)="onModelRemoved(model)"
            >
              {{ model.name }} ( {{ model.role_name }})
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-listbox>
        </div>
        <div class="row">
          <mat-select formControlName="users" multiple disableOptionCentering>
            <mat-select-trigger></mat-select-trigger>
            <mat-option *ngFor="let model of users" [value]="model">
              {{ model.name }} ( {{ model.role_name }})
            </mat-option>
          </mat-select>
        </div>
      </mat-form-field>
      <app-form-errors
        [control]="permissionsForm.get('users')"
        class="w-100 p-0"
      >
      </app-form-errors>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="btn gray-bg modal-button"
      mat-dialog-close="Cancelled"
      (click)="cancel()"
    >
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button class="btn orange-bg m-l-15 modal-button" (click)="save()">
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </mat-dialog-actions>
</div>
