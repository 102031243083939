import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { PageData } from "@modules/shared/models/page.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ServiceCategory } from "@models/servicecategory";
import { BackendHttpClient } from "@api/http";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ServiceCategoriesService {
  private serviceCategoriesUrl = "offers/serviceCategories";
  protected backendService = inject(BackendHttpClient);
  constructor(private http: HttpClient) {}

  // GET Service Categories
  getServiceCategories(params = {}): Observable<PageData<ServiceCategory>> {
    return this.backendService
      .get<any>("marketplace/categories", {
        params: params,
      })
      .pipe(map((data) => data.result));
  }

  getServiceCategoriesbyId(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceCategoriesUrl + "/" + id
    );
  }

  // GET List of documentation standards
  getDocumentationStandardsList(service_category_id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.serviceCategoriesUrl +
        "/getDocumentationStandard" +
        "/" +
        service_category_id
    );
  }

  /**
   * Get the service category tree
   */
  getCategoryTree(id): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}entities/providers/${id}/categories`
    );
  }

  /**
   * Download Template
   *
   * @returns {Observable<Object>}
   */
  downloadTemplate(service_category_id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        "offers/serviceCategories/" +
        service_category_id +
        "/importTemplate",
      { responseType: "blob" as const }
    );
  }

  /**
   * Remove category from provider
   *
   * @param categoryId
   * @param providerId
   */
  removeCategory(categoryId, providerId): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          "entities/providers/" +
          providerId +
          "/categories/" +
          categoryId,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
