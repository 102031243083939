<div class="details-body-wrapper activity-tabel">
  <div class="table-wrapper m-0">
    <div class="table-responsive activity-table">
      <table matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <ng-container matColumnDef="select"> </ng-container>
            <th>{{ "GENERALS.AUTHOR-NAME" | translate }}</th>
            <th>
              {{ "GENERALS.ACTION" | translate }}
            </th>

            <th>
              {{ "GENERALS.CREATED-AT" | translate }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading" class="d-none">
          <tr
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td class="gray">{{ item.author.name }}</td>
            <td class="gray">{{ item.action }}</td>
            <td class="gray activity-changes">
              <ng-container *ngFor="let key of item.new_value | keys">
                <div>
                  <span class="dark-gray">{{ key }}:</span>
                  {{ item.new_value[key] | json }}
                </div>
              </ng-container>
            </td>
            <td class="gray">
              {{ item.created_at | date : "dd/MM/yyyy HH:mm:s" }}
            </td>
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="3" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <div
          *ngFor="
            let item of items
              | paginate
                : {
                    itemsPerPage: itemsPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="item">{{ item.author.name }}</div>
                <div class="item">{{ item.action }}</div>
                <div class="item">
                  {{ item.created_at | date : "dd/MM/yyyy HH:mm:s" }}
                </div>
              </mat-expansion-panel-header>
              <mat-panel-description
                class="flex-column align-items-start px-3 pb-3"
              >
                <label>
                  {{ "LABEL.CHANGES" | translate }}
                </label>
                <div class="gray activity-changes">
                  <ng-container *ngFor="let key of item.new_value | keys">
                    <div>
                      <span class="dark-gray">{{ key }}:</span>
                      {{ item.new_value[key] | json }}
                    </div>
                  </ng-container>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="items?.length === 0">
          <div colspan="3" class="gray">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </div>
        </div>
      </div>
    </div>

    <!-- paginator -->
    <div class="paginator">
      <pagination-controls
        (pageChange)="getItems($event)"
        previousLabel=""
        nextLabel=""
      >
      </pagination-controls>
    </div>

    <!-- end paginator -->
  </div>
  <!-- end table -->
</div>
