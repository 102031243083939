<div class="custom-modal-wrapper p-0">
  <div class="modal-title-custom p-20">Confirm delete</div>

  <div class="modal-body-custom p-20 m-0">
    {{ message }}
  </div>
  <div class="modal-buttons p-20">
    <button class="btn gray-bg m-l-20 modal-button" (click)="delete(false)">
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button class="btn orange-bg" (click)="delete(true)">
      <mat-icon svgIcon="trash-icon" class="m-5-10 pointer white"> </mat-icon>
      {{ "GENERALS.DELETE" | translate }}
    </button>
  </div>
</div>
