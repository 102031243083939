import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Dictionary } from "@models/dictionary";
import { EntityUser } from "@models/entity.user";
import { ProviderData } from "@models/provider";
import { AlertService } from "@modules/alert";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { LegalEntityData } from "@modules/clients/modules/entities/models/legal-entity.data";

export interface CompanySettingsDialogComponentInput {
  provider: ProviderData;
}

@Component({
  selector: "app-company-settings-dialog",
  templateUrl: "./company-settings-dialog.component.html",
})
export class CompanySettingsDialogComponent implements OnInit {
  public isLoading = false;

  public provider: ProviderData;
  public defaultLegalEntity: LegalEntityData;

  public companyTypes: Dictionary[];
  public professionalAssociation: Dictionary[];
  public employeesNumber: Dictionary[];
  public departments: Dictionary[];
  public preselectedCurrency;

  public currencies = [
    { id: 1, name: "USD" },
    { id: 2, name: "EUR" },
    { id: 3, name: "GBP" },
  ];

  public countries;
  public cities;

  constructor(
    private readonly dictionariesService: DictionariesService,
    private readonly matDialogRef: MatDialogRef<CompanySettingsDialogComponent>,
    private readonly alertService: AlertService,
    private readonly providerService: ProvidersService,
    @Inject(MAT_DIALOG_DATA)
    public data: CompanySettingsDialogComponentInput
  ) {}

  public get LoadingType() {
    return LoadingTypeEnum;
  }

  ngOnInit() {
    this.provider = this.data.provider;
    this.defaultLegalEntity = { ...this.provider.default_legal_entity };
    if (this.provider && this.provider.currency_id) {
      this.preselectedCurrency = this.provider.currency_id;
    }
    this.getCountries();
    this.getCompanyTypes();
    this.getDepartments();
    this.getProfessionalAssociation();
    this.getEmployeesNumber();
  }

  getCountries() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getCompanyTypes() {
    this.dictionariesService.getCompanyTypes().subscribe((data) => {
      this.companyTypes = data.result;
    });
  }

  getDepartments() {
    this.dictionariesService.getDepartments().subscribe((data) => {
      this.departments = data.result;
    });
  }

  getProfessionalAssociation() {
    this.dictionariesService.getProfessionalAssociation().subscribe((data) => {
      this.professionalAssociation = data.result;
    });
  }

  getEmployeesNumber() {
    this.dictionariesService.getEmployeesNumber().subscribe((data) => {
      this.employeesNumber = data.result;
    });
  }

  loadCities(event?) {
    this.dictionariesService
      .cities(event?.term || "", this.defaultLegalEntity.country_code)
      .subscribe((response) => {
        if (response.success) {
          this.cities = response.result;
        }
      });
  }

  countryChanged() {
    this.cities = [];
    this.defaultLegalEntity.city = null;
  }

  public save() {
    let user: any = { ...this.provider };
    user.entity_details = new EntityUser();
    user.entity_details.company = this.provider;
    user = this.setDefaultLegalEntityData(user);

    if (!user.number_of_employees_id) {
      delete user["number_of_employees_id"];
    }
    this.isLoading = true;
    this.providerService.updateProviderCompanyDetails(user).subscribe(
      (res) => {
        this.isLoading = false;
        this.matDialogRef.close(res.result);
        if (res.messages) {
          this.alertService.success(res.messages);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  setDefaultLegalEntityData(data: any) {
    data.country_code = this.defaultLegalEntity.country_code;
    data.region = this.defaultLegalEntity.region;
    data.city = this.defaultLegalEntity.city;
    data.address = this.defaultLegalEntity.address;
    data.zip_code = this.defaultLegalEntity.zip_code;
    data.tax_number = this.defaultLegalEntity.tax_number;

    return data;
  }

  public close() {
    this.matDialogRef.close();
  }
}
