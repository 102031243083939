<app-dialog-header>
  {{ "GENERALS.ACCOUNT-INFO" | translate }}
</app-dialog-header>

<div class="dialog-body">
  <form [formGroup]="formGroup" class="field-grid">
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.FIRST-NAME" | translate }}</mat-label>
      <input matInput formControlName="first_name" name="first_name" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.LAST-NAME" | translate }}</mat-label>
      <input matInput formControlName="last_name" name="last_name" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.EMAIL" | translate }}</mat-label>
      <input matInput formControlName="email" name="email" type="email" />
    </mat-form-field>
    <mat-form-field class="phone-field w-full">
      <mat-label>
        {{ "LABEL.PHONE-NUMBER" | translate }}
      </mat-label>
      <ngx-mat-input-tel
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone_number"
        name="phone_number"
        describedBy="phoneInput"
        [format]="'national'"
        [required]="true"
        #phone
        maxLength="20"
        (countryChanged)="onCountryChanged($event)"
      ></ngx-mat-input-tel>
      <mat-error formControlError="phone_number"></mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }}</mat-label>
      <input
        matInput
        formControlName="company_position"
        name="company_position"
      />
    </mat-form-field>
  </form>
</div>
<div class="dialog-footer">
  <button type="button" class="btn-primary" (click)="submit()">
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
