import { Injectable } from "@angular/core";
import { CaseActions } from "@api/cases/actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, of, switchMap } from "rxjs";
import { TaskActions } from "../actions";
import { CaseTasksService } from "../services";
import { TypedAction } from "@ngrx/store/src/models";

@Injectable()
export class TaskEffects {
  onLoadCaseTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.loadCaseTasks),
      mergeMap(({ caseId }) => [
        TaskActions.fetchTasks({
          filters: {
            expat_case_id: `${caseId}`,
            per_page: "200",
            include: "",
          },
        }),
      ])
    );
  });

  onServiceCaseTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.loadServiceTasks),
      mergeMap(({ serviceId }) => [
        TaskActions.fetchTasks({
          filters: {
            case_service_id: `${serviceId}`,
            per_page: "200",
            include: "",
          },
        }),
      ])
    );
  });

  onSelect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.selectTask),
      mergeMap(({ taskId }) => [TaskActions.fetchTask({ taskId })])
    );
  });

  onFetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.fetchTask),
      switchMap(({ taskId }) => this.taskService.fetchTask(taskId)),
      mergeMap((task) => [
        TaskActions.fetchTaskSuccess({ task }),
        TaskActions.upsertTasks({ tasks: task.subtasks }),
      ]),
      catchError((error) => {
        return of(TaskActions.fetchTaskError({ error }));
      })
    );
  });

  fetchTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.fetchTasks),
      switchMap(({ filters }) => this.taskService.fetchTasks(filters)),
      mergeMap(({ items }) => [TaskActions.fetchTasksSuccess({ tasks: items })])
    );
  });

  requestUpdateTaskSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TaskActions.requestUpdateTaskSuccess,
        TaskActions.requestCreateTaskSuccess
      ),
      mergeMap(({ task }) => {
        const actions: Array<TypedAction<string>> = [
          CaseActions.fetchCase({ caseId: task.expat_case_id }),
        ];
        return actions;
      })
    );
  });

  constructor(
    private actions$: Actions,
    private taskService: CaseTasksService
  ) {}
}
