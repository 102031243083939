import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { EditComponent } from "@modules/documents/components/edit/edit.component";
import { ShareComponent } from "@modules/documents/components/share/share.component";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { PermissionResourceEnum } from "@modules/shared/_enums/permission-resource.enum";
import { TranslateService } from "@ngx-translate/core";
import {
  Observable,
  Subject,
  combineLatest,
  map,
  switchMap,
  takeUntil,
} from "rxjs";
import { AlertService } from "@modules/alert";
import { DocumentActionsEnum } from "@api/documents/enums/document-actions.enum";
import { debounceTime } from "rxjs/operators";
@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit, OnDestroy {
  public docId$: Observable<number>;
  public document$: Observable<DocumentData>;
  public entityType$: Observable<string>;
  public entityId$: Observable<number>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  actions: Array<any>;

  private deleteAction = {
    id: 3,
    text: "GENERALS.DELETE",
    needs_confirmation: true,
    customText: "NOTIFICATIONS.DOC-DELETE",
  };

  archiveAction = {
    text: "GENERALS.ARCHIVE-DOCUMENT",
    needs_confirmation: true,
    customText: "NOTIFICATIONS.PROCEED-CONFIRM",
  };

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.docId$ = this.route.params.pipe(map((params) => params.doc));

    this.initDocument();
    this.getStatusTransitions();
    this.entityId$ = this.document$.pipe(
      map((value: DocumentData) => value.entity_id)
    );
    this.entityType$ = this.document$.pipe(
      map((value: DocumentData) => value.entity_type)
    );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openEditDialog(): void {
    const result = this.document$.pipe(
      switchMap((documentData: DocumentData) =>
        this.dialog
          .open(EditComponent, {
            data: documentData,
          })
          .afterClosed()
      )
    );

    result.subscribe((result) => {
      if (result?.action === "saved") {
        this.initDocument();
      }
    });
  }

  public onFilesUpdated(): void {
    this.initDocument();
  }

  public share(): void {
    const data$ = combineLatest([
      this.docId$,
      this.entityType$,
      this.entityId$,
    ]).pipe(
      switchMap(([docId, entityType, entityId]) =>
        this.dialog
          .open(ShareComponent, {
            data: { documentIds: docId, type: entityType, id: entityId },
          })
          .afterClosed()
      )
    );

    data$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data && data.type === "saved") {
        this.initDocument();
      }
    });
  }

  public delete(): void {
    const closed$ = this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: this.deleteAction,
        },
      })
      .afterClosed();

    const result$ = combineLatest([closed$, this.docId$]).pipe(
      switchMap(([result, docId]) => {
        if (result) {
          return this.documentService.updateStatusBulk(this.deleteAction.id, [
            docId,
          ]);
        }
      })
    );

    result$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.alertService.success(
        this.translateService.instant("GENERALS.SUCCESSFULLY-DELETED")
      );

      this.router.navigate(["../"], {
        relativeTo: this.route.parent,
        replaceUrl: true,
      });
    });
  }

  archiveOnDashboard(status, item): void {
    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: this.archiveAction,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.documentService
            .archiveExpiredDocument(status.id, [item.id])
            .subscribe({
              next: (res) => {
                this.alertService.success(res.message);
                this.initDocument();
              },
              error: (error) => {
                if (error && error.message) {
                  this.alertService.stringError(error.message);
                }
              },
            });
        }
      });
  }

  public get PermissionResourceEnum() {
    return PermissionResourceEnum;
  }

  private initDocument(): void {
    this.document$ = this.docId$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap((docId: number) => this.documentService.preview(docId))
    );
  }

  public getStatusTransitions() {
    this.documentService.getStatusTransitions().subscribe(
      (data) => {
        if (data.success) {
          this.actions = data.result;
          Object.keys(this.actions).forEach((key) => {
            this.actions[key] = [...this.actions[key], this.deleteAction];
          });
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  public hasPermission(document: DocumentData, actionId: number): boolean {
    const permissions = {
      [this.deleteAction.id]: () => {
        return document.can_delete;
      },
    };

    if (permissions[actionId]) {
      return permissions[actionId]();
    }

    return true;
  }

  public changeStatus(status, item): void {
    if (status.id === DocumentActionsEnum.ARCHIVED) {
      this.archiveOnDashboard(status, item);

      return;
    }

    if (status.id !== DocumentActionsEnum.DELETE) {
      this.documentService
        .updateStatusBulk(status.id, [item.id])
        .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
        .subscribe(() => this.initDocument());
    }
  }

  public reloadPage() {
    this.initDocument();
  }

  get DocumentType() {
    return DocumentActionsEnum;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
