import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  debounceTime,
  tap,
} from "rxjs";

import { DictionariesService } from "@services/dictionaries.service";

import { Country } from "@models/country";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormErrorDirective } from "src/app/_directives/form-error.directive";

@Component({
  selector: "app-country-selector",
  templateUrl: "./country-selector.component.html",
  styleUrls: ["./country-selector.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormErrorDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CountrySelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CountrySelectorComponent,
    },
  ],
})
export class CountrySelectorComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  multiple: boolean = false;
  @Input()
  onlyCode: boolean = false;
  @Input()
  placeholder: string = "";
  @Input()
  showLabel: boolean = true;

  isLoading: boolean = false;
  selectedCountry: Country[] | Country | string;
  onChange: (val: any) => void;
  onTouched: () => void;
  touched = false;

  @Output()
  loadedEvent: EventEmitter<boolean> = new EventEmitter();

  @Output()
  valueUpdated: EventEmitter<string> = new EventEmitter();

  control: AbstractControl = new UntypedFormControl();

  loaded: boolean = false;

  protected _onDestroy = new Subject<void>();

  countrySearchControl = new UntypedFormControl("");
  countries$: Observable<Country[]>;
  filteredCountries$: Observable<Country[]>;

  protected unsubscribe$ = new Subject<void>();

  constructor(private dictionariesService: DictionariesService) {}

  ngOnInit(): void {
    this.countries$ = this.dictionariesService.getCountryListWithIsoFormat();

    this.isLoading = true;

    this.filteredCountries$ = combineLatest([
      this.countrySearchControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300)
      ),
      this.countries$,
    ]).pipe(
      map(([searchInput, countries]) => {
        if (!searchInput) {
          return countries;
        }
        const filterValue = searchInput.toLowerCase();
        return countries.filter((country) =>
          country.name.toLowerCase().includes(filterValue)
        );
      }),
      tap(() => (this.isLoading = false)),
      shareReplay(1)
    );

    this.filteredCountries$.subscribe(() => {
      if (!this.loaded) {
        this.loaded = true;

        this.loadedEvent.emit(true);
      }
    });
  }

  writeValue(selectCountry: MatSelectChange | Country | any): void {
    if (selectCountry && selectCountry.value) {
      this.selectedCountry = selectCountry.value;
    } else {
      this.selectedCountry = selectCountry;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onSelectChange(matSelect: MatSelectChange) {
    this.onChange(matSelect.value);
    this.valueUpdated.next(matSelect.value);
  }

  validate(control: AbstractControl): ValidationErrors | null | any {
    this.control = control;

    this.control.valueChanges.subscribe((val) => {
      this.valueUpdated.next(val);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  preselectCountries(
    countryInList: Country | any,
    countryFrom: Country | any
  ): boolean {
    if (countryInList && countryFrom) {
      // If we send Country object
      if (countryInList.iso && countryFrom.iso) {
        return countryInList.iso === countryFrom.iso;
      }

      // If we send only the country_code (this needs to be checked last always)
      if (countryInList && countryFrom) {
        return countryInList === countryFrom;
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled === this.control.disabled) {
      return;
    }

    if (isDisabled) {
      this.control?.disable();

      return;
    }

    this.control?.enable();
  }

  getCurrentValue() {
    return this.selectedCountry;
  }
}
