export function sanitizeBoolean(value: any): boolean {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "string") {
    return stringToBoolean(value);
  }
  return false;
}

const stringToBoolean = (stringValue): boolean => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    default:
      return false;
  }
};
