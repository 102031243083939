import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { AssignmentData } from "@api/assignments/models/assignment.model";

@Component({
  selector: "app-additional-details-step",
  templateUrl: "./additional-details-step.component.html",
  styleUrls: ["./additional-details-step.component.scss"],
})
export class AdditionalDetailsStepComponent {
  @Input() form!: UntypedFormGroup;
  @Input() edit: boolean;
  @Input() assignment: AssignmentData;
  @Input() isReview: boolean;
}
