import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { ExpatData } from "../../../../_models/expat";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateManagementService } from "../../../shared/services/date-management.service";
import { CustomValidators } from "../../../shared/_validators";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  startWith,
  switchMap,
} from "rxjs";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-expat-personal-information",
  templateUrl: "./expat-personal-information.component.html",
  styleUrls: ["./expat-personal-information.component.scss"],
})
export class ExpatPersonalInformationComponent implements OnInit {
  public expat!: ExpatData;

  @Output()
  public readonly personalInformationChange = new EventEmitter<ExpatData>();

  public form!: UntypedFormGroup;
  public isLoadingGeneral = true;

  public countryFilterControl = new UntypedFormControl();
  public cityFilterControl = new UntypedFormControl();
  public filteredCountries;
  public filteredCities$: Observable<any>;

  constructor(
    private fb: UntypedFormBuilder,
    private dateManagementService: DateManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ExpatPersonalInformationComponent>,
    public dictionariesService: DictionariesService
  ) {}

  ngOnInit() {
    this.setupForm();
    this.setupExpat();
    this.setFilteredCountries();
    this.filterCountries();
    this.isLoadingGeneral = false;

    this.filteredCities$ = combineLatest([
      this.cityFilterControl.valueChanges.pipe(
        startWith(""),
        debounceTime(400)
      ),
      this.form
        .get("birth_country")
        .valueChanges.pipe(
          startWith(this.form.get("birth_country").value),
          debounceTime(400)
        ),
    ]).pipe(
      switchMap(([city, country]) => {
        if (country) {
          return this.dictionariesService
            .cities(city, country)
            .pipe(map((res) => res.result));
        }
        return [];
      })
    );
  }

  public save() {
    this.triggerValidation();
    if (this.form.valid) {
      this.expat.expat_id = this.data.expat.id;
      if (
        this.form.controls.birth_date.value &&
        this.form.controls.birth_date.valid
      ) {
        this.expat.details.birth_date =
          this.dateManagementService.formatDateMaterialInput(
            this.form.controls.birth_date.value
          );
      } else {
        this.expat.details.birth_date = null;
      }

      this.expat.details.birth_city = this.form.controls.birth_city.value;
      this.expat.details.birth_country = this.form.controls.birth_country.value;
      this.expat.details.primary_language =
        this.form.controls.primary_language.value;
      this.expat.details.marital_status_id =
        this.form.controls.marital_status_id.value;
      this.expat.details.education_form_id =
        this.form.controls.education_form_id.value;

      // TO DO - this field shouldn't be here
      this.expat.first_name = this.data.expat.user.first_name;
      this.expat.last_name = this.data.expat.user.last_name;

      if (this.expat.details.manager_id === 0) {
        const { manager_id, ...rest } = this.data.expat.details;
        this.data.expat.details = rest;
      }

      this.dialogRef.close({ action: "save", expat: this.expat });
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  private filterCountries() {
    this.countryFilterControl.valueChanges.subscribe((inputValue) => {
      this.filteredCountries = this.data.countries.filter(
        (value) =>
          value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      );
    });
  }

  private triggerValidation() {
    this.form.markAsTouched();
  }

  private setupForm() {
    this.form = this.fb.group({
      birth_date: this.fb.control(
        this.setDate(this.data.expat.details.birth_date),
        [CustomValidators.futureDateValidator()]
      ),
      birth_city: this.fb.control(this.data.expat.details.birth_city),
      birth_country: this.fb.control(this.data.expat.details.birth_country),
      primary_language: this.fb.control(
        this.data.expat.details.primary_language
      ),
      marital_status_id: this.fb.control(
        this.data.expat.details.marital_status_id
      ),
      education_form_id: this.fb.control(
        this.data.expat.details.education_form_id
      ),
    });
  }

  private setFilteredCountries() {
    this.filteredCountries = this.data.countries;
  }

  private setDate(date: string) {
    if (date) {
      return new Date(date);
    }
    return null;
  }

  private setupExpat() {
    this.expat = this.data.expat;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
