<div class="office-list" *ngIf="countries$ | async as countries">
  <mat-expansion-panel #panel class="box">
    <mat-expansion-panel-header class="!p-0">
      <div
        class="box-header w-full"
        [ngClass]="{ 'border-none': !panel.expanded }"
      >
        <div class="box-title flex align-middle">
          {{ "LABEL.OFFICES" | translate }}
        </div>
        <div class="box-actions">
          <button
            class="btn-outline-none-primary"
            (click)="$event.stopPropagation(); addOrEdit(countries)"
          >
            <mat-icon svgIcon="plus-circle" class="text-icon"></mat-icon>
            {{ "LABEL.ADD" | translate }}
          </button>
        </div>
      </div>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="box-body">
        <div
          class="table-wrapper w-full shadow-none px-3"
          *ngIf="offices$ | async as offices"
        >
          <table mat-table [dataSource]="offices">
            <ng-container matColumnDef="id" [sticky]="true">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="text-right border-r border-r-gray-light w-1"
              >
                {{ "GENERALS.ID" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let office"
                class="text-right border-r border-r-gray-light text-secondary font-semibold"
              >
                #{{ office.id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.LABEL" | translate }}
              </th>
              <td mat-cell *matCellDef="let office">
                {{ office.label | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.ADDRESS" | translate }}
              </th>
              <td mat-cell *matCellDef="let office">
                {{ office.address | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.CITY" | translate }}
              </th>
              <td mat-cell *matCellDef="let office">
                {{ office.city | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="region">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.REGION" | translate }}
              </th>
              <td mat-cell *matCellDef="let office">
                {{ office.county | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="zip_code">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.ZIP_CODE" | translate }}
              </th>
              <td mat-cell *matCellDef="let office">
                {{ office.postalCode | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="country_code">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.COUNTRY" | translate }}
              </th>
              <td mat-cell *matCellDef="let office">
                {{ office.countryCode | country | async | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions" [stickyEnd]="true">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let office" class="text-right">
                <div class="box-actions">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="billingMenu"
                    class="btn-outline-none-primary items-end"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #billingMenu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      class="uppercase"
                      (click)="addOrEdit(countries, office)"
                    >
                      {{ "GENERALS.EDIT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      class="uppercase"
                      (click)="delete(office)"
                    >
                      {{ "GENERALS.DELETE" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="loading">
              <td
                mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="columns.length"
              >
                <app-loader-spinner-general
                  [loadingType]="LoadingType.TABLE"
                ></app-loader-spinner-general>
              </td>
            </ng-container>
            <ng-container matColumnDef="no-data">
              <td
                mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="columns.length"
              >
                <div
                  class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
                >
                  {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columns; sticky: true"
              class="cursor-pointer"
            ></tr>
            <tr
              mat-row
              class="cursor-pointer"
              *matRowDef="let offices; columns: columns"
            ></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="['no-data']"
              [hidden]="offices?.length"
            ></tr>
          </table>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
