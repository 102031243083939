import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";

import { PageData } from "@modules/shared/models/page.model";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";
import { TemplateMapperService } from "./template-mapper.service";
import {
  TemplateData,
  TemplateElement,
  TemplateSearchData,
  TemplateElementsSections,
} from "../models";

@Injectable()
export class TemplateService {
  templateId: string;
  templateWrapper: any;
  currentDocumentPage = 1;

  public items: TemplateData[];

  searchFilters = [];

  public listenForChanges = new Subject<any>();
  public listenForAnnotationsChanges = new Subject<any>();

  annotations: TemplateElement[] = [];

  public isTemplatePreview = false;

  constructor(
    private http: HttpClient,
    private templateMapperService: TemplateMapperService,
    private translate: TranslateService,
    protected alertService: AlertService
  ) {}

  public list(
    filters: Partial<TemplateSearchData>
  ): Observable<PageData<TemplateData>> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}templates`, {
        params: filters as any,
      })
      .pipe(
        map((response) => {
          const data = response;
          this.searchFilters = data.filters;
          data.items = this.templateMapperService.mapMany(data.items);
          return data;
        })
      );
  }

  private setArchivedStatus(item: TemplateData): Observable<string> {
    item.status = "draft";

    return this.http
      .put<any>(`${environment.gateway_endpoint}templates/${item.id}`, item)
      .pipe(map((response) => response));
  }

  public onChangeArchiveStatus(item: TemplateData, isArchive: boolean): void {
    item.archived = isArchive;
    this.setArchivedStatus(item).subscribe(() => {
      this.listenForChanges.next("");

      this.translate
        .get(isArchive ? "Template archived" : "Template unarchived")
        .subscribe((trans) => {
          this.alertService.success(trans);
        });
    });
  }

  public createUpdate(
    form: TemplateData,
    data?: any,
    id?: string
  ): Observable<TemplateData> {
    let uri = `${environment.gateway_endpoint}templates/`;
    let type = "post";
    if (data && id) {
      uri += `${id}`;
      type = "put";
    }

    return this.http[type]<any>(`${uri}`, form).pipe(map((result) => result));
  }

  uploadFiles(obj): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file && obj.file.length > 0) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    return this.http
      .post<any>(
        `${environment.gateway_endpoint}templates/file/upload`,
        formData
      )
      .pipe(map((response) => response));
  }

  public listElementsSections(): Observable<TemplateElementsSections[]> {
    return this.http
      .get<TemplateElementsSections>(
        `${environment.gateway_endpoint}elements/sections`
      )
      .pipe(
        map((response: TemplateElementsSections) => {
          return this.templateMapperService.prepareTemplateElements(response);
        })
      );
  }

  public getTemplateById(templateId: string): Observable<TemplateData> {
    return this.http
      .get<{ template: TemplateData }>(
        `${environment.gateway_endpoint}templates/${templateId}`
      )
      .pipe(
        map((response) => {
          return response.template;
        })
      );
  }

  public createElement(
    element: TemplateElementsSections
  ): Observable<TemplateElement> {
    const item: TemplateElement = {
      element_id: element.id,
      page: this.currentDocumentPage,
      name: element.slug,
      value: element.name,
      position: {
        x: 2,
        y: 2,
      },
      metadata: {},
      styles: {},
    };

    return this.http
      .post<TemplateElement>(
        `${environment.gateway_endpoint}templates/${this.templateId}/elements`,
        item
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateElement(element: TemplateElement): Observable<TemplateElement> {
    return this.http
      .put<TemplateElement>(
        `${environment.gateway_endpoint}templates/${this.templateId}/elements/${element.id}`,
        element
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public deleteElement(element: TemplateElement): Observable<TemplateElement> {
    return this.http
      .delete<TemplateElement>(
        `${environment.gateway_endpoint}templates/${this.templateId}/elements/${element.id}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
