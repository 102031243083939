<div class="flex items-center pt-2 pb-4 gap-2 border-b">
  <mat-form-field class="w-full" subscriptSizing="dynamic">
    <mat-label>{{ "Search quote" }}</mat-label>
    <input
      type="text"
      placeholder="Pick one"
      aria-label="Number"
      matInput
      [formControl]="autocompleteControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      [displayWith]="displayFn"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <mat-option
        [value]="item"
        *ngFor="let item of items$ | async"
        (onSelectionChange)="selectItem(item)"
      >
        {{ displayFn(item) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button
    class="btn-outline-primary"
    [disabled]="!selectedItem$.getValue()"
    (click)="addItem(selectedItem$.getValue())"
  >
    <mat-icon svgIcon="plus-circle"></mat-icon>
    {{ "Add to Order" }}
  </button>
</div>
<div class="items">
  <div class="block max-h-72 overflow-y-auto pe-2">
    <div
      class="quote-cart-item w-full grid border-b border-gray-200 py-2 ps-2 gap-4 items-center"
      *ngFor="let item of itemControls"
      [formGroup]="item"
    >
      <span class="grow">{{ item.controls.name.value }}</span>
      <app-quantity-input
        formControlName="quantity"
        subscriptSizing="dynamic"
        class="mb-1"
      ></app-quantity-input>
      <div>
        <div class="text-right">
          <span class="block text-secondary lowercase text-xs">
            {{
              item.controls.unit_price.value
                | currency : item.controls.currency.value
            }}
            / {{ "LABEL.UNIT" | translate }}
          </span>
          <span class="block font-bold">
            {{
              item.controls.price.value
                | currency : item.controls.currency.value
            }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="cart-total flex py-2 ps-2 font-bold items-center"
    *ngIf="total$ | async as total"
  >
    <span class="grow">
      {{ "GENERALS.TOTAL" | translate }}
    </span>
    <span class="text-lg">
      {{ total.amount | currency : total.currency }}
    </span>
  </div>
</div>
