import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DocumentLinkEntityTypeEnum } from "@api/documents/enums/document-link-entity-type.enum";
import { DocumentLink } from "@api/documents/models/document-link.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { LinkComponent } from "@modules/documents/components/link/link.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, combineLatest, switchMap, takeUntil } from "rxjs";
import { AlertService } from "@modules/alert";

@Component({
  selector: "app-document-linked-with",
  templateUrl: "./document-linked-with.component.html",
  styleUrls: ["./document-linked-with.component.scss"],
})
export class DocumentLinkedWithComponent implements OnInit, OnDestroy {
  @Input() public docId: number;
  @Input() public entityType$: Observable<string>;
  @Input() public entityId$: Observable<number>;

  public items$: Observable<DocumentLink[]>;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public isLoading: boolean = false;
  public isLoadingAddButton: boolean = false;

  constructor(
    private documentService: DocumentService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private alertService: AlertService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public init(): void {
    this.isLoading = true;

    this.items$ = this.documentService.linkedList(this.docId);

    this.items$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => (this.isLoading = false));
  }

  public ngOnInit(): void {
    this.init();
  }

  public add(): void {
    this.isLoadingAddButton = true;

    const data$ = combineLatest([this.entityId$, this.entityType$]).pipe(
      switchMap(([entityId, entityType]) =>
        this.dialog
          .open(LinkComponent, {
            data: {
              source: entityType,
              sourceId: entityId,
              docIds: this.docId,
            },
          })
          .afterClosed()
      )
    );

    data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: { reason: string }) => {
        this.isLoadingAddButton = false;

        if (res?.reason === "save") {
          this.init();
        }
      });
  }

  public unlink(id: number): void {
    this.documentService
      .unlink(this.docId, id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translateService.instant("DOCUMENTS.SUCCESSFULLY-UNLINKED")
          );

          this.init();
        },
        error: (error) => {
          this.alertService.errors(error.error.errors);
        },
      });
  }

  public get EntityTypeEnum(): typeof DocumentLinkEntityTypeEnum {
    return DocumentLinkEntityTypeEnum;
  }

  public resolveResourcePageUrl(item: DocumentLink): string {
    if (item.resource_type === DocumentLinkEntityTypeEnum.CASE) {
      return `cases/${item.resource_id}`;
    }

    if (item.resource_type === DocumentLinkEntityTypeEnum.TASK) {
      return `cases/${item.resource_data.case_id}/task/${item.resource_id}`;
    }

    if (item.resource_type === DocumentLinkEntityTypeEnum.CLIENT) {
      return `clients/${item.resource_id}`;
    }
  }

  public canViewRow(item: DocumentLink): boolean {
    if (Array.isArray(item.resource_data)) {
      return item.resource_data.length !== 0;
    }

    return true;
  }

  public get LoadingTypeEnum(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
