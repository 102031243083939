<div [formGroup]="form">
  <h6 *ngIf="isReview" class="mt-3 font-semibold">
    {{ "assignments.ESSENTIAL_INFORMATION" | translate }}
  </h6>
  <div class="field-grid pt-3">
    <!-- select expat -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "GENERALS.SELECT-EXPAT" | translate }}</mat-label>
      <mat-select
        formControlName="expat_id"
        [placeholder]="'GENERALS.SELECT-EXPAT' | translate"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'GENERALS.SELECT-EXPAT' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="expatFilterControl"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let expat of filteredExpats" [value]="expat.id">
          {{ expat.name }}
        </mat-option>
      </mat-select>
      <mat-error formControlError="expat_id"></mat-error>
    </mat-form-field>
    <!-- select expat -->

    <!-- assignment type -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ "assignments.headers.TYPE" | translate }} </mat-label>
      <mat-select
        formControlName="type"
        disableOptionCentering
        panelClass="create-assignemt-panel"
      >
        <mat-option [value]="type.id" *ngFor="let type of types$ | async">
          {{ type.text }}
        </mat-option>
      </mat-select>
      <mat-error formControlError="type"></mat-error>
    </mat-form-field>
    <!-- assignment type -->

    <!-- start date -->
    <div class="w-full">
      <app-date-range-calendar
        placeholder="{{ 'GENERALS.START-DATE' | translate }}"
        (change)="onCalendarChangeStartDate($event)"
        [singleDate]="startDate"
        [control]="form.get('start_date')"
        [maxDate]="endDate"
      ></app-date-range-calendar>
    </div>
    <!-- start date -->

    <!-- end date -->
    <div class="w-full">
      <app-date-range-calendar
        [placeholder]="
          ('GENERALS.END-DATE' | translate) + (isPermanentTransfer ? '' : ' *')
        "
        (change)="onCalendarChangeEndDate($event)"
        [singleDate]="endDate"
        [control]="form.get('end_date')"
        [minDate]="startDate"
        [isDisabled]="
          form.get('type').value === AssignmentTypeEnum.PERMANENT_TRANSFER
        "
      ></app-date-range-calendar>
    </div>
    <!-- end date -->

    <!-- home country -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label
        >{{ "assignments.headers.home-country" | translate }}
      </mat-label>
      <mat-select
        formControlName="home_country"
        [placeholder]="'LABEL.COUNTRY' | translate"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.COUNTRY' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="homeCountriesFilterControl"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let country of filteredHomeCountries"
          [value]="country.country_code"
        >
          {{ country.name }}
        </mat-option>
      </mat-select>
      <mat-error formControlError="home_country"></mat-error>
    </mat-form-field>
    <!-- home country -->

    <!-- home city -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-select
        formControlName="home_city"
        placeholder="{{ 'LABEL.HOME-CITY' | translate }} *"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.HOME-CITY' | translate"
            [formControl]="searchHomeCitiesControl"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let city of homeCities" [value]="city.name">
          {{ city.name }}
        </mat-option>
      </mat-select>
      <mat-error formControlError="home_city"></mat-error>
    </mat-form-field>
    <!-- home city -->

    <!-- host country -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label
        >{{ "assignments.headers.host-country" | translate }}
      </mat-label>
      <mat-select
        formControlName="host_country"
        placeholder="{{ 'LABEL.COUNTRY' | translate }}"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.COUNTRY' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="hostCountriesFilterControl"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let hostCountry of filteredHostCountries"
          [value]="hostCountry.country_code"
        >
          {{ hostCountry.name }}
        </mat-option>
      </mat-select>
      <mat-error formControlError="host_country"></mat-error>
    </mat-form-field>
    <!-- host country -->

    <!-- host city -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-select
        formControlName="host_city"
        placeholder="{{ 'LABEL.HOST-CITY' | translate }} *"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.HOST-CITY' | translate"
            [formControl]="searchHostCitiesControl"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let city of hostCities" [value]="city.name">
          {{ city.name }}
        </mat-option>
      </mat-select>
      <mat-error formControlError="host_city"></mat-error>
    </mat-form-field>
    <!-- host city -->
  </div>
</div>
