import { Component, EventEmitter, Input, Output } from "@angular/core";
import { User } from "@models/user";
import { CompanyUserData } from "@api/entities/models/company-user.model";
import { MatDialog } from "@angular/material/dialog";
import { AddProfilePictureComponent } from "../add-profile-picture/add-profile-picture.component";
import { ExpatUserData } from "@models/expat";

@Component({
  selector: "app-user-profile-image",
  templateUrl: "./user-profile-image.component.html",
})
export class UserProfileImageComponent {
  @Input() user: User | CompanyUserData | ExpatUserData;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(protected readonly dialog: MatDialog) {}

  onChange(event) {
    this.change.emit(event);
  }

  onProfileImageError(event) {
    event.target.src = "../assets/images/profile-pics-placeholder.jpg";
  }

  changeAvatar() {
    this.dialog
      .open(AddProfilePictureComponent, {
        data: {
          user: this.user,
          entityType: "user",
          entityId: this.user?.id,
          title: "GENERALS.UPLOAD-PIC",
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (!result) {
          return;
        }
        this.onChange("newProfilePicture");
      });
  }
}
