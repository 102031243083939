<div class="create-assignment dialog p-0">
  <app-dialog-header>
    {{
      (edit ? "assignments.EDIT-ASSIGNMENT" : "assignments.CREATE-ASSIGNMENT")
        | translate
    }}
  </app-dialog-header>

  <div class="dialog-body">
    <app-loader-spinner-general
      *ngIf="!isLoaded"
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      *ngIf="!isLoaded"
      class="d-flex justify-content-end"
      [loadingType]="LoadingType.BUTTONS"
      [repeat]="2"
    ></app-loader-spinner-general>
    <form [formGroup]="form">
      <ng-container *ngIf="isLoaded">
        <mat-stepper
          [animationDuration]="'300'"
          [linear]="true"
          class="assignment-order"
          labelPosition="bottom"
          #stepper
          (selectionChange)="onStepChange($event)"
        >
          <mat-step>
            <ng-template matStepLabel>{{
              "assignments.ESSENTIAL_INFO" | translate
            }}</ng-template>
            <app-essential-info-step
              [form]="form"
              [user]="user"
              [types$]="types$"
              [modalData]="data"
            ></app-essential-info-step>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>{{
              "assignments.ADDITIONAL_DETAILS" | translate
            }}</ng-template>
            <app-additional-details-step
              [form]="form"
              [edit]="edit"
              [assignment]="assignment"
            ></app-additional-details-step>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>{{
              "assignments.REVIEW" | translate
            }}</ng-template>
            <!-- <ng-container *ngIf="stepper?.selectedIndex === 2"> -->
            <app-essential-info-step
              [form]="form"
              [user]="user"
              [types$]="types$"
              [isReview]="true"
              [modalData]="data"
            ></app-essential-info-step>
            <app-additional-details-step
              [form]="form"
              [isReview]="true"
            ></app-additional-details-step>
            <!-- </ng-container> -->
          </mat-step>
        </mat-stepper>
      </ng-container>
    </form>
  </div>
  <div
    class="dialog-footer"
    [ngClass]="{
      'dialog-footer-stepper':
        stepper?.selectedIndex === 1 || stepper?.selectedIndex === 2
    }"
  >
    <button
      *ngIf="stepper?.selectedIndex === 1 || stepper?.selectedIndex === 2"
      class="btn-outline-secondary float-left"
      (click)="prevStep()"
      [disabled]="!isLoaded"
    >
      {{ "GENERALS.BACK" | translate }}
    </button>

    <button
      *ngIf="stepper?.selectedIndex === 0 || stepper?.selectedIndex === 1"
      class="btn-primary"
      (click)="nextStep()"
      [disabled]="!isLoaded || !form.valid"
    >
      {{ "GENERALS.NEXT" | translate }}
    </button>

    <button
      class="btn-primary"
      *ngIf="stepper?.selectedIndex === 2"
      (click)="save()"
      [disabled]="!isLoaded"
    >
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
