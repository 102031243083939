import { Pipe, PipeTransform } from "@angular/core";
import { DifferencePipe } from "ngx-moment";
import * as moment from "moment";

@Pipe({
  name: "timeSince",
  standalone: true,
})
export class TimeSincePipe implements PipeTransform {
  constructor(private differencePipe: DifferencePipe) {}

  transform(value: any): string {
    if (!value) return "";

    const today = moment();
    const joinedAt = moment(value);

    if (joinedAt.isAfter(today)) {
      return "";
    }

    const years = Math.abs(
      this.differencePipe.transform(joinedAt, today, "years")
    );
    const months = Math.abs(
      this.differencePipe.transform(joinedAt, today, "months") % 12
    );
    const days = Math.abs(
      this.differencePipe.transform(joinedAt, today, "days")
    );

    const yearLabel = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "";
    const monthLabel =
      months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "";
    const dayLabel = days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "";

    if (yearLabel && monthLabel) {
      return `${yearLabel} and ${monthLabel}`;
    } else if (yearLabel) {
      return yearLabel;
    } else if (monthLabel) {
      return monthLabel;
    } else if (dayLabel) {
      return dayLabel;
    } else {
      return "Just now";
    }
  }
}
