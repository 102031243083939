import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { InvoiceExtraData } from "@models/invoice-extra-data";
import { ConfirmInvoicePayment } from "@models/confirm-invoice-payment";
import { DeleteOrderInvoice } from "@models/delete-order-invoice";
import { ValueList } from "@modules/shared/models/value-list.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

/**
 * @deprecated use instead src/app/api/documents/services/documents.service.ts
 */
@Injectable()
export class DocumentsService {
  private docsUrl = "files";
  private ordersUploadUrl = "billing/orders";
  private clientsUploadUrl = "entities/clients";
  private providersUploadUrl = "entities/providers";
  public docTypeInvoice = "invoices";

  constructor(private http: HttpClient) {}

  // GET docs by entity and ID
  getDocuments(
    entityType,
    entityId,
    docType,
    params: ValueList<any> = {}
  ): Observable<any> {
    if (!params.include) {
      params.include = "filters;details";
    }
    return this.http.get<any>(
      `${environment.gateway_endpoint}${entityType}/${entityId}/${this.docsUrl}/${docType}`,
      { params: params }
    );
  }

  // GET statuses for doc upload
  getStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "files/statuses");
  }

  // GET statuses for invoice upload
  getOrderInvoiceStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "invoice/order-invoice-statuses"
    );
  }

  // GET currencies for invoice upload
  getCurrencies(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/currencies"
    );
  }

  // Upload by doc type
  uploadFiles(
    id,
    fileType,
    obj,
    entityType,
    invoiceExtraData: InvoiceExtraData = null
  ): Observable<any> {
    let url;
    const formData: FormData = new FormData();

    if (obj.file && obj.file.length > 0) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }
    if (invoiceExtraData) {
      if (invoiceExtraData.invoice_amount) {
        formData.append(
          "invoice_amount",
          invoiceExtraData.invoice_amount.toString()
        );
      }
      if (invoiceExtraData.invoice_currency) {
        formData.append("invoice_currency", invoiceExtraData.invoice_currency);
      }
      if (invoiceExtraData.invoice_number) {
        formData.append("invoice_number", invoiceExtraData.invoice_number);
      }
      if (invoiceExtraData.invoice_due_date) {
        formData.append("invoice_due_date", invoiceExtraData.invoice_due_date);
      }
      if (invoiceExtraData.invoice_issue_date) {
        formData.append(
          "invoice_issue_date",
          invoiceExtraData.invoice_issue_date
        );
      }
      if (invoiceExtraData.invoice_status) {
        formData.append("invoice_status", invoiceExtraData.invoice_status);
      }
    }

    switch (entityType) {
      case "clients":
        url = this.clientsUploadUrl;
        break;
      case "providers":
        url = this.providersUploadUrl;
        break;
      case "orders":
        url = this.ordersUploadUrl;
        break;
      case "user-agreements":
        url = this.providersUploadUrl;
        break;
    }

    return this.http
      .post<any>(
        environment.gateway_endpoint + url + "/" + id + "/" + fileType,
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  uploadImage(id, fileType, obj, entityType, docId): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    if (docId) {
      formData.append("file_id", docId);
      formData.append("status", "updated");
    }

    const url = entityType + "/" + id + "/update-profile-picture";

    return this.http
      .post<any>(environment.gateway_endpoint + url, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadProfilePicture(id, obj, entityType): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    const url = entityType + "/" + id + "/update-profile-picture";

    return this.http
      .post<any>(environment.gateway_endpoint + url, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Get Provider User Agreements

  getUserAgreements(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.providersUploadUrl +
        "/" +
        id +
        "/user-agreements",
      {
        params: params,
      }
    );
  }

  // Archive Provider User Agreement
  archiveUserAgreements(entID, docId): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          this.providersUploadUrl +
          "/" +
          entID +
          "/user-agreements/" +
          docId
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Delete doc
  deleteDocument(id): Observable<any> {
    return this.http
      .delete<any>(environment.gateway_endpoint + "files/" + id, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  deleteOrderInvoice(fileId): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint + "invoices/" + fileId,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET statuses for invoice upload
  getFilterOrderInvoicesStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "invoice/filter-order-invoices-statuses"
    );
  }

  confirmPayment(
    confirmInvoicePayment: ConfirmInvoicePayment = null
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append("file_id", confirmInvoicePayment.file_id.toString());
    if (confirmInvoicePayment.payment_date) {
      formData.append("payment_date", confirmInvoicePayment.payment_date);
    }
    if (confirmInvoicePayment.payment_method) {
      formData.append("payment_method", confirmInvoicePayment.payment_method);
    }
    if (confirmInvoicePayment.transaction_id) {
      formData.append("transaction_id", confirmInvoicePayment.transaction_id);
    }

    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "invoice/provider-confirm-invoice-payment",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  confirmDeleteOrderInvoice(
    deleteOrderInvoice: DeleteOrderInvoice = null
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append("file_id", deleteOrderInvoice.file_id.toString());
    formData.append("reason", deleteOrderInvoice.reason);

    return this.http
      .post<any>(
        environment.gateway_endpoint + "invoice/delete-order-invoice",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
