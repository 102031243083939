import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CanViewDirective } from "./directives/can-view.directive";
import { CanViewAnyDirective } from "./directives/can-view-any.directive";

@NgModule({
  declarations: [CanViewDirective, CanViewAnyDirective],
  exports: [CanViewDirective, CanViewAnyDirective],
  imports: [CommonModule],
})
export class AuthorizationModule {}
