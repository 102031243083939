import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";
import { UntypedFormControl } from "@angular/forms";
import { CustomValidators } from "@modules/shared/_validators";
import moment from "moment";

@Component({
  selector: "app-confirm-action-modal",
  templateUrl: "./confirm-action-modal.component.html",
  styleUrls: ["./confirm-action-modal.component.scss"],
})
export class ConfirmActionModalComponent {
  reason = "";
  customInputCtrl = new UntypedFormControl("");
  customDateCtrl = new UntypedFormControl(
    "",
    CustomValidators.validDateValidator
  );
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<any>,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    const params = [];

    if (this.data.action?.input && this.customInputCtrl.getRawValue()) {
      this.customInputCtrl.markAllAsTouched();

      if (!this.customInputCtrl.valid) {
        return;
      }

      params["input"] = this.customInputCtrl.getRawValue();
    }

    if (this.data.action?.date && this.customDateCtrl.getRawValue()) {
      this.customDateCtrl.markAllAsTouched();

      if (!this.customDateCtrl.valid) {
        return;
      }
      params["date"] = moment(this.customDateCtrl.getRawValue()).format(
        "YYYY-MM-DD"
      );
    }

    if (this.data.action?.input || this.data.action?.date) {
      this.dialogRef.close(params);
      return;
    }

    if (this.data.action.needs_reason) {
      if (this.reason !== "" && this.reason !== undefined) {
        this.dialogRef.close(this.reason);
      } else {
        this.alertService.stringError(
          this.translateService.instant("ERROR.REASON-REQUIRED")
        );
      }
    } else {
      this.dialogRef.close("no-reason");
    }
  }
}
