<div class="contact-list" *ngIf="contacts$ | async as contacts">
  <mat-expansion-panel #panel class="box">
    <mat-expansion-panel-header class="!p-0">
      <div
        class="box-header w-full"
        [ngClass]="{ 'border-none': !panel.expanded }"
      >
        <div class="box-title flex align-middle">
          {{ "EXPATS.EMERGENCY-CONTACTS" | translate }}
        </div>
        <div class="box-actions">
          <button
            class="btn-outline-none-primary"
            (click)="$event.stopPropagation(); createContact()"
          >
            <mat-icon svgIcon="plus-circle" class="text-icon"></mat-icon>
            {{ "LABEL.ADD" | translate }}
          </button>
        </div>
      </div>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="box-body">
        <div class="w-full" *ngIf="isLoading">
          <app-loader-spinner-general
            *ngIf="isLoading"
            [loadingType]="LoadingType.INFO"
          ></app-loader-spinner-general>
        </div>

        <div class="table-wrapper w-full shadow-none px-3">
          <table mat-table [dataSource]="contacts">
            <ng-container matColumnDef="flag" [sticky]="true">
              <th mat-header-cell *matHeaderCellDef class="text-right w-1"></th>
              <td
                mat-cell
                *matCellDef="let contact"
                class="text-center text-primary"
              >
                <div class="flex items-center">
                  <mat-icon
                    svgIcon="star"
                    class="text-icon"
                    *ngIf="contact.isPrimary"
                    [matTooltip]="'LABEL.IS_PRIMARY' | translate"
                  ></mat-icon>
                  <mat-icon
                    svgIcon="warning"
                    class="text-icon"
                    *ngIf="contact.isEmergency"
                    [matTooltip]="'LABEL.IS_EMERGENCY' | translate"
                  ></mat-icon>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="id" [sticky]="true">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="text-right border-r border-r-gray-light w-1"
              >
                {{ "GENERALS.ID" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let contact"
                class="text-right border-r border-r-gray-light"
              >
                <div class="flex items-center">
                  <mat-icon
                    svgIcon="star"
                    class="text-icon text-primary"
                    *ngIf="contact.isPrimary"
                    [matTooltip]="'LABEL.IS_PRIMARY' | translate"
                  ></mat-icon>
                  <mat-icon
                    svgIcon="warning"
                    class="text-icon text-primary"
                    *ngIf="contact.isEmergency"
                    [matTooltip]="'LABEL.IS_EMERGENCY' | translate"
                  ></mat-icon>
                  <span class="text-secondary font-semibold">
                    #{{ contact.id }}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.LABEL" | translate }}
              </th>
              <td mat-cell *matCellDef="let contact">
                {{ contact.label | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="company_position">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.POSITION-IN-COMPANY" | translate }}
              </th>
              <td mat-cell *matCellDef="let contact">
                {{ contact.company_position | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.NAME" | translate }}
              </th>
              <td mat-cell *matCellDef="let contact">
                {{ contact.name | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.EMAIL" | translate }}
              </th>
              <td mat-cell *matCellDef="let contact">
                {{ contact.email | notAvailable }}
              </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.PHONE-NUMBER" | translate }}
              </th>
              <td mat-cell *matCellDef="let contact">
                {{ contact?.phone_prefix | phoneNumber : contact?.phoneNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>
                {{ "LABEL.ADDRESS" | translate }}
              </th>
              <td mat-cell *matCellDef="let contact">
                <ng-container *ngIf="contact.address">
                  {{ contact.address?.address }}, {{ contact.address?.city }},
                  {{ contact.address?.state }}, {{ contact.address?.postCode }},
                  {{ contact.address?.countryCode | country | async }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions" [stickyEnd]="true">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let contact" class="text-right">
                <div class="box-actions">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="contactMenu"
                    class="btn-outline-none-primary items-end"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #contactMenu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      class="uppercase"
                      (click)="editContact(contact)"
                    >
                      {{ "GENERALS.EDIT" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      class="uppercase"
                      (click)="deleteContact(contact)"
                    >
                      {{ "GENERALS.DELETE" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="loading">
              <td
                mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="columns.length"
              >
                <app-loader-spinner-general
                  [loadingType]="LoadingType.TABLE"
                ></app-loader-spinner-general>
              </td>
            </ng-container>
            <ng-container matColumnDef="no-data">
              <td
                mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="columns.length"
              >
                <div
                  class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
                >
                  {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columns; sticky: true"
              class="cursor-pointer"
            ></tr>
            <tr
              mat-row
              class="cursor-pointer"
              *matRowDef="let billingDetail; columns: columns"
            ></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="['loading']"
              [hidden]="!isLoading"
            ></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="['no-data']"
              [hidden]="contacts?.length || isLoading"
            ></tr>
          </table>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
