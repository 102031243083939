<div class="box on-time-vs-overdue">
  <div class="box-header">
    <div class="box-title">
      {{ "GENERALS.ON-TIME-OVERDUE-CASES" | translate }}
    </div>
  </div>

  <div class="box-body">
    <app-loader-spinner-general
      class="m-fix"
      *ngIf="isLoading"
      [loadingType]="LoadingType.PIE"
    ></app-loader-spinner-general>
    <section class="chart-wrapper" *ngIf="!isLoading">
      <ngx-charts-pie-chart
        [customColors]="pieChartCustomColors"
        [results]="
          this.chartItems.length > 0 ? this.chartItems : this.defaultChartData
        "
        [explodeSlices]="false"
        [doughnut]="true"
        [arcWidth]="0.15"
      >
      </ngx-charts-pie-chart>
      <p class="m-0 font-semibold text-sm">
        {{ totalCases }} {{ "LEFT-NAVIGATION.CASES" | translate }}
      </p>
    </section>
    <div class="info flex">
      <div class="calendar-section">
        <app-date-range-calendar
          [placeholder]="'GENERALS.SELECT-CUSTOM-PERIOD' | translate"
          (change)="onCalendarChange($any($event))"
          [dateRange]="dateRange"
          [multiple]="true"
        ></app-date-range-calendar>
        <mat-icon (click)="clearDate()" (keyup)="clearDate()">clear</mat-icon>
      </div>
      <div class="loading-wrapper" *ngIf="isLoading">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <ng-container *ngIf="!isLoading">
        <div
          [appStatusStyle]="box.label | lowercase"
          class="status mb-2.5 cursor-pointer"
          *ngFor="
            let box of this.chartItems.length > 0
              ? chartItems
              : this.defaultChartData
          "
          (click)="navigate(box.name)"
        >
          {{ box.label | titlecase }}:
          {{ this.chartItems.length > 0 ? box.value : 0 }}
          {{ "LEFT-NAVIGATION.CASES" | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
