import { DOCUMENT } from "@angular/common";
import {
  Component,
  Input,
  Inject,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@modules/alert";
import { Subject, takeUntil } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";

@Component({
  selector: "app-document-files",
  templateUrl: "./document-files.component.html",
  styleUrls: ["./document-files.component.scss"],
})
export class DocumentFilesComponent implements OnDestroy {
  @Input() documentData: DocumentData;
  @Output() filesUpdated: EventEmitter<void> = new EventEmitter<void>();

  public fileUploadControl: FileUploadControl = new FileUploadControl();

  private unsubscribe$: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = new UntypedFormGroup({
    files: new UntypedFormControl([], Validators.required),
  });

  constructor(
    private documentService: DocumentService,
    @Inject(DOCUMENT) private document: Document,
    private alertService: AlertService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public add(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.documentService
      .uploadFile(this.documentData.id, this.fileUploadControl.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ success }) => {
        if (success) {
          this.alertService.success(
            this.translateService.instant(
              "DOCUMENTS.FILES-UPLOADED-SUCCESSFULLY"
            )
          );

          this.filesUpdated.emit();
        }
      });
  }

  public download(fileId: number): void {
    this.documentService
      .downloadFile(this.documentData.id, fileId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ result }) => {
        window.open(new URL(result));
      });
  }

  public delete(id: number): void {
    const data$ = this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: {
            text: "GENERALS.DELETE",
            needs_confirmation: true,
            customText: "NOTIFICATIONS.DOC-DELETE",
          },
        },
      })
      .afterClosed();

    data$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res) {
        this.documentService
          .deleteFile(this.documentData.id, id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(({ success }) => {
            if (success) {
              this.alertService.success(
                this.translateService.instant(
                  "DOCUMENTS.FILE-DELETED-SUCCESSFULLY"
                )
              );

              this.filesUpdated.emit();
            }
          });
      }
    });
  }
}
