export enum PermissionResourceEnum {
  PROVIDER = "provider",
  SERVICE = "service",
  CLIENT = "client",
  ASSIGNMENT = "assignment",
  ORDER = "order",
  CASE = "case",
  RFQ = "rfq",
  REPORTS = "reports",
  CATEGORY = "category",
  AD = "ad",
  MESSAGE = "message",
  USER = "user",
  EXPAT = "expat",
  DOCUMENT = "document",
  TASK = "task",
  COMPANY_USER = "companyUser",
  MILESTONE = "milestone",
  FORM = "form",
}

export enum PermissionSecondaryResourceEnum {
  MILESTONES = "milestones",
  ASSIGNMENT = "assignment",
  DETAILS = "details",
  FINANCIAL = "financial",
  PERMISSIONS = "permissions",
  ACTIVITY = "activity",
  CONTACTS = "contacts",
  DEPENDENTS = "dependents",
  OFFICES = "offices",
  WORKFLOWS = "workflows",
  BILLING_DETAILS = "billingDetails",
  PROVIDERS = "providers",
}

export enum PermissionActionEnum {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  MANAGE = "manage",
  DOWNLOAD = "download",
}
