import { Injectable } from "@angular/core";

import { environment } from "@environment/environment";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ResetPassword } from "@models/reset-password";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AuthActions } from "@api/authentication";
import { AccountActions, AccountSelectors, UserData } from "@api/account";
import { filterNullish } from "../lib";

/**
 * @deprecated refactor in progress do no use or add any methods.
 */
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public currentUser: Observable<UserData>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private cookieService: CookieService
  ) {
    this.currentUser = this.store
      .select(AccountSelectors.selectUser)
      .pipe(filterNullish());
  }

  /**
   * Set headers for request
   * @deprecated
   */
  public setOptions() {
    const x_client_secret = btoa(
      environment.client_secret +
        ":" +
        environment.client_id +
        ":" +
        environment.role
    );

    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Client-Secret": x_client_secret,
      }),
    };
  }

  /**
   * Clear Storage
   * @deprecated
   */
  removeLocalStorage() {
    localStorage.removeItem(environment.token_key);
    localStorage.removeItem(environment.session_local_storage_key);
    localStorage.removeItem(environment.user_local_storage_key);
    localStorage.removeItem(environment.entity_local_storage_key);
  }

  /**
   * Clear Cookies
   * @deprecated
   */
  removeCookies() {
    this.cookieService.delete(
      environment.session_local_storage_key,
      "/",
      environment.domain
    );
    this.cookieService.delete(
      environment.user_local_storage_key,
      "/",
      environment.domain
    );
  }
  /**
   *
   * @deprecated
   */
  save(data) {
    if (data && data.user && data.session) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      const user: UserData = data.user;
      localStorage.setItem(
        environment.user_local_storage_key,
        JSON.stringify(user)
      );
      this.cookieService.set(
        environment.session_local_storage_key,
        JSON.stringify(data.session),
        5, // expires in 5 days
        "/",
        environment.domain
      );
      this.store.dispatch(AuthActions.authorized({ token: data.session }));
      this.store.dispatch(
        AccountActions.updateAccountDataSuccess({ user: data.user })
      );
    }
  }
  /**
   *
   * @deprecated
   */
  resetPassword(resetPassword: ResetPassword) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "resetPassword",
        resetPassword,
        this.setOptions()
      )
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          if (response && response.result.session.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const user: UserData = response.result.user;
            localStorage.setItem(
              environment.user_local_storage_key,
              JSON.stringify(user)
            );
            this.store.dispatch(
              AuthActions.authorized({ token: response.result.session.token })
            );
            this.store.dispatch(
              AccountActions.updateAccountDataSuccess({ user })
            );
            this.cookieService.set(
              environment.session_local_storage_key,
              JSON.stringify(response.result.session),
              5, // expires in 5 days
              "/",
              environment.domain
            );
          }

          return response;
        })
      );
  }

  sendResetPasswordEmail(email) {
    return this.http.post<any>(
      environment.gateway_endpoint + "users/password/request",
      { username: email }
    );
  }

  abort404() {
    this.router.navigate(["/not-found"]);
  }

  abort403() {
    this.router.navigate(["/forbidden"]);
  }

  resendConfirmationMail(id) {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "users/" +
          id +
          "/send-confirmation-mail",
        {}
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // mail confirmation
  mailConfirmation(e): any {
    return this.http
      .post<any>(environment.gateway_endpoint + "confirm-email", e)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
