import { Component } from "@angular/core";
import { Reports } from "@models/_menu/reports";
import { Router } from "@angular/router";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent {
  public menu: Reports;
  public hasShow: boolean = true;

  constructor(private router: Router) {
    this.menu = new Reports(1, []);
    this.hasShow = this.router.url.split("?")[0] !== "/reports/milestone";
  }
}
