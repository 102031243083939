import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { environment } from "@environment/environment";
import { ValueList } from "@modules/shared/models/value-list.model";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import _ from "lodash";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { MenuBadgesService } from "./menu-badges.service";

export interface AsideNavItemData {
  name: string;
  icon?: string;
  label: string;
  badge?: Observable<any>;
  routerLink: any[];
  canDisplay: Array<Observable<boolean>>;
  children: AsideNavItemData[];
  active: boolean;
  activeCheck: boolean;
}

export interface AsideNavListData {
  children: AsideNavItemData[];
}

@Injectable({
  providedIn: "root",
})
export class AsideNavigationService {
  private menu: AsideNavListData;
  private enabledPages: ValueList<boolean>;
  constructor(
    public router: Router,
    public auth: AuthorizationService,
    private badgesService: MenuBadgesService
  ) {
    this.enabledPages = environment.enabled_pages || {};
  }

  getFlattenLinks(): Array<AsideNavItemData> {
    const getNavItems = (member) => {
      if (!member.children || !member.children.length) {
        return member;
      }
      return [member, _.flatMapDeep(member.children, getNavItems)];
    };
    return _.flatMapDeep(this.getMenu().children, getNavItems);
  }

  getLinkByName(name: string) {
    return this.getFlattenLinks().find((link) => link.name === name);
  }
  getMenu(): AsideNavListData {
    this.badgesService.getAllCounters();

    if (this.menu) {
      return this.menu;
    }
    this.menu = {
      children: [
        {
          name: "dashboard",
          icon: "menu-icon-dashboard",
          label: "LEFT-NAVIGATION.DASHBOARD",
          routerLink: ["/dashboard"],
          canDisplay: [
            this.auth.$hasAnyResource([
              PermissionResourceEnum.REPORTS,
              PermissionResourceEnum.CASE,
              PermissionResourceEnum.RFQ,
              PermissionResourceEnum.TASK,
              PermissionResourceEnum.DOCUMENT,
              PermissionResourceEnum.SERVICE,
            ]),
          ],
          children: [],
          active: false,
          activeCheck: false,
        },
        {
          name: "root-service-offers-list",
          icon: "icon-services-comp",
          label: "SUB-NAVIGATION.MY-OFFERS",
          routerLink: ["/service-offers/list"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.SERVICE)],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "service-offers",
          icon: "menu-icon-service-offers",
          label: "LEFT-NAVIGATION.SERVICE-OFFERS",
          routerLink: ["/service-offers"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.SERVICE)],
          active: false,
          activeCheck: true,
          children: [
            {
              name: "sub-offers-list",
              label: "SUB-NAVIGATION.MY-OFFERS",
              routerLink: ["/service-offers/list"],
              canDisplay: [
                this.auth.$hasResource(PermissionResourceEnum.SERVICE),
              ],
              children: [],
              active: false,
              activeCheck: true,
            },
            {
              name: "sub-offers-sales",
              label: "SUB-NAVIGATION.SALES",
              routerLink: ["/service-offers/sales"],
              canDisplay: [
                this.auth.$hasResource(PermissionResourceEnum.SERVICE),
              ],
              children: [],
              active: false,
              activeCheck: true,
            },
          ],
        },
        {
          name: "clients",
          icon: "menu-icon-expats",
          label: "LEFT-NAVIGATION.CLIENTS",
          routerLink: ["/clients"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.CLIENT)],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "orders",
          icon: "menu-icon-orders",
          label: "LEFT-NAVIGATION.ORDERS",
          routerLink: ["/orders"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.ORDER)],
          badge: this.auth
            .$can(
              PermissionActionEnum.MANAGE,
              PermissionResourceEnum.ORDER,
              PermissionSecondaryResourceEnum.DETAILS
            )
            .pipe(
              filter((permission) => permission),
              switchMap(() => this.badgesService.ordersChanged.asObservable())
            ),
          children: [],
          active: false,
          activeCheck: true,
        },

        {
          name: "cases",
          icon: "menu-icon-cases",
          label: "LEFT-NAVIGATION.CASES",
          routerLink: ["/cases"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.CASE)],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "tasks",
          icon: "menu-icon-tasks",
          label: "LEFT-NAVIGATION.TASKS",
          routerLink: ["/tasks"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.TASK)],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "documents",
          icon: "menu-icon-documents",
          label: "LEFT-NAVIGATION.DOCUMENTS",
          routerLink: ["/documents"],
          canDisplay: [],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "templates",
          icon: "menu-icon-templates",
          label: "LEFT-NAVIGATION.TEMPLATE",
          routerLink: ["/templates"],
          canDisplay: [
            this.auth.$hasAnyResource([
              PermissionResourceEnum.TEMPLATE,
              PermissionResourceEnum.FORM,
            ]),
          ],
          children: [
            {
              name: "documents",
              label: "LEFT-NAVIGATION.DOCUMENTS",
              routerLink: ["/templates/documents"],
              canDisplay: [
                this.auth.$hasResource(PermissionResourceEnum.TEMPLATE),
              ],
              children: [],
              active: false,
              activeCheck: true,
            },
            {
              name: "forms",
              label: "LEFT-NAVIGATION.FORMS",
              routerLink: ["/templates/forms"],
              canDisplay: [this.auth.$hasResource(PermissionResourceEnum.FORM)],
              children: [],
              active: false,
              activeCheck: true,
            },
          ],
          active: true,
          activeCheck: true,
        },
        {
          name: "rfq",
          icon: "menu-icon-rfq-bids",
          label: "LEFT-NAVIGATION.RFQ",
          routerLink: ["/rfq"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.RFQ)],
          badge: this.auth
            .$can(
              PermissionActionEnum.MANAGE,
              PermissionResourceEnum.RFQ,
              PermissionSecondaryResourceEnum.PROVIDERS
            )
            .pipe(
              filter((permission) => permission),
              switchMap(() => {
                return this.badgesService.rfqChanged.asObservable();
              })
            ),
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "reports",
          icon: "menu-icon-reports",
          label: "LEFT-NAVIGATION.REPORTS",
          routerLink: ["/reports/financial"],
          canDisplay: [
            this.auth.$hasSecondaryResource(
              PermissionResourceEnum.REPORTS,
              PermissionSecondaryResourceEnum.FINANCIAL
            ),
          ],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "billing",
          icon: "menu-icon-billing",
          label: "LEFT-NAVIGATION.BILLING",
          routerLink: ["/billing/plans"],
          canDisplay: [
            combineLatest([
              this.auth.$hasSecondaryResource(
                PermissionResourceEnum.PROVIDER,
                PermissionSecondaryResourceEnum.FINANCIAL
              ),
              of(environment.has_crm),
              of(environment.has_marketplace),
            ]).pipe(
              map(
                ([resource, crm, marketplace]) =>
                  resource && crm && !marketplace
              )
            ),
          ],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "financial",
          icon: "menu-icon-financial",
          label: "LEFT-NAVIGATION.FINANCIAL",
          routerLink: ["/invoices"],
          active: false,
          activeCheck: true,

          canDisplay: [
            this.auth.$hasSecondaryResource(
              PermissionResourceEnum.PROVIDER,
              PermissionSecondaryResourceEnum.FINANCIAL
            ),
          ],
          children: [
            {
              name: "sub-financial-list",
              label: "SUB-NAVIGATION.INVOICES",
              routerLink: ["/invoices/list"],
              canDisplay: [
                this.auth.$hasSecondaryResource(
                  PermissionResourceEnum.PROVIDER,
                  PermissionSecondaryResourceEnum.FINANCIAL
                ),
              ],
              children: [],
              active: false,
              activeCheck: true,
            },
            {
              name: "sub-financial-commissions",
              label: "SUB-NAVIGATION.COMMISSIONS",
              routerLink: ["/invoices/commissions"],
              canDisplay: [
                this.auth.$hasSecondaryResource(
                  PermissionResourceEnum.PROVIDER,
                  PermissionSecondaryResourceEnum.FINANCIAL
                ),
              ],
              children: [],
              active: false,
              activeCheck: true,
            },
          ],
        },
        {
          name: "ads",
          icon: "menu-icon-ads",
          label: "SUB-NAVIGATION.AD-CAMPAIGNS",
          routerLink: ["/ads"],
          canDisplay: [
            this.auth.$hasResource(PermissionResourceEnum.AD),
            of(environment.unreleased_features),
          ],

          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "categories",
          icon: "menu-icon-categories",
          label: "LEFT-NAVIGATION.CATEGORIES-COMMISSIONS",
          routerLink: ["/categories"],
          canDisplay: [
            this.auth.$hasSecondaryResource(
              PermissionResourceEnum.PROVIDER,
              PermissionSecondaryResourceEnum.FINANCIAL
            ),
          ],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "roles-and-permissions",
          icon: "menu-icon-permissions",
          label: "LEFT-NAVIGATION.ROLES_AND_PERMISSIONS",
          routerLink: ["/roles"],
          canDisplay: [],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "company-users",
          icon: "menu-icon-users",
          label: "LEFT-NAVIGATION.USERS",
          routerLink: ["/company-users"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.USER)],
          children: [],
          active: false,
          activeCheck: true,
        },
        {
          name: "settings",
          icon: "menu-icon-settings",
          label: "LEFT-NAVIGATION.SETTINGS",
          routerLink: ["/settings"],
          canDisplay: [this.auth.$hasResource(PermissionResourceEnum.PROVIDER)],
          children: [],
          active: false,
          activeCheck: false,
        },
      ],
    };
    return this.menu;
  }

  isPageEnabled(page: string): Observable<boolean> {
    return of(
      this.enabledPages[page] === true || this.enabledPages[page] === undefined
    );
  }
}
