import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "@modules/alert";
import * as moment from "moment";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { ExpatDependent } from "@modules/expats/models/expat-dependents.model";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { TranslateService } from "@ngx-translate/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { DictionaryModel } from "@modules/shared/models/dictionary.model";
@Component({
  selector: "app-add-dependent-form",
  templateUrl: "./add-dependent-form.component.html",
  styleUrls: ["./add-dependent-form.component.scss"],
})
export class AddDependentForm implements OnInit, OnDestroy {
  public isLoadingGeneral;

  public dependent: ExpatDependent;

  public edit: boolean;
  public form!: UntypedFormGroup;
  public expat_id;
  public maxDate = new Date();

  public relationships$: Observable<DictionaryModel[]>;

  private subscriptions: Subscription[] = [];
  constructor(
    private translationService: TranslateService,
    private expatsService: ExpatsService,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<AddDependentForm>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.expat_id = data.expat_id;
    this.dependent = data.dependent ? data.dependent : null;
  }

  ngOnInit() {
    this.getDependent();
    this.setupForm();
    this.relationships$ = this.expatsService.getExpatDependentRelationships();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private setupForm() {
    this.form = this.fb.group({
      first_name: new UntypedFormControl(
        this.getFormControl("first_name"),
        Validators.required
      ),
      last_name: new UntypedFormControl(
        this.getFormControl("last_name"),
        Validators.required
      ),
      relationship: new UntypedFormControl(
        this.getFormControl("relationship"),
        Validators.required
      ),
      residence_country: new UntypedFormControl(
        this.getFormControl("residence_country"),
        Validators.required
      ),
      primary_citizenship: new UntypedFormControl(
        this.getFormControl("primary_citizenship")
      ),
      birth_date: new UntypedFormControl(
        this.getFormControl("birth_date"),
        Validators.required
      ),
      birth_country: new UntypedFormControl(
        this.getFormControl("birth_country")
      ),
      birth_city: new UntypedFormControl(this.getFormControl("birth_city")),
    });
  }

  private getFormControl(firstProperty: string) {
    if (this.dependent[firstProperty]) {
      return this.dependent[firstProperty];
    }
    return "";
  }

  private getDependent() {
    if (!this.dependent) {
      this.dependent = new ExpatDependent();
      this.edit = false;
    } else {
      this.edit = true;
    }
  }

  public submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.isLoadingGeneral = true;

    const params = {
      ...this.dependent,
      ...this.form.getRawValue(),
    };
    params.birth_date = moment(params.birth_date).format("Y/MM/DD");
    const request$ = this.edit
      ? this.expatsService.updateExpatDependents(
          params,
          this.expat_id,
          this.dependent.id
        )
      : this.expatsService.createExpatDependents(params, this.expat_id);

    request$.subscribe({
      next: (dependent) => {
        this.alertService.success(
          this.translate.instant("GENERALS.SAVED SUCCESSFULLY")
        );
        this.dialogRef.close({ dependent });
      },
      error: (error) => {
        if (error && error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoadingGeneral = false;
      },
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
