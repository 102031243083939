<div class="reminder p-0">
  <app-dialog-header>
    {{
      (data.edit ? "REMINDERS.EDIT-REMINDER" : "REMINDERS.ADD-REMINDER")
        | translate
    }}
  </app-dialog-header>

  <div mat-dialog-content class="dialog-body">
    <form [formGroup]="remindersForm">
      <!-- date -->
      <div class="flex flex-wrap w-full">
        <mat-form-field class="w-1/2 pr-3">
          <mat-label>
            {{ "REMINDERS.REMIND-AT" | translate }}
          </mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="picker"
            [placeholder]="'GENERALS.SELECT-DATE' | translate"
            formControlName="remind_at"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(picker)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker
            [showSpinners]="true"
            [showSeconds]="false"
            [stepHour]="1"
            [stepMinute]="15"
            [enableMeridian]="false"
            [hideTime]="false"
            [stepSecond]="0"
          >
            <ngx-mat-datepicker-actions>
              <button mat-button ngxMatDatepickerCancel>
                {{ "GENERALS.CANCEL" | translate }}
              </button>
              <button mat-raised-button color="primary" ngxMatDatepickerApply>
                {{ "LABEL.APPLY" | translate }}
              </button>
            </ngx-mat-datepicker-actions>
          </ngx-mat-datetime-picker>
        </mat-form-field>

        <!-- subscribers -->
        <mat-form-field
          appearance="outline"
          [formGroup]="remindersForm"
          class="w-1/2 flex items-center"
        >
          <mat-label> {{ "LABEL.RECIPIENTS" | translate }} </mat-label>
          <mat-select #select multiple formControlName="subscribers">
            <mat-select-trigger class="flex flex-wrap">
              <div *ngFor="let user of selectedUsers" class="chips">
                {{ user.first_name }} {{ user.last_name }}
                <button
                  class="flex items-center ml-1.5"
                  (click)="removeSelectedUser($event, user)"
                >
                  <mat-icon svgIcon="cancel" class="btn-icon"></mat-icon>
                </button>
              </div>
            </mat-select-trigger>
            <ng-container *ngIf="this.data.subjectType === 'task'">
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'NOTES.SEARCH-RECIPIENT' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="searchUserControl"
                ></ngx-mat-select-search>
              </mat-option>
              <app-loader-spinner-general
                *ngIf="isLoadingRecipients"
                [loadingType]="LoadingType.INPUT"
              ></app-loader-spinner-general>
            </ng-container>
            <mat-option
              class="chip-option"
              *ngFor="let user of users"
              [value]="user.id"
            >
              {{ user.first_name }}
              {{ user.last_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- end-subscribers -->
      </div>
      <!-- end-date -->

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ "NOTES.NOTE" | translate }}</mat-label>
        <textarea
          class="pb-0"
          matInput
          formControlName="text"
          rows="15"
          placeholder="{{ 'NOTES.ENTER-TEXT' | translate }}"
        >
        </textarea>
        <mat-error formControlError="text"> </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="dialog-footer">
    <button class="btn-primary" (click)="submit()" [disabled]="isLoading">
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
