import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { CreateQuoteOrderData } from "@modules/quotes/models/create-quote-order.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { AlertService } from "@services/alert.service";
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs";
import { CheckoutDataForm } from "./checkout-step/checkout-step.component";
import { CartItemForm } from "./items-step/items-step.component";
import { ClientData } from "@modules/clients/models/client.model";
import { ClientsService } from "@api/clients/services";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export interface CartItem {
  id: number;
  name: string;
  quantity: number;
  unit_price: number;
  price: number;
}

export interface CreateOrderComponentInput {}
@Component({
  templateUrl: "./create-order.component.html",
  styleUrls: ["./create-order.component.scss"],
})
export class CreateOrderComponent implements OnInit {
  @ViewChild("stepper")
  stepper: MatStepper;

  cartItemsForm: FormArray<CartItemForm> = new FormArray<FormGroup>(
    [],
    Validators.compose([Validators.required, Validators.minLength(1)])
  );
  checkoutForm: CheckoutDataForm = new FormGroup({
    billingAddressId: new FormControl(null, Validators.required),
    contactId: new FormControl(null, Validators.required),
  });

  orderForm = new FormGroup({
    items: this.cartItemsForm,
    checkout: this.checkoutForm,
  });

  clientsSearchControl = new FormControl("");
  selectedClientControl = new FormControl<ClientData | null>(null);
  selectedClient$ = new BehaviorSubject<ClientData | null>(null);
  clients$!: Observable<ClientData[]>;

  authService = inject(AuthorizationService);
  dialogData = inject(MAT_DIALOG_DATA);
  quoteService = inject(QuotesService);
  dialogRef = inject(MatDialogRef);
  alertService = inject(AlertService);
  clientsService = inject(ClientsService);
  destroyRef = inject(DestroyRef);
  isLoading: boolean = false;

  ngOnInit(): void {
    if (this.openedFrom === "orders") {
      this.initClientsSearchControl();

      this.selectedClientControl.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((client) => {
          this.selectedClient$.next(client);
          this.dialogData.client_id = client.client_id;
        });
    }
  }

  initClientsSearchControl() {
    this.clients$ = this.clientsSearchControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchText) =>
        this.clientsService
          .list(this.dialogData.provider_id, {
            search_text: searchText?.trim(),
          })
          .pipe(
            catchError(() => of({ items: [] })),
            map((response) => {
              let items = response.items || [];
              const selected = this.selectedClient$.value;

              if (
                selected &&
                !items.some((p) => p.client_id === selected.client_id)
              ) {
                items = [selected, ...items];
              }

              return items;
            })
          )
      ),
      takeUntilDestroyed(this.destroyRef),
      shareReplay(1)
    );
  }

  get clientId() {
    return this.dialogData.client_id;
  }

  get providerId() {
    return this.dialogData.provider_id;
  }

  get openedFrom() {
    return this.dialogData.openedFrom;
  }

  removeCartItem(index: number) {
    this.cartItemsForm.removeAt(index);
  }

  toCheckout() {
    this.stepper.next();
  }

  submit() {
    const data: CreateQuoteOrderData = {
      quotes: this.cartItemsForm.getRawValue().map((quote) => ({
        id: quote.id,
        quantity: quote.quantity,
      })),
      billing_address_id: this.checkoutForm.value.billingAddressId,
      contact_id: this.checkoutForm.value.contactId,
      client_id: this.clientId,
      provider_id: this.providerId,
      currency_code: this.cartItemsForm.value[0].currency,
    };
    this.isLoading = true;
    this.quoteService
      .order(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (order) => {
          this.dialogRef.close({
            order,
          });
          this.alertService.success("Order created successfully");
        },
        error: (message) => {
          this.alertService.error(message);
        },
      });
  }
}
