import { OrderStatusEnum } from "../../_enums/order-status-enum";

export class OrderStatuses {
  active = [
    OrderStatusEnum.NEW,
    OrderStatusEnum.ACCEPTED,
    OrderStatusEnum.IN_PROGRESS,
    OrderStatusEnum.AWAITING_PAYMENT,
  ];
}
