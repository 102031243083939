import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ExpatData } from "@models/expat";
import { AlertService } from "@modules/alert";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { DictionariesService } from "@services/dictionaries.service";
import { combineLatest, Observable, Subject } from "rxjs";
import {
  finalize,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from "rxjs/operators";

export interface AddExpatDialogComponentInput {
  expat?: ExpatData;
  clientId: number;
}
export interface AddExpatDialogComponentOutput {
  expat: ExpatData;
}

@Component({
  selector: "app-add-expat-dialog",
  templateUrl: "./add-expat-dialog.component.html",
  styleUrls: ["./add-expat-dialog.component.scss"],
})
export class AddExpatDialogComponent implements OnInit, OnDestroy {
  public data = inject<AddExpatDialogComponentInput>(MAT_DIALOG_DATA);
  public isLoading = false;

  public form = new FormGroup({
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    phone_prefix: new FormControl(""),
    phone_number: new FormControl(""),
    country_code: new FormControl("", Validators.required),
    payroll_id: new FormControl(""),
    department_name: new FormControl(""),
    company_position: new FormControl(""),
    primary_citizenship: new FormControl(""),
  });

  public unsubscribe$: Subject<void>;

  countries$: Observable<any[]>;

  phonePrefixSearchControl: UntypedFormControl;
  filteredPrefixes$: Observable<any[]>;

  constructor(
    private dictionariesService: DictionariesService,
    private expatsService: ExpatsService,
    private translate: TranslateService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<AddExpatDialogComponent>
  ) {}

  ngOnInit() {
    this.unsubscribe$ = new Subject<void>();

    this.patchForm();

    this.getCountries();
    this.initPhonePrefixControl();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getCountries() {
    this.countries$ = this.dictionariesService
      .getCountryList()
      .pipe(takeUntil(this.unsubscribe$), shareReplay(1));
  }

  submit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    const data = this.form.getRawValue();
    const request$ = this.edit
      ? this.expatsService.updateExpat({
          ...this.expat,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_prefix: data.phone_prefix,
          phone_number: data.phone_number,
        })
      : this.expatsService.createExpat({
          client_id: this.clientId,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          payroll_id: data.payroll_id,
          phone_prefix: data.phone_prefix,
          phone_number: data.phone_number,
          country_code: data.country_code,
          details: {
            department_name: data.department_name,
            company_position: data.company_position,
          },
        });

    request$
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (expat) => {
          this.alertService.success(
            this.translate.instant("GENERALS.SAVED SUCCESSFULLY")
          );
          this.dialogRef.close({ expat });
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  private initPhonePrefixControl() {
    this.phonePrefixSearchControl = new UntypedFormControl();

    this.countries$ = this.dictionariesService.getCountryList();
    this.filteredPrefixes$ = combineLatest([
      this.phonePrefixSearchControl.valueChanges.pipe(startWith("")),
      this.countries$,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([searchInput, prefixes]) => {
        if (!searchInput) {
          return prefixes;
        }

        const filterValue = searchInput.toLowerCase();

        return prefixes.filter(
          (prefix) =>
            prefix.phone_code.toLowerCase().includes(filterValue) ||
            prefix.name.toLowerCase().includes(filterValue)
        );
      }),
      shareReplay(1)
    );
  }

  get expat() {
    return this.data?.expat;
  }

  get edit() {
    return !!this.expat;
  }

  get clientId() {
    return this.data.clientId;
  }

  private patchForm() {
    if (!this.expat) {
      return;
    }

    this.form.patchValue({
      first_name: this.expat.user.first_name,
      last_name: this.expat.user.last_name,
      email: this.expat.user.email,
      phone_prefix: this.expat.user.phone_prefix,
      phone_number: this.expat.user.phone_prefix + this.expat.user.phone_number,
      country_code: this.expat.country_code,
      department_name: this.expat.details.department_name,
      company_position: this.expat.details.company_position,
      payroll_id: this.expat.payroll_id,
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
