import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { UserData } from "@api/account";
import { BillingDetails } from "@api/clients/models/billing-details.models";
import { ClientsService } from "@api/clients/services";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs";
export interface CheckoutData {
  billingAddressId: number;
  contactId: number;
}
export type ToFormGroupType<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K] | null>;
}>;

export type CheckoutDataForm = ToFormGroupType<CheckoutData>;
@Component({
  selector: "app-checkout-step",
  templateUrl: "./checkout-step.component.html",
  styleUrls: ["./checkout-step.component.scss"],
})
export class CheckoutStepComponent implements OnInit {
  @Input()
  clientId: number;

  @Input()
  checkoutForm: CheckoutDataForm;

  clientService = inject(ClientsService);
  changeDetectorRef = inject(ChangeDetectorRef);

  addresses$: Observable<BillingDetails[]>;

  users$: Observable<UserData[]>;

  userSearchControl: FormControl = new FormControl("");
  selectedContact$ = new BehaviorSubject<UserData>(null);

  ngOnInit() {
    this.addresses$ = this.clientService
      .listBillingAddress(this.clientId)
      .pipe(shareReplay(1));

    this.users$ = this.userSearchControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap((value) =>
        this.clientService
          .getUsers(this.clientId, { search_text: value })
          .pipe(catchError(() => of([])))
      ),
      map((users) => {
        if (this.selectedContact$.value) {
          const exists = !users.some(
            (user) => user.id === this.selectedContact$.value.id
          );
          if (exists) {
            return [this.selectedContact$.value, ...users];
          }
        }
        return users;
      }),
      shareReplay(1)
    );
  }
  get LoadingTypeEnum() {
    return LoadingTypeEnum;
  }

  selectionChanged(event: UserData) {
    this.selectedContact$.next(event);
    this.changeDetectorRef.markForCheck();
  }
}
