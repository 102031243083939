import { Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import { CreateExpatData, ExpatData, UpdateExpatData } from "@models/expat";
import { CitizenshipUpdateModel } from "@modules/expats/models/citizenship";
import { ExpatDependent } from "@modules/expats/models/expat-dependents.model";
import { DictionaryModel } from "@modules/shared/models/dictionary.model";
import { PageData } from "@modules/shared/models/page.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const expatsUrl = "entities/expats";
const dependentRelationshipUrl = "entities/directories/dependent/relationships";

@Injectable()
export class ExpatsService {
  public clientCanChangeExpatStatus = false;

  constructor(private http: BackendHttpClient) {}

  /**
   * List of expats for the clients
   * @param providerId
   * @param clientId
   * @param params
   */
  public list(providerId, clientId, params = {}): Observable<any> {
    return this.http.get<any>(
      `entities/providers/${providerId}/clients/${clientId}/expats`,
      { params: params }
    );
  }

  public getExpatsList(
    clientId: number,
    params = {}
  ): Observable<PageData<ExpatData>> {
    return this.http.get<PageData<ExpatData>>(
      expatsUrl + `/${clientId}/expats`,
      {
        params: params,
      }
    );
  }

  public getExpatById(id): Observable<ExpatData> {
    return this.http
      .get<DefaultResponseData<ExpatData>>(expatsUrl + "/" + id)
      .pipe(map((response) => response.result));
  }

  public getStatusTransitions(): Observable<any> {
    return this.http.get("entities/expats/status-transition");
  }

  public getProfilePicture(id) {
    return this.http.get("expat/" + id + "/show-profile-picture");
  }

  public createExpat(params: CreateExpatData): Observable<ExpatData> {
    return this.http
      .post<DefaultResponseData<ExpatData>>(expatsUrl, params)
      .pipe(map((response) => response.result));
  }

  // When client edits expat details
  public updateExpat(params: UpdateExpatData): Observable<ExpatData> {
    return this.http
      .put<DefaultResponseData<ExpatData>>(expatsUrl + "/" + params.id, params)
      .pipe(map((response) => response.result));
  }

  // When expat edit its own details
  public updateCurrentCompany(params): Observable<any> {
    return this.http
      .patch<any>(expatsUrl + "/update-current-company", params)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updateStatusBulk(status_id, expats_ids, reason = ""): Observable<any> {
    return this.http.patch<any>("entities/expats/updateStatus", {
      status_id: status_id,
      ids: expats_ids,
      declined_reason: reason,
    });
  }

  // GET Expat history
  public getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>("history/expat/" + id, { params: params });
  }

  public getMaritalStatus(params = {}): Observable<any> {
    return this.http.get<any>(expatsUrl + "/get-marital-statuses", {
      params: params,
    });
  }

  public getEducationForms(params = {}) {
    return this.http.get<any>(expatsUrl + "/get-education-forms", {
      params: params,
    });
  }

  public getCitizenships(expatId: number, params = {}): Observable<any> {
    return this.http.get<any>(expatsUrl + `/${expatId}/citizenships`, {
      params: params,
    });
  }

  public getCitizenshipById(
    expatId: number,
    citizenshipId: number,
    params = {}
  ): Observable<any> {
    return this.http.get<any>(
      expatsUrl + `/${expatId}/citizenships/${citizenshipId}`,
      {
        params: params,
      }
    );
  }

  public updateCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<any> {
    return this.http
      .put<any>(
        expatsUrl + `/${expatId}/citizenships/${citizenshipId}`,
        citizenshipUpdateModel
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updatePrimaryCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel = {}
  ): Observable<any> {
    return this.http
      .put<any>(
        expatsUrl + `/${expatId}/citizenships/${citizenshipId}/primary`,
        citizenshipUpdateModel
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public createCitizenship(
    expatId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<any> {
    return this.http
      .post<any>(expatsUrl + `/${expatId}/citizenships`, citizenshipUpdateModel)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public deleteCitizenship(
    expatId: number,
    citizenshipId: number
  ): Observable<any> {
    return this.http
      .delete<any>(expatsUrl + `/${expatId}/citizenships/${citizenshipId}`)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public getExpatContacts(
    clientId: number,
    expatId: number,
    params = {}
  ): Observable<any> {
    return this.http
      .get<any>(`entities/clients/${clientId}/expats/${expatId}/contacts`, {
        params: params,
      })
      .pipe(map((response) => response.result.items));
  }

  public createExpatContact(
    clientId: number,
    expatId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .post<any>(
        `entities/clients/${clientId}/expats/${expatId}/contacts`,
        expatContact
      )
      .pipe(map((response) => response.result));
  }

  public updateExpatContact(
    clientId: number,
    expatId: number,
    contactId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .put<any>(
        `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`,
        expatContact
      )
      .pipe(map((response) => response.result));
  }

  public deleteExpatContact(
    clientId: number,
    expatId: number,
    contactId: number
  ): Observable<any> {
    return this.http
      .delete<any>(
        `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`
      )
      .pipe(map((_) => _.result));
  }

  public getExpatDependents(
    expatId: number,
    params = {}
  ): Observable<ExpatDependent[]> {
    return this.http
      .get<any>(`entities/expats/${expatId}/dependents`, {
        params: params,
      })
      .pipe(map((response) => response.result.dependents));
  }

  public deleteExpatDependents(
    expatId: number,
    dependentId: number
  ): Observable<ExpatDependent> {
    return this.http
      .delete<any>(`entities/expats/${expatId}/dependents/${dependentId}`)
      .pipe(map((_) => _.result));
  }

  public createExpatDependents(
    params,
    expatId: number
  ): Observable<ExpatDependent> {
    return this.http
      .post<any>(`entities/expats/${expatId}/dependents`, params)
      .pipe(map((response) => response.result?.dependent));
  }

  public updateExpatDependents(
    params,
    expatId: number,
    dependentId: number
  ): Observable<ExpatDependent> {
    return this.http
      .put<any>(`entities/expats/${expatId}/dependents/${dependentId}`, params)
      .pipe(map((response) => response.result?.dependent));
  }

  public getExpatDependentRelationships(
    params = {}
  ): Observable<DictionaryModel[]> {
    return this.http
      .get<any>(dependentRelationshipUrl, {
        params: params,
      })
      .pipe(map((_) => _.result));
  }

  public inviteExpat(id) {
    return this.http.post<any>("entities/expats/invite", {
      expat_ids: id,
    });
  }

  // Delete Expat
  public deleteExpat(data: {
    expatId: number;
    reason: string;
  }): Observable<any> {
    return this.http
      .delete<any>(expatsUrl + "/" + data.expatId, {
        body: {
          reason: data.reason,
        },
      })
      .pipe();
  }
}
