import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ClientMapperService, ClientsService } from "@api/clients/services";
import { AuthorizationModule } from "@modules/authorization/authorization.module";
import { CasesListComponent } from "@modules/cases/cases-shared/cases-list/cases-list.component";
import { DocumentCaseFormComponent } from "@modules/cases/cases-shared/document-case-form/document-case-form.component";
import { TaskCreateComponent } from "@modules/cases/cases-shared/task-create/task-create.component";
import { SharedModule } from "@modules/shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { CasesFormsModule } from "../cases-forms/cases-forms.module";
import { AddFollowerComponent } from "./add-follower/add-follower.component";
import { AddServicesComponent } from "./add-services/add-services.component";
import { AdminCaseFormComponent } from "./admin-case-form/admin-case-form.component";
import { CreateComponent } from "./create/create.component";
import { ExpatCaseFormComponent } from "./expat-case-form/expat-case-form.component";
import { MyCasesButtonComponent } from "./my-cases-button/my-cases-button.component";

@NgModule({
  declarations: [
    CasesListComponent,
    CreateComponent,
    AddServicesComponent,
    MyCasesButtonComponent,
    AddFollowerComponent,
    AdminCaseFormComponent,
    ExpatCaseFormComponent,
    TaskCreateComponent,
    DocumentCaseFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CasesFormsModule,
    TranslateModule,
    SharedModule,
    AuthorizationModule,
  ],
  providers: [ClientsService, ClientMapperService],
  exports: [CasesListComponent, CreateComponent, AddFollowerComponent],
})
export class CasesSharedModule {}
