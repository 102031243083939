import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Setting, SettingOption } from "@api/setting/services/setting.service";

export interface SettingWithControl extends Setting {
  control: FormControl;
}

@Component({
  selector: "app-setting-input",
  templateUrl: "./setting-input.component.html",
  styleUrls: ["./setting-input.component.scss"],
})
export class SettingInputComponent implements OnInit {
  @Input()
  setting: SettingWithControl;

  formControl: FormControl;

  type: string;

  label: string;
  name: string;

  options: SettingOption[];

  ngOnInit(): void {
    this.formControl = this.setting.control;
    this.type = this.setting.annotations.inputType || "text";
    this.label = this.setting.label;
    this.name = this.setting.name;
    this.options = this.setting.annotations.options || [];
  }
}
