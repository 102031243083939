import { inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AccountSelectors } from "../selectors";
import { filter, map } from "rxjs";
import { UserStatus } from "@models/_statuses/user-statuses";

export const setupGuard: CanActivateChildFn = (route, state) => {
  const router = inject(Router);
  return inject(Store)
    .select(AccountSelectors.selectAccountState)
    .pipe(
      filter((state) => state.loaded),
      map((state) => {
        if (state.user.status_id == UserStatus.setup) {
          return router.createUrlTree(["/", "setup"]);
        }
        return true;
      })
    );
};
