import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import {
  CreateMilestoneData,
  MilestoneData,
  MilestonesMapperService,
} from "@api/milestones";
import { PageData } from "@modules/shared/models/page.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface MilestoneListingFilters {
  per_page: string | number;
  page: string | number;
  search_text?: string;
}

export interface RequestUpdateMilestonesOrder {
  id: number;
  order_index: number;
}

@Injectable()
export class MilestonesService {
  private serviceMilestoneUrl = "milestones/";

  constructor(
    private http: BackendHttpClient,
    private readonly milestonesMapperService: MilestonesMapperService
  ) {}

  public list(
    filters: Partial<MilestoneListingFilters>
  ): Observable<PageData<MilestoneData>> {
    return this.http
      .get<any>(this.serviceMilestoneUrl, {
        params: filters,
      })
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.milestonesMapperService.mapMany(data.items);
          return data;
        })
      );
  }

  public details(id): Observable<MilestoneData> {
    return this.http
      .get<any>(this.serviceMilestoneUrl + id)
      .pipe(
        map((response: any) =>
          this.milestonesMapperService.mapOne(response.result)
        )
      );
  }

  public update(input: Partial<MilestoneData>): Observable<MilestoneData> {
    const data = this.milestonesMapperService.prepare(input);
    return this.http
      .put<any>(this.serviceMilestoneUrl + input.id, data)
      .pipe(
        map((response: any) =>
          this.milestonesMapperService.mapOne(response.result)
        )
      );
  }

  public create(milestone: CreateMilestoneData) {
    return this.http.post<any>(this.serviceMilestoneUrl, milestone);
  }

  public delete(milestoneId: number) {
    return this.http.delete<any>(this.serviceMilestoneUrl + milestoneId);
  }

  public updateOrder(milestones: RequestUpdateMilestonesOrder[]) {
    return this.http.patch<any>(this.serviceMilestoneUrl + "order", {
      milestones,
    });
  }
}
