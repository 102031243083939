import { Component, OnInit } from "@angular/core";
import { ReportsCaseService } from "@modules/reports/services/reports-case.service";
import moment, { Moment } from "moment";
import { finalize, take } from "rxjs";
import { PieChartNameEnum } from "../../enums/pie-chart-names.enum";
import { ChartCardComponent } from "@modules/reports/reports-shared/components/chart-card/chart-card.component";
import { Router } from "@angular/router";
import { CaseStatusEnum } from "src/app/_enums/case-status-enum";
import { CaseTagEnum } from "src/app/_enums/case-tag-enum";

@Component({
  selector: "app-on-time-vs-overdue-cases",
  templateUrl: "./on-time-vs-overdue-cases.component.html",
  styleUrls: ["./on-time-vs-overdue-cases.component.scss"],
})
export class OnTimeVsOverdueCasesComponent
  extends ChartCardComponent
  implements OnInit
{
  readonly defaultChartData = [
    {
      name: PieChartNameEnum.DEFAULT,
      label: PieChartNameEnum.ON_TIME.split("_").join(" "),
      color: "#D0DAE4",
      value: 1,
    },
    {
      name: PieChartNameEnum.DEFAULT,
      label: PieChartNameEnum.OVERDUE,
      color: "#D0DAE4",
      value: 0,
    },
  ];

  constructor(
    private reportsCaseService: ReportsCaseService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.initEmptyPie();
  }

  onCalendarChange(dateRange: {
    start_date: Moment | string;
    end_date: Moment | string;
  }): void {
    let start_end_date_range = "";

    if (
      moment.isMoment(dateRange.start_date) &&
      moment.isMoment(dateRange.end_date)
    ) {
      start_end_date_range = `${dateRange.start_date.format(
        "Y/M/D"
      )};${dateRange.end_date.format("Y/M/D")}`;
    }

    this.isLoading = true;

    this.reportsCaseService
      .getFinishedCases({ start_end_date_range }, this.reload)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.reload = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.setValues(data);
        },
        error: () => {
          this.initEmptyPie();
        },
      });
  }

  navigate(name: string): void {
    const baseParams = this.getBaseNavParams();

    const caseParams =
      {
        "overdue cases": {
          tag: CaseTagEnum.OVERDUE,
          status: CaseStatusEnum.DONE,
        },
        "on time finished cases": { status: CaseStatusEnum.DONE },
      }[name] || {};

    const params = { ...baseParams, ...caseParams };
    const url = this.router
      .createUrlTree(["/cases"], { queryParams: params })
      .toString();

    window.open(url, "_blank");
  }
}
