<section class="box est-vs-actual">
  <div class="box-header">
    <div class="box-title">
      {{ "REPORTS-CASES.estimated_actual_process_duration" | translate }}
    </div>
  </div>

  <div class="box-body flex flex-wrap gap-4">
    <div class="chart-wrapper">
      <ngx-charts-pie-chart
        [customColors]="pieChartCustomColors"
        [results]="
          this.chartItems.length > 0 ? this.chartItems : this.defaultChartData
        "
        [explodeSlices]="false"
        [doughnut]="true"
        [arcWidth]="0.15"
      >
      </ngx-charts-pie-chart>
      <div class="center-text">
        <p
          class="m-0 font-semibold text-sm"
          [ngStyle]="{ color: pieNumber.color }"
          *ngFor="
            let pieNumber of this.chartItems.length > 0
              ? this.chartItems
              : this.defaultChartData
          "
        >
          {{
            pieNumber.name === PieChartName.ESTIMATED_VS_ACTUAL &&
            pieNumber.value > 0
              ? "+"
              : ""
          }}{{ chartItems.length > 0 ? pieNumber.value : 0 }}
          {{ "GENERALS.DAYS" | translate }}
        </p>
      </div>
    </div>
    <div class="info flex">
      <form [formGroup]="form">
        <div class="calendar-section flex items-center">
          <app-date-range-calendar
            [placeholder]="'GENERALS.SELECT-CUSTOM-PERIOD' | translate"
            (change)="onCalendarChange($any($event))"
            [dateRange]="dateRange"
            [multiple]="true"
          ></app-date-range-calendar>
          <mat-icon
            class="cursor-pointer"
            (click)="clearDate()"
            (keyup)="clearDate()"
            >clear</mat-icon
          >
        </div>
        <div class="service-select mb-4">
          <mat-form-field appearance="outline" class="w-full p-0">
            <mat-label>{{ "LABEL.HOST-COUNTRY" | translate }}</mat-label>
            <mat-select formControlName="country" disableOptionCentering>
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'EXPATS.COUNTRY' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="countrySearchFilterCtrl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option>{{
                "REPORTS-CASES.all_countries" | translate
              }}</mat-option>
              <mat-option
                *ngFor="
                  let country of filteredCountries$ | async;
                  trackBy: trackByItem
                "
                [value]="country.id"
              >
                {{ country.text | country | async }}
              </mat-option>
              <app-loader-spinner-general
                *ngIf="isLoading"
                [loadingType]="LoadingType.INPUT"
                [repeat]="5"
              ></app-loader-spinner-general>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="service-select my-4">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ "LABEL.MANAGER" | translate }}</mat-label>
            <mat-select
              formControlName="case_manager_id"
              disableOptionCentering
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'LABEL.MANAGER' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="managerSearchFilterCtrl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option>{{ "LABEL.ALL_MANAGERS" | translate }}</mat-option>
              <mat-option
                *ngFor="
                  let manager of filteredManagers$ | async;
                  trackBy: trackByItem
                "
                [value]="manager.id"
              >
                {{ manager.text }}
              </mat-option>
              <app-loader-spinner-general
                *ngIf="isLoading"
                [loadingType]="LoadingType.INPUT"
                [repeat]="5"
              ></app-loader-spinner-general>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="service-select my-4">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ "LABEL.CLIENT" | translate }}</mat-label>
            <mat-select formControlName="client_id" disableOptionCentering>
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'LABEL.CLIENT' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="clientSearchFilterCtrl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option>{{ "LABEL.ALL_CLIENTS" | translate }}</mat-option>
              <mat-option
                *ngFor="
                  let client of filteredClients$ | async;
                  trackBy: trackByItem
                "
                [value]="client.id"
              >
                {{ client.text }}
              </mat-option>
              <app-loader-spinner-general
                *ngIf="isLoading"
                [loadingType]="LoadingType.INPUT"
                [repeat]="5"
              ></app-loader-spinner-general>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="service-select mb-4">
          <mat-form-field appearance="outline" class="w-full p-0">
            <mat-label>{{ "GENERALS.SERVICES" | translate }}</mat-label>
            <mat-select
              formControlName="service_id"
              disableOptionCentering
              appInfiniteScrollDirective
              (infiniteScroll)="getNextBatch()"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'GENERALS.SERVICES' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="serviceSearchFilterCtrl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let service of filteredServices$ | async"
                [value]="service.service_id"
              >
                {{ service.service_name }}
              </mat-option>
              <app-loader-spinner-general
                *ngIf="isLoading"
                [loadingType]="LoadingType.INPUT"
                [repeat]="5"
              ></app-loader-spinner-general>
            </mat-select>
          </mat-form-field>
          <app-form-errors [control]="form.get('service_id')"></app-form-errors>
        </div>
      </form>
      <div class="loading-wrapper" *ngIf="isLoading">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <ng-container *ngIf="!isLoading">
        <div
          [appStatusStyle]="box.label | lowercase"
          class="status mb-2.5"
          *ngFor="
            let box of this.boxes.length > 0 ? boxes : this.defaultChartData
          "
        >
          {{
            (this.boxes.length > 0 ? "REPORTS-CASES." + box.name : box.label)
              | translate
              | titlecase
          }}:
          {{ this.boxes.length > 0 ? box.value : 0 }}
        </div>
      </ng-container>
    </div>
  </div>
</section>
