import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../shared/shared.module";
import { OfficeComponent } from "./components/office/office.component";
import { AuthorizationModule } from "@modules/authorization/authorization.module";
@NgModule({
  exports: [],
  providers: [],
  declarations: [OfficeComponent],
  imports: [CommonModule, SharedModule, AuthorizationModule],
})
export class ClientSharedModule {}
