<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- left -->
  <div *ngIf="!hideTitle" class="float-left big-title">
    {{ "LEFT-NAVIGATION.ORDERS" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="button-bar">
    <button class="btn-outline-primary" (click)="exportOrders()">
      <mat-icon svgIcon="export" class="btn-icon"></mat-icon>
      {{ "GENERALS.EXPORT" | translate }}
    </button>
    <button class="btn-primary" (click)="placeOrder()">
      <mat-icon svgIcon="cart" class="btn-icon"></mat-icon>
      {{ "GENERALS.PLACE-ORDER" | translate }}
    </button>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="float-left w-full advanced-search-wrapper p-0">
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="LEFT-NAVIGATION.ORDERS"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="openConfirmAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        [matSortActive]="active"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <ng-container matColumnDef="select">
              <th class="w-1">
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </th>
            </ng-container>
            <th
              mat-sort-header="identity_number"
              class="text-right border-r w-1"
            >
              {{ "GENERALS.ORDER-NO" | translate }}
            </th>
            <th mat-sort-header="client_name">
              {{ "GENERALS.CLIENT" | translate }}
            </th>
            <th mat-sort-header="amount" class="text-right">
              {{ "GENERALS.ORDER-VALUE" | translate }}
            </th>
            <th mat-sort-header="created_at" class="text-right">
              {{ "PORTAL.PLACED-ON" | translate }}
            </th>
            <th class="text-right">
              {{ "PORTAL.ASSIGNED-SERVICES" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr
            class="cursor-pointer"
            *ngFor="
              let order of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (keyup)="$event.stopPropagation()"
                (change)="$event ? changeSelection(order) : null"
                [checked]="selection.isSelected(order)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </td>
            <td class="text-right border-r">
              <a
                [routerLink]="['/orders', order.identity_number]"
                class="text-secondary font-semibold text-right"
              >
                #{{ order.identity_number }}</a
              >
            </td>
            <td [routerLink]="['/orders', order.identity_number]">
              {{ order.client.company_name }}
            </td>
            <td
              class="text-right"
              [routerLink]="['/orders', order.identity_number]"
            >
              {{ order.amount | currency : order.currency_code }}
            </td>
            <td
              class="text-right"
              [routerLink]="['/orders', order.identity_number]"
            >
              {{ order.placed_on | date : "dd/MM/yyyy" }}
            </td>
            <td
              class="text-right {{
                helper.remainingExpatsToAssign(order) > 0
                  ? 'text-primary'
                  : '!text-success'
              }}"
              [routerLink]="['/orders', order.identity_number]"
            >
              {{ helper.assignedExpatsOnOrder(order) }} /
              {{ helper.expatsOnOrder(order) }}
            </td>
            <td [routerLink]="['/orders', order.identity_number]">
              <div
                class="status"
                [appStatusStyle]="order.status_name | lowercase"
              >
                {{ order.status_name | uppercase }}
              </div>
            </td>

            <!-- table cel with action -->
            <td class="text-right">
              <div class="box-actions">
                <button
                  *ngIf="actions[order.status_id]?.length"
                  mat-icon-button
                  [matMenuTriggerFor]="orderMenu"
                  class="btn-outline-none-primary items-end"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #orderMenu="matMenu" xPosition="before">
                  <button
                    mat-menu-item
                    *ngFor="let status of actions[order.status_id]"
                    (click)="openConfirmAction(status, order)"
                  >
                    {{ status.text | uppercase }}
                  </button>
                </mat-menu>
              </div>
              <!-- end table action with overlay -->
            </td>
            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="100" class="text-secondary">
              <div class="notification_row bg_light_blue my-2.5 p-3">
                {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoading">
      <div
        class="resposive-table-wrapper cursor-pointer"
        *ngFor="
          let order of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-check">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? changeSelection(order) : null"
              [checked]="selection.isSelected(order)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </div>
          <div
            [routerLink]="['/orders', order.identity_number]"
            class="responsive-table-details"
          >
            <span class="btn-small orange-bg">{{
              "GENERALS.DETAILS" | translate
            }}</span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ORDER-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.identity_number }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.CLIENT" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.client.company_name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ORDER-VALUE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ order.amount | currency : order.currency_code }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "PORTAL.PLACED-ON" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ order.placed_on }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "PORTAL.DUE-BY" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ order.max_due_date }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "PORTAL.ASSIGNED-SERVICES" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ helper.assignedExpatsOnOrder(order) }} /
            {{ helper.expatsOnOrder(order) }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ order.status_id }}">{{
              order.status_name
            }}</span>
          </div>
        </div>

        <div
          *ngIf="
            actions[order.status_id] && actions[order.status_id].length !== 0
          "
          class="responsive-table-action"
        >
          <span
            *ngFor="let status of actions[order.status_id]"
            (click)="openConfirmAction(status, order)"
          >
            {{ status.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
