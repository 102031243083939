import { finalize } from "rxjs";
import { Component, DestroyRef, inject, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { UserData } from "@api/account";
import { environment } from "@environment/environment";
import { CalendarHelper } from "@helpers/calendar.helper";
import { QuoteModel } from "@modules/quotes/models/quote.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { AlertService } from "../../../../_services/alert.service";
import { UsersService } from "../../../../_services/users.service";
import { WindowResizeHelperService } from "../../../../_services/window-resize-helper.service";
import { CreateOrderComponent } from "../create-order/create-order.component";
import { CreateQuoteComponent } from "../create-quote/create-quote.component";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ColumnDefinition } from "src/app/_interfaces/column-definition.interface";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-quotes",
  templateUrl: "./quotes.component.html",
  styleUrls: ["./quotes.component.scss"],
})
export class QuotesComponent extends ItemsListComponent implements OnInit {
  @Input() public clientId: number;
  @Input() public orderId: number;
  @Input() public orderIdentityNumber: number;
  @Input() public clientName: string;
  @Input() public pageName: string;

  public isMobileDevice = false;
  public items: QuoteModel[];
  public user: UserData;
  public hasCrm = environment.has_crm;
  allSelectedItems: QuoteModel[] = [];

  protected columns = [
    {
      name: "select",
      label: "LABEL.SELECT",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
    {
      name: "id",
      label: "LABEL.ID",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
    {
      name: "offer_id",
      label: "LABEL.OFFER_ID",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "service_name",
      label: "LABEL.SERVICE_NAME",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "unit_price",
      label: "LABEL.UNIT_PRICE",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "offer_country",
      label: "LABEL.COUNTRY",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "expires_at",
      label: "LABEL.VALID_UNTIL",
      visible: true,
      readonly: false,
    } as ColumnDefinition,

    {
      name: "actions",
      label: "LABEL.ACTIONS",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
  ];

  protected destroyRef = inject(DestroyRef);

  constructor(
    private service: QuotesService,
    private windowResizeHelper: WindowResizeHelperService,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private alert: AlertService,
    public calendar: CalendarHelper,
    private matDialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isMobileDevice = data.responsiveStatus;
    });

    this.userService.getCurrentUser().subscribe((data) => {
      this.user = data;

      this.route.queryParams.subscribe((query) => {
        this.watchQueryParams(query);
      });
    });

    this.updateQueryParams();
  }

  private updateQueryParams(): void {
    this.router
      .navigate([], {
        queryParams: this.getParams(1),
        relativeTo: this.route,
        replaceUrl: true,
      })
      .then();
  }

  getItems() {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params: any = { ...this.filters };
    if (this.orderIdentityNumber) {
      params.order_id = this.orderIdentityNumber;
    }

    this.isLoading = true;

    this.request_call = this.service
      .list(this.user.entity_id, this.clientId, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.processResponse({ result: data });

          this.items.forEach((row) => {
            if (
              this.allSelectedItems.some(
                (selectedItem) => selectedItem.id === row.id
              )
            ) {
              this.selection.select(row);
            }
          });
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  changeSelection(item: any) {
    this.selection.toggle(item);

    const itemIndex = this.allSelectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    if (itemIndex === -1) {
      this.allSelectedItems.push(item);
    } else {
      this.allSelectedItems.splice(itemIndex, 1);
    }
  }

  toggleAllSelections(event: any) {
    if (!event) {
      return;
    }

    this.masterToggle();

    const checked = event.checked;

    if (checked) {
      this.allSelectedItems = [
        ...this.allSelectedItems,
        ...this.items.filter(
          (item) =>
            !this.allSelectedItems.some(
              (selectedItem) => selectedItem.id === item.id
            )
        ),
      ];
    } else {
      this.allSelectedItems = this.allSelectedItems.filter(
        (item) =>
          !this.items.some((selectedItem) => selectedItem.id === item.id)
      );
    }
  }

  selectionCleared() {
    this.allSelectedItems = [];
  }

  remove(quote: QuoteModel) {
    this.service
      .delete(this.user.entity_id, this.clientId, quote, this.orderId)
      .subscribe((data) => {
        this.alert.success(data.message);
        this.getItems();
      });
  }

  createOrEdit(quote = null) {
    this.matDialog
      .open(CreateQuoteComponent, {
        data: {
          quote,
          clientId: this.clientId,
          orderId: this.orderId,
          orderIdentityNumber: this.orderIdentityNumber,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result?.quote) {
          return;
        }
        this.getItems();
      });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        queryParams: params,
        relativeTo: this.route,
        replaceUrl: true,
      })
      .then();
  }

  placeOrder(quotes: QuoteModel[]) {
    if (quotes.length === 0) {
      this.alertService.stringError(
        this.translateService.instant("QUOTES.ORDER.ERROR-EMPTY")
      );
      return;
    }
    const today = moment();
    const expiredExists = quotes.some((item) =>
      moment(item.expires_at).isBefore(today)
    );
    if (expiredExists) {
      this.alertService.stringError(
        this.translateService.instant("QUOTES.ORDER.ERROR-EXPIRED")
      );
      return;
    }

    this.matDialog
      .open(CreateOrderComponent, {
        data: {
          quotes,
          client_id: this.clientId,
          provider_id: this.user.entity_id,
          openedFrom: "quotes",
        },
      })
      .afterClosed()
      .pipe(filterNullish())
      .subscribe((result) => {
        if (result.order) {
          this.router.navigate(["/orders", result.order.identity_number]);
        }
      });
  }
  export() {
    this.filters["provider_id"] = this.user.entity_id;
    this.filters["client_id"] = this.clientId;
    this.exportItems(this.clientName + " Service Quotes");
  }

  sendQuotesToClient() {
    if (!this.items.length) {
      this.alertService.stringError(
        this.translateService.instant(
          "PAGES.ORDERS.ORDER_QUOTES.NO_QUOTES_TO_SEND"
        )
      );

      return;
    }

    this.service
      .sendQuotesToClient(
        this.user.entity_id,
        this.clientId,
        this.orderIdentityNumber
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          this.alert.success(data.message);
        },
      });
  }

  protected getService() {
    return this.service;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
