<p class="mb-2">
  {{ setting.label }}
</p>

<ng-container [ngSwitch]="type">
  <mat-form-field *ngSwitchCase="'select'" subscriptSizing="dynamic">
    <mat-select [formControl]="formControl">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngSwitchDefault subscriptSizing="dynamic">
    <mat-label>{{ label }}</mat-label>
    <input matInput type="text" [name]="name" [formControl]="formControl" />
  </mat-form-field>
</ng-container>
