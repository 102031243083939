import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { OrderQuote } from "@models/order-quote";
import { AuthenticationService } from "./authentication.service";
import { ExpatAssignmentResponse } from "@models/expat-assignment-response";
import { OrderData } from "@api/billing";
import { BackendHttpClient } from "@api/http";

const expatsUrl = "entities/expats";
@Directive()
@Injectable()
export class OrdersService {
  private ordersUrl = "billing/orders";
  private orderUrlProviders = "billing/orders/providers";
  private orderHistory = "history/order";
  orderModel;
  public docTypeInvoice = "invoices";

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: BackendHttpClient,
    private auth: AuthenticationService
  ) {}

  getOrdersList(params: Record<string, any> = {}, id): Observable<any> {
    if (params.search_text == "") {
      delete params.search_text;
    }
    return this.http.get<any>(this.ordersUrl, {
      params: params,
    });
  }

  getOrderById(params, id): Observable<OrderData> {
    return this.http
      .get<any>(this.ordersUrl + "/" + id, params)
      .pipe(map((response: any) => response.result));
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      this.ordersUrl + "/status-transitions",
      this.auth.setOptions()
    );
  }

  getOrderByIdentityNumber(params, id): Observable<any> {
    return this.http
      .get<any>(this.ordersUrl + "/get-order-by-identity-number/" + id, params)
      .pipe(
        tap((data) => {
          this.orderModel = data.result;
        })
      );
  }

  /**
   * Get search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get("orders/search-fields");
  }

  /**
   * Get statuses
   */
  getStatuses(): Observable<any> {
    return this.http.get<any>("orders/statuses");
  }

  assignExpatAssignmentOnOrder(
    orderId,
    data
  ): Observable<ExpatAssignmentResponse> {
    return this.http.post<ExpatAssignmentResponse>(
      `${this.ordersUrl}/${orderId}/assignments`,
      { ...data, order_id: orderId }
    );
  }

  /**
   * Set delivery date on order for expats
   */

  assignOrderExpat(orderID, offerID, expatID, date?): Observable<any> {
    return this.http
      .patch<any>(
        this.ordersUrl +
          "/" +
          orderID +
          "/details/" +
          offerID +
          "/expats/" +
          expatID +
          "/dates",
        { delivery_date: date }
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateOrderDetails(orderID, params): Observable<any> {
    return this.http
      .put<any>(this.ordersUrl + "/details/" + orderID, params)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Status Bulk
  updateStatusBulk(status_id, order_ids, reason = ""): Observable<any> {
    let body: any = { status_id: status_id, order_ids: order_ids };
    if (reason) {
      body = {
        status_id: status_id,
        order_ids: order_ids,
        declined_reason: reason,
      };
    }
    const response = this.http.patch<any>(this.ordersUrl, body);

    this.statusChange.emit();
    return response;
  }

  deleteAssignedExpat(orderID, offerID, expatID): Observable<any> {
    return this.http
      .delete<any>(
        this.ordersUrl +
          "/" +
          orderID +
          "/details/" +
          offerID +
          "/expats/" +
          expatID
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getExpatById(id): Observable<any> {
    return this.http.get<any>(expatsUrl + "/" + id);
  }

  // GET Orders history
  getOrderHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(this.orderHistory + "/" + id, {
      params: params,
    });
  }

  /**
   * Export filtered orders to CSV
   * @param params
   */
  exportOrders(params = {}) {
    return this.http.get(this.ordersUrl + "/export/orders/", {
      responseType: "blob",
      params: params,
    });
  }

  /**
   * Get number of providers pending approval
   */
  getPendingCount(): Observable<any> {
    return this.http.get(this.ordersUrl + "/pending/count", {
      params: { "low-priority": "low-priority" },
    });
  }

  getRatingActivity(providerId: number, params = {}): Observable<any> {
    return this.http.get<any>(`entities/providers/${providerId}/ratings`, {
      params: params,
    });
  }

  /**
   * Add a new quote to the order
   * @param orderId
   * @param quote
   */
  createQuote(orderId, quote: OrderQuote): Observable<any> {
    return this.http.post(this.ordersUrl + `/${orderId}/quotes`, quote);
  }

  /**
   * List quotes for an order
   * @param orderId
   */
  listQuotes(orderId): Observable<any> {
    return this.http.get(this.ordersUrl + `/${orderId}/quotes`);
  }

  /**
   * Remove a quote from an order
   * @param orderId
   * @param quoteId
   */
  deleteQuote(orderId, quoteId): Observable<any> {
    return this.http.delete(this.ordersUrl + `/${orderId}/quotes/${quoteId}`);
  }

  updateOrderContact(orderId: number, contactId: number): Observable<any> {
    return this.http.patch<any>(`${this.ordersUrl}/${orderId}/contacts`, {
      contact_id: contactId,
    });
  }
}
