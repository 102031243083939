import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { ListComponent } from "./components/list/list.component";
import { EditDialogComponent } from "./components/edit-dialog/edit-dialog.component";
import { ContactDialogService } from "./services/contact-dialog.service";
import { EditComponent } from "./components/edit/edit.component";
import { AuthorizationModule } from "@modules/authorization/authorization.module";

@NgModule({
  declarations: [ListComponent, EditComponent, EditDialogComponent],
  exports: [ListComponent, EditDialogComponent],
  providers: [ContactDialogService],
  imports: [CommonModule, SharedModule, AuthorizationModule],
})
export class ContactsModule {}
