import { Component, Input, OnInit } from "@angular/core";
import { finalize, take } from "rxjs/operators";
import { ExpatData } from "@models/expat";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-expat-card",
  templateUrl: "./expat-card.component.html",
})
export class ExpatCardComponent implements OnInit {
  public expat: ExpatData;
  public isLoading: boolean = false;

  @Input() expatId: number;

  constructor(private service: ExpatsService) {}

  ngOnInit() {
    this.loadDetails();
  }

  loadDetails() {
    this.isLoading = true;

    this.service
      .getExpatById(this.expatId)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        this.expat = data;
      });
  }

  onChange(e) {
    if (e === "newProfilePicture") {
      this.loadDetails();
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
