import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Country } from "@models/country";
import { map, Observable, of, startWith, switchMap } from "rxjs";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-office",
  templateUrl: "./office.component.html",
})
export class OfficeComponent implements OnInit {
  public form = new FormGroup({
    id: new FormControl(""),
    legal_entity_id: new FormControl(""),
    label: new FormControl("", [Validators.required]),
    countryCode: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.required]),
    county: new FormControl("", [Validators.minLength(3)]),
    address: new FormControl("", [Validators.minLength(3)]),
    postalCode: new FormControl(""),
  });

  public countries!: Country[];
  public filteredCountries!: Country[];
  public countryFilterControl = new FormControl();
  public cityFilterControl = new FormControl();
  public cities$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<OfficeComponent>,
    private dictionariesService: DictionariesService
  ) {
    this.setupCountries();
  }

  public ngOnInit() {
    this.patchForm();
    this.filterCountries();
    this.filterCities();
  }

  private patchForm() {
    if (this.data.office) {
      this.form.patchValue(this.data.office);
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close({ office: this.form.value });
    }
  }

  private filterCountries() {
    this.countryFilterControl.valueChanges.subscribe((inputValue) => {
      this.filteredCountries = this.countries.filter(
        (value) =>
          value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      );
    });
  }

  private filterCities() {
    this.form.get("countryCode").valueChanges.subscribe(() => {
      this.cityFilterControl.setValue("");
    });

    this.cities$ = this.cityFilterControl.valueChanges.pipe(
      startWith(this.data.office?.city ?? ""),
      switchMap((search) => {
        if (this.form.get("countryCode").value) {
          return this.dictionariesService
            .cities(search, this.form.get("countryCode").value)
            .pipe(map((res) => res.result));
        }

        return of([]);
      })
    );
  }

  private setupCountries() {
    this.countries = this.data.countries.result;
    this.filteredCountries = this.data.countries.result;
  }
}
