import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { AlertService } from "@modules/alert";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { UsersService } from "src/app/_services/users.service";
import { UserData } from "@api/account";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { OrderData } from "@api/billing";
import { ExpatData } from "@models/expat";
import {
  AddExpatDialogComponent,
  AddExpatDialogComponentInput,
  AddExpatDialogComponentOutput,
} from "@modules/expats/expats-shared/components/add-expat-dialog/add-expat-dialog.component";

const validateExpat = function (ctrl: AbstractControl) {
  if (
    !([null, "new_expat"].includes(ctrl.value) || Number.isInteger(ctrl.value))
  ) {
    return {
      validateExpat: {
        valid: false,
      },
    };
  }
  return null;
};

export interface SelectedExpatsListItem {
  expat: number;
  assignment: number;
}

export interface ExpatListItem {
  id: number;
  name: string;
}

@Component({
  selector: "app-assign-expat-assignment-dialog",
  templateUrl: "./assign-expat-assignment-dialog.component.html",
  styleUrls: ["./assign-expat-assignment-dialog.component.scss"],
})
export class AssignExpatAssignmentDialogComponent implements OnInit, OnDestroy {
  order: OrderData;
  expatData: OrderData;
  expatsList: ExpatListItem[] = [];
  user: UserData;
  expat: ExpatData;

  public isLoading: boolean = false;

  expatControl = new FormGroup({
    expat: new UntypedFormControl(
      "",
      Validators.compose([validateExpat, Validators.required])
    ),
    assignment: new UntypedFormControl("", Validators.required),
    dependent: new UntypedFormControl(""),
  });

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AssignExpatAssignmentDialogComponent>,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private alertService: AlertService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.order = this.data.order;
    this.expat = this.data.orderExpat.expat;
    this.expatsList = [
      {
        id: this.expat.id,
        name: this.expat.name,
      },
    ];
    this.expatControl.controls.expat.setValue(this.expat.id);
    this.expatControl.controls.expat.disable();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: UserData) => (this.user = user));
  }

  getExpatList() {
    const params = { status_id: 3 };

    return this.expatsService
      .list(this.user.entity_id, this.order.client.id, params)
      .pipe(
        map((response) => {
          const expatsList = response.result.items.map((exp) => ({
            id: exp.id,
            name: exp.name,
          }));
          return expatsList;
        })
      );
  }

  public addExpat(): Observable<AddExpatDialogComponentOutput> {
    return this.dialog
      .open(AddExpatDialogComponent, {
        data: {
          clientId: this.order.client.id,
        } as AddExpatDialogComponentInput,
      })
      .afterClosed();
  }

  save(e): void {
    this.isLoading = true;
    const params: any = {};
    params.data = this.expatControl.getRawValue();
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
