import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

const statusClasses = {
  pending: ["bg-gray", "text-black"],
  expired: ["bg-gray", "text-black"],
  archived: ["bg-gray", "text-black"],
  deleted: ["bg-gray", "text-black"],
  draft: ["bg-gray", "text-black"],
  "pending approval": ["bg-gray", "text-black"],
  "on hold by client": ["bg-gray", "text-black"],
  "on hold by provider": ["bg-gray", "text-black"],
  active: ["bg-green"],
  completed: ["bg-green"],
  new: ["bg-green"],
  done: ["bg-green"],
  approved: ["bg-green"],
  open: ["bg-green"],
  "pending & active": ["bg-green"],
  finalized: ["bg-green-dark"],
  "published & active": ["bg-green-dark"],
  "in progress": ["bg-yellow"],
  "in renewal": ["bg-yellow"],
  "reminder set": ["bg-yellow"],
  in_progress: ["bg-yellow"],
  published: ["bg-yellow"],
  "awaiting payment": ["bg-yellow"],
  "pending & inactive": ["bg-yellow"],
  invited: ["bg-orange"],
  closed: ["bg-orange"],
  "published & inactive": ["bg-orange"],
  accepted: ["bg-gray-dark"],
  suspended: ["bg-gray-dark"],
  deactivated: ["bg-gray-dark"],
  cancelled: ["bg-gray-dark"],
  default: ["bg-gray-dark"],
  blocked: ["bg-gray-dark"],
  "not tracked": ["bg-gray-dark"],
  refused: ["bg-red"],
  overdue: ["bg-red"],
  rejected: ["bg-red"],
  "cancelled by client": ["bg-red"],
  "cancelled by provider": ["bg-red"],
  refunded: ["bg-blue"],
  extended: ["bg-blue"],
  invoiced: ["bg-green"],
  sent: ["bg-green"],
  "finished on time": ["bg-green"],
  "avg. actual duration": ["bg-green"],
  "avg. estimated duration": ["bg-yellow"],
  signed: ["bg-green"],
};

@Directive({
  selector: "[appStatusStyle]",
  standalone: true,
})
export class StatusStyleDirective implements OnChanges {
  @Input() appStatusStyle: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["appStatusStyle"]) {
      this.updateClass(
        changes["appStatusStyle"].currentValue,
        changes["appStatusStyle"].previousValue
      );
    }
  }

  private updateClass(newStatus: string, oldStatus: string): void {
    // remove styling for the old status
    if (oldStatus && statusClasses[oldStatus]) {
      statusClasses[oldStatus].forEach((status: string) => {
        this.renderer.removeClass(this.el.nativeElement, status);
      });
    }
    // add new styling
    if (newStatus && statusClasses[newStatus]) {
      statusClasses[newStatus].forEach((status: string) => {
        this.renderer.addClass(this.el.nativeElement, status);
      });
    }

    if (!statusClasses[newStatus]) {
      statusClasses.default.forEach((status: string) => {
        this.renderer.addClass(this.el.nativeElement, status);
      });
    }
  }
}
