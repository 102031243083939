import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AlertService } from "@modules/alert";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { OrdersService } from "src/app/_services/orders.service";
import { UsersService } from "src/app/_services/users.service";
import { UserData } from "@api/account";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { OrderData } from "@api/billing";
import { OrderService } from "@models/order";

const validateExpat = function (ctrl: AbstractControl) {
  if (
    !([null, "new_expat"].includes(ctrl.value) || Number.isInteger(ctrl.value))
  ) {
    return {
      validateExpat: {
        valid: false,
      },
    };
  }
  return null;
};

export interface SelectedExpatsListItem {
  expat: number;
  assignment: number;
}

export interface ExpatListItem {
  id: number;
  name: string;
}

@Component({
  selector: "app-assign-expat-dialog",
  templateUrl: "./assign-expat-dialog.component.html",
  styleUrls: ["./assign-expat-dialog.component.scss"],
})
export class AssignExpatDialogComponent implements OnInit, OnDestroy {
  order: OrderData;
  offer: OrderService;
  isAssigned = false;
  expatId: number;
  totalNoExpats: number;
  minDate: Date;
  expatsList: ExpatListItem[];
  selectedExpatsList: SelectedExpatsListItem[];
  user: UserData;

  public isLoading: boolean = false;

  expatControls = new UntypedFormArray([]);

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AssignExpatDialogComponent>,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private orderService: OrdersService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data.data !== undefined) {
      this.order = data.data.order || {};
      this.offer = data.data.offer || {};
    }
    this.offer = data.offer;
    this.order = data.order;
    this.totalNoExpats =
      this.offer.quantity - this.offer.expats.length > 0
        ? this.offer.quantity - this.offer.expats.length
        : 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: UserData) => (this.user = user));

    this.addControl();
    // fixme set loading=true until this finishes
    this.getExpatList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        this.expatsList = list;
        this.selectedExpatsList = this.data.offer.expats.map((expat) => ({
          expat: expat.expat_id,
          assignment: expat.assignment_id,
        }));
      });

    this.expatControls.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.selectedExpatsList = this.data.offer.expats.map((expat) => ({
          expat: expat.expat_id,
          assignment: expat.assignment_id,
        }));

        value.forEach((item) => {
          if (item.expat !== "") {
            this.selectedExpatsList.push(item);
          }
        });

        this.isAssigned = this.hasSelectedExpats();
      });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  getExpatList() {
    const params = { status_id: 3 };

    return this.expatsService
      .list(this.user.entity_id, this.order.client.id, params)
      .pipe(
        map((response) => {
          const expatsList = response.result.items.map((exp) => ({
            id: exp.id,
            name: exp.name,
          }));
          return expatsList;
        })
      );
  }

  private addControl() {
    this.expatControls.push(
      new UntypedFormGroup({
        expat: new UntypedFormControl(
          "",
          Validators.compose([validateExpat, Validators.required])
        ),
        assignment: new UntypedFormControl("", Validators.required),
        dependent: new UntypedFormControl(null),
      })
    );
  }

  addMoreExpats() {
    if (this.expatsRemaining() > 0) {
      this.addControl();
    }
  }

  showAlreadyAddedExpats() {
    return typeof this.order !== "undefined" && this.expatsRemaining() > 0;
  }

  expatsRemaining() {
    const selectedExpats = this.selectedExpatsCount();

    return this.totalNoExpats - selectedExpats;
  }

  private selectedExpatsCount() {
    return this.expatControls.controls.length;
  }

  private hasSelectedExpats() {
    return this.selectedExpatsCount() > 0;
  }

  save(): void {
    this.expatControls.markAllAsTouched();

    if (!this.expatControls.valid) {
      return;
    }

    this.isLoading = true;

    const params: any = {};
    params.data = this.expatControls.controls
      .filter((item: UntypedFormGroup) => item.valid)
      .map((item: UntypedFormGroup) => {
        const data = item.getRawValue();

        return {
          expat_id: data.expat,
          order_detail_id: this.offer.id,
          assignment_id: data.assignment,
          dependent_id: data.dependent,
        };
      });

    if (!params.data.length) {
      this.onNoClick();
      return;
    }
    this.orderService
      .assignExpatAssignmentOnOrder(this.order.id, params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.dialogRef.close("Successfully assigned");
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }

  public get expatControlsArr(): FormGroup[] {
    return this.expatControls.controls as FormGroup[];
  }
}
