import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { loadStripe, Stripe, StripeError } from "@stripe/stripe-js";
import { from, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import { StripeInvoice } from "@models/stripe-invoice";

@Injectable({
  providedIn: "root",
})
export class StripeService {
  stripe$: Observable<Stripe> = from(loadStripe(environment.stripe_public_key));
  private apiUrl = environment.gateway_endpoint;

  constructor(private http: HttpClient, protected route: ActivatedRoute) {}

  getSession(stripeInvoice: StripeInvoice): Observable<string> {
    return this.http
      .post<any>(
        `${this.apiUrl}/invoice/stripe/provider-pay-invoice`,
        stripeInvoice
      )
      .pipe(
        map((model) => {
          return model.result.sessionId;
        })
      );
  }

  redirectToCheckout(
    sessionId: string
  ): Observable<never | { error: StripeError }> {
    return this.stripe$.pipe(
      mergeMap((stripe: Stripe) => stripe.redirectToCheckout({ sessionId }))
    );
  }

  goToCheckout(stripeInvoice: StripeInvoice) {
    return this.getSession(stripeInvoice).pipe(
      mergeMap((sessionId: string) => this.redirectToCheckout(sessionId))
    );
  }

  confirmPayment(stripeSessionId): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/invoice/stripe/provider-confirm-payment`, {
        session_id: stripeSessionId,
      })
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getStripeSessionId() {
    return this.route.snapshot.queryParamMap.get("session_id");
  }
}
