import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import moment from "moment";
import { AlertService } from "@modules/alert";
import { DocumentsService } from "src/app/_services/documents.service";
import { InvoiceExtraData } from "@models/invoice-extra-data";
import { OrdersService } from "../../../_services/orders.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { finalize } from "rxjs";

interface AddDocComponentInput {
  entityType: string;
  entityId: number;
  docType: string;
}

@Component({
  selector: "app-add-doc",
  templateUrl: "./add-doc.component.html",
  styleUrls: ["./add-doc.component.scss"],
})
export class AddDocComponent implements OnInit {
  @Input() entityType;
  @Input() entityId;
  @Input() docType;

  @Output() changeDoc: EventEmitter<any> = new EventEmitter();

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  uploadedFiles = { file: null, name: null, status: null };
  statuses;
  invoiceExtraData = new InvoiceExtraData();
  public currencies = [];
  public orderInvoiceStatuses;

  sts = [{ id: 1, text: "signed" }];
  constructor(
    protected service: DocumentsService,
    private alertService: AlertService,
    public ordersService: OrdersService,
    protected readonly dialogRef: MatDialogRef<AddDocComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: AddDocComponentInput
  ) {
    this.setModalOpen = false;
    this.entityType = data.entityType;
    this.entityId = data.entityId;
    this.docType = data.docType;
  }

  ngOnInit() {
    this.getStatuses();
    this.getOrderInvoiceStatuses();
    this.getCurrencies();
  }

  limitFilesUpload(e) {
    if (e && e.length > 1) {
      this.uploadedFiles.file = [e[0]];
    }
  }

  getCurrencies() {
    this.service.getCurrencies().subscribe((res) => {
      this.currencies = res;
    });
  }

  getStatuses() {
    this.service.getStatuses().subscribe((res) => {
      this.statuses = res.result;
    });
  }

  getOrderInvoiceStatuses() {
    this.service.getOrderInvoiceStatuses().subscribe((res) => {
      this.orderInvoiceStatuses = res.result;
    });
  }

  uploadFile() {
    this.isLoading = true;

    this.service
      .uploadFiles(
        this.entityId,
        this.docType,
        this.uploadedFiles,
        this.entityType,
        {
          ...this.invoiceExtraData,
          invoice_due_date: this.getDateString(
            this.invoiceExtraData.invoice_due_date
          ),
          invoice_issue_date: this.getDateString(
            this.invoiceExtraData.invoice_issue_date
          ),
        }
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.changeDoc.emit();
          this.dialogRef.close();
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  setDate(date: any, propertyName: string): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.invoiceExtraData[propertyName] = moment(date);
  }

  getDateString(event) {
    const d = new Date(event);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
