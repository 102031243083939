import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from "@environment/environment";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { combineLatest, Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { UserStatus } from "@models/_statuses/user-statuses";
import {
  AsideNavigationService,
  AsideNavItemData,
  AsideNavListData,
} from "src/app/_services/aside-navigation.service";
import { UsersService } from "src/app/_services/users.service";
import { select, Store } from "@ngrx/store";
import { XpathState } from "../../../reducers";
import { AccountSelectors } from "@api/account";
import { filterNullish } from "../../../lib";
import { TaskStatusEnum } from "../../../_enums/task-status-enum";

@Component({
  selector: "app-left-navigation",
  styleUrls: ["./left-navigation.component.scss"],
  templateUrl: "./left-navigation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftNavigationComponent implements OnInit {
  urlMarketplace: string;
  public status$: Observable<boolean | any>;
  public menuList: AsideNavListData;

  private params = {
    page: 1,
    per_page: 25,
    search_fields: "",
  };

  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly asideMenu: AsideNavigationService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private userService: UsersService,
    private store: Store<XpathState>
  ) {
    this.urlMarketplace = environment.marketplace;
    this.status$ = this.userService
      .getCurrentUserStatus()
      .pipe(map((data) => data.result));
  }

  ngOnInit() {
    this.menuList = this.asideMenu.getMenu();
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        this.updateActiveMenuItems();
      });
    this.updateActiveMenuItems();
  }
  updateActiveMenuItems() {
    const isActive = (item: AsideNavItemData) => {
      try {
        const link = this.router.createUrlTree(item.routerLink);
        return this.router.url.indexOf(link.toString()) >= 0;
      } catch (e) {
        return false;
      }
    };
    this.menuList.children = this.menuList.children.map((item) => {
      item.active = isActive(item);
      item.children = item.children.map((sub) => {
        sub.active = isActive(sub);
        return sub;
      });
      return item;
    });
    this.changeDetectorRef.markForCheck();
  }

  goToMarketplace() {
    window.open(environment.marketplace + "home");
  }

  canDisplay(item: AsideNavItemData) {
    return combineLatest([
      this.asideMenu.isPageEnabled(item.name),
      ...item.canDisplay,
    ]).pipe(map((values) => !values.some((val) => !val)));
  }

  navigateTo(item: AsideNavItemData) {
    if (item.children && item.children.length) {
      return;
    }

    if (item.name === "tasks") {
      this.navigateToTasks(item, this.params);

      return;
    }

    this.navigate(item, this.params);
  }

  private navigateToTasks(item: AsideNavItemData, defaultParams: any) {
    const params = { ...defaultParams };

    this.store
      .pipe(select(AccountSelectors.selectUser), filterNullish(), first())
      .subscribe((user) => {
        params["assignee_ids"] = user["id"];

        params["status"] = [TaskStatusEnum.PENDING, TaskStatusEnum.IN_PROGRESS];

        this.navigate(item, params);
      });
  }

  private navigate(item: AsideNavItemData, params: any) {
    this.router
      .navigate(item.routerLink, {
        relativeTo: this.route,
        queryParams: item.routerLink[0] === "/dashboard" ? {} : params,
      })
      .then(() => {});
  }

  get hasCrm() {
    return environment.has_crm;
  }

  get UserStatus() {
    return UserStatus;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
