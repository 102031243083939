import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
} from "@angular/forms";
import { SubscriptSizing } from "@angular/material/form-field";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from "rxjs";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  selector: "app-date-range-calendar",
  templateUrl: "./date-range-calendar.component.html",
  styleUrls: ["./date-range-calendar.component.scss"],
})
export class DateRangeCalendarComponent
  implements OnInit, OnChanges, OnDestroy
{
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() control: UntypedFormControl | AbstractControl = new FormControl();
  @Input() isDisabled: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = "";
  @Input() multiple: boolean = false;
  @Input() singleDate: any = "";
  @Input() subscriptSizing: SubscriptSizing = "fixed";
  @Input() dateRange: {
    start_date: string | Date | moment.Moment;
    end_date: string | Date | moment.Moment;
  };
  @Input() visibleInput: boolean = true;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;

  private destroyed$ = new Subject<void>();
  dateChange = new Subject<{ inputValue: string; editDate: string }>();
  dateRangeMat: any = { start_date: "", end_date: "" };
  signleDateSub = new BehaviorSubject<moment.Moment>(this.singleDate);
  dateRangeSubStart = new BehaviorSubject<moment.Moment>(
    this.dateRangeMat.start_date
  );
  dateRangeSubEnd = new BehaviorSubject<moment.Moment>(
    this.dateRangeMat.end_date
  );

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  ngOnChanges(changes) {
    if (changes.dateRange?.currentValue === null) {
      this.dateRangeMat = {};
    } else {
      if (this.dateRange !== undefined) {
        this.dateRangeMat = this.dateRange;
      }
    }
  }
  get dateControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
  ngOnInit() {
    if (this.singleDate) {
      this.singleDate = moment(this.singleDate).toDate();

      this.signleDateSub.next(this.singleDate);
    }

    if (this.dateRange) {
      this.dateRangeMat = this.dateRange;

      this.dateRangeSubStart.next(moment(this.dateRange.start_date));
      this.dateRangeSubEnd.next(moment(this.dateRange.end_date));
    }

    this.dateChange
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(700),
        distinctUntilChanged()
      )
      .subscribe((change) => {
        let momentDate: moment.Moment = null;

        if (moment(change.inputValue, "D/M/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "D/M/YYYY", true);
        }

        if (moment(change.inputValue, "D/MM/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "D/MM/YYYY", true);
        }

        if (moment(change.inputValue, "DD/M/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "DD/M/YYYY", true);
        }

        if (moment(change.inputValue, "DD/MM/YYYY", true).isValid()) {
          momentDate = moment(change.inputValue, "DD/MM/YYYY", true);
        }

        if (momentDate) {
          switch (change.editDate) {
            case "single":
              this.signleDateSub.next(momentDate);
              break;
            case "start":
              this.dateRangeSubStart.next(momentDate);
              break;
            case "end":
              this.dateRangeSubEnd.next(momentDate);
              break;
          }
        }
      });

    this.dateRangeSubStart
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date: any) => {
        if (
          moment.isMoment(date) &&
          this.minDate &&
          !this.compareDates(moment(date).toDate(), this.minDate)
        ) {
          this.translateService
            .get("GENERALS.DATE-OUT-OF-RANGE")
            .subscribe((text: string) => {
              this.alertService.stringError(
                `${text} (${moment(this.minDate).format("DD/MM/YYYY")})`
              );
            });

          return;
        }

        if (date && moment.isMoment(date)) {
          this.onChangeStartDate(date.toDate());
        }
      });

    this.dateRangeSubEnd
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date: any) => {
        if (
          moment.isMoment(date) &&
          this.maxDate &&
          !this.compareDates(this.maxDate, moment(date).toDate())
        ) {
          this.translateService
            .get("GENERALS.DATE-OUT-OF-RANGE")
            .subscribe((text: string) => {
              this.alertService.stringError(
                `${text} (${moment(this.maxDate).format("DD/MM/YYYY")})`
              );
            });

          return;
        }

        if (date && moment.isMoment(date)) {
          this.onChangeEndDate(date.toDate());
        }
      });

    this.signleDateSub
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date: any) => {
        if (date && moment.isMoment(date)) {
          if (this.minDate) {
            if (!this.compareDates(moment(date).toDate(), this.minDate)) {
              this.translateService
                .get("GENERALS.DATE-OUT-OF-RANGE")
                .subscribe((text: string) => {
                  this.alertService.stringError(
                    `${text} (${moment(this.minDate).format("DD/MM/YYYY")})`
                  );
                });

              return;
            }
          }

          if (this.maxDate) {
            if (!this.compareDates(this.maxDate, moment(date).toDate())) {
              this.translateService
                .get("GENERALS.DATE-OUT-OF-RANGE")
                .subscribe((text: string) => {
                  this.alertService.stringError(
                    `${text} (${moment(this.maxDate).format("DD/MM/YYYY")})`
                  );
                });

              return;
            }
          }

          this.singleDate = date;
          this.onChangeSingleDate();
        }
      });
  }

  compareDates(newerDate: Date, olderDate: Date): boolean {
    if (moment(newerDate).year() > moment(olderDate).year()) {
      return true;
    }

    if (moment(newerDate).year() < moment(olderDate).year()) {
      return false;
    }

    if (moment(newerDate).month() > moment(olderDate).month()) {
      return true;
    }
    if (moment(newerDate).month() < moment(olderDate).month()) {
      return false;
    }

    if (moment(newerDate).date() > moment(olderDate).date()) {
      return true;
    }
    if (moment(newerDate).date() < moment(olderDate).date()) {
      return false;
    }

    return true;
  }

  onChangeStartDate(date: any): void {
    this.dateRangeMat.start_date = moment(date).startOf("day");
    this.emitChanges();
  }

  onChangeEndDate(date: any): void {
    this.dateRangeMat.end_date = moment(date).startOf("day");
    this.emitChanges();
  }

  onChangeSingleDate(): void {
    if (moment(this.singleDate).isValid()) {
      this.change.emit(this.singleDate);
    }
  }

  emitChanges(): void {
    if (this.dateRangeMat.start_date && this.dateRangeMat.end_date) {
      this.change.emit(this.dateRangeMat);
    }
  }

  validateInput(inputValue: string, editDate: string): void {
    if (inputValue) {
      this.dateChange.next({ inputValue, editDate });
    }
  }
}
