import { Pipe, PipeTransform } from "@angular/core";
import { DifferencePipe } from "ngx-moment";

@Pipe({
  name: "durationDifference",
})
export class DurationDifferencePipe implements PipeTransform {
  constructor(private differencePipe: DifferencePipe) {}

  transform(firstDate: string | Date, secondDate: string | Date): string {
    if (!firstDate || !secondDate) {
      return "";
    }

    const years = this.differencePipe.transform(firstDate, secondDate, "years");

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""}`;
    }

    const months = this.differencePipe.transform(
      firstDate,
      secondDate,
      "months"
    );

    if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""}`;
    }

    const days = this.differencePipe.transform(firstDate, secondDate, "days");

    return `${days} day${days > 1 ? "s" : ""}`;
  }
}
