<div
  class="box case-status-report w-full"
  [ngClass]="loadedFrom === 'dashboard' ? 'dashboad-box' : 'report-box'"
>
  <div *ngIf="loadedFrom !== 'dashboard'" class="box-header">
    <div class="box-title">
      {{ "LEFT-NAVIGATION.CASES" | translate }}
    </div>
  </div>

  <div
    class="box-body flex flex-wrap"
    [ngClass]="{
      '!pt-2 !pb-0': loadedFrom === 'dashboard',
      'gap-4': loadedFrom === 'reports'
    }"
  >
    <app-loader-spinner-general
      [loadingType]="LoadingType.PIE"
      *ngIf="isLoading"
      class="m-fix"
    ></app-loader-spinner-general>
    <div class="chart-wrapper" *ngIf="!isLoading">
      <ngx-charts-pie-chart
        [customColors]="pieChartCustomColors"
        [results]="chartItems"
        [explodeSlices]="false"
        [doughnut]="true"
        [arcWidth]="0.15"
      >
      </ngx-charts-pie-chart>
      <p class="m-0 font-semibold text-sm">
        {{ totalCases }} {{ "LEFT-NAVIGATION.CASES" | translate }}
      </p>
    </div>
    <div class="info flex">
      <div class="calendar-section">
        <app-date-range-calendar
          [placeholder]="'GENERALS.SELECT-CUSTOM-PERIOD' | translate"
          (change)="onCalendarChange($any($event))"
          [dateRange]="dateRange"
          [multiple]="true"
        ></app-date-range-calendar>
        <mat-icon (click)="clearDate()" (keyup)="clearDate()">clear</mat-icon>
      </div>
      <div class="loading-wrapper" *ngIf="isLoading">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </div>
      <ng-container *ngIf="!isLoading">
        <div
          [appStatusStyle]="box.label | lowercase"
          class="status mb-2.5 cursor-pointer"
          *ngFor="
            let box of this.chartItems.length > 0
              ? chartItems
              : this.defaultChartData
          "
          (click)="navigate(box.name)"
        >
          {{ box.label | titlecase }}:
          {{ this.chartItems.length > 0 ? box.value : 0 }}
          {{ "LEFT-NAVIGATION.CASES" | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
