import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../_services/alert.service";

@Component({
  selector: "app-alert",
  templateUrl: "alert.component.html",
  styleUrls: ["alert.component.scss"],
})
export class AlertComponent implements OnInit {
  messages: any;
  messagesType: any;
  isErrValidation: boolean;
  isSuccNotif: boolean;
  isErrNotif: boolean;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService.getMessage().subscribe((messages) => {
      if (!messages) {
        return;
      }

      this.isErrValidation = false;
      this.isSuccNotif = false;
      this.isErrNotif = false;

      this.messages = Array.isArray(messages.text)
        ? messages.text
        : [messages.text];
      this.messagesType = messages.type;

      if (messages.type === "error") {
        this.isErrValidation = true;
        setTimeout(() => {
          this.isErrValidation = false;
        }, 5000);
      } else if (messages.type === "success") {
        this.isSuccNotif = true;
        setTimeout(() => {
          this.isSuccNotif = false;
        }, 5000);
      } else if (messages.type === "string-error") {
        this.isErrNotif = true;
        setTimeout(() => {
          this.isErrNotif = false;
        }, 5000);
      }
    });
  }

  closeError(): void {
    setTimeout(() => {
      this.messages = "";
    }, 300);
    this.isErrValidation = false;
  }

  closeErrorNotification() {
    setTimeout(() => {
      this.messages = "";
      this.alertService.closeErrNotification(true);
    }, 300);
    this.isErrNotif = false;
  }
}
